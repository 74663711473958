import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/measures"

export default {

    getMeasures(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    createMeasure(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateMeasure(productID, data) {
        return apiCall('patch', `${PREFIX}/${productID}`, data)
    },

    deleteMeasure(productID) {
        return apiCall('delete', `${PREFIX}/${productID}`)
    }
}