import { apiCall } from "../imports"

export default {
    deleteMenuItem(menuID) {
        return apiCall('delete', `management/menu-items/${menuID}`)
    },

    updateMenuItem(menuItemID, data) {
        return apiCall('post', `management/menu-items/${menuItemID}`, data)
    },

    createMenuItem(menu, data) {
        return apiCall('post', `management/menus/${menu}/items`, data)
    },

    getMenuItems(menu) {
        return apiCall('get', `management/menus/${menu}/items`)
    },

    getMenus() {
        return apiCall('get', "management/menus")
    },

    createMenu(data) {
        return apiCall('post', "management/menus", data)
    },

    updateMenu(menuID, data) {
        return apiCall('post', `management/menus/${menuID}/update`, data)
    },

    deleteMenu(menuID) {
        return apiCall('delete', `management/menus/${menuID}`)
    }
}