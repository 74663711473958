import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route('payment');

RouteRegistrar.add("payment/paymethods/Paymethods", "paymethods", "paymethods", "شیوه ها پرداخت")

RouteRegistrar.add("payment/transactions/Transactions", "transactions", "transactions", "لیست تراکنش ها")
RouteRegistrar.add("payment/transactions/ManualTransactions", "manual-transactions", "manual-transaction", "لیست پرداخت مستقیم")



const routes = RouteRegistrar.allRoutes()


export default routes;
