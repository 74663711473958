<template>
  <div slot="body" v-if="dataLoaded">
    <bita-form ref="form">
      <div slot="body">
        <div class="col-12" v-show="this.isUpdateMode">
          <bita-select
            v-if="$acl.can('view', 'sellers') && $acl.can('create', 'features')"
            name="supplires"
            ref="supplires"
            label="فروشنده"
            rules=""
            validation-container-class="col-lg-7"
            input-class="form-control"
            :data-source="supplires"
            data-text-field="seller_fullname"
            @change="getProductPricesOfSeller"
            data-value-field="id"
            v-model="seller_id"
          />
        </div>
        <div
          class="row"
          v-if="
            productPrices &&
              productPrices.length > 1 &&
              $acl.can('create', 'features')
          "
        >
          <bita-select
            name="feature_id"
            ref="featureSelect"
            label="شاخص"
            rules=""
            validation-container-class="col-3"
            input-class="form-control"
            :data-source="features"
            data-text-field="name"
            data-value-field="id"
            v-model="featureID"
          />
          <bita-formbutton
            title="افزودن شاخص"
            btn-width="col-lg-3"
            buttonType="btn-w-m btn-primary"
            @click="addFeature()"
          />
        </div>
        <div class="row">
          <template
            v-if="$acl.can('view', 'admin-of-sellers')"
            v-for="(fID, index) in featureIDs"
          >
            <div :key="index" class="feature-badge">
              <a @click="removeFeature(fID, index)" href="#"
                ><i class="fas fa-times-circle badge-close"></i
              ></a>
              {{ findFeatureNames(fID) }}
            </div>
          </template>
        </div>
        <template v-for="(price, index) in productPrices">
          <div :key="index">
            <div class="card p-3 m-3">
              <div class="card-header">
                جدول قیمت
              </div>
              <div class="card-body">
                <div class="row">
                  <template v-for="(fID, index1) in featureIDs">
                    <bita-select-simple
                      :disabled="!$acl.can('view', 'admin-of-sellers')"
                      :key="index1"
                      v-if="productPrices && productPrices.length > 1"
                      :name="'feature_value_id' + index + fID"
                      ref="featureValueSelect"
                      :label="findFeatureNames(fID)"
                      rules="required"
                      :validation-container-class="largeColWidth"
                      input-class="form-control"
                      :data-source="findFeatureValues(fID)"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="productPrices[index].mapped_combinations[fID]"
                    />
                  </template>
                </div>
                <div class="row">
                  <form-button
                    title="-"
                    button-type="btn-danger"
                    v-if="$acl.can('create', 'features') && deletable()"
                    @click="deletePrice(index, price)"
                  />
                    <!-- <bita-input
                      type="text"
              
                    label="نام فروشنده"
                    rules=""
                   :validation-container-class="largeColWidth"
                    input-class="form-control"
                   v-model="price.seller.seller_fullname"
                    /> -->
                  <bita-input
                    type="text"
                    :name="'code' + index"
                    label="کد"
                    rules=""
                    :validation-container-class="middleColWidth"
                    input-class="form-control"
                    v-model="price.code"
                  />
                  <bita-input
                    type="text"
                    :name="'barcode' + index"
                    label="بارکد"
                    rules=""
                    :validation-container-class="middleColWidth"
                    input-class="form-control"
                    v-model="price.barcode"
                  />
                  <bita-input
                    type="text"
                    :name="'shipping_time' + index"
                    label="مدت زمان ارسال"
                    subtitle="متن نمایشی مانند 3 روز کاری "
                    rules=""
                    :validation-container-class="extraLargeColWidth"
                    input-class="form-control"
                    v-model="price.shipping_time"
                    v-if="$acl.can('view', 'admin-of-sellers')"
                  />
                </div>
                <div class="row">
                  <bita-textarea
                    type="text"
                    :name="'description' + index"
                    label="توضیحات"
                    subtitle="توضیحات اضافه مانند ضمانت بازگشت و ... "
                    rules=""
                    :validation-container-class="extraLargeColWidth"
                    input-class="form-control"
                    v-model="price.description"
                    v-if="$acl.can('view', 'admin-of-sellers')"
                  />
                  <bita-input
                    type="text"
                    :name="'price' + index"
                    :label="$currencyLabel('قیمت')"
                    :has-thousand-seprator="true"
                    rules="required"
                    :validation-container-class="middleColWidth"
                    input-class="form-control"
                    v-model="price.price"
                  />
                  <bita-input
                    type="text"
                    :name="'stock' + index"
                    label="موجودی"
                    rules="required"
                    :validation-container-class="middleColWidth"
                    input-class="form-control"
                    v-model="price.stock"
                  />
                </div>
              </div>
            </div>
          </div>
          <block-button
                v-if="
                  productPrices.length == index + 1 &&
                    $acl.can('create', 'features')
                "
                icon="fas fa-plus-circle"
                @click="addPrice()"
              />
        </template>
      </div>
    </bita-form>
  </div>
</template>
<script>
import BlockButton from "@/components/template/buttons/BlockButton";
import FormButton from "@/components/template/buttons/FormButton";
import { productApi, supplireApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
    extraLargeColWidth: {
      default: "col-lg-5",
    },
    largeColWidth: {
      default: "col-lg-4",
    },
    middleColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    product: {},
    propsSupplires: {
      type: Array,
    },
  },
  components: { BlockButton, FormButton },
  data() {
    return {
      seller_id: {},
      supplires: [],
      form: {},
      productPrices: [],
      formData: [{}],
      featureID: "",
      featureIDs: [],
      productId: "",
      features: [],
      dataLoaded: false,
      UpdateMode: false,
    };
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
    isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
    featureKeyedArray() {
      let res = {};
      for (var feature of this.features) {
        res[feature.id] = "";
      }
      return res;
    },
  },
  methods: {
    setInitialValue(_data) {
      this.features = [];
      this.form = _data;
      if (this.form.id) {
        this.loadData();
      }
    },
    deletable() {
      return this.productPrices.length > 1;
    },
    removeFeature(featureID, index) {
      this.featureIDs.splice(index, 1);
      this.$forceUpdate();
    },
    addFeature() {
      if (this.featureID && !this.featureIDs.includes(Number(this.featureID))) {
        this.featureIDs.push(this.featureID);
        this.featureSelect.emptyKendo();
        this.featureID = "";
        this.$forceUpdate();
      }
    },
    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
            if (this.seller_id == "") {
              productApi
                .updateProductPrices(this.form.id, {
                  prices: this.productPrices,
                  feature_ids: this.featureIDs,
                  product_id: this.productId,
                })
                .then((res) => {
                  this.reset();
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  reject(err);
                });
            } else {
              productApi
                .updateProductPricesOfSelelr(this.form.id, this.seller_id, {
                  prices: this.productPrices,
                  feature_ids: this.featureIDs,
                  product_id: this.productId,
                })
                .then((res) => {
                  this.reset();
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  reject(err);
                });
            }
          } else reject(false);
        });
      });
    },
    getSellerOfProduct() {
      this.isLoadingModal = true;
      supplireApi
        .getSellerOfProduct(this.productId)
        .then((res) => {
          this.supplires = res.data.entity;
          e.success(res.data.entity);
        })
        .catch(() => e.success([]))
        .finally(() => (this.isLoadingModal = false));
    },
    addPrice() {
      let mapped_combinations = [];
      mapped_combinations.push(this.featureKeyedArray);
      this.productPrices.push({ mapped_combinations });
    },

    findFeatureValues(featureID) {
      let res = collect(this.features)
        .where("id", Number(featureID))
        .first();
      return res ? res.feature_values : [];
    },

    findFeatureNames(featureID) {
      let res = collect(this.features)
        .where("id", Number(featureID))
        .first();
      return res ? res.name : "-";
    },

    deletePrice(index, price) {
      if (!price.id) return this.$delete(this.productPrices, index);
      this.$emit("is-loading", true);

      productApi
        .deleteProductPrice(price.id)
        .then((res) => {
          this.$delete(this.productPrices, index);
          this.$izitoast("success", "عملیات موفق", res.data.message);
        })
        .finally(() => this.$emit("is-loading", false));
    },

    loadData() {
      this.$emit("is-loading", true);
      this.seller_id = "";
      Promise.all([
        productApi.getProductPrices(this.form.id),
        productApi.getProductFeaturables(this.form.id),
      ])
        .then((res) => {
          var prices = res[0].data.entity;
          this.features = res[1].data.entity;
          this.productId = prices[0].product_id;
          var combinations = [];
          for (var price of prices) {
            for (var combination of price.combinations) {
              combinations.push(combination);
            }
          }
          var featureIDs = collect(combinations)
            .pluck("feature_id")
            .unique()
            .toArray();
          this.featureIDs = featureIDs;

          for (let a of res[0].data.entity) {
            this.productPrices.push({
              mapped_combinations: this.featureKeyedArray,
            });
          }
          this.dataLoaded = true;
          setTimeout(() => {
            this.featureIDs = featureIDs;
          }, 50);
          setTimeout(() => {
            this.productPrices = res[0].data.entity;
          }, 100);
          this.$forceUpdate();
        })
        .finally(() => {
          if (this.$acl.can("view", "admin-of-sellers"))
            this.getSellerOfProduct();
          setTimeout(() => {
            this.$emit("is-loading", false);
          }, 120);
        });
    },
    getProductPricesOfSeller() {
      this.$emit("is-loading", true);
      Promise.all([
        productApi.getProductPricesOfSeller(this.form.id, this.seller_id),
        productApi.getProductFeaturables(this.form.id),
      ])
        .then((res) => {
          var prices = res[0].data.entity;
          this.features = res[1].data.entity;
          this.productId = prices[0].product_id;
          var combinations = [];
          for (var price of prices) {
            for (var combination of price.combinations) {
              combinations.push(combination);
            }
          }
          var featureIDs = collect(combinations)
            .pluck("feature_id")
            .unique()
            .toArray();
          this.featureIDs = featureIDs;

          for (let a of res[0].data.entity) {
            this.productPrices.push({
              mapped_combinations: this.featureKeyedArray,
            });
          }
          this.dataLoaded = true;
          setTimeout(() => {
            this.featureIDs = featureIDs;
          }, 50);
          setTimeout(() => {
            this.productPrices = res[0].data.entity;
          }, 100);
          this.$forceUpdate();
        })
        .finally(() => {
          setTimeout(() => {
            this.$emit("is-loading", false);
          }, 120);
        });
    },
    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      if (this.$refs.featureSelect) this.$refs.featureSelect.emptyKendo();
      if (this.dataLoaded && this.formRef && this.formRef.reset())
        this.formRef.reset();
      this.dataLoaded = false;
      this.form = {};
      this.productPrices = [];
      this.featureIDs = null;
    },
  },
};
</script>

<style scoped>
.feature-badge {
  padding: 5px 21px;
  margin: 5px;
  background: #1ab394;
  border-radius: 10px;
  color: #fff;
  position: relative;
}
.badge-close {
  color: #ed5565;
  font-size: 18px;
  position: absolute;
  left: -2px;
  top: -3px;
}
</style>
