<template>
  <div slot="body">
    <bita-form  @close="reset()" ref="form">
      <div slot="body">
        <div class="row">
          <div v-for='(form, index) in financeForm' :key="index" class="row">
            <bita-formgroup title="اطلاعات حساب بانکی" id="finance_record" class="row">

          <bita-select
              label="حساب در بانک "
              name="bank_id"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="banks"
              data-text-field="name"
              data-value-field="id"
              v-model="form.bank_id"
            />
            <bita-input
              label="نام صاحب حساب"
              name="account_name"
              :validation-container-class="colWidth"
              input-class="form-control"
              data-text-field="name"
              data-value-field="id"
              v-model="form.account_name"
            />
            <bita-input
              type="text"
              name="cart_number"
              label="شماره کارت"
              rules="required|numeric"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.cart_number"
            />
            <bita-input
              type="text"
              name="account_number"
              label="شماره حساب"
              rules="numeric"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.account_number"
            />
            <bita-input
              type="text"
              name="sheba_number"
              label="شماره شبا"
              rules="required|numeric"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.sheba_number"
              subtitle="بدون IR ثبت نمایید."
            />
            <!-- <form-button title="+" icon="fas fa-plus-circle" @click="addFinancialRecord()" btn-width="150px" /> -->
            <form-button
            title="-"
            button-type="btn-danger"
            v-if="deletable()"  @click="deleteRow(index, form)"></form-button>
          </bita-formgroup>

          </div>
        </div>
        
      </div>
    </bita-form>
  </div>
</template>
<script>
import BlockButton from "@/components/template/buttons/BlockButton";
import FormButton from "@/components/template/buttons/FormButton";
import { supplireApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
  },
  components: { BlockButton, FormButton },
  data() {
    return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
      form:{}
      ,  
      financeForm: [{
        id:'',
        account_name:'',
        account_number:'',
        cart_number:'',
        sheba_number :'',
        bank_id:''  
      }],
      banks: [],
      sellerId:''


    };
  },
  computed: {
    formRef() {
      return this.$refs.form;
      
    },
    isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
  },
  mounted() {
 
    this.getBanks()
    },
  methods: {
    setInitialValue(data) {
      this.form = data;
  
      if (this.form.id) this.loadData();
      //  if (this.form.id) this.loadData();

    },
    loadData() {

      this.$emit("is-loading", true);
      this.reset()
      Promise.all([
        supplireApi.getSellerFinances(this.form.id)
        ])
        .then((res) => {
          console.log(res[0].data.entity.finances);
          for (let a of res[0].data.entity.finances) {
            this.financeForm.push({});
          }

            setTimeout(() => {
              this.sellerId = res[0].data.entity.id;
              if(res[0].data.entity.finances.length) {
                this.financeForm = res[0].data.entity.finances

              }else {
                this.financeForm.push({});

              }

          }, 50);
        })
        .finally(() => {
          setTimeout(() => {
            this.$emit("is-loading", false);
          }, 120);
        });
    },
    save() {
      return new Promise((resolve, reject) => {

        this.$refs.form.validate().then((result) => {

          if (result) {

            this.$emit("is-loading", true);

            if (this.isUpdateMode) {
              
                supplireApi
              .updateSellerFinance(this.form.id,{
                finance_items:this.financeForm
              })
               
                .then((res) => {
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
              
                     
            } else {
              supplireApi
              .createFinance({
                finance_items:this.financeForm
              })
               
                .then((res) => {
                  this.form = res.data.entity;
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });       
              }
          } else reject(false);
        });
      });
    },
    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
    
      
      this.financeForm = [];
    },

    addFinancialRecord() {
      
      this.financeForm.push({});
    },
    deletable() {
      return this.financeForm.length > 1;
    },
    deleteRow(index, myform) {
      
      if (!myform.id) return this.$delete(this.financeForm, index);
      this.$emit("is-loading", true);
      supplireApi
        .deleteFinancial(myform.id)
        .then(() => {
          this.$delete(this.financeForm, index);
          this.$izitoast("success", "عملیات موفق", res.data.message);
        })
        .finally(() => this.$emit("is-loading", false));


    },
    getBanks() {
       supplireApi.getBanks().then(({ data }) => {
        this.banks = data.entity;
      });
    },

  },
};
</script>
<style>
#finance {
  padding: 5px 25px 0 0;
}
#finance_record {
  margin: 25px 0 0 20px ;
}
</style>