import { apiCall } from "../imports";

const PREFIX = "shop/middle-pages"

export default {

    gets() {
        return apiCall('get', `${PREFIX}`);
    },

    createMiddlePages(data)
    {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateMiddlePages(middlePageId, data)
    {
        return apiCall('patch', `${PREFIX}/${middlePageId}`, data)
    },

    deleteMiddlePages(middlePageId) {
        return apiCall('delete', `${PREFIX}/${middlePageId}`)
    },
}