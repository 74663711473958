<template>
  <bita-modal
    id="detail-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="onModalClose()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.name"
            />
            <bita-input
              type="text"
              name="slug"
              label="اسلاگ"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.slug"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!form.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    title: String,
    isLoading: Boolean,
    categories: {},
    propData: {
      type: Object,
    },
  },
  data() {
    return {
      colWidth: "col-lg-4",
      form: {},
    };
  },
  mounted() {},
  watch: {
    propData: function (_data) {
      this.form = _data;
    },
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
  },
  methods: {
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.form);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.form);
        }
      });
    },
    reset() {
      this.form = {};
      this.formRef.reset();
    },
    onModalClose(){
      this.reset();
      this.$emit('close')
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
