<template>
  <bita-modal
    id="payments-modal"
    title="ثبت پرداخت "
    :is-loading="isLoading"
    :modal-lg="true"
    @open="setFormData"
    @close="reset()"
  >
    >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-select
              label=" انتخاب  فروشنده"
              name="seller_id"
              ref="sellerSelect"
              :validation-container-class="colWidth"
              rules="required"
              input-class="form-control"
              :data-source="supplires"
              data-text-field="seller_fullname"
              data-value-field="id"
              v-model="formData.seller_id"
            />

            <!-- <bita-select
                        label="شماره سفارش"
                        ref="order_number"
                        name="order_number"
                        rules="required"
                        :validation-container-class="colWidth"
                        input-class="form-control"
                        :data-source="orders"
                        data-text-field="id"
                        data-value-field="id"              
                        v-model="formData.order_number"
                        /> -->

            <bita-select
              label="شیوه پرداخت"
              ref="payment_method"
              name="payment_method"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="paymethod"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.pay_method_id"
            />
            <bita-datepicker
              type="datetime"
              id="payment_date"
              validation-container-class="col-lg-3"
              rules=""
              label="تاریخ پرداخت"
              inputClass="form-control"
              v-model="formData.date_time"
              format="YYYY-MM-DD HH:mm:ss"
              display-format="jYYYY/jMM/jDD HH:mm:ss"
            />

            <bita-input
              type="number"
              name="cheque_number"
              label="شماره چک"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.cheque_number"
              v-if="isCheckPayment(formData)"
            />
            <bita-datepicker
              type="datetime"
              id="cheque_date"
              validation-container-class="col-lg-3"
              rules=""
              label="تاریخ چک"
              inputClass="form-control"
              v-model="formData.cheque_date"
              format="YYYY-MM-DD HH:mm:ss"
              display-format="jYYYY/jMM/jDD HH:mm:ss"
              v-if="isCheckPayment(formData)"
            />
            <bita-input
              type="text"
              name="value"
              :label="$currencyLabel('مبلغ قیمت')"
              :has-thousand-seprator="true"
              min="0"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.cost"
            />

            <bita-input
              type="number"
              name="origin_of_payment"
              label="شماره حساب مبدا"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.origin_of_payment"
            />

            <bita-input
              type="number"
              name="payment_destination"
              label="شماره حساب مقصد"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.payment_destination"
            />

            <bita-input
              type="number"
              name="reference_number"
              label="شماره مرجع"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.reference_number"
            />

            <bita-input
              type="number"
              name="tracking_code"
              label="شماره پیگیری"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.tracking_code"
            />
            <bita-input
              type="number"
              name="transaction_number"
              label="شماره تراکنش"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.transaction_number"
            />
          </div>
        </div>
      </bita-form>
    </div>

    <div slot="footer">
      <bita-bootbutton
        title="ثبت"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
        :disabled="isLoading"
      />
    </div>
  </bita-modal>
</template>
<script>
import { supplireApi, paymethodApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      isLoading: false,
      largeColWidth: "col-lg-12",
      colWidth: "col-lg-3",
      errors: {},
      formData: [],
      product: [],
      filters: {},
      products: [],
      formExistsProducts: false,
      formData: {},
      myform: {},
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
      rewardFine: [
        { id: 1, title: "جریمه" },
        { id: 2, title: "تشویق" },
      ],
      supplires: [],
      orders: [],
      paymethod: [],
      chequeMethodID:'',
    };
  },
  mounted() {
    if (this.$acl.can("view", "sellers")) {
      this.getActiveSupplire();
    }

    this.getPayMethods();
  },
  methods: {
    setFormData() {
      //this.$refs.products.clear();
      //  this.$refs.products.emptyKendo();
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },

    createProduct() {
      this.$toggleModal("#productinsert-modal");
    },
    getActiveSupplire() {
      supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },
    // getOrdersOfSupplire(event) {
    //    supplireApi.getOrdersOfSupplire(event).then(({ data }) => {
    //     this.orders = data.entity;
    //   });
    // },
    getPayMethods() {
      paymethodApi.getPayMethodsOfSeller().then(({ data }) => {
        this.paymethod = data.entity;
        this.paymethod.forEach(element => {
          if(element.method==4)
          this.chequeMethodID=element.id;
        });

      });
    },
    reset() {
      this.formData = {};
      this.$refs.form.reset();
    },

    isCheckPayment(formData) {
      return formData.pay_method_id == this.chequeMethodID;
    },
  },
};
</script>
