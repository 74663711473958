<template>
  <bita-modal
    :id="modalId"
    :title="title"
    :modal-lg="true"
    :isLoading="isLoading"
    @close="onCloseModal"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="title"
              id="title"
              label="عنوان"
              rules="required"
              validation-container-class="col-lg-4 col-md-6 col-sm-6"
              input-class="form-control"
              v-model="formItems.title"
            />
            <bita-input
              type="text"
              name="price"
              id="price"
              :label="$currencyLabel('مبلغ')"
              :has-thousand-seprator="true"
              rules="required"
              validation-container-class="col-lg-4 col-md-6 col-sm-6"
              input-class="form-control"
              v-model="formItems.price"
            />
            <bita-input
              type="number"
              name="weight"
              id="weight"
              label="وزن"
              rules=""
              validation-container-class="col-lg-4 col-md-6 col-sm-6"
              input-class="form-control"
              v-model="formItems.weight"
            />
            <bita-input
              type="text"
              name="description"
              id="description"
              label="توضیحات"
              rules=""
              validation-container-class="col-lg-12 col-md-12 col-sm-12"
              input-class="form-control"
              v-model="formItems.description"
            />
          </div>
          <bita-uploader
            name="image"
            label="تصویر"
            rules="size:50"
            ref="uploader"
            validation-container-class="col-lg-12 col-md-12 col-sm-12"
            input-class="form-control"
            v-model="formItems.image"
            :icon-url="formItems.icon_url"
          />
          <bita-select
            name="status"
            label="وضعیت"
            ref="statusDropDown"
            rules="required"
            validation-container-class="col-lg-12 col-md-12 col-sm-12"
            input-class="form-control"
            :data-source="statuses"
            data-text-field="title"
            data-value-field="id"
            v-model="formItems.status"
          />
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!isCreateMode"
        type="primary"
        title="بروزرسانی"
        icon="fa fa-check"
        btn-width="150px"
        @click.native="handleUpdate()"
      />
      <bita-bootbutton
        v-if="isCreateMode"
        type="primary"
        title="ذخیره"
        icon="fa fa-check"
        btn-width="150px"
        @click.native="handleSubmit()"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    modalId: {},
    isLoading: {},
    title: {},
    propsData: {
      type: Object,
    },
  },
  data() {
    return {
      formItems: {},
    };
  },
  computed: {
    isCreateMode() {
      return !this.formItems.id;
    },
  },
  watch: {
    propsData: function(_val) {
      this.formItems = _val;
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-submit", this.formItems);
        }
      });
    },
    handleUpdate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formItems);
        }
      });
    },
    reset() {
      this.form = {};
      this.$refs.form.reset();
      this.$refs.uploader.reset();
    },
    onCloseModal(e) {
      this.reset();
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
