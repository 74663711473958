<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :grid-server-side="true"
            :schema-total="'total'"
            :schema-data="'data'"
            :server-paging="true"
            :batch="true"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { transactionApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "لیست تراکنش ها", link: "/shop/transactions" },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      pageSize: 30,
      currentPage: 1,
    };
  },
  computed: {
    gridColumns() {
      return [
        { field: "user", title: "نام کاربر", "template": e => e.user ? e.user.full_name : '-' },
        {
          field: "price",
          title: this.$currencyLabel("قیمت"),
          template: (e) => {
            return this.$thousandSeprator(this.$round(e.price));
          },
        },
        { field: "transaction_id", title: "کد پیگیری" },
        { field: "ip", title: "آی پی آدرس" },
        { field: "paymethod_text", title: "درگاه پرداخت" },
        {
          field: "status",
          title: "وضعیت",
          template: (e) => {
            let status = "";
            switch (Number(e.status)) {
              case 1:
                status = "شروع شده";
                break;
              case 2:
                status = "انجام شده";
                break;
              case 3:
                status = "رد شده";
                break;
            }
            return status;
          },
        },
        { field: "message", title: "پیام" },
        {
          field: "created_at",
          title: "زمان تراکنش",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
      ];
    },
  },
  methods: {
    readData(e) {
      this.isLoadingGrid = true;
      this.pageSize = e.data.pageSize;
      this.currentPage = e.data.page;
      transactionApi
        .getTransactions({
          page_size: this.pageSize,
          page: this.currentPage,
          ...this.filters,
        })
        .then((res) => {
          e.success(res.data);
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
  },
};
</script>
