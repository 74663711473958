<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            :title="`افزودن زیرمنو ${menuName}`"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="openModal"
            v-if="$acl.can('create', 'menu-items')"
          />
          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <menu-items-form
      ref="menuItemsform"
      id="menu-items-form"
      :title="`افزودن زیرمنو برای ${this.menuName}`"
      :is-loading="isLoadingModal"
      :props-data="updateForm"
      @on-create="create"
      @on-update="update"
    />
  </div>
</template>
<script>
import { menuApi } from "@/api/apiManagement";
import MenuItemsForm from "./components/MenuItemsForm";
export default {
  props: ["menu"],
  components: { MenuItemsForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "زیرمنوها", link: "/shop/menu-items" },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      updateForm: {
        title: "",
        type: "",
        icon_url: "",
        order: "",
        status: "",
        link: "",
      },
      menuName: "",
    };
  },
  computed: {
    gridColumns() {
      return [
        {
          field: "icon_url",
          title: "تصویر",
          template: (e) => (e.icon_url ? this.getPicture(e.icon_url) : ""),
        },
        {
          field: "type",
          title: "نوع",
          template: (e) => (e.type ? this.getMenuType(e.type) : ""),
        },
        { field: "title", title: "عنوان" },
        { field: "status", title: "وضعیت", template: this.statusIcon },
        { field: "order", title: "اولویت" },
        { field: "link", title: "آدرس" },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  methods: {
    statusIcon(e) {
      let view = e.status == 1 ? "fas fa-lock-open" : "fas fa-lock";
      let color = e.status == 1 ? "green" : "red";
      return `<span class="${view}" style="color:${color}"></span>`;
    },
    getPicture: function (icon) {
      return (
        '<div class="rounded-circle rounded-image-grid" ' +
        (icon ? "" : "no-pic") +
        'style="background-image:url(' +
        (icon ? icon : "") +
        ')">' +
        (icon ? "" : '<i class="fa fa-file-image-o" aria-hidden="true"></i>') +
        "</div>"
      );
    },
    getMenuType(type) {
      switch (type) {
        case 1:
          return "داخلی";
        case 2:
          return "خارجی";
        case 3:
          return "صفحات ثابت";

        default:
          return "";
      }
    },
    readData(e) {
      this.isLoadingGrid = true;
      menuApi
        .getMenuItems(this.menu)
        .then((res) => {
          this.menuName = res.data.entity.menu?.title;
          e.success(res.data.entity.data);
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("edit", "menu-items")) {
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
      }

      if (this.$acl.can("delete", "menu-items"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    edit(e) {
      let dataItem = this.$refs.grid.getSelectedGridRow(e);
      this.updateForm = {
        id: dataItem.id,
        title: dataItem.title,
        type: dataItem.type,
        icon_url: dataItem.icon_url,
        order: dataItem.order,
        status: dataItem.status,
        link: dataItem.link,
      };
      this.toggleForm();
    },
    delete(e) {
      let page = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف صفحه ${page.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          menuApi
            .deleteMenuItem(page.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    create(e) {
      this.isLoadingModal = true;
      menuApi
        .createMenuItem(this.menu, this.$getFormData(e))
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.loadData();
          this.toggleForm();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    update(e) {
      this.isLoadingModal = true;
      menuApi
        .updateMenuItem(e.id, this.$getFormData(e))
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.loadData();
          this.toggleForm();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    openModal() {
      this.updateForm = {};
      this.toggleForm();
    },
    toggleForm() {
      this.$toggleModal("#menu-items-form");
    },
  },
};
</script>