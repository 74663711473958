import { apiCall } from "../imports";

const PREFIX = "blog/posts";

export default {

    getPosts() {
        return apiCall('get', `${PREFIX}`);
    },

    findPost(id) {
        return apiCall('get', `${PREFIX}/${id}`);
    },
    
    createPost(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updatePost(blogId, data) {
        return apiCall('post', `${PREFIX}/${blogId}/update`, data)
    },

    deletePost(blogId) {
        return apiCall('delete', `${PREFIX}/${blogId}`)
    },

    deleteFile(imageId) {
        return apiCall('delete', `${PREFIX}/${imageId}/file`)
    },

}