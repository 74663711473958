import Vue from "vue";


import FormValidation from "./../components/formManagement/FormValidation"

import GCheckBox from "./../components/formManagement/formElements/GCheckBox"
import GDatePicker from "./../components/formManagement/formElements/GDatePicker"
import GInput from "./../components/formManagement/formElements/GInput"
import GMultiSelect from "./../components/formManagement/formElements/GMultiSelect"
import GMultiSelectServer from "./../components/formManagement/formElements/GMultiSelectServer"
import GSelect from "./../components/formManagement/formElements/GSelect"
import GSelectSimple from "./../components/formManagement/formElements/GSelectSimple"
import GSelectServer from "./../components/formManagement/formElements/GSelectServer"
import GTreeSelect from "./../components/formManagement/formElements/GTreeSelect"
import GTextArea from "./../components/formManagement/formElements/GTextArea"
import GAutoComplete from "./../components/formManagement/formElements/GAutoComplete"
import GTextEditor from "./../components/formManagement/formElements/GTextEditor-1"
import GUploader from "./../components/formManagement/formElements/GUploader"
import GColorPicker from "./../components/formManagement/formElements/GColorPicker"
import GTypeAhead from "./../components/formManagement/formElements/GTypeAhead"



Vue.component("bita-form", FormValidation);
Vue.component("bita-checkbox", GCheckBox);
Vue.component("bita-datepicker", GDatePicker);
Vue.component("bita-input", GInput);
Vue.component("bita-multiselect", GMultiSelect);
Vue.component("bita-multiselectserver", GMultiSelectServer);
Vue.component("bita-select", GSelect);
Vue.component("bita-select-simple", GSelectSimple);
Vue.component("bita-select-server", GSelectServer);
Vue.component("bita-treeselect", GTreeSelect);
Vue.component("bita-textarea", GTextArea);
Vue.component("bita-autocomplete", GAutoComplete);
Vue.component("bita-texteditor", GTextEditor);
Vue.component("bita-uploader", GUploader);
Vue.component("bita-colorpicker", GColorPicker);
Vue.component("bita-typeahead", GTypeAhead);