<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            v-if="!showForm && $acl.can('create', 'faq')"
            type="primary"
            title="پرسش و پاسخ جدید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="addModal"
          />
          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <form-modal
      ref="editModal"
      :isLoading="isLoadingModal"
      :title="title"
      @on-create="onCreate"
      @on-update="onUpdate"
    />
  </div>
</template>
<script>
import faqForm from "./components/FaqForm";
import { faqApi } from "@/api/apiManagement.js";

export default {
  components: { "form-modal": faqForm },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت پرسش و پاسخ ها ",
          link: "/management/faq",
          active: true,
        },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      showForm: false,
    };
  },
  computed: {
    gridColumns() {
      return [
        { field: "question", title: "سوال" },
        {
          field: "status_text",
          title: "وضعیت",
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
    title() {
      let title = "افزودن";
      if (this.$refs.editModal && this.$refs.editModal.getId())
        title = "ویرایش";
      return title + " پرسش و پاسخ ";
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    statusTemlate(e) {
      return e.status ? "فعال" : "غیر فعال";
    },
    onCreate(data) {
      this.isLoadingModal = true;

      faqApi
        .createFaq(data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        })

        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    successForm() {
      this.loadData();
      this.$refs.editModal.resetForm();

      this.$toggleModal("#edit-modal");
    },
    onUpdate(data) {
      this.isLoadingModal = true;

      faqApi
        .updateFaq(data.id, data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        })

        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;

      faqApi
        .getFaq()
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف کوپن  ${data.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          faqApi
            .deleteFaq(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("edit", "faq"))
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
      if (this.$acl.can("delete", "faq"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    openModal() {
      this.$toggleModal("#edit-modal");
    },
    addModal() {
      this.openModal();
    },
    edit(e) {
      let {id,question,status,answer} = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.editModal.setFormData({id,question,status,answer});
      this.openModal();
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
