<template>
  <div class="col-lg-12 more-container">
    <div class="btn-container">
      <div class="line-in-middle"></div>
      <button
        class="btn btn-success btn-circle"
        data-toggle="tooltip"
        title="بیشتر"
        type="button"
        @click="toggleMore"
      >
        <div class="sk-spinner sk-spinner-circle" v-if="isLoading">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>
        <span v-show="!isOpen && !isLoading">
          <i class="fa fa-chevron-down"></i>
        </span>
        <span v-show="isOpen && !isLoading">
          <i class="fa fa-chevron-up"></i>
        </span>
      </button>
    </div>
    <transition name="fadeHeight">
      <div class="row" v-if="isOpen">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isLoading: false,
    };
  },
  methods: {
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    toggleMore() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style lang="scss">
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
.more-container {
  margin-bottom: 15px;
  .btn-container {
    height: 30px;
    position: relative;
    text-align: center;
    button {
      position: absolute;
    }
    .line-in-middle {
      position: absolute;
      top: calc(50% - 0.5px);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      left: 0;
      right: 0;
    }
  }
  .row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 7px;
  }
  .sk-spinner-circle {
    margin-top: -3px;
  }
}
</style>
