import Vue from "vue";
import store from "./../store";
import router from "./../router";


var axios = require('axios');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
axios.defaults.baseURL = process.env.VUE_APP_API_BASEPOINT;


function toast(title, message) {
    let messages = {
        message: message ? message : "عملیات ناموفق",
        title: title,
        rtl: true,
        position: "topRight",
        displayMode: 2,
    };

    iziToast.error(messages);
}

function catchError(error) {
    let errors = error.response && error.response.data ? error.response.data : {};
    if (errors.errors) {
        let error = errors.errors;
        for (const key in error) {
            toast('خطایی رخ داده است', error[key][0]);
        }
    } else {
        let err = errors.message ? errors.message : null;
        toast('خطایی رخ داده است', err);
    }
}

export default (method, uri, data = null) => {
    return new Promise((resolve, reject) => {
        axios[method](uri, data)
            .then((res) => {
                store.commit('SET_XSRF_TOKEN');
                resolve(res);
            })
            .catch((error) => {
                catchError(error)
                reject(error);
            });
    });
}