import { apiCall, createQueryString } from "../imports"

const PREFIX = "management/newsletters"

export default {

    getUsers(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    toggleUserStatuser(userId) {
        return apiCall('post', `${PREFIX}/${userId}/toggle-status`)
    },
    
    deleteUsers(userId) {
        return apiCall('delete', `${PREFIX}/${userId}`)
    },
}