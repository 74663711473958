<template>
  <bita-modal
    id="year-modal"
    title="انتقال مانده حساب"
    :is-loading="isLoading"
    :modal-lg="true"
    @open="setFormData"
    @close="reset()">
  >

    <div slot="body">
      <bita-form ref="yearForm">
        <div slot="body">
          <div class="row">
            <bita-select
                        label="از سال"
                        ref="years"
                        name="years"
                        rules="required"
                        :validation-container-class="colWidth"
                        :data-source="years"
                        input-class="form-control"
                        data-text-field="fiscal_year"
                        data-value-field="id"
                        v-model="formData.from_fiscal_year_id"

                        />            <bita-select
                        label="به سال"
                        ref="years"
                        name="years"
                        rules="required"
                        :validation-container-class="colWidth"
                        :data-source="years"
                        input-class="form-control"
                        data-text-field="fiscal_year"
                        data-value-field="id"
                        v-model="formData.to_fiscal_year_id"

                        />

          </div>
          
        </div>
      </bita-form>
    </div>


    <div slot="footer">
      <bita-bootbutton
        title="انتقال مانده حساب"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
        :disabled="isLoading"
      />
    </div>
  </bita-modal>
</template>
<script>
import { supplireApi,paymethodApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      isLoading: false,
      largeColWidth: "col-lg-12",
      colWidth: "col-lg-6",
      errors: {},
      product: [],
      filters: {},
      products: [],
      years:[],
      formExistsProducts : false,
      formData:{},
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
      rewardFine: [
        { id: 1, title: "جریمه" },
        { id: 2, title: "تشویق" },
      ],
      supplires:[],
    };
  },
  mounted() {

    this.getListFiscalYears();
    },
  methods: {
   
    setFormData() {
      //this.$refs.products.clear();
    //  this.$refs.products.emptyKendo();
    },
    // savePenaltyReward() {
    //   this.$refs.form.validate().then((result) => {
    //     if (result) {
    //       this.$emit("on-create", this.myform);
    //     }
    //   });
    // },
    
    save() {
      this.$refs.yearForm.validate().then((result) => {
        if (result) {
         this.transferAccountBalance()
          //this.$emit("on-create", this.formData);
        }
      });
    },
    transferAccountBalance() {
     
     this.$alert(
       "question",
       ` آیا از انتقال مانده حساب به سال جدید اطمینان دارید؟`,
       "",
       {
         confirmButtonText: "بله",
       }
     ).then((result) => {
       if (result.value) {
        this.isLoading = true;
          supplireApi
            .transferAccountBalance(this.formData)
            .then(() => {
            this.formSucceeded();
            })
            .finally(() => {
              this.isLoading = false;
            });
       }
     });
   },
   formSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$toggleModal("#year-modal");
    },
    getListFiscalYears() {
       supplireApi.getListFiscalYears().then(({ data }) => {
        this.years = data.entity;
      });
    },
    // getFormData() {
    //   let formData = new FormData();
    //   for (var key in this.formData) {
    //     if (this.formData[key] != null) {
    //       formData.append(key, this.formData[key]);
    //     }
    //   }
    //   return formData;
    // },
    createProduct() {
      this.$toggleModal("#productinsert-modal");
      
     
    },
    getActiveSupplire() {
       supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },

    
    reset() {
      this.yearForm = {};
      this.$refs.yearForm.reset();
   
    },
 

  },
};
</script>
