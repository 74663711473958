<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن اسلاید"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="addNew"
            v-if="$acl.can('create', 'slides')"
          />
          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :schema-total="'total'"
            :schema-data="'data'"
          />
        </div>
      </bita-ibox>
    </div>
    <slide-form
      ref="form"
      :title="formTitle"
      :is-loading="isLoadingModal"
      @on-create="(e) => save(e)"
      @on-update="(e) => update(e)"
    />
  </div>
</template>
<script>
import SlideForm from "./components/SlideForm";
import { slideApi } from "@/api/apiManagement";

export default {
  components: { SlideForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت اسلایدها", link: "" },
      ],
      isLoadingModal: false,
      isLoadingGrid: false,
      formTitle: "",
      slides: [],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    gridColumns() {
      let commands = [];
      if (this.$acl.can("edit", "slides"))
        commands.push({
          text: "ویرایش",
          name: "my",
          // template: this.gridButtons.edit,
          click: this.editRow,
        });
      if (this.$acl.can("delete", "slides"))
        commands.push({
          text: "حذف",
          name: "delete",
          // template: this.gridButtons.delete,
          click: this.delete,
        });
      return [
        {
          field: "image",
          title: "عکس",
          template: this.getImage,
          width: "100px",
        },
        { field: "title", title: "نام" },
        { field: "type", title: "نوع", template: this.getType },
        { field: "order", title: "ترتیب" },
        { field: "size", title: "اندازه", template: this.getSize },
        {
          title: "عملیات",
          command: commands,
        },
      ];
    },
  },

  methods: {
    getType(element) {
      let title = "";

      switch (Number(element.type)) {
        case 1:
          title = "اسلایدر";
          break;
        case 2:
          title = "بنر وسط";
          break;
        case 3:
          title = "بنر ساید بار";
          break;
        case 4:
          title = "بنر سرصفحه";
          break;
        case 5:
          title = "بنر کالکشن محصول";
          break;
          case 6:
          title = "بنر سرصفحه موبایلی";
          break;
          case 7:
          title = " دسته بندی سرصفحه";
          break;
          case 8:
          title = " اسلایدر حالت موبایل";
          break;
          case 9:
          title = "بنر تخفیف ویژه موبایلی";
          break;
          case 10:
          title = "بنر تخفیف ویژه دسکتاپ";
          break;
          case 11:
          title = "دسته بندی بالای  کالکشن";
          break;
          case 12:
          title = "اسلایدر صفحه واسط";
          break;
          case 13:
          title = "دسته بندی سر صفحه واسط";
          break;
          case 14:
          title = "اسلاید حالت موبایلی صفحه واسط";
          break;
          case 15:
          title = "بنر تخفیف ویژه موبایلی صفحه واسط";
          break;
          case 16:
          title = "بنر تخفیف ویژه دسکتاپ صفحه واسط";
          break;
      }
      return title;
    },
    getSize(element) {
      if (!element) return "";
      if (this.isSlider(element.type)) return "-";
      switch (element.size) {
        case 1:
          return "کوچک";
        case 2:
          return "متوسط";
        case 3:
          return "بزرگ";
          case 4:
          return "کوچک دوتایی";
        default:
          return "";
      }
    },
    getImage(element) {
      return `
      <img src="${element.image_url}" alt="تصویر"
       class="img rounded-circle circle-border slider-image">
      `;
    },
    isSlider(type) {
      return type == 1;
    },
    save(slide) {
      this.isLoadingModal = true;
      console.log(slide);
      slide = this.getFormData(slide);
      console.log(slide);
      slideApi
        .createSlide(slide)
        .then((res) => {
          this.formSucceeded(res.data.message);
          this.toggleModal();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    formSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$refs.form.reset();
      this.toggleModal();
      this.loadData();
    },
    update(slide) {
      this.isLoadingModal = true;
      let formData = this.getFormData(slide);

      slideApi
        .updateSlide(slide.id, formData)
        .then((res) => {
          this.formSucceeded(res.data.message);
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    toggleModal() {
      this.$toggleModal("#slide-form");
    },
    addNew() {
      this.toggleModal();
      this.$refs.form.setForm();
    },
    editRow(e) {
      e.preventDefault();
      let slide = this.$refs.grid.getSelectedGridRow(e);
      let editForm = {
        id: slide.id,
        title: slide.title,
        image_url: slide.image_url,
        order: slide.order,
        size: slide.size,
        link: slide.link,
        type: slide.type,
        meta_id: slide.meta_id,
        meta_css:slide.meta_css,
      };
      this.formTitle = `ویرایش ${slide.title}`;
      this.toggleModal();
      this.$refs.form.setForm(editForm);
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    readData(e) {
      this.isLoadingGrid = true;
      // this.$resetGrid();
      slideApi
        .getSlides()
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    delete(e) {
      e.preventDefault();
      let slide = this.$refs.grid.getSelectedGridRow(e);
      console.log(slide);
      this.$alert("question", `آیا از حذف ${slide.title} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          slideApi
            .deleteSlide(slide.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
table td {
  text-align: center;
}
</style>
<style>
img.slider-image {
  border: none;
  border-radius: 50%;
  object-fit: cover;
  height: 50px;
  width: 50px;
}
</style>
