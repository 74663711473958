<template>
  <div class="print-page">
    <h1 style="text-align: center;">
      فاکتور فروش
    </h1>
    <table class="print-table print-header-table" style="width: 100%">
      <tr>
        <td
          style="width: 1.8cm; height: 2cm;vertical-align: center; padding: 0 0 4px"
        >
          <div class="print-header-item-wrapper">
            <div class="print-portait" style="margin: 20px">خریدار</div>
          </div>
        </td>
        <td style="height: 2cm;vertical-align: center; padding: 0 4px 4px">
          <div class="print-bordered header-item-data">
            <table class="print-table centered" style="height: 100%">
              <tr>
                <td style="width: 5cm">
                  <span class="print-label">شماره فاکتور</span
                  >{{ order.tracking_code }}
                </td>
                <td style="width: 4cm" >
                  <span class="print-label">تاریخ:</span>{{ $getJalaliDate(order.created_at) }}
                </td>
              </tr>
              <tr>
                <td style="width: 3cm">
                  <span class="print-label">خریدار:</span>{{ order.full_name}}
                </td>

                <td style="width: 3cm">
                  <span class="print-label">شماره تماس:</span> {{ order.mobile }} -
                  {{ order.tel }}
                </td>
                <td style="width: 3cm">
                  <span class="print-label">کد پستی:</span> {{ order.postal_code }}
                </td>
                <td style="width: 10cm">
                  <span class="print-label">نشانی:</span>
                    {{ order.city }}, {{ order.state }},
                    {{ order.address }}
                </td>
              </tr>
            </table>
          </div>
        </td>
        <!-- <td style="padding: 0 0 4px">
          <div class="grow bordered" style="padding: 2mm 5mm;">
            <div class="flex">
              <div>تاریخ:</div>
              <div class="flex-grow" style="text-align: left">
                {{ $getJalaliDate(order.created_at) }}
              </div>
            </div>
            <div class="flex">
              <div>پیگیری:</div>
              <div class="flex-grow font-medium" style="text-align: left">
                {{ order.tracking_code }}
              </div>
            </div>
            <div class="barcode">
              <span></span>
            </div>
          </div>
        </td> -->
      </tr>
    </table>

    <table class=" print-table print-content-table">
      <thead class="print-thead">
        <tr>
          <th>ردیف</th>
          <th style="width: 30%">نام کالا</th>
          <th>تعداد</th>
          <th style="width: 2.3cm">مبلغ واحد </th>
          <th style="width: 2.3cm">مبلغ کل </th>
          <th>مبلغ تخفیف(ریال)</th>
          <th>مبلغ کل پس از تخفیف(ریال)</th>
        </tr>
      </thead>
      <tr v-for="(item, index) in order.items" :key="index">
        <td>{{ index+1 }}</td>
        <td>
          <strong
            >{{ item.product.name }} <br />
            <small>{{ item.combination_text }}</small></strong
          >
        </td>
        <td>{{ item.count }}</td>
        <td>{{ $thousandSeprator(item.price) }}</td>
        <td>{{ $thousandSeprator(item.price * item.count) }}</td>
        <td>{{ $thousandSeprator(item.price - item.payable_price) }}</td>
        <td>{{ $thousandSeprator(item.payable_price * item.count) }}</td>
      </tr>
      <tfoot class="print-tfoot">
        <tr>
          <td colspan="3">هزینه حمل و نقل</td>
          <td  colspan="2"></td>
          <td colspan="2">{{ $thousandSeprator(order.shipping_price) }}</td>
        </tr>

        <tr>
          <td colspan="3">تخفیف</td>
          <td  colspan="2"></td>
          <td colspan="2">{{ $thousandSeprator(order.discount) }}</td>
        </tr>
        <tr>
          <td colspan="3">جمع کل:</td>
          <td  colspan="2"></td>
          <td colspan="2">{{ $thousandSeprator(order.total) }}</td>
        </tr>
        <tr>
          <td colspan="3">پرداخت شده</td>
          <td  colspan="2"></td>
          <td colspan="2">{{ $thousandSeprator(order.payable) }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import { orderApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      order: {},
    };
  },
  mounted() {
    this.$setLayout("simple-layout");
    this.loadOrder();
  },
  methods: {
    loadOrder() {
      let orderID = this.$route.params.id;
      this.$setIsLoading(true);
      orderApi
        .findOrder(orderID)
        .then((res) => {
          this.order = res.data;
          setTimeout(function() {
            window.print();
          }, 1000);
        })
        .finally(() => this.$setIsLoading(false));
    },
  },
};
</script>
<style>
@import url("https://cdn.fontcdn.ir/Font/Persian/Shabnam/Shabnam.css");
</style>
<style>
.print-page {
  color: #000;
  background-color: #fff !important;
  padding: 0;
  margin: 0 auto;
  max-width: 29.7cm;
  -webkit-print-color-adjust: exact;
}

.print-page {
  padding: 0.5cm;
}

.print-page {
  color: #000;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.print-table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}

.print-header-table {
  table-layout: fixed;
  border-spacing: 0;
}

.print-header-table td {
  padding: 0;
  vertical-align: top;
}

.print-page {
  font: 9pt Shabnam !important;
  direction: rtl;
}

.print-button {
  font: 9pt Shabnam !important;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  background: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;

  outline: none;
  text-align: center;

  padding: 8px 16px;
  font-size: 12px;
  font-size: 0.857rem;
  line-height: 1.833;
  font-weight: 700;
  background-color: #0fabc6;
  color: #fff;
  border: 1px solid #0fabc6;
}

.print-page {
  background: white;
  page-break-after: always;
}

.print-flex {
  display: flex;
}

.print-flex > * {
  float: left;
}

.print-flex-grow {
  flex-grow: 10000000;
}

.print-barcode {
  text-align: center;
  margin: 12px 0 0 0;
  height: 30px;
}

.print-barcode span {
  font-size: 35pt;
}

.print-portait {
  transform: rotate(-90deg) translate(0, 40%);
  text-align: center;
}

.print-header-item-wrapper {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  background: #eee;
  display: flex;
  align-content: center;
}

.print-thead,
.print-tfoot {
  background: #eee;
}

.header-item-data {
  height: 100%;
  width: 100%;
}

.print-bordered {
  border: 1px solid #000;
  padding: 0.12cm;
}

.print-header-table table {
  width: 100%;
  vertical-align: middle;
}

.print-content-table {
  border-collapse: collapse;
}

.print-content-table td,
th {
  border: 1px solid #000;
  text-align: center;
  padding: 0.1cm;
  font-weight: normal;
}

.print-table.centered td {
  vertical-align: middle;
}

.serials {
  direction: ltr;
  text-align: left;
}

.print-title {
  text-align: right;
}


.print-font-small {
  font-size: 8pt;
}



.print-label {
  font: 9pt Shabnam !important;
  font-weight: bold;
  padding: 0 0 0 2px;
}

@page {
  size: A4 landscape;
  margin: 0;
  margin-bottom: 0.5cm;
  margin-top: 0.5cm;
}

.print-ltr {
  direction: ltr;
  display: block;
}
@media print {
  .print-button {
    display: none;
  }
}
</style>
