<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-form ref="filterForm">
            <div slot="body">
              <div class="row">
                <bita-input
                  type="text"
                  name="name"
                  label="جستجو"
                  validation-container-class="col-lg-4"
                  input-class="form-control"
                  v-model.lazy="filters.search"
                  @keyup.enter.prevent="loadData"
                />
                <div class="col-lg-4">
                  <a class="margin-top btn btn-success" @click="loadData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>
                <more-filter>
                  <bita-datepicker
                    type="datetime"
                    id="paymentat"
                    validation-container-class="col-lg-2"
                    name="start_at"
                    rules=""
                    label="شروع تاریخ ایجاد"
                    inputClass="form-control"
                    v-model="filters.start_at"
                    format="YYYY-MM-DD HH:mm:ss"
                    display-format="jYYYY/jMM/jDD HH:mm:ss"
                  />
                  <bita-datepicker
                    type="datetime"
                    id="paymentat"
                    validation-container-class="col-lg-2"
                    name="end_at"
                    rules=""
                    label="پایان تاریخ ایجاد"
                    inputClass="form-control"
                    v-model="filters.end_at"
                    format="YYYY-MM-DD HH:mm:ss"
                    display-format="jYYYY/jMM/jDD HH:mm:ss"
                  />
                </more-filter>
              </div>
            </div>
          </bita-form>
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :hasRow="false"
            :grid-server-side="true"
            :schema-total="'total'"
            :schema-data="'data'"
            :has-row-number="true"
            :server-paging="true"
            :batch="true"
            v-if="$acl.can('view', 'carts')"
          />
        </div>
      </bita-ibox>
    </div>
    <form-modal
      ref="editModal"
      :isLoading="isLoadingModal"
      modal-id="edit-modal"
      title="نمایش آیتم های خرید"
    />
  </div>
</template>
<script>
import RequestsForm from "./components/CartForm";
import { cartApi } from "@/api/apiManagement";
import MoreFilter from "@/components/MoreFilter.vue";

export default {
  components: { "form-modal": RequestsForm, MoreFilter },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت سبد خرید کاربران",
          link: "/shop/carts",
          active: true,
        },
      ],
      filters: {},
      isLoadingGrid: false,
      isLoadingModal: false,
      pageSize: 30,
      currentPage: 1,
    };
  },
  computed: {
    gridColumns() {
      return [
        {
          field: "user",
          title: "نام شخص",
          template: (e) => (e.user ? e.user.full_name : " - "),
        },
        {
          field: this.$currencyLabel("items_payable"),
          title: "جمع محصولات",
          template: (e) =>
            e.items_payable ? this.$thousandSeprator(e.items_payable) : " - ",
        },
        {
          field: "payable",
          title: this.$currencyLabel("مبلغ"),
          template: (e) =>
            e.payable ? this.$thousandSeprator(e.payable) : " - ",
        },
        {
          field: "coupon_id",
          title: this.$currencyLabel("وضعیت کوپن"),
          template: (e) =>
            e.coupon_id
              ? this.$thousandSeprator(e.coupon_price)
              : " استفاده نشده ",
        },
        {
          field: "shipping_price",
          title: this.$currencyLabel("هزینه ارسال"),
          template: (e) =>
            e.shipping_price ? this.$thousandSeprator(e.shipping_price) : " - ",
        },
        {
          field: "paymethod",
          title: "شیوه ارسال",
          width: "120px",
          template: (e) => (e.shipping ? e.shipping.name : ""),
        },
        {
          field: "paymethod",
          title: "شیوه پرداخت",
          width: "120px",
          template: (e) => (e.paymethod ? e.paymethod.title : ""),
        },
        {
          field: "updated_at",
          title: "تاریخ ویرایش",
          template: (e) =>
            e.updated_at ? this.$getJalaliDateTime(e.updated_at) : "",
        },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    resetFilters() {
      this.$refs.filterForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
      this.filters = {};
    },
    readData(e) {
      this.pageSize = e.data.pageSize;
      this.currentPage = e.data.page;

      this.$resetGrid();
      this.isLoadingGrid = true;
      let filters = {};

      Object.entries(this.filters).forEach(([key, value]) => {
        if (value) filters[key] = value;
      });

      cartApi
        .getCarts({
          page: this.currentPage,
          page_size: this.pageSize,
          ...filters,
        })
        .then((res) => {
          e.success(res.data);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    itemsModalEdit() {
      this.$toggleModal("#edit-modal");
    },
    gridCommands() {
      let commands = [];

      commands.push({
        text: "items",
        click: this.edit,
        template: this.gridButtons.items,
      });
      return commands;
    },
    edit(e) {
      let request = this.$refs.grid.getSelectedGridRow(e);
      if (request.items && request.items.length > 0) {
        this.$refs.editModal.setInitialData(request);
        this.itemsModalEdit();
      }else {
        this.$alert("error", "خطا", "موردی وجود ندارد")
      }
    },
  },
};
</script>
<style scoped>
a.margin-top {
  margin-top: 27px;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
</style>
