<template>
  <div class="side-bar" ref="sideBar" v-click-outside="onClickOutside">
    <side-bar-header :is-open="isOpen" />
    <div class="side-bar-items" v-if="importedMenus.length > 0">
      <side-bar-menu-group
        v-for="(groups, index) in menus"
        :key="index"
        :is-open="isOpen"
        :name="groups.name"
        :icon="groups.icon"
        :childs="groups.childs"
      />
    </div>
  </div>
</template>
<script>
import { collect } from "collect.js";
import SideBarHeader from "./SideBarHeader";
import SideBarMenuGroup from "./SideBarMenuGroup";
import Vue from "vue";

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
export default {
  components: {
    SideBarHeader,
    SideBarMenuGroup,
  },
  data() {
    return {
      isOpen: true,
      openedByMouseEnter: false,
      importedMenus: [],
      permissions: {},
      debounce: false
    };
  },
  watch: {
    isOpen(newVal) {
      var minVal = window.innerWidth > 550 ? "5em" : "0em";
      if (newVal) {
        this.$refs.sideBar.style.width = "18em";
      } else this.$refs.sideBar.style.width = minVal;
    },
  },
  computed: {
    menus() {
      let menus = [];
      for (let menu of this.importedMenus) {
        if (menu.childs && this.hasPermission(menu.childs)) {
          menus = menus.concat(menu);
        } else if (Array.isArray(menu)) menus = menus.concat(this.menuChildren(menu));
      }
      return collect(menus).sortBy('order').toArray();
    },
  },
  mounted() {
    this.initialMenus();
    this.$root.$on("side-bar:toggle", () => {
      this.isOpen = !this.isOpen;
      this.openedByMouseEnter = false;
      this.debounce = true;
      setTimeout(() => {
        this.debounce = false;
      }, 500);
    });
  },
  methods: {
    onClickOutside(){
      if(this.isOpen && !this.debounce && window.innerWidth < 550)
        this.isOpen = false;
    },
    menuChildren(items) {
      let menus = [];
      for (let menu of items) {
        if (menu.childs && this.hasPermission(menu.childs)) {
          menus = menus.concat(menu);
        } else if (Array.isArray(menu)) menus = menus.concat(this.menuChildren(menu));
      }
      return menus;
    },
    hasPermission(children) {
      let hasPermission = false;

      if (children)
        for (let { route_name } of children) {
          hasPermission = this.$acl.can("view", route_name);
          if (hasPermission) break;
        }

      return hasPermission;
    },
    onMouseEnter() {
      if (!this.isOpen) {
        this.openedByMouseEnter = true;
        this.isOpen = true;
      }
    },
    onMouseLeave() {
      if (this.openedByMouseEnter) {
        this.openedByMouseEnter = false;
        this.isOpen = false;
      }
    },
    initialMenus() {
      var _this = this;

      const requireComponent = require.context(
        "@/views",
        true,
        /[menus]\w+\.(json)$/
      );
      requireComponent.keys().forEach((fileName) => {
        _this.$flog.success("Menus Registerd", fileName);
        _this.importedMenus.push(require(`@/views/${fileName.substring(2)}`));
      });
    },
  },
};
</script>
<style scoped>
.side-bar {
  width: 18em;
  height: 100vh;
  overflow-y: auto;
}

.side-bar::-webkit-scrollbar {
  display: none;
}



.side-bar-items {
  padding: 0;
  line-height: 36px;
}

@media (prefers-color-scheme: dark) {
  .side-bar {
    background: #4b4b4bd1 !important;
  }
}

@media (max-width: 550px) {
  .side-bar {
    /* width: 0em; */
     background: #2f4050;
    transition: all 500ms;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    overflow-y: scroll;
    height: 100vh;
  }
}
</style>
