import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route('blog');

RouteRegistrar.add("blog/posts/Posts", "posts", "posts", "نوشته ها")
RouteRegistrar.add("blog/posts/components/PostForm", "posts/create", "posts-form", "افزودن نوشته")
RouteRegistrar.add("blog/posts/components/PostForm", "posts/:id/update", "posts-form", "ویرایش نوشته")

RouteRegistrar.add("blog/categories/Categories", "categories", "categories-post", "مدیریت دسته بندی ها")

const routes = RouteRegistrar.allRoutes()


export default routes;
