import { apiCall } from "../imports";

const PREFIX = "management";

export default {

    getCountries() {
        return apiCall('get', `${PREFIX}/countries`);
    },

    getCities(data) {
        return apiCall('get', `${PREFIX}/cities${createQueryString(data)}`);
    },

    getProvinces(data) {
        return apiCall('get', `${PREFIX}/provinces${createQueryString(data)}`);
    },

}