import { apiCall } from "../imports";

const PREFIX = "article/categories";

export default {
  getCategories(data) {
    let perfix = PREFIX;

    return apiCall("get", `${perfix}`, data);
  },

  getCategoryDetails(categoryID) {
    return apiCall("get", `${PREFIX}/${categoryID}/details`);
  },

  createCategory(data) {
    return apiCall("post", `${PREFIX}`, data);
  },

  updateCategory(categoryID, data) {
    return apiCall("post", `${PREFIX}/${categoryID}/update`, data);
  },

  deleteCategory(categoryID) {
    return apiCall("delete", `${PREFIX}/${categoryID}`);
  },

  search(data) {
    return apiCall("get", `${PREFIX}`, data);
  },
};
