<template>
  <div :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="form-group k-rtl">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i>
          </div>
        </label>
        <div>
          <drop-down-tree
            :id="inputID"
            :name="name"
            :ref="name"
            :type="type"
            :option-label="placeHolder"
            :data-source="db"
            v-model="content"
            :data-text-field="dataTextField"
            :data-value-field="dataValueField"
            :disabled="disabled"
            :filter="'contains'"
            :index="null"
            @changemodel="handleInput(content)"
            :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
          />
        </div>
        <span v-if="hasSubtitle" class="form-text m-b-none">{{
          subtitle
        }}</span>
        <span
          class="invalid-feedback"
          :style="`display: ${errors ? 'inline-block' : 'none'}`"
          >{{ errors[0] }}</span
        >
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";

import {
  DropDownTree,
  // DropDownTreeInstaller,
} from "@progress/kendo-dropdowntree-vue-wrapper";

// import "@progress/kendo-ui/js/kendo.dropdowntree";
export default {
  extends: InputCommons,
  components: { DropDownTree },
  // use: { DropDownTreeInstaller },
  computed: {
    db() {
      var datasorce = new kendo.data.HierarchicalDataSource({
        data: this.prepareTree(
          this.dataSource,
          0,
          this.dataTextField,
          this.dataValueField
        ),
      });
      return datasorce;
    },
  },
  methods: {
    prepareTree(
      items,
      _parentValue = 0,
      _textField = "name",
      _valueField = "id"
    ) {
      var that = this;
      var res = [];
      let bases = collect(items).where("parent_id", _parentValue).toArray();
      bases.forEach((node) => {
        let parent = {};
        parent[_textField] = node[_textField];
        parent[_valueField] = node[_valueField];
        parent.items = [];
        let newNode = that.createTree(items, parent, _textField, _valueField);
        res.push(newNode);
      });
      return res;
    },

    createTree(items, node, _textField, _valueField) {
      items.forEach((child) => {
        if (Number(child.parent_id) == Number(node.id)) {
          let children = {
            state: { checked: false, expanded: false },
          };
          children[_textField] = child[_textField];
          children[_valueField] = child[_valueField];
          children.items = [];
          this.createTree(items, children, _textField, _valueField);
          node.items.push(children);
        }
      });
      return node;
    },
    emptyKendo() {
      var test = this.$refs[this.name].kendoWidget();
      test.value("");
    },
  },
};
</script>
<style>
.k-multiselect.form-control,
.form-control.k-dropdowntree {
  height: auto;
  min-height: 34px !important;
}

.k-dropdowntree .k-dropdown-wrap .k-input {
  height: calc(7px + 1.8285714286em) !important;
}
</style>
