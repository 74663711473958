<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingPage">
        <div slot="body">
          <span class="d-none d-md-block">
            <div class="row">
              <div class="col-12">
                <div class="float-left">
                  <bita-bootbutton
                    title="ایجاد تیکت"
                    type="primary"
                    icon="fas fa-plus-square"
                    btn-width="200px"
                    @click.native="createsTicket"
                    v-if="$acl.can('create', 'tickets')"
                  />
                </div>
              </div>
              &nbsp

              <bita-grid
                ref="grid"
                :columns="gridColumns"
                :read-data="readData"
                :page-size="pageSize"
                :current-page="currentPage"
                :has-row-number="true"
              />
            </div>
          </span>
        </div>
      </bita-ibox>
    </div>
    <ticketing-form ref="ticketingForm" :title="title" @load-data="loadData" />
    <ticket-modal ref="form" @on-create="createNewTicket" />
  </div>
</template>
<script>
import { ticketApi } from "@/api/apiManagement";
import ticketingForm from "./components/TicketingForm.vue";
import TicketModal from "./components/TicketModal.vue";

export default {
  components: {
    ticketingForm,
    TicketModal,
  },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت تیکتینگ",
          link: "/ticketings/tickets",
          active: true,
        },
      ],
      isLoadingPage: false,
      pageSize: 10,
      currentPage: 1,
      title: "",
    };
  },
  computed: {
    gridColumns() {
      return [
        { title: "عنوان", template: (e) => (e.title ? e.title : "-") },
        {
          title: "ایجاد کننده",
          template: (e) =>
            e.user_id && e.user && e.user.full_name ? e.user.full_name : "-",
        },
        {
          title: "وضعیت",
          template: (e) => (e.status && e.status_text ? e.status_text : "-"),
        },
        {
          title: "زمان ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "-",
        },
        {
          title: "آخرین به روز رسانی",
          template: (e) =>
            e.updated_at ? this.$getJalaliDateTime(e.updated_at) : "-",
        },
        { title: "عملیات", command: this.gridCommands() },
      ];
    },
  },
  methods: {
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    readData(e) {
      this.isLoadingPage = true;
      ticketApi
        .gets()
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch(() => e.success([]))
        .finally(() => (this.isLoadingPage = false));
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("view", "tickets"))
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
      if (this.$acl.can("delete", "tickets"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    createsTicket() {
      this.$toggleModal("#ticket-modal");
    },
    createNewTicket(tickets) {
      this.isLoading = true;
      tickets = this.getFormData(tickets);
      ticketApi
        .create(tickets)
        .then(() => {
          this.createTicketSucceeded();
          this.loadData();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    edit(e) {
      let ticketing = this.$refs.grid.getSelectedGridRow(e);
      console.log(ticketing);
      this.title = `ویرایش ${ticketing.title} `;
      this.$refs.ticketingForm.setInitialData(ticketing);
      this.$toggleModal("#ticketing-form");
    },
    createTicketSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$refs.form.reset();
      this.toggleTicketModal();
    },
    toggleTicketModal() {
      this.$toggleModal("#ticket-modal");
    },
    delete(e) {
      let ticketing = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف درخواست شماره ${ticketing.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingPage = true;
          ticketApi
            .delete(ticketing.id)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.loadData();
            })
            .finally(() => (this.isLoadingPage = false));
        }
      });
    },
  },
};
</script>
