<template>
  <div slot="body">
    <bita-form ref="form">
      <div slot="body">
        <div class="row">
          <component
            v-for="detail in details"
            :key="detail.id"
            v-model="form[detail.id]"
            :type="detail.input_type"
            :id="`dropdown-${detail.id}`"
            :label="detail.name"
            rules=""
            :data-source="[
              {id: 0, name: 'هیچکدام'},
              ...detail.detail_values
            ]"
            data-text-field="name"
            data-value-field="id"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-bind:is="detail.component_type"
          ></component>
        </div>
      </div>
    </bita-form>
  </div>
</template>
<script>
import { categoryApi, detailApi, productApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    largeColWidth: {
      default: "col-lg-6",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    productDetails: {},
  },
  data() {
    return {
      product: {},
      form: {},
      isLoading: false,
      details: [],
    };
  },
  watch: {},
  methods: {
    setInitialValue(_product) {
      this.product = _product;
      if (_product.id) this.getCategoryDetails();
    },

    getCategoryDetails() {
      this.isLoading = true;
      this.$emit("is-loading", true);
      productApi
        .getProductDetail(this.product.id)
        .then((res) => {
          this.details = res.data.entity.details;
          setTimeout(() => {
            this.form = res.data.entity.values;
            for (const key in this.form) {
              let refrenceID = "dropdown-" + key;
              var dropdownlist = $("#" + refrenceID).data("kendoDropDownList")
                ? $("#" + refrenceID).data("kendoDropDownList")
                : "";
              if (dropdownlist) {
                dropdownlist.select((dataItem) => {
                  return dataItem.id == this.form[key];
                });
                dropdownlist.trigger("change");
              }
            }
          }, 100);
        })
        .finally(() => {
          setTimeout(() => {
            this.$emit("is-loading", false);
            this.isLoading = false;
          }, 120);
        });
    },

    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
            productApi
              .updateProductDetail(this.product.id, this.form)
              .then(() => {
                this.$emit("on-update", this.form);
                this.$izitoast('success')

                resolve(true);
              })
              .finally(() => {
                this.$emit("is-loading", false);
              })
              .catch(() => reject(false));
          } else reject(false);
        });
      });
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      this.form = {};
      this.formRef.reset();
    },
  },
};
</script>
