import { apiCall } from "./../imports"

export default {

    getRoles() {
        return apiCall('get', "management/roles")
    },

    createRole(data) {
        return apiCall('post', `management/roles`, data)
    },

    updateRole(roleID, data) {
        return apiCall('patch', `management/roles/${roleID}`, data)
    },

    deleteRole(roleID) {
        return apiCall('delete', `management/roles/${roleID}`)
    }
}