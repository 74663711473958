<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <span class="d-none d-md-block">
            <tools v-if="$acl.can('view', 'admin-of-sellers')" excel="shop/products/export/excel" />
            
            <div class="row">
              <div class="col-12">
                <div class="float-left">

                    <!-- <bita-bootbutton
                    title="افزودن محصول جدید"
                    type="primary"
                    icon="fas fa-plus-square"
                    btn-width="200px"
                    @click.native="createProduct"
                    v-if="$acl.can('create', 'products')"
                  /> -->

                  <bita-bootbutton
                      title="افزودن محصول"
                      type="primary"
                      icon="fas fa-file-upload"
                      btn-width="200px"
                      @click.native="openProductInsertModal"
                      v-if="$acl.can('create', 'products')"
                      :disabled="sellerAuth.seller_status_id!=3 || sellerAuth.active!=1"
                  />

                  <p class="mt-2 text-warning" v-if="sellerAuth.seller_status_id!=3 || sellerAuth.active!=1" > برای ایجاد کردن محصول ابتدا باید پروفایل شما توسط پشتیبانی تایید شود لطفا منتظر بمانید ! </p>

                </div>

                <div class="float-right">
                  <bita-bootbutton
                    title="بروزرسانی قیمت"
                    type="warning"
                    icon="fas fa-file-upload"
                    btn-width="200px"
                    @click.native="openUpdateModal"
                    v-if="$acl.can('view', 'admin-of-sellers')"
                  />
                </div>

              </div>
            </div>

            <hr v-if=" articles.article_public.length > 0 || articles.article_private.length > 0 " />

            <div class="card mb-3" v-if="articles.article_public.length > 0" >
              <div class="card-header">
                اطلاعیه عمومی
              </div>

              <div class="card-body">
                <div id="carouselPublicArticle" class="carousel slide" data-ride="carousel" data-interval="9000">

                  <div class="carousel-inner">
                    <div :class="[ index == 0 ? `active` : '', `carousel-item`]" v-for="(item, index) in articles.article_public">
                      <img v-if="item.thumbnail != null" :src="item.thumbnail_url" :alt="item.title" class="w-100 announcement-border-radius px-5 bg-dark">
                      <div v-else class="p-3 bg-dark announcement-border-radius"> </div>
                      <div class="background-notification">
                        <h5 class="pt-2 px-5 bg-dark m-0 text-white" v-html="item.title"></h5>
                        <p class="p-1 px-5 bg-dark text-white text-justify" v-html="item.content"></p>
                      </div>
                    </div>
                  </div>

                  <button class="carousel-control-prev carousel-announcement-prev" type="button" data-target="#carouselPublicArticle" data-slide="prev">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                    <defs>
                    </defs>
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(45.02412451361867 45.024124513618645) scale(1.83 1.83)">
                      <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                      <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    </g>
                    </svg>
                  </button>
                  
                  <button class="carousel-control-next carousel-announcement-next" type="button" data-target="#carouselPublicArticle" data-slide="next">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                    <defs>
                    </defs>
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(209.979766536965 209.97976653696495) rotate(180) scale(1.83 1.83) matrix(1 0 0 -1 0 90)">
                      <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                      <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    </g>
                    </svg>
                  </button>

                </div>
              </div>

            </div>

            <div class="card mb-3" v-if="articles.article_private.length > 0">
              <div class="card-header">
                اطلاعیه شخصی
              </div>

              <div class="card-body">
                <div id="carouselPrivateArticle" class="carousel slide" data-ride="carousel" data-interval="9000">

                  <div class="carousel-inner">
                    <div :class="[ index == 0 ? `active` : '', `carousel-item`]" v-for="(item, index) in articles.article_private">

                      <a class="close-announcement" @click="readedPrivateArticleBySeller(item.id , item.seller_article_type)" >
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                          <defs>
                          </defs>
                          <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                            <path d="M 28.902 66.098 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 32.196 -32.196 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 32.438 64.633 C 31.461 65.609 30.182 66.098 28.902 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                            <path d="M 61.098 66.098 c -1.279 0 -2.56 -0.488 -3.535 -1.465 L 25.367 32.438 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 32.195 32.196 c 1.953 1.952 1.953 5.118 0 7.07 C 63.657 65.609 62.377 66.098 61.098 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                            <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 10 c -19.299 0 -35 15.701 -35 35 s 15.701 35 35 35 s 35 -15.701 35 -35 S 64.299 10 45 10 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                          </g>
                        </svg>
                      </a>

                      <img v-if="item.thumbnail != null" :src="item.thumbnail_url" :alt="item.title" class="w-100 announcement-border-radius px-5 bg-dark">
                      <div v-else class="pt-5 pb-3 bg-dark announcement-border-radius"></div>
                      <div>
                        <h5 class="pt-2 px-5 bg-dark m-0 text-white" v-html="item.title"></h5>
                        <p class="p-1 px-5 bg-dark text-white text-justify" v-html="item.content"></p>
                      </div>
                    </div>
                  </div>

                  <button class="carousel-control-prev carousel-announcement-prev" type="button" data-target="#carouselPrivateArticle" data-slide="prev">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                    <defs>
                    </defs>
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(45.02412451361867 45.024124513618645) scale(1.83 1.83)">
                      <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                      <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    </g>
                    </svg>
                  </button>
                  
                  <button class="carousel-control-next carousel-announcement-next" type="button" data-target="#carouselPrivateArticle" data-slide="next">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                    <defs>
                    </defs>
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(209.979766536965 209.97976653696495) rotate(180) scale(1.83 1.83) matrix(1 0 0 -1 0 90)">
                      <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                      <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    </g>
                    </svg>
                  </button>


                </div>
              </div>

            </div>

            <hr v-if="articles.article_public.length > 0 || articles.article_private.length > 0" />
          </span>

          <bita-form ref="filterForm">
            <div slot="body">
              <div class="row">

                <bita-input
                  type="text"
                  name="name"
                  label="جستجو"
                  validation-container-class="col-lg-4"
                  input-class="form-control"
                  v-model.lazy="filters.search"
                  @keyup.enter.prevent="loadData"
                />

                <bita-select
                  v-if="$acl.can('view', 'admin-of-sellers')"
                  label=" انتخاب تامین کننده"
                  name="seller_id"
                  ref="sellerSelect"
                  :validation-container-class="colWith"
                  rules="required"
                  input-class="form-control"
                  :data-source="supplires"
                  data-text-field="seller_fullname"
                  data-value-field="id"
                  v-model="filters.seller_id"
                />

                <bita-select
                  v-if="$acl.can('view', 'admin-of-sellers')"
                  label="نوع محصول محصول"
                  name="status"
                  ref="status"
                  :validation-container-class="colWith"
                  rules="required"
                  input-class="form-control"
                  :data-source="productsstatus"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="filters.status"
                />

                <div class="col-lg-4">
                  <a class="margin-top btn btn-success" @click="loadData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>

                <more-filter ref="moreFilter">

                  <bita-input
                    type="text"
                    name="barcode"
                    label="بارکد"
                    validation-container-class="col-lg-2"
                    input-class="form-control"
                    v-model.lazy="filters.barcode"
                    @keyup.enter.prevent="loadData"
                  />

                  <bita-input
                    type="text"
                    name="code"
                    label="کد محصول"
                    validation-container-class="col-lg-2"
                    input-class="form-control"
                    v-model.lazy="filters.code"
                    @keyup.enter.prevent="loadData"
                  />

                  <bita-select
                    name="status"
                    ref="status"
                    label="نوع محصول"
                    :validation-container-class="colWith"
                    input-class="form-control"
                    :data-source="statuses"
                    data-text-field="title"
                    data-value-field="id"
                    v-model="filters.is_saleable"
                  />

                  <bita-select
                    name="brand_id"
                    ref="brandSelect"
                    label="برند"
                    :validation-container-class="colWith"
                    input-class="form-control"
                    :data-source="brands"
                    data-text-field="name"
                    data-value-field="id"
                    v-model="filters.brand_id"
                  />

                  <bita-multiselect
                    name="standard_id"
                    ref="standardSelect"
                    label="استاندارد"
                    :validation-container-class="colWith"
                    input-class="form-control"
                    :data-source="standards"
                    data-text-field="name"
                    data-value-field="id"
                    v-model="filters.standard_id"
                  />

                  <bita-treeselect
                    name="category_id"
                    ref="categoryTreeselect"
                    label="گروه"
                    :validation-container-class="colWith"
                    input-class="form-control"
                    :data-source="categories"
                    data-text-field="name"
                    data-value-field="id"
                    v-model="filters.category_id"
                  />

                  <bita-select
                    name="is_recommended"
                    ref="recommendedSelect"
                    label="پیشنهاد لحظه ای"
                    :validation-container-class="colWith"
                    input-class="form-control"
                    :data-source="[
                      { id: 0, name: 'خیر' },
                      { id: 1, name: 'بله' },
                    ]"
                    data-text-field="name"
                    data-value-field="id"
                    v-model="filters.is_recommended"
                  />

                </more-filter>

              </div>
            </div>
          </bita-form>

          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :grid-server-side="true"
            :schema-total="'total'"
            :schema-data="'data'"
            :server-paging="true"
            :batch="true"
            :resizable="true"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>

    </div>

    <bita-modal
      id="info-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      >

      <div slot="body">
        <info-form
          ref="infoForm"
          @on-update="(e) => infoUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>

      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('infoForm')"
          :disabled="isLoadingModal"
        />
      </div>

    </bita-modal>

    <bita-modal
      id="details-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      >

      <div slot="body">
        <product-detail-form
          ref="detailForm"
          @on-update="(e) => detailUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>

      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('detailForm')"
          :disabled="isLoadingModal"
        />
      </div>

    </bita-modal>

    <bita-modal
      id="images-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      @close="(e) => $refs.imagesForm.reset()"
      >

      <div slot="body">
        <product-images-form
          ref="imagesForm"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>

      <!-- <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="$refs.infoForm.save()"
        />
      </div> -->
    </bita-modal>

    <bita-modal
      id="prices-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      >

      <div slot="body">
        <product-prices-form
          ref="pricesForm"
          @on-update="(e) => pricesUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
          :props-supplires="supplires"
        />
      </div>

      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('pricesForm')"
          :disabled="isLoadingModal"
        />
      </div>

    </bita-modal>

    <bita-modal
      id="wholesale-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      >

      <div slot="body">
        <product-wholesale-form
          ref="wholesaleForm"
          @on-update="(e) => wholesaleUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
          :props-supplires="supplires"
        />
      </div>

      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('wholesaleForm')"
          :disabled="isLoadingModal"
        />
      </div>

    </bita-modal>

    <bita-modal
      id="video-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      @close="(e) => $refs.videoForm.reset()"
      >

      <div slot="body">
        <product-video
          ref="videoForm"
          @on-update="(e) => videoUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>

      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('videoForm')"
          :disabled="isLoadingModal"
        />
      </div>

    </bita-modal>

    <bita-modal
      id="features-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      @close="(e) => $refs.featuresForm.reset()"
      >
      
      <div slot="body">
        <product-feature-form
          ref="featuresForm"
          @on-update="(e) => featuresUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>

      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('featuresForm')"
          :disabled="isLoadingModal"
        />
      </div>

    </bita-modal>

    <bita-modal
      id="seo-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
      @close="(e) => $refs.seoForm.reset()"
      >

      <div slot="body">
        <product-seo
          ref="seoForm"
          @on-update="(e) => seoUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>

      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-plus-circle"
          btn-width="150px"
          @click.native="save('seoForm')"
          :disabled="isLoadingModal"
        />
      </div>

    </bita-modal>

    <price-update-modal />
    <product-relation ref="productRelation" />
    <product-insert-modal />
    <product-supplires ref="productSupplires" />
    <product-feedback ref="productFeedback" />
  </div>
</template>
<script>
import InfoForm from "./components/ProductInfoForm";
import ProductImagesForm from "./components/ProductImagesForm";
import ProductDetailForm from "./components/ProductDetailForm";
import ProductPricesForm from "./components/ProductCombinationPricesForm";
import ProductWholesaleForm from "./components/ProductWholesaleForm";
import ProductFeatureForm from "./components/ProductFeatureForm";
import PriceUpdateModal from "./components/PriceUpdateModal";
import ProductSeo from "./components/ProductSeo";
import ProductRelation from "./components/ProductRelation";
import ProductVideo from "./components/ProductVideo";
import ProductInsertModal from "./components/ProductInsertModal";
import ProductSupplires from "./components/ProductSupplires.vue";
import ProductFeedback from "./components/ProductFeedback.vue";
import {
  productApi,
  categoryApi,
  brandApi,
  standardApi,
  measureApi,
  supplireApi,
  articleApi
} from "@/api/apiManagement";
import MoreFilter from "@/components/MoreFilter.vue";
import Tools from "../../../components/Tools.vue";
export default {
  components: {
    InfoForm,
    ProductImagesForm,
    ProductDetailForm,
    ProductPricesForm,
    ProductWholesaleForm,
    ProductFeatureForm,
    PriceUpdateModal,
    ProductSeo,
    MoreFilter,
    ProductRelation,
    ProductVideo,
    ProductInsertModal,
    ProductSupplires,
    ProductFeedback,
    Tools,
  },

  data() {
    return {
      statuses: [
        { id: 1, title: "فعال" },
        { id: 0, title: "غیر فعال" },
      ],
      productsstatus: [
        { id: 1, title: "منتشر شده" },
        { id: 2, title: "در انتظار تایید انتشار" },
        { id: 3, title: "دارای نقص" },
      ],
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "فروشگاه", link: "" },
        {
          title: "مدیریت محصولات",
          link: "/management/roles",
          active: true,
        },
      ],
      colWith: "col-lg-2",
      pageSize: 30,
      currentPage: 1,
      isLoadingForm: false,
      isLoadingGrid: false,
      isLoadingModal: false,
      // moreFilter: null,
      formTitle: "",
      permissions: [],
      categories: [],
      brands: [],
      standards: [],
      role: {
        name: "",
        permissions: [],
      },
      editForm: {},
      filters: {},
      supplires: [],
      articles: [],
      sellerAuth: {},
      formData: {
        article_id: "",
        type: "",
      },
    };
  },
  computed: {
    gridColumns() {
      return [
        // { title: "#", template: this.$getTemplate, width: "40px" },
        {
          field: "name",
          title: "نام محصول",
          width: 350,
        },
        {
          field: "status",
          title: "وضعیت محصول",
          template:(e)=> this.productStatusTitle(e),
           width: 150,
        },
        {
          field: "visit_count",
          title: "بازدیدها",
          width: 70,
        },
        // {
        //   field: "coefficient",
        //   title: "ضریب فروش",
        //   // width: 40,
        // },
        {
          field: "is_saleable",
          title: "نوع محصول فروش",
          template: this.saleableRender,
          // width: 40,
        },

        // {
        //   field: "is_recommended",
        //   title: "پیشنهاد",
        //   template: this.recommendedRender,
        //   // width: 40,
        // },
        {
          field: "active",
          title: "نوع محصول",
          template: this.activeRender,
          // width: 40,
        },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
          width: 150,
        },
        {
          title: "عملیات",
          width: 580,
          command: this.getGridColumns(),
        },
      ];
    },
  },
  mounted() {
    this.getArticlesForProduct();
    if (this.$acl.can("view", "verified-of-sellers")) {
      this.getActiveSupplire();
    }
    this.getTypeOfSellerAuth();
    // this.moreFilter = this.$refs.moreFilter;

    // this.moreFilter.setLoading(true);
    var proms = [categoryApi.getCategories(), brandApi.get()];
    if (this.$acl.can("view", "standards")) {
      proms.push(standardApi.get());
    }
    Promise.all(proms).then((res) => {
      // this.moreFilter.setLoading(false);
      this.categories = res[0].data.entity;
      this.brands = res[1].data.entity;
      if (this.$acl.can("view", "standards"))
        this.standards = res[2].data.entity;
    });
    // this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {

    /*
     * readed private article by seller
     *
     * @param int article_id
     * @param int type
    */
    readedPrivateArticleBySeller (article_id,type) {

      this.formData.article_id = article_id;
      this.formData.type = type;

      articleApi
        .readedPrivateArticleBySeller(this.formData)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          window.location.reload();
        })
        .finally(() => {
          this.$emit("is-loading", false);
        });

    },

    getGridColumns() {
      let commands = [];
      if (this.$acl.can("edit", "products")) {
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
        commands.push({
          text: "items",
          click: this.editDetails,
          template: this.gridButtons.items,
        });
        commands.push({
          text: "info",
          click: this.editFeatures,
          template: this.gridButtons.info,
        });
        commands.push({
          text: "images",
          click: this.editImages,
          template: this.gridButtons.images,
        });
        commands.push({
          text: "prices",
          click: this.editPrices,
          template: this.gridButtons.prices,
        });
        commands.push({
          text: "wholesale",
          click: this.editWholesale,
          template: this.gridButtons.wholesale,
        });
        commands.push({
          text: "seo",
          click: this.editSeo,
          template: this.gridButtons.seo,
        });
        commands.push({
          text: "video",
          click: this.editVideo,
          template: this.gridButtons.video,
        });
        if (this.$acl.can("view", "change-sale-status"))
          commands.push({
            text: "saleSatus",
            click: this.editStatus,
            template: this.gridButtons.saleSatus,
          });
      }
      if (this.$acl.can("view", "productrelations"))
        commands.push({
          text: "relation",
          click: this.productRelation,
          template: this.gridButtons.relation,
        });
      commands.push({
        text: "view",
        click: this.viewProduct,
        template: this.gridButtons.view,
      });
      if (this.$acl.can("confirmation", "product-of-seller"))
        commands.push({
          text: "sellerProductConfirm",
          click: this.productStatus,
          template: this.gridButtons.sellerProductConfirm,
        });

      if (this.$acl.can("view", "sellers-by-product"))
        commands.push({
          text: "sellers",
          click: this.productSupplires,
          template: this.gridButtons.sellers,
        });
      if (this.$acl.can("view", "feedback"))
        commands.push({
          text: "sellerProductFeedback",
          click: this.productFeedback,
          template: this.gridButtons.sellerProductFeedback,
        });
      if (this.$acl.can("delete", "products"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });

      return commands;
    },
    save(ref) {
      this.$refs[ref].save();
    },
    resetFilters() {
      this.filters = {};
      this.$refs.filterForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
    },
    saleableRender(e) {
      let color = e.is_saleable == 1 ? "green" : "red";
      return `<span class="fas fa-shopping-bag" title="${this.getStringIsSalable(
        e.is_saleable
      )}" style="color:${color}"></span>`;
    },
    productStatusTitle(e) {
            let status = "";
            switch (Number(e.status)) {
              case 1:
                status = `<span class="badge badge-info" ">منتشر شده</span>`;
                break;
              case 2:
                status = `<span class="badge badge-warning" ">در انتظار تایید انتشار</span>`;
                break;
              case 3:
                status = `<span class="badge badge-danger" ">دارای نقص</span>`;
                break;
            }
            return status;
          },
    activeRender(e) {
      let view = e.active == 1 ? "fas fa-lock-open" : "fas fa-lock";
      let color = e.active == 1 ? "green" : "red";
      return `<span class="${view}" style="color:${color}"></span>`;
    },
    recommendedRender(e) {
      let view = this.isActive(e.is_recommended) ? "fa" : "far";
      let color = this.isActive(e.is_recommended) ? "gold" : "gray";
      return `<span class="${view} fa-star" style="color:${color}"></span>`;
    },
    formSucceeded(ref) {
      this.loadData();
      this.formTitle = "";
      this.$refs.infoForm.setInitialValue({});
      this.$toggleModal("#info-form");
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    readData(e) {
      this.pageSize = e.data.pageSize;
      this.currentPage = e.data.page;
      this.isLoadingGrid = true;
      this.$resetGrid();
      productApi
        .getProductsPaginated({
          page_size: this.pageSize,
          page: this.currentPage,
          ...this.filters,
        })
        .then((res) => {
          e.success(res.data);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    edit(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      if (
        this.$acl.can("view", "admin-of-sellers") ||
        product.status == 2 ||
        product.status == 3
      ) {
        this.isLoadingGrid = true;
        productApi
          .getProductInfo(product.id)
          .then((res) => {
            this.$refs.infoForm.setInitialValue({
              ...res.data.entity,
              category_id: String(product.category_id),
            });
            this.formTitle = `ویرایش اطلاعات ${product.name}`;
            this.$toggleModal("#info-form");
          })
          .finally(() => {
            this.isLoadingGrid = false;
          });
      } else
        this.$izitoast(
          "error",
          "محصول شما توسط ادمین تایید شده است و قابل ویرایش نمی باشد در صورت نیاز به ویرایش تیکت بزنید",
          "عدم دسترسی"
        );
    },
    editImages(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      if (this.$acl.can("view", "admin-of-sellers")) {
        this.$refs.imagesForm.setInitialValue(product);
        this.formTitle = `ویرایش تصاویر ${product.name}`;
        this.$toggleModal("#images-form");
      } else if (product.status == 2 || product.status == 3) {
        this.$refs.imagesForm.setInitialValue(product);
        this.formTitle = `ویرایش تصاویر ${product.name}`;
        this.$toggleModal("#images-form");
      }
    },
    editDetails(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      if (this.$acl.can("view", "admin-of-sellers")) {
        this.$refs.detailForm.setInitialValue(product);
        this.formTitle = `ویرایش جزئیات ${product.name}`;
        this.$toggleModal("#details-form");
      } else if (product.status == 2 || product.status == 3) {
        this.$refs.detailForm.setInitialValue(product);
        this.formTitle = `ویرایش جزئیات ${product.name}`;
        this.$toggleModal("#details-form");
      }
    },
    isActive(status) {
      return status == 1;
    },
    getStringIsSalable(isSalable) {
      return isSalable == 1 ? "قابل فروش" : "کاتالوگ";
    },
    editStatus(e) {
      let { id, name, is_saleable } = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از ${this.getStringIsSalable(
          !is_saleable
        )} کردن ${name} اطمینان دارید؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          productApi.toggleStatus(id).then(() => {
            this.loadData();
          });
        }
      });
    },
    editSeo(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      if (this.$acl.can("view", "admin-of-sellers")) {
        this.$refs.seoForm.setInitialValue(product);
        this.$toggleModal("#seo-form");
      }
      if (product.status == 2 || product.status == 3) {
        this.$refs.seoForm.setInitialValue(product);
        this.$toggleModal("#seo-form");
      }
    },
    editVideo(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      if (this.$acl.can("view", "admin-of-sellers")) {
        this.$refs.videoForm.setInitialValue(product);
        this.formTitle = `  ویدیو ${product.name}`;
        this.$showModal("#video-form");
      } else if (product.status == 2 || product.status == 3) {
        this.$refs.videoForm.setInitialValue(product);
        this.formTitle = `  ویدیو ${product.name}`;
        this.$showModal("#video-form");
      }
    },
    viewProduct(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      window.open(product.url);
    },
    productRelation(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      if (this.$acl.can("view", "admin-of-sellers")) {
        this.$refs.productRelation.setInitialData(product);
        this.$toggleModal("#product-relation");
      } else if (product.status == 2 || product.status == 3) {
        this.$refs.productRelation.setInitialData(product);
        this.$toggleModal("#product-relation");
      }
    },
    productSupplires(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.productSupplires.setInitialData(product);
      this.formTitle = `تامین کنندگان محصول${product.name}`;
      this.$toggleModal("#product-supplires");
    },
    productFeedback(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.productFeedback.setInitialData(product);
      this.formTitle = `بررسی محصول ${product.name}`;
      this.$toggleModal("#product-feedback");
    },
    editFeatures(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      if (this.$acl.can("view", "admin-of-sellers")) {
        this.$refs.featuresForm.setInitialValue(product);
        this.formTitle = `ویرایش مشخصات ${product.name}`;
        this.$toggleModal("#features-form");
      } else if (product.status == 2 || product.status == 3) {
        this.$refs.featuresForm.setInitialValue(product);
        this.formTitle = `ویرایش مشخصات ${product.name}`;
        this.$toggleModal("#features-form");
      }
    },
    editPrices(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.pricesForm.setInitialValue(product);
      this.formTitle = `ویرایش قیمت ها ${product.name}`;
      this.$showModal("#prices-form");
    },
    editWholesale(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.wholesaleForm.setInitialValue(product);
      this.formTitle = `فعال سازی عمده فروشی ${product.name}`;
      this.$showModal("#wholesale-form");
    },
    infoUpdated(_data) {
      this.loadData();
      this.formTitle = "";
      this.$refs.infoForm.setInitialValue({});
      this.$hideModal("#info-form");
    },
    detailUpdated(_data) {
      this.formTitle = "";
      this.$refs.detailForm.setInitialValue({});
      this.$hideModal("#details-form");
    },
    pricesUpdated(_data) {
      this.formTitle = "";
      // this.$refs.pricesForm.reset();
      this.$hideModal("#prices-form");
    },
    wholesaleUpdated(_data) {
      this.formTitle = "";
      this.$refs.wholesaleForm.reset();
      this.$hideModal("#wholesale-form");
    },
    videoUpdated(_data) {
      this.formTitle = "";
      this.$refs.videoForm.reset();
      this.$hideModal("#video-form");
    },
    featuresUpdated() {
      this.formTitle = "";
      this.$hideModal("#features-form");
    },
    seoUpdated() {
      this.formTitle = "";
      this.$hideModal("#seo-form");
    },
    delete(e) {
      let role = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف ${role.name} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          productApi
            .deleteProduct(role.id)
            .then((res) => {
              this.$izitoast("success", "با موفقیت حذف شد", "موفق");
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    productStatus(e) {
      let { id, name, is_saleable } = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        ` آیا از تایید محصول  ${name} اطمینان دارید؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          productApi.activeProductOfSeller(id).then(() => {
            this.loadData();
          });
        }
      });
    },
    createProduct() {
      this.$router.push({ path: "/shop/products/create" });
    },

    normalizeDatabase() {
      this.isLoadingGrid = true;
      productApi
        .normalizeDatabase()
        .then((e) => {
          this.$alert("success", "نرمال سازی شد");
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    openUpdateModal() {
      this.$toggleModal("#priceupdate-modal");
    },
    openProductInsertModal() {
      this.$toggleModal("#productinsert-modal");
    },
    getActiveSupplire() {
      supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },
    getArticlesForProduct() {
      articleApi.getArticlesForProduct().then(({ data }) => {
        this.articles = data.entity;
      });
    },
    getTypeOfSellerAuth() {
      productApi.getTypeOfSellerAuth().then(({ data }) => {
        this.sellerAuth = data.entity;
      });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
a.margin-top {
  margin-top: 27px;
}
a.margin-right {
  margin-right: 5px;
}
.margin-rigth {
  margin-right: 16px;
}

</style>

<style>
[data-field="is_saleable"],
[data-field="is_recommended"] {
  text-align: center !important;
}
</style>
