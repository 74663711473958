<template>
  <div :class="validationContainerClass">
    <v-validate :rules="rules" v-slot="{ errors, classes }" :name="label">
      <div class="form-group">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">*</div>
        </label>
        <div>
          <select
          :disabled="disabled"
            :name="name"
            :ref="name"
            :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
            v-model="content"
            @change="handleInput(content)"
          >
            <option
              v-for="selectData in dataSource"
              :value="selectData[dataValueField]"
              :key="selectData[dataValueField]"
            >{{selectData[dataTextField]}}</option>
          </select>
          <span class="invalid-feedback">{{ errors[0] }}</span>
        </div>
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons
};
</script>
