<template>
  <div :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="form-group">
        <label :for="id"
          >{{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i></div
        ></label>
        <date-picker
          :id="inputID"
          :name="name"
          :ref="name"
          :type="type"
          :placeholder="placeHolder"
          v-model="content"
          @input="handleInput(content)"
          :clearable="true"
          :auto-submit="true"
          :editable="true"
          :format="format"
          :display-format="displayFormat"
          :locale="locale"
          :locale-config="datePickerLocalConfig"
          :day-change-shortcut="true"
          :color="color"
          :disabled="disabled"
          :inputClass="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
        />
        <span class="invalid-feedback">{{ errors[0] }}</span>
      </div>
    </v-validate>
  </div>
</template>
<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons,
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  props: {
    locale: {
      type: String,
      default: "fa,en",
    },
    color: {
      type: String,
      default: "#1ab394",
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    displayFormat: {
      type: String,
      default: "jYYYY/jMM/jDD",
    },
  },
  computed: {
    datePickerLocalConfig() {
      if ((this.type == "datetime")) {
        return {
          fa: {
            displayFormat: "jYYYY/jMM/jDD HH:mm:ss",
            lang: { label: "شمسی" },
          },
          en: {
            displayFormat: "YYYY/MM/DD HH:mm:ss",
            lang: { label: "Gregorian" },
          },
        };
      } 
      if ((this.type == "datetime-with-format")) {
        return {
          fa: {
            displayFormat: "jYYYY/jMM/jDD HH:mm:ss",
            format: "YYYY/MM/DD HH:mm:ss",
            lang: { label: "شمسی" },
          },
          en: {
            displayFormat: "YYYY/MM/DD HH:mm:ss",
            format: "YYYY/MM/DD HH:mm:ss",
            lang: { label: "Gregorian" },
          },
        };
      } 
      if ((this.type == "date-only")) {
        return {
          fa: {
            displayFormat: "jYYYY/jMM/jDD",
            format: "YYYY/MM/DD",
            lang: { label: "شمسی" },
          },
          en: {
            displayFormat: "YYYY/MM/DD",
            format: "YYYY/MM/DD",
            lang: { label: "Gregorian" },
          },
        };
      } 
      if ((this.type == "months-days")) {
        return {
          fa: {
            displayFormat: "jMM/jDD",
            format: "YYYY/MM/DD",
            locale:"fa",
            lang: { label: "شمسی" },
          },
          en: {
            displayFormat: "jMM/jDD",
            format:"YYYY/MM/DD",
            locale:"fa",
            lang: { label: "Gregorian" },
          },
        };
      } 
      if ((this.type == "year")) {
       
        return {
          fa: {
            displayFormat: "jYYYY",
            format: "jYYYY",
            locale:"fa",
            lang: { label: "شمسی" },
          },
          en: {
           displayFormat: "jYYYY",
            format: "jYYYY",
            locale:"fa",
            lang: { label: "شمسی" },
          },
        };
      } 
      return {
        fa: {
          displayFormat: this.displayFormat,
          lang: { label: "شمسی" },
        },
        en: {
          displayFormat: this.format,
          lang: { label: "Gregorian" },
        },
      };
    },
  },
};
</script>
<style scoped>
.invalid-feedback {
  display: inline-block !important;
}
label {
  margin-bottom: 0 !important;
}
/* label {
    display: inline-block;
    margin-bottom: 0 !important;
}
.vpd-icon-btn {
    padding: 9px 10px !important;
} */
</style>
<style>
.vpd-input-group input {
  width: 100%;
}
label.vpd-icon-btn {
  margin-bottom: 0 !important;
}
</style>
