 <template>
  <div class="footer">
    
    <!-- <div v-if='this.$acl.can("view", "admin-of-sellers")' ><strong> تمام حقوق و امتیاز سایت متعلق به 
      <a href="https://taamsamane.com">شرکت تام سامانه دکان</a>
       می باشد </strong>&copy; ۱۴۰۱ {{ $appVersion }}</div> -->
  </div>
</template>
<script>
export default {
    
}
</script>