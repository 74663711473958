<template>
    <div class="L-body">
      <div class="container h-100">
        <div class="d-flex justify-content-center h-100 align-items-center">
          <div class="card-login row text-center">
            <!-- <div
              class="left-side col-4 text-center"
              style="background-color: #008080;"
            >
              <img
                class="mt-5 mb-3"
                src="https://koochebazaar.com/uploads/setting/LxBt52YTdv3nGBhlfw1Q3xS4oNCquhLdHDDRWQqv.png"
                alt=""
                style="width: 100px; border-radius: 100%;"
              />
              <p>
                {{ $appDescription }}
              </p>
            </div> -->
            <!-- <div class="right-side col-12 my-5"> -->
              <form class="login-form">
                <h1 class="bg-color-wh text-center">
                  {{ $appName }}
                </h1>
                <div :class="`form-group position-relative ${hasError ? 'has-error' : ''}`">
                  <input
                    type="email"
                    placeholder="ایمیل یا نام‌کاربری"
                    required="true"
                    v-model="login.login"
                    class="form-control curve  text-center"
                    id="username"
                  />
                </div>
                <div :class="`form-group position-relative ${hasError ? 'has-error' : ''}`">
                  <input
                  type="password"
                    class="form-control curve text-center"
                    placeholder="کلمه‌عبور"
                    required="true"
                    v-model="login.password"
                    id="password"
                  />
                </div>
                <h5  class="bg-color-wh text-left">
                  <a class="bg-color-wh" href="https://koochebazaar.com/users/profile/edit">رمز عبور / نام کاربری را فراموش کرده ام</a>
                </h5>
                <h3 v-if="hasError" class="text-danger">
                  {{ errorMessage }}
                </h3>
                <button type="submit" @click.prevent="logUserIn" class="btn btn-primary blue w-100 mb-5">
                  ورود به سامانه
                </button>
              </form>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import api from "@/api/auth";
  export default {
    data() {
      return {
        login: {
          login: "",
          password: "",
        },
        hasError: false,
        errorMessage: "",
      };
    },
    mounted() {
      this.$setLayout("simple-layout");
    },
    methods: {
      logUserIn() {
        if (!this.login.login || !this.login.password) {
          this.hasError = true;
          this.errorMessage = "اطلاعات کامل وارد نشده است";
          return;
        }
        this.$setIsLoading(true);
        api.getCsrf().then(() => {
          api
            .login(this.login)
            .then((res) => {
              this.$store.dispatch("login", res.data);
              this.$router.push("/");
            })
            .catch((error) => {
              this.hasError = true;
              this.errorMessage = error.response?.data?.message;
            })
            .finally(() => {
              setTimeout(() => {
                this.$setIsLoading(false);
              }, 500);
            });
        });
      },
    },
  };
  </script>
  <style scoped>
  .L-body {
    background-image: url("https://koochebazaar.com/uploads/texteditor/NbQyySiH6PJ2HH288wZyIeHZ7FegBBIo0gob7594.jpg");
    background-size: cover;
    height: 100% !important;
  }
  
  .card-login {
    width: 900px;
    border-radius: 8px;
    color: #ffffff99;
  }
  .login-form
  {
    width: 40%;
    margin: auto;
  }
  .curve
  {
    border-radius: 6px;
  }
  .blue
  {
    background-color:#124abb !important;
    border-color: #124abb !important;
    border-radius: 6px !important;
  }
  .bg-color-wh
  {
    color: white !important;
  }

  
  .card-header-login {
    background-color: #0009;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 14px;
    font-size: 21px;
  
    text-align: center;
  }
  
  .card-body-login {
    padding: 30px;
    background-color: #1f1f1f7f;
  }
  
  .form-control {
    box-shadow: none !important;
    color: black !important;
  }
  </style>
  