<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            v-if="!showForm&&$acl.can('create', 'users')"
            type="primary"
            title="کاربر جدید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="statusChangeModalCreate"
          />
          <tools excel="management/users/export/excel" />
          <hr />
          <bita-form ref="filterForm">
            <div slot="body">
              <div class="row">
                <bita-input
                  type="text"
                  name="name"
                  label="جستجو"
                  validation-container-class="col-lg-3"
                  input-class="form-control"
                  v-model.lazy="filters.search"
                  @keyup.enter="loadData()"
                />
                <bita-select
                  name="userStatus"
                  ref="userStatus"
                  label="نوع کاربر"
                  validation-container-class="col-lg-3"
                  input-class="form-control"
                  :data-source="[
                    { type: '2', title: 'همه کاربران' },
                    { type: '0', title: 'کاربران' },
                    { type: '1', title: 'مدیران' },
                  ]"
                  data-text-field="title"
                  data-value-field="type"
                  v-model="filters.type"
                />
                <div class="col-lg-4">
                  <a class="margin-top btn btn-success" @click="loadData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>
              </div>
            </div>
          </bita-form>
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :grid-server-side="true"
            :schema-total="'total'"
            :schema-data="'data'"
            :server-paging="true"
            :batch="true"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <form-modal
      ref="editModal"
      :isLoading="isLoadingModal"
      :props-data="updateForm"
      :props-roles="roles"
      :props-supplires="supplires"
      modal-id="edit-modal"
      :title="title"
      @on-create="onCreate"
      @on-update="onUpdate"
      @on-close="resetUser"
    />
  </div>
</template>
<script>
import UsersForm from "./components/UserForm";
import { userApi, roleApi,supplireApi } from "@/api/apiManagement.js";
import Tools from "../../../components/Tools.vue";
export default {
  components: { "form-modal": UsersForm, Tools },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت کاربران",
          link: "/management/users",
          active: true,
        },
      ],
      supplires:[],
      roles: [],
      filters: {},
      isLoadingGrid: false,
      isLoadingModal: false,
      showForm: false,

      updateForm: {},

      gridColumns: [
        { field: "name", title: "نام", width: "150px" },
        { field: "family", title: "نام خانوادگی", width: "150px" },
        { field: "email", title: "ایمیل", width: "150px" },
        { field: "mobile", title: "شماره تماس", width: "150px" },
        {
          field: "role",
          title: "نقش",
          template: this.getRoleBadge,
        },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          width: "150px",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          width: "150px",
          command: [],
        },
      ],
    };
  },
  computed: {
    title() { 
      let title = "افزودن";
      if (this.updateForm.id) title = "ویرایش";
      return " کاربر " + title;
    },
  },
  mounted() {
    this.loadData();
    this.rolesData();
    this.getActiveSupplire()

    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    resetFilters() {
      this.filters = {};
      this.$refs.filterForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
    },
    getRoleBadge(data) {
      let outPut = "";
      data.roles.forEach((element) => {
        outPut += `<span class="badge badge-primary" style="margin-right: 5px">${element.name}</span>`;
      });
      return outPut;
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    onCreate(data) {
      this.handleSubmit(data);
    },
    onUpdate(data) {
      this.handleSubmit(data, true);
    },
    handleSubmit(data, url = null) {
      this.isLoadingModal = true;
      let api;
      if (!url)
        api = userApi.createUser(this.getFormData(data))
      else
        api = userApi.updateUser(data.id,this.getFormData(data))

      api
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.$toggleModal('#edit-modal');

          this.updateForm = {};

          this.loadData();
          this.$refs.editModal.reset();

        })
        .finally(() => {
          this.isLoadingModal = false;
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        });
    },
    readData(e) {
      this.$resetGrid();
      this.pageSize = e.data.pageSize;
      this.currentPage = e.data.page;
      this.isLoadingGrid = true;
      userApi
        .getUsers({
          page_size: this.pageSize,
          page: this.currentPage,
          ...this.filters,
        })
        .then((res) => {
          e.success(res.data);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    rolesData() {
      this.$resetGrid();
      this.isLoadingGrid = true;
      roleApi
        .getRoles()
        .then((res) => {
          this.roles = res.data.entity;
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف کاربر  ${data.name} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          userApi
            .deleteUser(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    statusChangeModalCreate() {
      this.updateForm = {};
      $("#edit-modal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
    },
    gridCommands() {
      let commands = [];
      // commands.push({
      //   text: "view",
      //   click: this.view,
      //   template: this.gridButtons.view,
      // });
      if (this.$acl.can("edit", "users"))
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      if (this.$acl.can("delete", "users"))
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    resetUser() {
      this.updateForm = {};
    },
    edit(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.updateForm = {
        role_id: collect(data.roles)
          .pluck("id")
          .toArray(),
        ...data,
      };
      this.$toggleModal("#edit-modal");
    },
    getActiveSupplire() {
       supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
