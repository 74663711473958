<template>
  <bita-modal
    :id="id"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="title"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
            />
            <bita-select
              label="نوع"
              name="type"
              ref="type"
              :validation-container-class="colWidth"
              rules="required"
              input-class="form-control"
              :data-source="types"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.type"
            />
            <bita-uploader
              name="icon"
              label="تصویر"
              rules="size:50"
              ref="uploader"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.icon"
              :icon-url="formData.icon_url"
            />
            <bita-select
              v-show="isStaticPagesType"
              label="صفحه"
              name="link"
              ref="link"
              :validation-container-class="colWidth"
              rules="required"
              input-class="form-control"
              :data-source="staticPages"
              data-text-field="title"
              data-value-field="link"
              v-model="formData.link"
            />
            <bita-input
              v-show="!isStaticPagesType"
              type="link"
              name="link"
              label="لینک"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.link"
            />
            <bita-input
              type="number"
              name="order"
              label="اولویت"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.order"
            />
            <bita-select
              v-show="isUpdateMode"
              label="وضعیت"
              name="status"
              ref="status"
              :validation-container-class="colWidth"
              rules=""
              input-class="form-control"
              :data-source="statuses"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.status"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-show="!isUpdateMode"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleCreate"
        :disabled="isLoading"
      />
      <bita-bootbutton
        v-show="isUpdateMode"
        type="primary"
        title="بروزرسانی"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleUpdate"
        :disabled="isLoading"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
import { staticpageApi } from "@/api/apiManagement";
export default {
  props: {
    isLoading: Boolean,
    title: String,
    id: String,
    propsData: {},
  },
  data() {
    return {
      colWidth: "col-lg-4",
      formData: {},
      isLoadingModal: false,
      staticPages: [],
      types: [
        { name: "لینک داخلی", id: 1 },
        { name: "لینک خارجی", id: 2 },
        { name: "صفحات ثابت", id: 3 },
      ],
      statuses: [
        { name: "فعال", id: 1 },
        { name: "غیرفعال", id: 0 },
      ],
    };
  },
  watch: {
    propsData: function (_val) {
      this.formData = _val;
    },
    isLoading: function (_val) {
      this.isLoadingModal = _val;
    },
  },
  computed: {
    isStaticPagesType() {
      return this.formData.type == 3;
    },
    isUpdateMode() {
      return this.formData.id && this.formData.id != undefined;
    },
  },
  mounted() {
    this.getStaticPages();
  },
  methods: {
    getStaticPages() {
      staticpageApi.getStaticPages().then((res) => {
        this.staticPages = res.data.entity;
      });
    },
    handleCreate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    handleUpdate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.type.emptyKendo();
      this.$refs.uploader.reset()
    },
  },
};
</script>