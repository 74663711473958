import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route('supplire');

RouteRegistrar.add("supplire/sellers/Sellers", "sellers", "complete-seller-profile", "مدیریت تامین کنندگان")
RouteRegistrar.add("supplire/sellers/SellerCreate", "sellers/create", "complete-seller-profile", "ایجاد تامین کننده جدید")
RouteRegistrar.add("supplire/transactions/Transactions", "transactions", "finance-transactions-of-seller", "لیست تراکنش ها")

const routes = RouteRegistrar.allRoutes()

export default routes;
