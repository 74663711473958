<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoading">
        <div slot="body">
          <form-wizard
            ref="wizard"
            title
            subtitle
            next-button-text="مرحله بعد"
            back-button-text="مرحله قبل"
            finish-button-text="پایان"

            color="#1ab394"
            shape="circle"
            @on-complete="onComplete"
          >
            <tab-content
              title="اطلاعات فروشگاه "
              icon="fas fa-info"
              :before-change="() => validateInfo('infoForm', 'addressForm')"

            >

              <supplire-info-form
                ref="infoForm"
                :supplire="createSupplire"
                @on-update="(e) => (createSupplire = e)"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>


            <tab-content
              title="اطلاعات آدرس فروشنده"
              icon="fas fa-address-sign"
              :before-change="() => validateInfo('addressForm', 'financialForm')"


            >
              <supplire-address-form
                ref="addressForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>


             <tab-content
              title="اطلاعات مالی"
              icon="fas fa-dollar-sign"
              :before-change="() => validateInfo('financialForm', 'documentForm')"


            >
              <supplire-financial-form
                ref="financialForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>

            <tab-content
              title="اسناد "
              icon="fa fa-file"


            >
              <supplire-document-form
                ref="documentForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>


            <tab-content
              title="قوانین "
              icon="fa fa-balance-scale"
              :before-change="() => validateInfo('ruleForm')"

            >
              <supplire-rule-form
                ref="ruleForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>

            <tab-content
              title="تکمیل "
              icon="fa fa-check"

            >
              <supplire-complete-form
                ref="completeForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>
          </form-wizard>
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import SupplireInfoForm from "./components/SupplireInfoForm.vue";
import SupplireAddressForm from "./components/SupplireAddressForm.vue";
import SupplireFinancialForm from "./components/SupplireFinancialForm";
import SupplireDocumentForm from "./components/SupplireDocumentForm.vue";
import SupplireRuleForm from "./components/SupplireRuleForm.vue";
import SupplireCompleteForm from "./components/SupplireCompleteForm.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    SupplireInfoForm,
    SupplireAddressForm,
    SupplireCompleteForm,

    SupplireFinancialForm,
    SupplireDocumentForm,
    SupplireRuleForm
  },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "تامین کنندگان", link: "" },
        { title: "مدیریت تامین کنندگان", link: "/supplire/sellers" },
        {
          title: "پروفایل تامین کننده",
          link: "/supplire/sellers/create",
          active: true,
        },
      ],
      createSupplire: {},
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    validateInfo(_ref, _nextRef) {
      return new Promise((resolve, reject) => {
        console.log(_ref)
        console.log(_nextRef)
        this.$refs[_ref]
          .save()
          .then((res) => {
            this.$flog.success("WIZARD MOVE:", _ref, "TO", _nextRef);
            this.setInitials(_nextRef);
            resolve(true);
          })
          .catch((err) => {
            console.log('error')
            this.$flog.error("WIZARD MOVE FAILED:", _ref, "TO", _nextRef);
            reject(false);
          });
      });
    },

    setInitials(_ref) {
      switch (_ref) {
        
        case "addressForm":
          this.$refs.addressForm.setInitialValue(this.createSupplire);
          break;
        case "financialForm":
          this.$refs.financialForm.setInitialValue(this.createSupplire);
          break;
          case "documentForm":
          this.$refs.documentForm.setInitialValue(this.createSupplire);
          break;
          case "ruleForm":
          this.$refs.ruleForm.setInitialValue(this.createSupplire);
          break;
          case "completeForm":
          this.$refs.completeForm.setInitialValue(this.createSupplire);
          break;
        default:
          break;
      }
    },
    onComplete() {
      this.$router.push({ path: "/supplire/sellers" });

     /* this.createSupplire = {};
      let refsToReset = ["wizard", "infoForm","addressForm","financialForm","documentForm","ruleForm"];
      for (let toreset of refsToReset) {
        if (this.$refs[toreset]) this.$refs[toreset].reset();
      }*/
    },
  },
};
</script>
<style>
.vue-form-wizard .wizard-progress-bar {
  float: right;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left {
  float: right;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-right {
  float: left;
}

.vue-form-wizard .wizard-icon {
  height: inherit;
}
</style>