<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoading">
        <div slot="body">
          <div slot="body">
            <bita-form ref="form">
              <div slot="body">
                <div class="tabs-container">
                        <ul class="nav nav-tabs" role="tablist">
                            <li v-for="(value, index) in settings" :key="index">
                              <a :class="'nav-link ' + setActive(index)" data-toggle="tab" href="#tab-1" @click="openTab(value.children)"> {{ value.category_text }} </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div 
                              role="tabpanel" 
                              id="tab-1" 
                              class="tab-pane active"
                            >
                            
                                <div class="panel-body">
                                  <div
                                    class="row"
                                    v-for="(setting, index) in settingChildrens"
                                    :key="index"
                                  >
                                  <div class="col-lg-3">{{ setting.name }}</div>
                                    <div class="col-lg-4">
                                      <bita-input
                                        type="text"
                                        :name="setting.slug"
                                        :validation-container-class="colWidth"
                                        input-class="form-control"
                                        v-model="dataForm[setting.slug]"
                                        :subtitle="setting.description"
                                        v-if="isInput(setting.type)"
                                      />
                                      <bita-textarea
                                        v-else-if="isText(setting.type)"
                                        type="text"
                                        :name="setting.slug"
                                        :validation-container-class="colWidth"
                                        input-class="form-control"
                                        v-model="dataForm[setting.slug]"
                                        :subtitle="setting.description"
                                      />
                                      <bita-uploader
                                        v-else-if="isImage(setting.type)"
                                        :name="setting.slug"
                                        :ref="setting.slug"
                                        rules="size:50"
                                        :validation-container-class="colWidth"
                                        input-class="form-control"
                                        v-model="dataForm[setting.slug]"
                                        :icon-url="setting.image_url"
                                      />
                                      <bita-select
                                        :name="setting.slug"
                                        ref="categories"
                                        v-else-if="isActive(setting.type)"
                                        :validation-container-class="colWidth"
                                        :subtitle="setting.description"
                                        input-class="form-control"
                                        :data-source="[
                                          { id: 1, name: 'فعال' },
                                          { id: 2, name: 'غیرفعال' },
                                        ]"
                                        data-text-field="name"
                                        data-value-field="id"
                                        v-model="dataForm[setting.slug]"
                                      />
                                      <bita-colorpicker
                                      :name="setting.slug"
                                      :ref="setting.slug"
                                      v-else-if="isColor(setting.type)"
                                      :subtitle="setting.description"
                                       v-model="dataForm[setting.slug]" 
                                       input-class="form-control"/>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                  </div>
              </div>
            </bita-form>
          </div>
          <div slot="footer">
            <bita-bootbutton
              type="primary mt-3"
              title="ذخیره"
              icon="fa fa-check"
              btn-width="150px"
              @click.native="handleSubmit()"
            />
          </div>
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { settingApi } from "@/api/apiManagement.js";

export default {
  data() {
    return {
      color:"",
      isLoading: false,

      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت تنظیمات",
          link: "/management/settings",
          active: true,
        },
      ],
      colWidth: "col-lg-12",
      dataForm: {},
      settings: [],
      settingChildrens: [],
    };
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    isInput(value) {
      return value == 1;
    },
    isText(value) {
      return value == 2;
    },
    isImage(value) {
      return value == 3;
    },
    isActive(value) {
      return value == 4;
    },
    isColor(value) {
      return value == 5;
    },
    getSettings() {
      this.isLoading = true;
      settingApi.getSettings().then(({ data }) => {
        this.settings = data.entity;
        if ( this.settings.length > 0 && this.settings[0].children[0] ) this.openTab(this.settings[0].children);
        this.isLoading = false;
      });
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    handleSubmit() {
      this.$refs.form.validate().then((result) => {
        this.isLoading = true;
        if (result) {
          settingApi
            .updateSetting(this.getFormData(this.dataForm))
            .then(() => {
              this.getSettings();
              this.$izitoast("success", "اطلاعات با موفقیت ثبت شد.");
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    setActive(_index) {
      return _index === 0 ? 'active' : null;
    },
    openTab(_children) {
      this.isLoading = true;
      this.settingChildrens = _children;
      setTimeout(() => {
        this.dataForm = collect(_children).pluck("value", "slug").all();
        this.isLoading = false;
      }, 500);
    },
  },
};
</script>
<style scoped>
img {
  width: 75px;
  max-height: 120px;
}
.custom-file-label {
  padding: 0.5rem 4.2rem 0 0;
}
.center-align {
  text-align: center;
}
</style>
