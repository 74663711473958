<template>
  <div :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="form-group k-rtl">
        <!-- <div class="row">
          <div class="col-lg-12">
            <div class="uploader-input align-middle"> -->
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i>
          </div>
          <a v-if="content && deletable" @click="deleteImg"><i class="fas fa-trash-alt" ></i></a>
        </label>
        <input
          type="file"
          :id="inputID"
          :name="name"
          :ref="name"
          accept=""
          @change="setIcon"
          :class="`overflow-hidden ${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
        />
        <img
          v-if="preview"
          class="img rounded-circle circle-border m-b-md"
          :src="getImageUrl"
          alt="تصویر"
        />

        <span class="form-text m-b-none">
          حجم تصویر نباید بیشتر از {{ imageSize }} مگ باشد
        </span>
        <!-- </div>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-lg-12 text-center mx-auto"> -->
        <!-- <button
              class="btn btn-xs btn-danger remove-button"
              tooltip.top="حذف"
              type="button"
              v-if="form.icon"
              @click="removePicture"
              style="position: absolute"
            >
              <i class="fa fa-close"></i>
            </button> -->
        <!-- <img
              class="img rounded-circle circle-border m-b-md"
              :src="getImageUrl"
              alt="تصویر"
            />
          </div>
        </div> -->
        <span
          class="invalid-feedback"
          :style="`display: ${errors ? 'inline-block' : 'none'}`"
          >{{ errors[0] }}</span
        >
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons,
  props: {
    iconUrl: {
      type: String,
    },
    imageSize: {
      default: 1,
      type: Number,
    },
    preview: {
      default: true,
    },
    deletable: {
      default: false,
    }
  },
  data() {
    return {
      imageData: "",
    };
  },
  computed: {
    getImageUrl() {
      if (this.imageData) {
        return this.imageData;
      } else if (this.iconUrl) {
        return this.iconUrl;
      }
      return "/img/no-img.png";
    },
  },
  methods: {
    setIcon(event) {
      let files = event.target.files;
      if (files[0].size / (1024 * 1024) > this.imageSize) {
        this.$izitoast(
          "error",
          "خطا!",
          `سایز فایل نباید از ${this.imageSize} مگابایت بیشتر باشد`,
          {
            position: "topRight",
            displayMode: 2,
          }
        );
        $("#" + event.target.id).val("");
        return;
      }
      var input = event.target;
      if (input.files && input.files[0]) {
        this.loadIcon(input.files[0]);
        this.imageData = files[0];
        this.content = this.imageData;
        this.handleInput(this.imageData);
        this.$forceUpdate();
      }
    },
    deleteImg(){
      this.content = 'delete'
      this.handleInput('delete');
      this.reset();
    },
    loadIcon(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    reset() {
      this.imageData = "";

      var imageRef = this.$refs[this.name];
      imageRef.nextElementSibling.value = "";
      imageRef.value = "";
      imageRef.nextElementSibling.setAttribute("src", "/img/no-img.png");
    },
  },
};
</script>
<style scoped>
.img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 20px;
  left: 6px;
  object-fit: contain;
  transition: all 200ms;
}
.img:hover {
  transform: scale(3);
}
img.circle-border {
  border: 4px solid #cccccc;
  border-radius: 50%;
}
</style>
