<template>
  <bita-modal
    id="edit-modal"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.name"
            />
            <bita-select
              name="country"
              label="کشور"
              rules=""
              ref="countrySelect"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="countries"
              data-text-field="name"
              data-value-field="id"
              v-model="form.country_id"
            />
            <bita-input
              type="text"
              name="website"
              label="وبسایت"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.website"
            />
            <bita-uploader
              name="icon"
              label="تصویر"
              ref="uploader"
              rules="size:50"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.icon"
              :icon-url="form.icon_url"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!form.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    title: String,
    isLoading: Boolean,
    countries: Array,
  },
  data() {
    return {
      colWidth: "col-lg-4",
      form: {},
    };
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
  },
  methods: {
    setForm(data) {
      this.form = data;
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          var newData = this.getFormData(this.form);
          this.$emit("on-create", newData);
        }
      });
    },
    getFormData(data) {
      let formData = new FormData();

      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }

      if (this?.form?.id) {
        formData.append("id", this?.form?.id);
      }

      return formData;
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          var newData = this.getFormData(this.form);
          this.$emit("on-update", newData);
        }
      });
    },
    reset() {
      this.form = {};
      this.$refs.uploader.reset();
      this.$refs.countrySelect.emptyKendo();
      this.formRef.reset();
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
