import { apiCall, createQueryString } from "../imports"

const PREFIX = "payment/gateway-transactions"

export default {

    getTransactions(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    getTransactionsManaul(filters) {
        return apiCall('get', `${PREFIX}/manual${createQueryString(filters)}`)
    },

    createTransactions(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateTransactions(shippingID, data) {
        return apiCall('post', `${PREFIX}/${shippingID}/update`, data)
    },
    
    deleteTransactions(shippingID) {
        return apiCall('delete', `${PREFIX}/${shippingID}`)
    },
}