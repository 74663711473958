<template>
  <div class="btn btn-primary btn-rounded">{{ title }}</div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    // icon: {
    //   type: String,
    // },
  },
};
</script>
