import { apiCall } from "./../imports"

export default {

    getSettings() {
        return apiCall('get', "management/settings")
    },

    updateSetting(data) {
        return apiCall('post', `management/settings`, data)
    },

}