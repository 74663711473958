<template>
  <bita-modal
    id="fines-modal"
    title="ثبت جریمه / مرجوعی"
    :is-loading="isLoading"
    :modal-lg="true"
    @open="setFormData"
    @close="reset()"
  >
    >

    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-select
              label=" انتخاب  فروشنده"
              name="seller_id"
              ref="sellerSelect"
              :validation-container-class="colWidth"
              rules="required"
              input-class="form-control"
              :data-source="supplires"
              data-text-field="seller_fullname"
              data-value-field="id"
              v-model="formData.seller_id"
            />
            <bita-select
              label=" نوع تراکنش"
              name="type"
              ref="type_sellect"
              :validation-container-class="colWidth"
              rules="required"
              input-class="form-control"
              :data-source="[
              { id: 3, name: 'جریمه' },
              { id: 5, name: 'مرجوعی کالا' },
              ]"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.type"
            />

            <bita-input
              type="text"
              name="order_number"
              label="شماره سفارش"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.order_number"
            />
            <!-- <bita-select
                  name="reward_fine"
                  ref="reward_fine"
                  label="نوع"
                  rules="required"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :data-source="rewardFine"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="myform.reward_fine"
                /> -->

            <bita-input
              type="text"
              name="price"
              :label="$currencyLabel('قیمت')"
              :has-thousand-seprator="true"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.price"
            />

            <bita-datepicker
              type="datetime"
              id="check_date"
              validation-container-class="col-lg-3"
              rules=""
              label="تاریخ "
              inputClass="form-control"
              v-model="formData.date_time"
              format="YYYY-MM-DD HH:mm:ss"
              display-format="jYYYY/jMM/jDD HH:mm:ss"
            />
            <bita-input
              type="text"
              name="description"
              label="توضیحات"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.description"
            />
          </div>
        </div>
      </bita-form>
    </div>

    <div slot="footer">
      <bita-bootbutton
        title="ثبت"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
        :disabled="isLoading"
      />
    </div>
  </bita-modal>
</template>
<script>
import { supplireApi, paymethodApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      isLoading: false,
      largeColWidth: "col-lg-12",
      colWidth: "col-lg-3",
      errors: {},
      product: [],
      filters: {},
      products: [],
      formExistsProducts: false,
      formData: {},
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
      rewardFine: [
        { id: 1, title: "جریمه" },
        { id: 2, title: "تشویق" },
      ],
      supplires: [],
    };
  },
  mounted() {
    if (this.$acl.can("view", "sellers")) {
      this.getActiveSupplire();
    }
  },
  methods: {
    setFormData() {
      //this.$refs.products.clear();
      //  this.$refs.products.emptyKendo();
    },
    // savePenaltyReward() {
    //   this.$refs.form.validate().then((result) => {
    //     if (result) {
    //       this.$emit("on-create", this.myform);
    //     }
    //   });
    // },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },

    // getFormData() {
    //   let formData = new FormData();
    //   for (var key in this.formData) {
    //     if (this.formData[key] != null) {
    //       formData.append(key, this.formData[key]);
    //     }
    //   }
    //   return formData;
    // },
    createProduct() {
      this.$toggleModal("#productinsert-modal");
    },
    getActiveSupplire() {
      supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },

    reset() {
      this.formData = {};
      this.$refs.form.reset();
    },
  },
};
</script>
