<template>
    <bita-modal id="supplire-feedback" :is-loading="isLoadingModal" :modal-lg="true" title="بازخوردهای تامین کننده"
        @close="reset()">
        <div slot="body">
            <bita-form ref="feedbackForm"   v-if="$acl.can('create', 'supplire-feedback')">
        <div slot="body">
          <div class="row">
            <bita-select
                        label="نام سند"
                        ref="fields"
                        name="fields"
                        :validation-container-class="colWidth"
                        :data-source="fields"
                        input-class="form-control"
                        data-text-field="field_name"
                        data-value-field="id"
                        v-model="myform.seller_feedback_field_id"
                        />

                <bita-textarea  
                                        label="علت "

                                        type="text"
                                        validation-container-class="col-lg-12"
                                        input-class="form-control"
                                        v-model="myform.description"
                                      />
</div>
<bita-bootbutton title="ثبت" type="primary mb-3" icon="fas fa-save" btn-width="150px"
                        @click.native="submit" :disabled="isLoadingModal" />
</div>
</bita-form>
<bita-grid  v-if="showGrid && $acl.can('view', 'product-feedback')" ref="feedbackGrid" :columns="gridColumns" :read-data="readData"
                :page-size="pageSize" :has-row-number="true"  />

  

        </div>
    </bita-modal>
</template>
<script>
import {
    productApi,
    supplireApi,

} from "@/api/apiManagement";
export default {
    data() {
        return {
            product: [],
            formData: {},
            colWidth: "col-lg-3",
      colFullWidth: "col-lg-12",
      formData: {},
      myform:{},
      supplire: [],
      fields: [],
      filters: {},
      pageSize: 10,
      isLoadingModal: false,
            showGrid: false,

        }
    },
    mounted() {
     if (this.$acl.can("view", "view-fields-feedback-of-product")) {
       this.getProductTableFiledsname()
        }
    },
    computed: {
        gridColumns() {
            return [
            { title: "نام فیلد",template: this.getFieldName},
            { title: "عنوان",template: this.getPersianFieldLabel},
            { title: "علت",template: this.getReason},
                { title: "وضعیت",template:this.getStatusField},
                { title: "عملیات", command:  this.gridCommands()  }
            ]
        }
    },
    methods: {
        setInitialData(_data) {
            console.log(_data)
            this.showGrid = true;
            console.log(this.showGrid)

            this.product = _data;   
            this.myform.product_id = this.product.id 
            //this.getProductsAll();
            },


    getFieldName(e) {
        let fieldName = e.field_name;
        return `${fieldName}`;  
    },
    getPersianFieldLabel(e) {
        let persianLabel = e.fa_field_name ;
        return `${persianLabel}`;  
    },
    getStatusField(e) {
        let status = e.active ;
      return `${status}`;
    },
    getReason(e) {
        let reason = e.description ;
        return `${reason}`;

    },

        isCategory(selectValue) {
            return selectValue == 2;
        },
        readData(e) {
           
            this.isLoadingModal = true;
            productApi.getProductFeedback(this.product.id)
                .then((res) => {
                    e.success(res.data.entity);
                })
                .catch(() => e.success([]))
                .finally(() => this.isLoadingModal = false);
        },
        loadData() {
            this.$refs.feedbackGrid.loadDataSource();
        },
        gridCommands() {
            let commands = [];
            if (this.$acl.can("delete", "product-feedback"))
            commands.push({
                text: "delete",
                click: this.delete,
                template: this.gridButtons.delete
            });
            if (this.$acl.can("change", "flag-for-product-feedback-of-seller"))

            commands.push({
          text: "view",
          click: this.changeStatusOfFeedBack,
          template: this.gridButtons.view,
        });
            return commands;
        },
       
    delete(e) {
            let feedback = this.$refs.feedbackGrid.getSelectedGridRow(e);
            this.$alert("question", `آیا از حذف اطمینان دارید؟`, "", {
                confirmButtonText: "بله",
            }).then((result) => {
                if (result.value) {
                    this.isLoadingModal = true;
                    productApi.deleteFeedBack(feedback.id)
                        .then((res) => {
                            this.$izitoast("success", "موفق!", res.data.message);
                            this.loadData();
                        })
                        .finally(() => this.isLoadingModal = false);
                }
            });
        },
        changeStatusOfFeedBack(e){
      let feedback = this.$refs.feedbackGrid.getSelectedGridRow(e);

            console.log(feedback)
      this.$alert(
        "question",
        `بازخورد مورد نظر انجام شده است !؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          console.log(feedback);
          supplireApi
            .changeStatusOfFeedBack(feedback.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
       
        submit() {
            this.$refs.feedbackForm.validate()
                .then((res) => {
                    if (res) {
                        console.log(this.myform)
                        this.isLoadingModal = true;
                        productApi.insertProductFeedback(this.myform)
                            .then((res) => {
                                this.$izitoast("success", "موفق!", res.data.message);
                                this.loadData();
                                this.reset();
                            })
                            .finally(() => this.isLoadingModal = false);
                    }
                })
        },

        getProductTableFiledsname() {
            productApi.getProductTableFiledsname().then(({ data }) => {
        this.fields = data.entity;
      });
    },
        resetSelect() {
            this.$refs.products.clear();
            this.$refs.myform.clear();
            
            this.$refs.value_type.emptyKendo();

        },
        reset() {
            this.myform = {};
            this.$refs.fields.clear();
            this.showGrid = false;
        },
        getFormData() {
      let formData = new FormData();
      for (var key in this.formData) {
        if (this.formData[key] != null) {
          formData.append(key, this.formData[key]);
        }
      }
      return formData;
    },

        
    }
}
</script>
