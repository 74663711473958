<template>
    <bita-modal id="product-relation" :is-loading="isLoadingModal" :modal-lg="true" title="محصولات مرتبط"
        @close="reset()">
        <div slot="body">
            <bita-form ref="relationForm">
                <div slot="body">
                    <div class="row">
                        <bita-select
                            label="نوع انتخاب محصولات"
                            name="type"
                            ref="type"
                            :validation-container-class="colWidth"
                            rules="required"
                            input-class="form-control"
                            :data-source="selectType"
                            data-text-field="name"
                            data-value-field="id"
                            v-model="selectValue"
                        />
                        <bita-multiselectserver
                        label="محصول"
                        ref="products"
                        name="products"
                        rules="required"
                        validation-container-class="col-lg-12"
                        :data-source="products"
                        data-text-field="name"
                        data-value-field="id"
                        :get-data="getProducts"
                        v-if="!isCategory(selectValue)" 
                        v-model="formData.related_product_id"
                        />
                        <!-- <bita-multiselect label="محصولات" ref="products" name="products"
                            validation-container-class="col-lg-12" :data-source="products" data-text-field="name"
                             :get-data="getProducts" data-value-field="id" v-model="formData.related_product_id" /> -->
                        <bita-treeselect name="category_id" ref="categoryTreeselect" label="انتخاب از گروه" rules=""
                            v-if="isCategory(this.selectValue)" :validation-container-class="colWidth"
                            input-class="form-control" :data-source="categories" data-text-field="name"
                            data-value-field="id" v-model="formData.category_id" />
                        <bita-select label="نوع" name="type" ref="type" :validation-container-class="colWidth"
                            rules="required" input-class="form-control" :data-source="type" data-text-field="name"
                            data-value-field="id" v-model="formData.type" />
                    </div>
                    <bita-bootbutton title="ثبت" type="primary mb-3" icon="fas fa-save" btn-width="150px"
                        @click.native="submit" :disabled="isLoadingModal" />
                </div>
            </bita-form>
            <bita-grid v-if="showGrid" ref="relationGrid" :columns="gridColumns" :read-data="readData"
                :page-size="pageSize" :has-row-number="true" />
        </div>
    </bita-modal>
</template>
<script>
import {
    productApi,
    categoryApi,

} from "@/api/apiManagement";
import ProductForm from "./ProductForm.vue";
export default {
    data() {
        return {
            selectValue: 2,
            pageSize: 10,
            colWidth: "col-4",
            isLoadingModal: false,
            showGrid: false,
            type: [
                { id: 1, name: "محصولات مشابه" },
                { id: 2, name: "محصولات مرتبط" },
                { id: 3, name: "پیشنهادات" }
            ],
            selectType: [
                { id: 1, name: "انتخاب از محصولات" },
                { id: 2, name: "انتخاب از گروه " }

            ],
            product: [],
            filters: {},
            products: [],
            formData: {},
            categories: [],

        }
    },
    mounted() {
        var _this = this;
        Promise.all([
            categoryApi.getCategories(),
        ]).then((res) => {
            this.categories = res[0].data.entity;

        });
        this.getProductsAll();
    },
    computed: {
        gridColumns() {
            return [
                { title: "نام", template: e => (e.product_id && e.product && e.product.name ? e.product.name : '-') },
                { title: "نوع", template: e => (e.type && e.type_text ? e.type_text : '-') },
                { title: "عملیات", command: this.gridCommands() }
            ]
        }
    },
    methods: {
        setInitialData(_data) {
            console.log(_data)
            this.showGrid = true;
            this.product = _data;
        },
        isCategory(selectValue) {
            return selectValue == 2;
        },
        readData(e) {
            this.isLoadingModal = true;
            productApi.getProductRelations(this.product.id)
                .then((res) => {
                    e.success(res.data.entity);
                })
                .catch(() => e.success([]))
                .finally(() => this.isLoadingModal = false);
        },
        loadData() {
            this.$refs.relationGrid.loadDataSource();
        },
        gridCommands() {
            let commands = [];
            commands.push({
                text: "delete",
                click: this.delete,
                template: this.gridButtons.delete
            });
            return commands;
        },
        delete(e) {
            let relation = this.$refs.relationGrid.getSelectedGridRow(e);
            this.$alert("question", `آیا از حذف ${relation.product.name} اطمینان دارید؟`, "", {
                confirmButtonText: "بله",
            }).then((result) => {
                if (result.value) {
                    this.isLoadingModal = true;
                    productApi.deleteProductRelation(this.product.id, relation.id)
                        .then((res) => {
                            this.$izitoast("success", "موفق!", res.data.message);
                            this.loadData();
                        })
                        .finally(() => this.isLoadingModal = false);
                }
            });
        },
        getProducts(e) {
            let filter = {
                search: e.filter?.value
            };
            productApi.getProducts(filter)
                .then((res) => {
                    this.products = _.unionBy(res.data, this.products, "id")
                })
        },
        getProductsAll(){
            productApi.getProducts()
                .then((res) => {
                    this.products = res.data
                    // this.products = _.unionBy(res.data, this.products, "id")
                })
        },
        submit() {
            this.$refs.relationForm.validate()
                .then((res) => {
                    if (res) {
                        this.isLoadingModal = true;
                        productApi.postProductRelation(this.product.id, this.formData)
                            .then((res) => {
                                this.$izitoast("success", "موفق!", res.data.message);
                                this.loadData();
                                this.resetSelect();
                            })
                            .finally(() => this.isLoadingModal = false);
                    }
                })
        },
        resetSelect() {
            this.$refs.products.clear();
            this.$refs.category_id.clear();
            this.$refs.type.emptyKendo();

        },
        reset() {
            this.product = {};

            this.showGrid = false;
        }
    }
}
</script>