import { render, staticRenderFns } from "./DimButton.vue?vue&type=template&id=0842e495&scoped=true&"
import script from "./DimButton.vue?vue&type=script&lang=js&"
export * from "./DimButton.vue?vue&type=script&lang=js&"
import style0 from "./DimButton.vue?vue&type=style&index=0&id=0842e495&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0842e495",
  null
  
)

export default component.exports