<template>
  <bita-modal
    :id="id"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.name"
              :disabled="true"
            />
            <!-- <bita-input
              type="text"
              name="slug"
              label="اسلاگ"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.slug"
              :disabled="true"
            /> -->
            <bita-select
              label="نوع"
              name="type"
              ref="type"
              :validation-container-class="colWidth"
              rules="required"
              input-class="form-control"
              :data-source="templateType"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.template_type"
              :disabled="true"
            />
            <bita-input
              v-show="formData.template_type == 3"
              type="text"
              name="template_key"
              label="کلید"
              :rules="formData.template_type == 3 ? 'required' : ''"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.template_key"
            />
          </div>
          <div class="row">
            <div
              class="col-lg-3"
              v-if="formData.id && formData.template_type == 1"
            >
              <h5>تگ های قابل استفاده در متن</h5>
              <small> جهت کپی تگ بر روی آن کلیک نمایید </small>
              <hr />
              <div class="row">
                <div
                  @click="copyToClipboard(key)"
                  class="p-1"
                  v-for="(index, key) in this.formData.variables"
                  :key="key"
                  :value="key"
                >
                  {{ index + ` : [${key}]` }}
                </div>
              </div>
            </div>
            <bita-textarea
              v-if="formData.template_type == 1"
              name="content"
              ref="contentTextarea"
              label="محتوا"
              rules=""
              validation-container-class="col-lg-9"
              input-class="form-control"
              v-model="formData.content"
            />
            <div class="row" v-show="formData.template_type == 3">
              <bita-select-simple
                label="متغیر ۱"
                name="type"
                ref="type"
                :validation-container-class="colWidth"
                :rules="formData.template_type == 3 ? 'required' : ''"
                input-class="form-control"
                :data-source="varDB"
                data-text-field="key"
                data-value-field="key"
                v-model="formData.token"
                :disabled="true"
              />
              <bita-select-simple
                label="متغیر ۲"
                name="type"
                ref="type"
                :validation-container-class="colWidth"
                rules=""
                input-class="form-control"
                :data-source="varDB"
                data-text-field="key"
                data-value-field="key"
                v-model="formData.token2"
                :disabled="true"
              />
              <bita-select-simple
                label="متغیر ۳"
                name="type"
                ref="type"
                :validation-container-class="colWidth"
                rules=""
                input-class="form-control"
                :data-source="varDB"
                data-text-field="key"
                data-value-field="key"
                v-model="formData.token3"
                :disabled="true"
              />
            </div>
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleUpdate"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    isLoading: Boolean,
    id: String,
    title: String,
  },
  data() {
    return {
      colWidth: "col-lg-4",
      formData: {},
      variable: {},
      templateType: [
        { id: 1, name: "SMS" },
        { id: 2, name: "E-Mail" },
        { id: 3, name: "Verification" },
      ],
    };
  },
  computed: {
    varDB() {
      var res = [{key: null}];
      for (var item in this.formData.variables) {
        res.push({ key: item });
      }
      return res;
    },
  },
  methods: {
    setInitialData(formData) {
      this.formData = formData;
      this.formData.variables =
        this.formData && this.formData.variables
          ? this.serializeVariables(this.formData.variables)
          : null;
      // this.$refs.contentTextarea.setInitialValue(
      //   this.formData.content ? this.formData.content : ""
      // );
    },
    serializeVariables(_variables) {
      let filter = collect(_variables);
      return filter
        .filter(function(_val, _key) {
          return (
            _key !== "_events" &&
            _key !== "uid" &&
            _key !== "_handlers" &&
            _key !== "parent"
          );
        })
        .all();
    },
    copyToClipboard(e) {
      this.$clipboard(`[${e}]`);
      this.$izitoast("success", "موفق", "تگ مورد نظر کپی شد.");
    },
    handleUpdate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    reset() {},
  },
};
</script>
