<template>
  <div slot="body">
    <bita-form ref="form">
      <div slot="body">
        <div class="row">
          <bita-input
            type="text"
            name="name"
            label="نام فروشگاه"
            rules="required"
            :validation-container-class="colWidth"
            input-class="form-control"
            v-model="form.name"
          />
          <hr />

          <bita-input
            type="text"
            name="ssn"
            label="کد ملی فروشنده"
            rules="required|numeric|irani"
            :validation-container-class="colWidth"
            input-class="form-control"
            v-model="form.ssn"
          />
          <bita-input
            type="text"
            name="phone"
            label="شماره تماس فروشگاه"
            :validation-container-class="colWidth"
            input-class="form-control"
            v-model="form.phone"
          />
          <bita-select
            label="سمت"
            name="post"
            :validation-container-class="colWidth"
            input-class="form-control"
            :data-source="getPost"
            data-text-field="title"
            data-value-field="title"
            v-model="form.post"
          />
          <bita-uploader
            name="logo"
            label="لوگو فروشگاه"
            rules="size:50"
            ref="uploader"
            :validation-container-class="colWidth"
            input-class="form-control"
            v-model="form.logo"
            :icon-url="form.logo_url"
          />
          <bita-input
            type="text"
            name="rate"
            label="رتبه فروشگاه"
            :validation-container-class="colWidth"
            input-class="form-control"
            v-model="form.rate"
            v-if="$acl.can('view', 'admin-of-sellers')"
          />
          <bita-input
            type="text"
            name="phone"
            label="عملکرد"
            :validation-container-class="colWidth"
            input-class="form-control"
            v-model="form.performance"
            v-if="$acl.can('view', 'admin-of-sellers')"
          />
          <bita-textarea
            name="description"
            ref="shortDescriptionTextarea"
            label="توضیح مختصر در مورد فروشگاه"
            rules=""
            validation-container-class="col-lg-12"
            input-class="form-control"
            v-model="form.description"
          />
        </div>
      </div>
      <div slot="body">
        <p>
          (این تصاویر بعد از تایید در وب سایت به مشتریان نمایش داده میشود لطفا
          در بارگزاری و ارسال آن دقت نمایید )تصاویر از محیط کار و فروشگاه:
        </p>

        <div class="row">
          <label for="file" class="btn">انتخاب فایل</label>
          <input
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />
          <div class="col-lg-6">
            <bita-bootbutton
              type="primary"
              title="بارگزاری"
              icon="fas fa-save"
              btn-width="150px"
              @click.native="saveStoreImage"
            />
          </div>
        </div>

        <div class="row" id="photos">
          <div
            class="col-lg-3 photo"
            v-for="(image, index) in documents"
            :key="index"
          >
            <span
              class="delete-image"
              @click="deleteImageStoreOfSeller(formData.store_id, image.id)"
              ><i style="color: red;" class="fas fa-times-circle"></i
            ></span>
            <a
              @click="
                downloadFile(
                  image.id,
                  image.document_title.name,
                  image.images[0].mime_type
                )
              "
              style="    font-size: xxx-large;margin: 16px;"
              ><img :src="image.full_url" width="200" height="200"
            /></a>
          </div>
        </div>
      </div>
    </bita-form>
  </div>
</template>
<script>
import { supplireApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
    largeColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    supplire: {},
  },
  data() {
    return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
      delForm: {
        store_id: "",
        file_id: "",
      },
      formData: {
        store_id: "",
        file: "",
      },
      form: {},
      imageForm: {},
      description: "",
      name: "",
      ssn: "",
      documents: [],
      getPost: [
        { id: 1, title: "تامین کننده" },
        { id: 2, title: "وارد کننده" },
        { id: 3, title: "تولید کننده" },
        { id: 3, title: "فروشنده" },
      ],
    };
  },
  // mounted() {
  //   this.getImageStoreOfSellerAuth();
  // },

  computed: {
    formRef() {
      return this.$refs.form;
    },
    isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
  },
  methods: {
    setInitialValue(_data) {
      this.formData.store_id = _data?.store?.id;
      this.form.name = _data.store.name;
      this.form.ssn = _data.store.ssn;
      this.form.description = _data.store.description;
      this.form.post = _data.store.post;
      this.form.performance = _data.store.performance;
      this.form.rate = _data.store.rate;
      this.form.logo_url = _data.store.logo_url;
      this.form.seller_id = _data.id;
      this.getImageStoreOfSellerAuth();
    },
    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
            //if (this.isUpdateMode) {
            supplireApi
              .createSupplire(this.getDataInForm(this.form))
              .then((res) => {
                this.$emit("on-update", this.getDataInForm(this.form));
                resolve(true);
                this.$hideModal("#info-form");
              })
              .finally(() => {
                this.$emit("is-loading", false);
              })
              .catch((err) => {
                this.setErrors(err.response.data.errors);
                reject(err);
              });
            //}
            /*  else {

              supplireApi
                .createSupplire(this.$getFormData(this.form))
                .then((res) => {
                  this.form = res.data.entity;
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
                   }*/
          } else reject(false);
        });
      });
    },
    saveStoreImage(formData) {
      this.isLoadingModal = true;
      //documentImage = this.getFormData(this.formData);
      supplireApi
        .UploadImageOfStoreSeller(this.getFormData(this.form))
        .then((res) => {
          console.log(res);
          this.getImageStoreOfSellerAuth();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },

    deleteImageStoreOfSeller: function(store, file) {
      this.delForm.store_id = store;
      this.delForm.file_id = file;
      let question = "آیا از حذف تصویر سند اطمینان دارید؟";

      this.$alert("question", question, null, {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.$emit("is-loading", true);
          supplireApi
            .deleteImageOfStoreSeller(this.delForm)
            .then((res) => {
              this.getImageStoreOfSellerAuth();
              // this.getDocumentsOfSeller(document.seller_id);
              this.$izitoast("success", "عملیات موفق", res.data.message);
            })
            .finally(() => {
              this.$emit("is-loading", false);
            });
        }
      });
    },
    handleFileUpload() {
      this.formData.file = this.$refs.file.files[0];
    },
    getImageStoreOfSellerAuth() {
      console.log(this.formData);
      supplireApi
        .getImagesOfSellerStoreAuth(this.formData.store_id)
        .then(({ data }) => {
          this.documents = data.entity;
        });
    },
    getFormData() {
      let formData = new FormData();
      for (var key in this.formData) {
        if (this.formData[key] != null) {
          formData.append(key, this.formData[key]);
        }
      }
      return formData;
    },
    getDataInForm() {
      let form = new FormData();
      for (var key in this.form) {
        if (this.form[key] != null) {
          form.append(key, this.form[key]);
        }
      }
      return form;
    },
    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },
    reset() {
      this.form = {};

      this.formRef.reset();
    },
  },
};
</script>
