<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :grid-server-side="true"
            :schema-total="'total'"
            :schema-data="'data'"
            :server-paging="true"
            :batch="true"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <ManualTransactionsDialog ref="dialog" />
  </div>
</template>
<script>
import { transactionApi } from "@/api/apiManagement";
import ManualTransactionsDialog from "./components/ManualTransactionsDialog";
export default {
  components: { ManualTransactionsDialog },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "لیست پرداخت های مستقیم", link: "/shop/transactions" },
      ],
      isLoadingGrid: false,
      pageSize: 30,
      currentPage: 1,
    };
  },
  computed: {
    gridColumns() {
      return [
        {
          field: "user",
          title: "نام",
          template: (e) => `${e.name||''} ${e.family||''}`,
        },
        {
          field: "user",
          title: "موبایل",
          template: (e) => `${e.mobile}`,
          width: 100,
        },
        {
          field: "price",
          title: this.$currencyLabel("قیمت"),
          width: 120,
          template: (e) => {
            return this.$thousandSeprator(this.$round(e.price));
          },
        },
        { field: "transaction.transaction_id", title: "کد پیگیری" },
        {
          field: "transaction.paymethod_text",
          title: "درگاه پرداخت",
        },
        {
          field: "created_at",
          title: "زمان تراکنش",
          width: 200,
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          width: 80,
          command: this.getGridColumns(),
        },
      ];
    },
  },
  methods: {
    readData(e) {
      this.isLoadingGrid = true;
      this.pageSize = e.data.pageSize;
      this.currentPage = e.data.page;
      transactionApi
        .getTransactionsManaul({
          page_size: this.pageSize,
          page: this.currentPage,
          ...this.filters,
        })
        .then((res) => {
          e.success(res.data);
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    getGridColumns() {
      return [
        {
          text: "info",
          click: this.showInfo,
          template: this.gridButtons.info,
        },
      ];
    },
    showInfo(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.dialog.setDetail(data);
      this.$toggleModal("#show-modal");
    },
  },
};
</script>
