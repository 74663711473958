<template>
    <bita-modal
        id="forum-form"
        :modal-lg="true"
        :is-loading="isLoadingModal"
        @close="reset()"
        :title="title"
    >
        <div slot="body">
            <bita-form ref="form">
                <div slot="body">
                    <div class="row">
                        <bita-input
                            type="text"
                            name="name"
                            label="نام ایجاد کننده"
                            rules=""
                            :validation-container-class="colWidth"
                            input-class="form-control"
                            v-model="user.name"
                            :disabled="true"
                        />
                        <bita-input
                            type="text"
                            name="email"
                            label="پست الکترونیک"
                            rules=""
                            :validation-container-class="colWidth"
                            input-class="form-control"
                            v-model="user.email"
                            :disabled="true"
                        />
                        <bita-input
                            type="text"
                            name="mobile"
                            label="تلفن همراه"
                            rules=""
                            :validation-container-class="colWidth"
                            input-class="form-control"
                            v-model="user.mobile"
                            :disabled="true"
                        />
                        <bita-select
                            name="type"
                            ref="categories"
                            label="وضعیت"
                            :validation-container-class="colWidth"
                            input-class="form-control"
                            :data-source="statuses"
                            data-text-field="name"
                            data-value-field="id"
                            v-model="formData.status"
                        />
                        <bita-input
                            type="text"
                            name="title"
                            label="عنوان"
                            rules="required"
                            validation-container-class="col-12"
                            input-class="form-control"
                            v-model="formData.title"
                        />
                        <bita-textarea
                            type="text"
                            name="message"
                            label="پیام"
                            validation-container-class="col-12"
                            input-class="form-control"
                            v-model="formData.message"
                            rowspan="5"
                        />
                    </div>
                </div>
            </bita-form>
            <div class="my-2">
                <bita-bootbutton
                    title="بروزرسانی"
                    type="primary"
                    icon="fas fa-save"
                    btn-width="150px"
                    @click.native="update"
                />
            </div>
            <div class="small-chat-box">
                <div class="content">
                <div
                    :class="isMyComment(replay) ? 'right' : 'left'"
                    v-for="replay in formData.replies"
                    :key="replay.id"
                >
                    <small 
                        v-if="replay.files && replay.files.length > 0" 
                        class="chat-date chat-icon text-success" 
                        @click="openFiles(replay)"
                        data-toggle="tooltip" title="نمایش فایل پیوست"
                    >
                        <i class="fa fa-link"> </i>
                    </small>
                    <small class="chat-date chat-icon text-success" @click="editReply(replay)" data-toggle="tooltip" title="ویرایش">
                        <i class="fas fa-pen-square"> </i>
                    </small>
                    <small class="chat-date chat-icon text-danger" @click="deleteReply(replay)" data-toggle="tooltip" title="حذف">
                        <i class="fas fa-times"> </i>
                    </small>
                    <div class="author-name">
                    {{ replay.user.full_name }}
                    <small class="chat-date">{{
                        $getJalaliDateTime(replay.created_at)
                    }}</small>
                    </div>
                    <div
                    :class="
                        isMyComment(replay)
                        ? 'chat-message'
                        : 'chat-message active'
                    "
                    >
                    {{ replay.message }}
                    </div>
                </div>
                </div>
                <bita-textarea
                    type="text"
                    name="message"
                    label="پیام"
                    validation-container-class="col-12"
                    input-class="form-control"
                    v-model="form.message"
                    rowspan="5"
                />
                <bita-bootbutton
                    v-if="form.id"
                    type="primary mx-2"
                    title="ذخیره"
                    icon="fa fa-paper-plane"
                    btn-width="150px"
                    @click.native="updateReply"
                />
                <bita-bootbutton
                    v-else
                    type="primary mx-2"
                    title="ارسال"
                    icon="fa fa-paper-plane"
                    btn-width="150px"
                    @click.native="sendReply"
                />
                <bita-bootbutton
                    v-if="form.id"
                    type="danger"
                    title="حذف"
                    icon="fas fa-times"
                    btn-width="150px"
                    @click.native="resetReply"
                />
            </div>
        </div>
    </bita-modal>
</template>
<script>
import { forumApi } from "@/api/apiManagement";
export default {
    props: {
        title: String,
    },
    data() {
        return {
            isLoadingModal: false,
            formData: {},
            form: {},
            colWidth: "col-4",
            statuses: [
                { id: 0, name: "در انتظار تایید" },
                { id: 1, name: "در حال نمایش" },
                { id: 2, name: "در انتظار پاسخ" },
                { id: 3, name: "پاسخ داده شد" },
            ],
            edit: false,
        }
    },
    computed: {
        user() {
            return this.formData && this.formData.user ? this.formData.user : {};
        }
    },
    methods: {
        setInitialData(_data) {
            this.formData = _data;
        },
        getForum(_forumID) {
            this.isLoadingModal = true;
            forumApi.get(_forumID)
                .then( (res) => {
                    this.formData = res.data.entity;
                })
                .finally( () => this.isLoadingModal = false );
        },
        isMyComment(_reply) {
            if ( (_reply && _reply.user ) && (this.formData && this.formData.user) )
            return _reply.user.id == this.formData.user.id ? false : true;
        },
        editTicket(_ticket) {
            this.form = _ticket;
        },
        openFiles(_ticket) {
            let url = _ticket.files && _ticket.files.length > 0 && _ticket.files[0] ? _ticket.files[0].full_url : null;
            window.open(url, '_blank');
        },
        deleteTicket(_ticket) {
            this.$alert(
                "question",
                `آیا از حذف ${_ticket.title} اطمینان دارید ؟`,
                "",
                {
                confirmButtonText: "بله",
                }
            ).then((result) => {
                if (result.value) {
                this.isLoadingPage = true;
                forumApi.delete(_ticket.id)
                    .then((res) => {
                        this.$izitoast("success", "موفق!", res.data.message);
                        this.$toggleModal('#ticketing-form');
                        this.loadData();
                    })
                    .finally(() => this.isLoadingPage = false )
                }
            });
        },
        editReply(_reply) {
            this.form = {
                id: _reply.id,
                message: _reply.message
            }
        },
        deleteReply(_reply) {
            this.$alert(
                "question",
                `آیا از حذف ${_reply.title} اطمینان دارید ؟`,
                "",
                {
                confirmButtonText: "بله",
                }
            ).then((result) => {
                if (result.value) {
                this.isLoadingPage = true;
                forumApi.deleteReply(this.formData.id, _reply.id)
                    .then((res) => {
                        this.$izitoast("success", "موفق!", res.data.message);
                        this.getForum(this.formData.id);
                    })
                    .finally(() => this.isLoadingPage = false )
                }
            });
        },
        sendReply() {
            if ( this.form && this.form.message ) {
                this.isLoadingModal = true;
                forumApi.createReply(this.formData.id, this.form)
                    .then( (res) => {
                        this.$izitoast("success", "موفق!", res.data.message);
                        this.getForum(this.formData.id);
                        this.resetReply();
                    })
                    .finally( () => this.isLoadingModal = false );
            } else {
                this.$izitoast('error', 'ناموفق!', 'پیام الزامی است');
            }

        },
        updateReply() {
            if ( this.form && this.form.message ) {
                this.isLoadingModal = true;
                let data = {
                    message: this.form.message
                };
                forumApi.updateReply(this.formData.id, this.form.id, data)
                    .then( (res) => {
                        this.$izitoast("success", "موفق!", res.data.message);
                        this.getForum(this.formData.id);
                        this.resetReply();
                    })
                    .finally( () => this.isLoadingModal = false );
            }
        },
        resetReply() {
            this.form = {}
        },
        update() {
            this.$refs.form.validate()
                .then( (res) => {
                    if ( res ) {
                        let data = {
                            status: this.formData.status,
                            message: this.formData.message,
                            title: this.formData.title,
                            department_id: this.formData.department_id,
                        }
                        this.isLoadingModal = true;
                        forumApi.update(this.formData.id, data)
                            .then( (res) => {
                                this.$izitoast("success", "موفق!", res.data.message);
                                this.getForum(this.formData.id);
                            })
                            .finally( () => this.isLoadingModal = false );
                    }
                })
        },
        reset() {
            this.$emit("load-data");
        },
    }
}
</script>
<style scoped>
    .chat-icon {
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 10px;
    }
</style>