import { apiCall } from "../imports";

const PREFIX = "article";

export default {

    getArticles() {
        return apiCall('get', `${PREFIX}`);
    },

    findArticle(id) {
        return apiCall('get', `${PREFIX}/${id}`);
    },
    
    createArticle(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateArticle(blogId, data) {
        return apiCall('post', `${PREFIX}/${blogId}/update`, data)
    },

    deleteArticle(blogId) {
        return apiCall('delete', `${PREFIX}/${blogId}`)
    },

    deleteFile(imageId) {
        return apiCall('delete', `${PREFIX}/${imageId}/file`)
    },
    getArticleMiddelePages(blogId) {
        return apiCall('get', `${PREFIX}/${blogId}/middlepage`)
    },

    getArticlesForHome() {
        return apiCall('get', `${PREFIX}/panel/home`)
    },

    getArticlesForProduct() {
        return apiCall('get', `${PREFIX}/panel/product`)
    },

    readedPrivateArticleBySeller(data) {
        return apiCall('patch', `${PREFIX}/panel/read`, data)
    },
}