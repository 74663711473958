<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <div class="row">
        <div class="col-md-3 mb-2">
          <div class="ibox">
            <div class="ibox-title" v-show="!isLoading">
              <h5>{{ user.name }} {{ user.family }}</h5>
            </div>
            <div>
              <div
                class="ibox-content no-padding border-left-right text-center container-imga"
                v-show="!isLoading"
              >
                <div class="container-avatar">
                  <img
                    alt="image"
                    class="img-fluid mt-3 image"
                    style="border-radius: 50%; height: 150px; width: 150px"
                    :src="user.avatar_url"
                  />
                  <div class="middle">
                    <a @click="avatarClick()"><li class="fas fa-edit"></li></a>
                  </div>
                </div>
                <div class="d-none">
                  <label for="file" class="btn">انتخاب فایل</label>
                  <input
                  id="file"
                    type="file"
                    ref="avatarInput"
                    @change="changeAvatar()"
                  />
                </div>
              </div>
              <div class="ibox-content profile-content">
                <bita-spinner
                  v-show="isLoading"
                  :is-loading="isLoading"
                  styleName="align-self: center;"
                />
                <div class="row m-t-lg"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 mb-4">
          <profile-form
            ref="form"
            :user="user"
            :is-loading="isLoading"
            :is-loading-update="isLoadingUpdate"
            :status-input="statusInput"
            @on-update-profile="(e) => updateProfile(e)"
            @on-change-status-input="(e) => chnageStatusInput(e)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileForm from "./components/ProfileForm";
import { userApi } from "@/api/apiManagement";
export default {
  components: { ProfileForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت سیستم", link: "" },
        {
          title: "پروفایل",
          link: "/management/profile",
          active: true,
        },
      ],
      colWidth: "col-lg-4",
      user: {},
      isLoading: false,
      isLoadingUpdate: false,
      statusInput: true,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.isLoading = true;

      userApi
        .getMe()
        .then((res) => {
          this.user = res.data;
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateProfile(e) {
      this.isLoadingUpdate = true;

      userApi
        .updateProfile(e)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.user = res.data.entity;
          this.$store.dispatch("userUpdate", res.data.entity);
          this.chnageStatusInput(!this.statusInput);
          this.$refs.form.reset();
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingUpdate = false;
        });
    },
    chnageStatusInput(e) {
      this.statusInput = e;
    },
    avatarClick() {
      this.$refs.avatarInput.click();
    },
    changeAvatar() {
      this.imageUrl = this?.$refs?.avatarInput?.files[0];

      let formData = new FormData();

      formData.append("avatar", this.imageUrl);

      userApi
        .updateAvatar(formData)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.user = res.data.entity;

          this.$store.dispatch("userUpdate", res.data.entity);
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingUpdate = false;
        });
    },
  },
};
</script>
<style>
.container-avatar {
  position: relative;
  height: 50%;
  width: 50%;
}

.container-avatar:hover .image {
  opacity: 0.5;
}

.container-avatar:hover .middle {
  opacity: 1;
  display: block;
}

.image {
  opacity: 1;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.text {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.container-imga {
  display: flex;
  justify-content: center;
}
</style>