<template>
  <bita-modal
    id="commission-form"
    :is-loading="isLoadingModal" :modal-lg="true" 
        @close="reset()">
  >
    <div slot="body">
     
      

      <bita-form ref="commissionForm">
        <div slot="body">
          <div class="row">
            <bita-multiselectserver
                        label="محصول"
                        ref="products"
                        name="products"
                        rules="required"
                        :validation-container-class="colWidth"
                        :data-source="products"
                        data-text-field="name"
                        data-value-field="id"
                        :get-data="getProducts"
              
                        v-model="formData.related_product_id"
                        />
            <bita-select
                  name="value_type"
                  ref="value_type"
                  label="نوع هزینه"
                  rules="required"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :data-source="priceTypes"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="myform.value_type"
                />

                <bita-input
             type="text" 
             name="value" 
             :label="$currencyLabel(' مقدار کمسیون قیمت')"
              :has-thousand-seprator="true" 
              min="0" 
              rules="required"
              v-if="!isPercentage(myform)" 
              :validation-container-class="colWidth" 
              input-class="form-control"
              v-model="myform.value" />

            <bita-input name="value" type="number" label="درصد کمسیون" v-else min="0" max="100" rules="required"
              :validation-container-class="colWidth" input-class="form-control" v-model="myform.value" />
</div>
<bita-bootbutton title="ثبت" type="primary mb-3" icon="fas fa-save" btn-width="150px"
                        @click.native="submit" :disabled="isLoadingModal" />
</div>
</bita-form>
<bita-grid  ref="relationGrid" :columns="gridColumns" :read-data="readData"
                :page-size="pageSize" :has-row-number="true" />

    </div>

    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
import {
  supplireApi

} from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
  },
  data() {
    return {
      colWidth: "col-lg-4",
      colFullWidth: "col-lg-12",
      formData: {},
      myform:{},
      supplire: [],
      products: [],
      filters: {},
      selectValue: 2,
      pageSize: 10,
      isLoadingModal: false,
            showGrid: false,
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
    };
  },
  computed: {
    gridColumns() {
            return [
                { title: "نام محصول" },
                { title: "مقدار کمسیون" },
                { title: "عملیات", command: this.gridCommands() }
            ]
        },
    isSupport() {
      return this.formData.support == 4;
    },
  },
  mounted() {
    this.getProductsAll();

  },
  methods: {
    setInitialData(_data) {
            this.showGrid = true;
            this.supplire = _data;
        },
   

    getProductsAll(){
            supplireApi.getProductsOfSeller()
                .then((res) => {
                    this.products = res.data
                    // this.products = _.unionBy(res.data, this.products, "id")
                })
        },
        getProducts(e) {
            let filter = {
                search: e.filter?.value
            };
            productApi.getProducts(filter)
                .then((res) => {
                    this.products = _.unionBy(res.data, this.products, "id")
                })
        },
        readData(e) {
          console.log(this.supplire.id)
      //  this.isLoadingModal = true;
        console.log('tesss  ')
            supplireApi.getCommissionOfseller(this.supplire.id)
                .then((res) => {
                    e.success(res.data.entity);
                })
                .catch(() => e.success([]))
                .finally(() => this.isLoadingModal = false);
        },
        loadData() {
            this.$refs.relationGrid.loadDataSource();
        },
        gridCommands() {
            let commands = [];
            commands.push({
                text: "delete",
                click: this.delete,
                template: this.gridButtons.delete
            });
            return commands;
        },
        submit() {
            this.$refs.commissionForm.validate()
                .then((res) => {
                    if (res) {
                        this.isLoadingModal = true;
                        productApi.postProductRelation(this.product.id, this.formData)
                            .then((res) => {
                                this.$izitoast("success", "موفق!", res.data.message);
                                this.loadData();
                                this.resetSelect();
                            })
                            .finally(() => this.isLoadingModal = false);
                    }
                })
        },
        delete(e) {
            let relation = this.$refs.relationGrid.getSelectedGridRow(e);
            this.$alert("question", `آیا از حذف ${relation.product.name} اطمینان دارید؟`, "", {
                confirmButtonText: "بله",
            }).then((result) => {
                if (result.value) {
                    this.isLoadingModal = true;
                    productApi.deleteProductRelation(this.product.id, relation.id)
                        .then((res) => {
                            this.$izitoast("success", "موفق!", res.data.message);
                            this.loadData();
                        })
                        .finally(() => this.isLoadingModal = false);
                }
            });
        },
    isPercentage(myform) {
      return myform.value_type == 1;
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },

    resetSelect() {
            this.$refs.products.clear();
           // this.$refs.category_id.clear();
            this.$refs.type.emptyKendo();

        },
        
        reset() {
            this.product = {};
          //  this.$refs.products.clear();
          this.$refs.form.reset();

            this.showGrid = false;
        },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
