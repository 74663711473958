import { apiCall } from "../imports"

const PREFIX = "shop/coupons"

export default {

    getCoupons(data) {
        return apiCall('get', `${PREFIX}`, data)
    },

    createCoupon(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateCoupon(couponID, data) {
        return apiCall('patch', `${PREFIX}/${couponID}`, data)
    },

    deleteCoupon(couponID) {
        return apiCall('delete', `${PREFIX}/${couponID}`)
    }
}