<template>
  <bita-modal
    :id="modalId"
    :title="title"
    :modal-lg="true"
    :isLoading="isLoading"
    @close="onCloseModal"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.name"
            />
            <bita-input
              type="text"
              name="family"
              label="نام خانوادگی"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.family"
            />
            <bita-input
              type="text"
              name="username"
              label="نام کاربری"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.username"
            />
            <bita-input
              type="text"
              name="ssn"
              label="کدملی"
              rules="numeric|irani"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.ssn"
            />
            <bita-input
              type="text"
              name="mobile"
              label="شماره تماس"
              rules="required|mobile"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.mobile"
            />
            <bita-input
              type="email"
              name="email"
              label="پست الکترونیک"
              rules="required|email"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.email"
            />
            <bita-input
              type="password"
              name="password"
              label="رمزعبور"
              :subtitle="dataForm.id ? 'در صورت پر شدن تغییر خواهد کرد' : ''"
              :rules="!dataForm.id ? 'required' : ''"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.password"
            />
            <bita-select
              label="جنسیت"
              name="sex"
              ref="sex"
              :validation-container-class="colWidth"
              rules=""
              input-class="form-control"
              :data-source="sex"
              data-text-field="name"
              data-value-field="id"
              v-model="dataForm.sex"
            />
            <bita-select
              label="دسترسی به مدیریت"
              name="is_administrator"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="[{id: 0,name: 'خیر'},{id: 1,name: 'بله'}]"
              data-text-field="name"
              data-value-field="id"
              v-model="dataForm.is_administrator"
            />
            <bita-multiselect
              label="نقش کاربری"
              ref="role_id"
              name="role_id"
              validation-container-class="colWidth"
              :data-source="propsRoles"
              data-text-field="fa_name"
              data-value-field="id"
              v-model="dataForm.role_id"
              v-show="accessToPanel"

            />
            <bita-select
              label="فروشنده ؟"
              ref="is_supplire"
              name="is_supplire"
              :validation-container-class="colWidth"
              :data-source="[{id: 0,name: 'خیر'},{id: 1,name: 'بله'}]"
              data-text-field="name"
              input-class="form-control"
              data-value-field="id"
              v-model="dataForm.is_seller"
              v-show="accessToPanel"
            />

            <!-- <bita-select
              label="لیست تامین کنندگان"
              ref="supplires"
              name="supplires"
              :validation-container-class="colWidth"
              :data-source="propsSupplires"
              data-text-field="seller_store_name"
              input-class="form-control"

              data-value-field="id"
              v-model="dataForm.is_seller_subset"
              v-show="!is_supplire"
              /> -->
            <bita-uploader
              name="icon"
              label="تصویر"
              rules="size:50"
              ref="uploader"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="dataForm.avatar"
              :icon-url="dataForm.avatar_url"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!isUpdate()"
        type="primary"
        title="ذخیره"
        icon="fa fa-check"
        btn-width="150px"
        @click.native="handleSubmit()"
      />
      <bita-bootbutton
        v-if="isUpdate()"
        type="primary"
        title="بروزرسانی"
        icon="fa fa-check"
        btn-width="150px"
        @click.native="handleSubmit()"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
        @click.native="
          () => {
            $emit('change-status-modal');
          }
        "
      />
    </div>
  </bita-modal>
</template>
<script>
import { collect } from "collect.js";
import { userApi } from "@/api/apiManagement.js";

export default {
  props: {
    modalId: {},
    isLoading: {},
    title: {},
    onSubmit: {},
    entities: {
      type: Array,
    },
    propsRoles: {
      type: Array,
    },
    propsSupplires: {
      type: Array,
    },
    propsData: {
      type: Object,
      default: {
        id: 0,
        name: "",
        family: "",
        ssn: "",
        mobile: "",
        password: "",
        role_id: [],
      },
    },
  },
  data() {
    return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
      dataForm: { avatar: "" },
      imageData: "",
      sex: [
        { id:1 ,name:"آقا"},
        { id:2 ,name:"خانوم"}
      ],
      
    };
  },
  watch: {
    propsData: function (_data) {
      this.dataForm = _data;
      // this.dataForm.role_id = collect(_data.roles)
      //   .pluck("id")
      //   .toArray()
      //   .filter((i) => i);
    },
  },
  computed:{
     accessToPanel() {
      return this.dataForm.is_administrator == 1
     },
     is_supplire() {
      return this.dataForm.is_seller == 1

     }
    },

  methods: {
    isUpdate() {
      return !!this.propsData.id;
    },
    handleSubmit() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (this.isUpdate()) this.$emit("on-update", this.dataForm);
          else this.$emit("on-create", this.dataForm);
          this.reset();
        }
      });
    },
    reset() {
      // this.dataForm = {};
      this.$refs.role_id.emptyKendo();
      this.$refs.form.reset();
      this.$refs.uploader.reset();
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
    onCloseModal(e) {
      this.$emit('on-close');
      this.reset();
    },
    onModelChange(e) {
      // this.$refs.modelIDDropDown.emptyKendo()
      this.$emit("change-model-type", e);
    },

  },
};
</script>
<style scoped>
img {
  width: 75px;
  max-height: 120px;
}
.custom-file-label {
  padding: 0.5rem 4.2rem 0 0;
}
.center-align {
  text-align: center;
}
</style>