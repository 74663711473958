<template>
  <bita-modal
    id="standard-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.name"
            />
            <bita-texteditor
              name="descriptionz"
              ref="descriptionTextarea"
              label="توضیحات"
              rules=""
              validation-container-class="col-12"
              input-class="form-control"
              v-model="formData.description"
            />
            <bita-uploader
              name="logo"
              label="تصویر"
              ref="logo"
              rules="size:50"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.logo"
              :icon-url="formData.logo_url"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="create"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
import { standardApi } from "@/api/apiManagement.js";

export default {
  data() {
    return {
      colWidth: "col-lg-4",
      formData: {},
      isLoadingModal: false,
      title: "",
    };
  },
  methods: {
    setInitialData(_data) {
      this.formData = _data;
      this.$refs.descriptionTextarea.setInitialValue(_data.description);

      if (_data && _data.id) this.title = `ویرایش ${_data.name}`;
      else this.title = `افزودن استاندارد`;
    },
    create() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.isLoadingModal = true;
          let data = {
            name: this.formData.name,
            description: this.formData.description,
            logo: this.formData.logo,
          };
          standardApi
            .create(this.$getFormData(data))
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.$toggleModal("#standard-form");
              this.$emit("load-data");
            })
            .finally(() => (this.isLoadingModal = false));
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.isLoadingModal = true;
          let data = {
            name: this.formData.name,
            description: this.formData.description,
            logo: this.formData.logo,
          };
          standardApi
            .update(this.formData.id, this.$getFormData(data))
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.$toggleModal("#standard-form");
              this.$emit("load-data");
            })
            .finally(() => (this.isLoadingModal = false));
        }
      });
    },
    reset() {
      this.formData = {};
      this.$refs.logo.reset();
      this.$refs.form.reset();
      this.$refs.descriptionTextarea.reset();
    },
  },
};
</script>
