<template>
  <bita-modal
    :id="id"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="title"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
            />
            <bita-select
              name="type"
              ref="typeSelect"
              label="نوع"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="typesSource"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.type"
            />
            <bita-treeselect
            v-if="formData.type == 1"
            name="category_id"
            ref="categoryTreeselect"
            label="گروه"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="categories"
            data-text-field="name"
            data-value-field="id"
            v-model="formData.category_id"
          />
            <bita-select
            v-if="formData.type == 2"
            name="brand_id"
            ref="brandSelect"
            label="برند"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="brands"
            data-text-field="name"
            data-value-field="id"
            v-model="formData.brand_id"
          />
            <bita-select
              name="active"
              ref="active"
              label="وضعیت نمایش "
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="active"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.active"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleCreate"
      />
      <bita-bootbutton
        v-if="formData.id"
        type="primary"
        title="به روزرسانی"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleUpdate"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
import {
  categoryApi,
  brandApi,
} from "@/api/apiManagement";
export default {
  props: {
    isLoading: Boolean,
    title: String,
    id: String,
    largeColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    propsData: {},
  },
  data() {
    return {
      colWidth: "col-lg-4",
      formData: {},
      isLoadingModal: false,
      categories: [],
      brands: [],
      active: [
        { id: 1, title: "فعال" },
        { id: 0, title: "غیرفعال" },
      ],
       };
  },
  computed:{
    typesSource() {
      return [
        { id: 1, name: " صفحه واسط دسته بندی با نشست دسته بندی منو" },
        { id: 2, name: "صفحه واسط برند" },
      ];
    },

    
  },
  mounted() {
      categoryApi
      .getCategories()
      .then((res) => {
      this.categories = res.data.entity;
    });
    brandApi
    .get()
    .then((res) => {
      this.brands = res.data.entity;
    });
  },
  watch: {
    propsData: function (_val) {
      this.formData = _val;
      // this.setInitialValue(_val.content);
    },
    isLoading: function (_val) {
      this.isLoadingModal = _val;
    },
  },
  methods: {
    setInitialValue(_data) {
      this.form = _data;
      this.$refs.contentTextarea.setInitialValue(_data ? _data : "");
    },
    handleCreate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    handleUpdate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    setErrors(err) {
      this.$refs.form.setErrors(err);
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.categoryTreeselect.emptyKendo();
    },
  },
};
</script>