import { apiCall, createQueryString } from "../imports"

const PREFIX = "management/jobs"

export default {

    get() {
        return apiCall('get', `${PREFIX}`)
    },

    getFailed() {
        return apiCall('get', `${PREFIX}/failed`)
    },

    flush() {
        return apiCall('post', `${PREFIX}/flush`)
    },

    retry(id) {
        return apiCall('post', `${PREFIX}/${id}/retry`)
    },

    retryAll() {
        return apiCall('post', `${PREFIX}/retry`)
    },

    // updateStaticPages(staticPageId, data) {
    //     return apiCall('patch', `${PREFIX}/${staticPageId}`, data)
    // },
    
    // deleteStaticPages(staticPageId) {
    //     return apiCall('delete', `${PREFIX}/${staticPageId}`)
    // },
}