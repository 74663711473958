import { apiCall, createQueryString } from "../imports"

const PREFIX = "payment/payment-methods"

export default {

    getPaymentMethods(filters) {
        return apiCall('get', `${PREFIX}/portals${createQueryString(filters)}`)
    },

    getPayMethods(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },
    getPayMethodsOfSeller() {
        return apiCall('get', `payment/payment-methods/seller`)
    },
    getBanks() {
        return apiCall('get', `${PREFIX}/banks`)
    },

    createPayMethods(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    deletePayMethods(paymethodId) {
        return apiCall('delete', `${PREFIX}/${paymethodId}`)
    },

    updatePayMethods(paymethodId,data) {
        return apiCall('post', `${PREFIX}/${paymethodId}/update`, data)
    },

}
