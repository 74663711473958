<template>
  <div slot="body">
    <bita-form ref="formRef">
      <div slot="body">
        <div class="row" v-if="features && features.length > 0">
          <bita-select
            v-for="(feature, index) in features"
            :key="index"
            type="text"
            :name="`select${feature.id}`"
            :ref="getRef(feature)"
            :label="feature.name"
            rules=""
            :validation-container-class="colWidth"
            :data-source="[
              {id: 0, name: 'هیچکدام'},
              ...feature.feature_values
            ]"
            data-text-field="name"
            data-value-field="id"
            input-class="form-control"
            v-model="featureValues[feature.id]"
          />
        </div>
      </div>
    </bita-form>
  </div>
</template>
<script>
import { productApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    colWidth: {
      default: "col-lg-3",
    },
  },
  data() {
    return {
      product: {},
      form: {},
      isLoading: false,
      features: [],
      featureValues: [],
    };
  },
  watch: {},
  methods: {
    setInitialValue(_product) {
      this.product = _product;
      this.getFeaturables();
    },

    getFeaturables() {
      this.$emit("is-loading", true);
      productApi
        .getProductFeaturables(this.product.id)
        .then((res) => {
          this.features = res.data.entity;
        })
        .finally(() => {
          // setTimeout(() => {
          this.$emit("is-loading", false);
          this.getValues();
          // }, 500);
        });
    },

    getValues() {
      this.$emit("is-loading", true);

      productApi
        .getProductFeatureIds(this.product.id)
        .then((res) => {
          this.featureValues = res.data;
        })
        .then(() => {
          this.$emit("is-loading", false);
        });
    },

    save() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
            productApi
              .updateProductFeatures(this.product.id, {
                feature_values: this.featureValues,
              })
              .then(() => {
                this.$emit("on-update");

                resolve(true);
              })
              .finally(() => {
                this.$emit("is-loading", false);
              })
              .catch(() => reject(false));
          } else reject(false);
        });
      });
    },

    setErrors(err) {
      this.$refs.formRef.setErrors(err);
    },

    getRef(feature) {
      console.log("fn", `SelectBox${feature.id}`);
      return `SelectBox${feature.id}`;
    },

    reset() {
      this.features = [];
      this.featureValues = {};
      this.$refs.formRef.reset();
      this.$forceUpdate();
    },
  },
};
</script>
