<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <span class="d-none d-md-block">
          <tools excel="supplire/sellers/export/excel" />
          <div class="row">
            <div class="col-12">
              <div class="float-left">
                <bita-bootbutton
                  title="پروفایل تامین کننده  "
                  type="primary"
                  icon="fas fa-plus-square"
                  btn-width="200px"
                  @click.native="createsSupplire"
                  v-if="$acl.can('create', 'supplires')"
                />

              </div>
             
            </div>
          </div>
          <hr />
          </span>
            

            <bita-grid
              ref="grid"
              :columns="gridColumns"
              :read-data="readData"
              :page-size="pageSize"
              :current-page="currentPage"
              :has-row-number="true"
              
            /> 
        </div>
      </bita-ibox>
    </div>


    <bita-modal
      id="info-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <info-form
          ref="infoForm"
          @on-update="(e) => infoUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('infoForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>

    <bita-modal
      id="address-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <address-form
          ref="addressForm"
          @on-update="(e) => addressUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('addressForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal> 
 
    <bita-modal
      id="financial-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <financial-form
          ref="financialForm"
          @on-update="(e) => financialUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('financialForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>
    
    <bita-modal
      id="document-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <info-form
          ref="documentForm"
          @on-update="(e) => documentUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('documentForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>
    

    <bita-modal
      id="rule-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <info-form
          ref="ruleForm"
          @on-update="(e) => ruleUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('ruleForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>

  </div>
</template>
<script>
import InfoForm from "./components/SupplireInfoForm";
import FinancialForm from "./components/SupplireFinancialForm";
import AddressForm from "./components/SupplireAddressForm.vue";
import DocumentForm from "./components/SupplireDocumentForm.vue";
import RuleForm from "./components/SupplireRuleForm.vue";
import {
  supplireApi,

} from "@/api/apiManagement";
import Tools from "../../../components/Tools.vue";
export default {
  components: {
    InfoForm,
    FinancialForm,
    AddressForm,
    DocumentForm,
    RuleForm,

    Tools,
  },

  data() {
    return {

      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "تامین کنندگان", link: "" },
        {
          title: "مدیریت تامین کنندگان",
          link: "/management/roles",
          active: true,
        },
      ],
      filters: {},

      colWith: "col-lg-2",
      pageSize: 30,
      currentPage: 1,
      isLoadingForm: false,
      isLoadingGrid: false,
      isLoadingModal: false,
      formTitle: "",
      permissions: [],
      editForm: {},
     // provinces:[]


    };
  },
  computed: {
    gridColumns() {
      return [
        // { title: "#", template: this.$getTemplate, width: "40px" },
        
        { field: "name",
         title: "تامین کننده",
         template: this.getSellerName,
         },
        { field: "store",
         title: "نام فروشگاه",
         template: this.getStoreName,
 },
        { field: "email", title: "ایمیل" },
        {
          field: "phone",
          title: "شماره تماس فروشگاه",
          template: this.getSellerPhone,
          // width: 100,
        },

        {
          field: "is_recommended",
          title: "وضعیت",
          template: ({ status }) => (status ? status : " - "),
          // width: 80,
        },

        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
          // width: 150,
        },
        {
          title: "عملیات",
    
          command: this.getGridColumns(),
        },
      ];
    },
  },
  mounted() {
 //   this.loadData();
    //this.getBanks();
     // this.getProvinces(); 

  },
  methods: {  
    getProvinces() {
       supplireApi.getProvinces().then(({ data }) => {
        this.provinces = data;
      });
    },
    getGridColumns() {
      let commands = [];
        commands.push({
          text: "items",
          name: "editaddress",
          click: this.editAddress,
          template: this.gridButtons.items,
        });
    
  return commands
     //   }


      return commands;
    },
    getStoreName(e) {
      let name = e.seller_store.name ;
      return `${name}`;
    },
    getSellerName(e) {
      let full_name = e.user.full_name ;
      return `${full_name}`;
    },
    getSellerEmail(e) {
      let full_name = e.user.full_name ;
      return `${full_name}`;
    },
    getSellerPhone(e) {
      let phone = e.seller_store.landline_number   ;
      return `${phone}`;
    },
    editAddress(e) {
      e.preventDefault();
      console.log('asdas')
      let seller = this.$refs.grid.getSelectedGridRow(e);
     
      this.formTitle = `ویرایش آدرس ${seller.name}`;
      this.$refs.addressForm.setInitialValue(seller);

      this.$showModal("#address-form");

    },
    save(ref) {
      this.$refs[ref].save();
    },
    
    formSucceeded(ref) {
      this.loadData();
      this.formTitle = "";
      this.$refs.infoForm.setInitialValue({});
      this.$toggleModal("#info-form");
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
  
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;

      supplireApi
        .getSupplires(this.filters)
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },


    createsSupplire() {
      this.$router.push({ path: "/supplire/sellers/create" });
    },



  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
a.margin-top {
  margin-top: 27px;
}
a.margin-right {
  margin-right: 5px;
}
.margin-rigth {
  margin-right: 16px;
}
</style>

<style>
[data-field="is_saleable"],
[data-field="is_recommended"] {
  text-align: center !important;
}
</style>
