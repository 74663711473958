<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <!-- <h2>{{ pageTitle }}</h2> -->
            <ol class="breadcrumb">
                <li
                    :class="{ 'breadcrumb-item': true, 'active': item.active }"
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <strong v-if="item.active">{{ item.title }}</strong>
                    <router-link v-else :to="item.link">
                        {{ item.title }}
                    </router-link>
                </li>
            </ol>
        </div>
        <div class="col-lg-2"></div>
    </div>
</template>
<script>
export default {
    name: "BreadCrumb",
    props: {
        /**
         * BreadCrumb items
         * @type Array
         * @default [{ title: "صفحه اصلی", link: "/" }]
         * @example [{ title: "صفحه اصلی", link: "/" }, { title: "مدیریت هتل ها", link: "/hotels", active: "true" }]
         */
        items: { type: Array, default: [{ title: "صفحه اصلی", link: "/" }] }
    },
    computed: {
        pageTitle() {
            return collect(this.items).where('active',true).pluck('title').first();
        }
    }
};
</script>
<style scoped>
.breadcrumb-item + .breadcrumb-item {
    margin-right: 25px;
    position: relative;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 5px;
    color: #6c757d;
    content: "/";
    padding-left: 5px;
    position: absolute;
    right: -21px;
}
.page-heading {
    padding: 8px
}
</style>
