<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن بسته بندی جدید"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="createPacking"
            v-if="$acl.can('create', 'packings')"
          />
          <hr />
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    </div>
    <packing-modal
      ref="packingModal"
      :isLoading="isLoadingModal"
      modal-id="packing-modal"
      :props-data="updateForm"
      title="افزودن بسته بندی جدید"
      @on-submit="handleCreate"
      @on-update="handleUpdate"
    />
  </div>
</template>
<script>
import { packingApi } from "@/api/apiManagement";
import packingModal from "./components/PackingForm";
export default {
  components: { packingModal },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت بسته بندی",
          link: "/shop/packings",
          active: true,
        },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      updateForm: {},
      gridColumns: [
        { title: "#", template: this.$getTemplate, width: "40px" },
        {
          field: "title",
          title: "عنوان",
          template: (e) => (e.title ? e.title : " - "),
        },
        {
          field: "status",
          title: "وضعیت",
          template: (e) => (e.status_text ? e.status_text : "-"),
        },
        {
          field: "price",
          title: this.$currencyLabel("مبلغ"),
          template: (e) => (e.price ? this.$thousandSeprator(e.price) : " - "),
        },
        {
          field: "weight",
          title: "وزن",
          template: (e) => (e.weight ? e.weight : " - "),
        },
        {
          field: "desription",
          title: "توضیحات",
          template: (e) => (e.desription ? e.desription : " - "),
        },
        {
          title: "عملیات",
          command: [],
        },
      ],
    };
  },
  mounted() {
    this.loadData();
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;
      packingApi
        .getPackings()
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("edit", "packings"))
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      if (this.$acl.can("delete", "packings"))
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    edit(e) {
      this.isLoadingModal = true;
      this.$toggleModal("#packing-modal");
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.updateForm = data;
      console.log("update form in update", this.updateForm);
      this.isLoadingModal = false;
    },
    delete(e) {
      let packing = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف  ${packing.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          packingApi
            .deletePacking(packing.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    createPacking() {
      this.updateForm = {};
      this.$toggleModal("#packing-modal");
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    handleCreate(e) {
      console.log(e);
      this.isLoadingModal = true;
      packingApi
        .createPacking(this.getFormData(e))
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.$toggleModal("#packing-modal");
          this.loadData();
          this.updateForm = {};
        })
        .catch((err) => {
          this.$refs.packingModal.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    handleUpdate(e) {
      this.isLoadingModal = true;
      packingApi
        .updatePacking(e.id, this.getFormData(e))
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.$toggleModal("#packing-modal");
          this.loadData();
          this.updateForm = {};
        })
        .catch((err) => {
          this.$refs.packingModal.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
  },
};
</script>