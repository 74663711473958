<template>
    <div class="body rtls">
      <div class="gray-bg">
        <div class="middle-box text-center loginscreen animated fadeInDown">
          <div class="content-centered">
            <div>
              <h1 class="logo-name">{{ $appName }}</h1>
            </div>
            <p style="margin-top: 10px">
              {{ $appDescription }}
              <!--Continually expanded and constantly improved Inspinia Admin Them (IN+)-->
            </p>
            <div class="adittional">
              <form class="m-t" role="form">
                <div :class="`form-group ${hasError ? 'has-error' : ''}`">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="شماره‌موبایل یا نام‌کاربری"
                    required="true"
                    v-model="login.login"
                  />
                </div>
                <div :class="`form-group ${hasError ? 'has-error' : ''}`">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="کلمه‌عبور"
                    required="true"
                    v-model="login.password"
                  />
                </div>
                <h3 v-if="hasError" class="text-danger">
                  {{ errorMessage }}
                </h3>
                <button
                  @click.prevent="logUserIn"
                  type="submit"
                  class="btn btn-primary block full-width m-b"
                >
                  ورود
                </button>
              </form>
  
              <p class="m-t">
                <small style="font-size: 11px"
                  >تمام حقوق و امتیاز سایت متعلق به شرکت تام سامانه دکان می باشد .</small
                >
                {{ $appVersion }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import api from "@/api/auth";
  export default {
    data() {
      return {
        login: {
          login: "",
          password: "",
        },
        hasError: false,
        errorMessage: "",
      };
    },
    mounted() {
      this.$setLayout("simple-layout");
    },
    methods: {
      logUserIn() {
        if (!this.login.login || !this.login.password) {
          this.hasError = true;
          this.errorMessage = "اطلاعات کامل وارد نشده است";
          return;
        }
        this.$setIsLoading(true);
        api.getCsrf().then(() => {
          api
            .login(this.login)
            .then((res) => {
              this.$store.dispatch("login", res.data);
              this.$router.push("/");
            })
            .catch((error) => {
              this.hasError = true;
              this.errorMessage = error.response?.data?.message;
            })
            .finally(() => {
              setTimeout(() => {
                this.$setIsLoading(false);
              }, 500);
            });
        });
      },
    },
  };
  </script>
  <style scoped>
  .btn {
    background-color: #19202f !important;
    border: 1px solid #ccc !important;
  }
  .logo-name {
    color: #e6e6e6 !important;
    font-size: 68px !important;
    font-weight: 800 !important;
    letter-spacing: -5px !important;
    margin-bottom: 0 !important;
  }
  .middle-box {
    /* max-width: 400px;
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    max-width: 450px;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
  }
  .content-centered {
    position: absolute;
    top: 45%;
    right: 15%;
    transform: translate(0, -50%);
  }
  .loginscreen.middle-box {
    width: 100%;
    /* background: #00000042; */
    background: #172330;
    /* padding: 48px; */
    /* border-radius: 10px; */
  }
  input {
    text-align: center;
    height: 39px;
    border-radius: 5px;
    box-shadow: #939393 -1px 1px 12px 0px;
  }
  .body.rtls {
    height: 100vh;
    background: url("~@/assets/img/background.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  a.btn.btn-primary.block.full-width.m-b {
    height: 39px;
    line-height: 27px;
    color: #fff;
  }
  </style>
  
