import Vue from "vue";
import Vuex from "vuex";
var VueCookie = require("vue-cookie");
import _acl from "./../scripts/acl";
import { collect } from "collect.js";
const acl = new Vue(_acl);
// Tell Vue to use the plugin
Vue.use(VueCookie);

Vue.use(Vuex);

const getCookie = function (cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default new Vuex.Store({
  state: {
    user: {},
    layout: "app-layout",
    isLoading: false,
    token: "",
    xsrfToken: "",
    permissions: [],
    roles: [],
  },
  mutations: {
    LOGIN(state, _loginResponse) {
      state.user = _loginResponse.user;
      state.permissions = _loginResponse.permissions;
      state.roles = _loginResponse.roles;
      state.xsrfToken = getCookie('XSRF-TOKEN');
      // state.token = `Bearer ${_loginResponse.access_token}`;

      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("permissions", JSON.stringify(state.permissions));
      localStorage.setItem("roles", JSON.stringify(state.roles));
      // localStorage.setItem("token", state.token);

      // acl.setCookie("token", `${_loginResponse.token}`, 30000000000);
      // window.axios.defaults.headers.common["Authorization"] = state.token;
    },
    USER_UPDATE(state, _loginResponse) {
      state.user = _loginResponse;

      localStorage.setItem("user", JSON.stringify(state.user));
    },
    LOGOUT(state) {
      state.user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      localStorage.removeItem("roles");
    },
    LOAD_LOCAL_USER_DATA(state) {
      state.user = JSON.parse(localStorage.getItem("user"));
      state.permissions = JSON.parse(localStorage.getItem("permissions"));
      state.roles = JSON.parse(localStorage.getItem("roles"));
      state.token = localStorage.getItem("token");
      state.xsrfToken = getCookie('XSRF-TOKEN');
    },
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    },
    SET_ISLOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_XSRF_TOKEN(state) {
      state.xsrfToken = getCookie('XSRF-TOKEN');
    },
  },
  actions: {
    login({ commit }, _loginResponse) {
      commit("LOGIN", _loginResponse);
    },
    userUpdate({ commit }, _loginResponse) {
      commit("USER_UPDATE", _loginResponse);
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
    loadLocalUserData({ commit }) {
      commit("LOAD_LOCAL_USER_DATA");
    },
  },
  getters: {
    token(state) {
      return `${state.token}`;
    },
    isLoggedIN(state) {
      if (localStorage.getItem("token")) return true;
      return false;
    },
    isSuperAdmin(state) {
      let roles = state.roles;
      return roles.find((e) => e == "super-admin") ? true : false;
    },
    layout(state) {
      return state.layout;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
  modules: {},
});
