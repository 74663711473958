<template>
  <bita-modal
    id="category-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="reset"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام فارسی"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.name"
            />
            <bita-input
              type="text"
              name="slug"
              label="نام انگلیسی"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.slug"
            />
            <bita-treeselect
              name="category_id"
              ref="categoryTreeselect"
              label="گروه"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="getCategories"
              data-text-field="name"
              data-value-field="id"
              v-model="form.parent_id"
            />
            <bita-uploader
              name="uploader"
              label="تصویر"
              rules="size:50"
              ref="uploader"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.icon"
              :icon-url="form.icon_url"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!form.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    title: String,
    isLoading: Boolean,
    categories: Array,
    category: Object,
  },
  data() {
    return {
      colWidth: "col-lg-4",
      form: {},
      imageData: "",
    };
  },
  watch: {
    category(category) {
      this.form = category;
    },
  },
  computed: {
    getCategories() {
      if (this.form.id)
        return collect(this.categories).where("id", "!=", this.form.id).all();
      return this.categories;
    },
  },
  methods: {
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.form);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.form);
        }
      });
    },
    reset() {
      this.dataForm = {};
      this.$refs.form.reset();
      this.$refs.uploader.reset();
      this.$refs.categoryTreeselect.emptyKendo();
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
