import Vue from "vue";
import store from "./../store";
var VueCookie = require("vue-cookie");
const ACL_DEBUG = process.env.NODE_ENV == "development"
// Tell Vue to use the plugin
Vue.use(VueCookie);

const acl = {
  data() {
    return {
      // permissions: [],
      // roles: [],
      openRoutes: ["home", "404", "403", "dashboard", "invoiceprint", "profile"],
      publicRoutes: ["downloads"],
      permissionsLoadded: false,
      rolesLoadded: false,
    };
  },
  watch: {
    permissions: function (_permissions) {
      if (_permissions.length > 0) this.permissionsLoadded = true;
    },
    roles: function (_roles) {
      if (_roles.length > 0) this.rolesLoadded = true;
    },
  },
  computed: {
    isReady() {
      return this.rolesLoadded;
    },
    permissions() {
      // return [];
      let data =
        store && store.state && store.state.permissions
          ? store.state.permissions
          : [];
      return data.length > 0 ? data : JSON.parse(localStorage.getItem("permissions"))
    },
    roles() {
      // return [];
      let data =
        store && store.state && store.state.roles ? store.state.roles : [];
      return data.length > 0 ? data : JSON.parse(localStorage.getItem("roles"))
    },
    isSuperAdmin() {
      return this.roles && this.roles.find((e) => e.name == "super-admin") != null;
    },
  },
  methods: {
    can(operation, name) {
      if (this.isSuperAdmin)
        return true;

      let isOpenRoute = this.openRoutes.find((e) => e == name) ? true : false
      let hasPermission = this.permissions && this.permissions.find((e) => e.name == `${operation}-${name}`) ? true : false;
      let res = isOpenRoute || hasPermission
      return res
    },
    getUser() {
      return localStorage.getItem("user") || false;
    },
    isPublicRoute(routeName) {
      return this.publicRoutes.find((e) => e == routeName) ? true : false
    },

    hasToken() {
      if (store.state.user?.name || localStorage.getItem("user"))
        return true;
      return false;
    },

    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    setCookie(cname, cvalue, exsecond) {
      var d = new Date();
      d.setTime(d.getTime() + exsecond * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    initialApp(router, store) {
      const currentUser = this.getUser();

      if (currentUser) {
        store.dispatch("loadLocalUserData");
      }
      axios.interceptors.response.use(null, (err) => {
        const { response, config } = err;
        if (!response)
          return Promise.reject(err)

        if (![400, 401, 419].includes(response.status))
          return Promise.reject(err)

        store.dispatch('logout');
        if (router.currentRoute.path != '/login')
          router.push("/login");

        return Promise.reject(err)
      })
    },
    handleRoutes(to, from, next) {
      if (!to.matched.length) {
        next("/404");
        return;
      }
      if (to.name) {
        if (this.isPublicRoute(to.name))
          return next();
        if (!this.hasToken() && to.path != "/login") {
          next("/login");
          return;
        } else {
          if (!this.can("view", to.name) && to.path != "/login") {
            next("/403");
            return;
          } else {
            next();
            return;
          }
        }
      }
    },

    log(_event, _payload) {
      if (ACL_DEBUG)
        console.info(
          `%cDebug: ACL LOG`,
          "color: red; font-weight: 600",
          "Event:",
          _event,
          "Payload:",
          _payload
        );
    },
  },
};

export default acl;
