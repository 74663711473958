<template>
  <bita-modal
    id="priceupdate-modal"
    title="اپلود فایل بروزرسانی قیمت"
    :is-loading="isLoading"
    :modal-lg="true"
    @open="setFormData"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-select
              name="type"
              label="بروزرسانی بر اساس"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="[
                { id: 1, name: 'کد محصول' },
                { id: 2, name: 'بارکد محصول' },
              ]"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.type"
            />
            <div class="col-lg-3">
              <label>فایل اکسل </label>
              <label for="file" class="btn">انتخاب فایل</label>
              <input
                type="file"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
              />
            </div>
          </div>
          <div class="row">
            <bita-input
              type="text"
              name="code"
              label="کد"
              rules="excelColumn"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.code"
            />
            <bita-input
              type="text"
              name="barcode"
              label="بارکد"
              rules="excelColumn"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.barcode"
            />
            <bita-input
              type="text"
              name="stock"
              label="موجودی"
              rules="required|excelColumn"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.stock"
            />
            <bita-input
              type="text"
              name="price"
              label="قیمت"
              rules="required|excelColumn"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.price"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="submitFile()"
        :disabled="isLoading"
      />
    </div>
  </bita-modal>
</template>
<script>
import { productApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      isLoading: false,
      largeColWidth: "col-lg-12",
      colWidth: "col-lg-3",
      errors: {},
      formData: {},
    };
  },
  methods: {
    setFormData() {
      this.formData = {
        type: 1,
        code: "E",
        barcode: "",
        stock: "C",
        price: "D",
      };
      $("input[type='file']#file").val("");
    },
    handleFileUpload() {
      this.formData.file = this.$refs.file.files[0];
    },
    submitFile() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          productApi
            .importPricesExcel(this.getFormData())
            .then(() => {
              this.$toggleModal("#priceupdate-modal");
              this.$izitoast("success", "بروزرسانی در صف پردازش قرار گرفت.");
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    getFormData() {
      let formData = new FormData();
      for (var key in this.formData) {
        if (this.formData[key] != null) {
          formData.append(key, this.formData[key]);
        }
      }
      return formData;
    },
  },
};
</script>