<template>
  <div id="article-form">
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoading">
        <div slot="body">

          <bita-form ref="articlesForm">

            <div slot="body">
              <div class="row">
                <div class="col-lg-12">

                  <bita-formgroup title="اطلاعات" class="row">

                    <bita-input
                      type="text"
                      name="title"
                      label="عنوان"
                      rules="required"
                      :validation-container-class="colWidthHalf"
                      input-class="form-control"
                      v-model="form.title"
                    />

                    <bita-treeselect
                      name="category_id"
                      ref="categoryTreeselect"
                      label="گروه"
                      rules="required"
                      :validation-container-class="colWidthHalf"
                      input-class="form-control"
                      :data-source="categories"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.category_id"
                    />

                    <bita-texteditor
                      name="introduction"
                      ref="introduction"
                      label="مقدمه"
                      :validation-container-class="colWidth"
                      input-class="form-control"
                      v-model="form.introduction"
                    />

                    <bita-texteditor
                      type="text"
                      name="content"
                      ref="content"
                      label="توضیحات"
                      :validation-container-class="colWidth"
                      input-class="form-control"
                      v-model="form.content"
                    />
                    
                  </bita-formgroup>

                  <bita-formgroup title="تاریخ" class="row">

                    <bita-datepicker
                      type="datetime"
                      id="paymentat"
                      :validation-container-class="colWidthforth"
                      name="date"
                      label="تاریخ نوشته"
                      inputClass="form-control"
                      v-model="form.date"
                      format="YYYY-MM-DD HH:mm:ss"
                      display-format="jYYYY/jMM/jDD HH:mm:ss"
                    />

                    <bita-datepicker
                      type="datetime"
                      id="paymentat"
                      :validation-container-class="colWidthforth"
                      name="publish_at"
                      label="تاریخ انتشار"
                      inputClass="form-control"
                      v-model="form.publish_at"
                      format="YYYY-MM-DD HH:mm:ss"
                      display-format="jYYYY/jMM/jDD HH:mm:ss"
                    />

                    <bita-datepicker
                      type="datetime"
                      id="paymentat"
                      :validation-container-class="colWidthforth"
                      name="expire_at"
                      label="تاریخ انقضا"
                      inputClass="form-control"
                      v-model="form.expire_at"
                      format="YYYY-MM-DD HH:mm:ss"
                      display-format="jYYYY/jMM/jDD HH:mm:ss"
                    />

                  </bita-formgroup>

                  <bita-formgroup title="بیشتر" class="row">

                    <bita-input
                      type="text"
                      name="source"
                      label="منبع"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      v-model="form.source"
                    />

                    <bita-select
                      name="status"
                      ref="status"
                      label="وضعیت"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      :data-source="statuses"
                      data-text-field="title"
                      data-value-field="id"
                      v-model="form.active"
                    />

                    <bita-select
                      name="is_special"
                      ref="is_special"
                      label="ویژه"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      :data-source="[
                        { id: 1, name: 'بله' },
                        { id: 2, name: 'خیر' },
                      ]"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.is_special"
                    />

                    <bita-uploader
                      name="thumbnail"
                      label="تصویر"
                      ref="uploader"
                      rules="size:50"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      v-model="form.thumbnail"
                      :icon-url="form.thumbnail_url"
                    />

                    <bita-uploader
                      name="file"
                      label="فایل"
                      ref="uploader"
                      rules="size:50"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      v-model="form.file"
                      :icon-url="form.file_url"
                    />

                    <bita-typeahead
                      type="text"
                      name="tags"
                      label="کلمه های کلیدی"
                      :validation-container-class="colWidthforth"
                      input-class="form-control"
                      :data-source="matches"
                      v-model="form.tags"
                      @on-search="(e) => loadDataSource(e)"
                    />

                    <bita-select
                      name="seller_article_flag"
                      ref="showInPanel"
                      rules="required"
                      label="نمایش در پنل"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      :data-source="[
                        { id: 1, name: 'بله' },
                        { id: 0, name: 'خیر' },
                      ]"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.seller_article_flag"
                    />

                    <bita-multiselect
                      label="نمایش در"
                      name="middlePages"
                      validation-container-class="col-lg-12"
                      :data-source="middlePages"
                      data-text-field="title"
                      data-value-field="id"
                      v-model="form.middlePages"
                      v-show="!showInPanel"
                    />

                    <bita-select
                      name="seller_article_type"
                      ref="typeSelect"
                      label="نمایش مقاله در صفحه"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      :data-source="[
                        { id: 1, name: 'خانه' },
                        { id: 2, name: 'محصولات' },
                      ]"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.seller_article_type"
                      v-show="showInPanel"
                    />
                  
                    <bita-select
                      name="seller_article_private"
                      ref="is_special"
                      label="این مقاله خصوصی هست"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      :data-source="[
                        { id: 1, name: 'بله' },
                        { id: 0, name: 'خیر' },
                      ]"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.seller_article_private"
                      v-show="showInPanel"
                    />

                    <bita-multiselect
                      label="فروشندگان"
                      name="sellers"
                      validation-container-class="col-lg-12"
                      :data-source="sellers"
                      data-text-field="seller_fullname"
                      data-value-field="id"
                      v-model="form.sellers"
                      v-show="showToSellersPrivate"
                    />

                  </bita-formgroup>

                  <div class="row">
                    <div class="col-lg-12">

                      <bita-bootbutton
                        v-if="!form.id"
                        type="primary"
                        title="ذخیره"
                        icon="fas fa-save"
                        btn-width="150px"
                        @click.native="save"
                      />

                      <bita-bootbutton
                        v-else
                        title="بروزرسانی"
                        type="primary"
                        icon="fas fa-save"
                        btn-width="150px"
                        @click.native="update"
                      />

                    </div>
                  </div>

                </div>
              </div>
            </div>

          </bita-form>

        </div>
      </bita-ibox>
    </div>
  </div>
</template>

<script>
import {
  articleCategoryApi,
  articleApi,
  tagArticleApi,
  mdidlePagesApi,
  supplireApi
} from "@/api/apiManagement";

export default {
  data() {
    return {
      isLoading: false,
      priceTitle: "",
      colWidth: "col-lg-12",
      colWidthHalf: "col-lg-6",
      colWidthThird: "col-lg-3",
      colWidthforth: "col-lg-4",
      form: {},
      categories: [],
      middlePages: [],
      sellers: [],
      matches: [],
      statuses: [
        { id: 1, title: "فعال" },
        { id: 0, title: "غیر فعال" },
      ],
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت نوشته ها",
          link: "/shop/article",
          active: true,
        },
        {
          title: "افزودن نوشته",
          link: "",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getCategories();
    this.getMiddlePages();
    this.getSellers();
    this.getArticleMiddlePages();
  },
  computed:{
    showInPanel() {
        return this.form.seller_article_flag == 1
    },
    showToSellersPrivate() {
        return this.form.seller_article_private == 1 && this.form.seller_article_flag == 1
    },
  },
  methods: {
    loadDataSource(tag) {
      tagArticleApi.findTags({ tag: tag }).then((res) => {
        this.matches = collect(res.data.entity)
          .pluck("name.fa")
          .toArray();
      });
    },
    getForm(id) {
      this.isLoading = true;
      let articleId = this.$route.params.id;
      articleApi.findArticle(articleId).then(({ data }) => {
        data.entity.category_id = String(data.entity.category_id);
        data.entity.middlePages = data.entity.middle_pages;

        this.form = data.entity;

        this.form.tags = collect(this.form.tags)
          .pluck("name.fa")
          .toArray();

        this.$refs.introduction.setInitialValue(this.form.introduction);
        this.$refs.content.setInitialValue(this.form.content);
        this.isLoading = false;
      });
    },
    getMiddlePages() {
      this.isLoading = true;
      mdidlePagesApi
        .gets()
        .then((res) => {
          this.middlePages = res.data.entity;
        })
        .finally(() => (this.isLoading = false));
    },
    getSellers() {
      this.isLoading = true;
      supplireApi
        .getSupplires()
        .then((res) => {
          this.sellers = res.data.entity;
        })
        .finally(() => (this.isLoading = false));
    },
    getArticleMiddlePages(){
      let id = this.$route.params.id;
      if(id){
       articleApi.getArticleMiddelePages(id).then((res) => {
          console.log( res.data.entity.map(middlepage=>middlepage.id));
          var middlepages_id=res.data.entity.map(middlepage=>middlepage.id);
           this.form.middlePages = middlepages_id;
        }).catch((err) => {
          console.log(err.response.data.errors);
              this.$refs.form.setErrors(err.response.data.errors);
            })
        .finally(() => (this.isLoading = false));   
      } 
    },
    getCategories() {
      this.isLoading = true;
      return articleCategoryApi
        .getCategories({})
        .then(({ data }) => {
          this.categories = data.entity;
        })
        .finally(() => {
          this.isLoading = false;
          let id = this.$route.params.id;
          if (id) this.getForm(id);
        });
    },
    resetForm() {
      this.$refs.articlesForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
      this.form = {};
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    successForm() {
      this.resetForm();
      this.$router.push({ path: "/article" });
    },
    save() {
      this.$refs.articlesForm.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          articleApi
            .createArticle(this.getFormData(this.form))
            .then(({ data }) => {
              this.successForm();
            })
            .catch((err) => {
              this.$refs.form.setErrors(err.response.data.errors);
            })
            .finally(() => (this.isLoading = false));
        }
      });
    },
    update() {
      this.$refs.articlesForm.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          articleApi
            .updateArticle(this.form.id, this.getFormData(this.form))
            .then(({ data }) => {
              this.successForm();
            })
            .catch((err) => {
              this.$refs.articlesForm.setErrors(err.response.data.errors);
            })
            .finally(() => (this.isLoading = false));
        }
      });
    },
  },
};
</script>
<style>
#article-form #introduction {
  height: 82px;
}
</style>
