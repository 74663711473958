<template>
  <div class="file-box">
    <div class="file">
      <a href="#">
        <span class="corner"></span>

        <div class="image" v-if="imagePreview">
          <img alt="image" class="img-fluid" :src="imagePreview" />
        </div>
        <div class="icon" v-else>
          <i :class="typeIcon"></i>
        </div>
        <div class="file-name">
          {{ fileName }}
          <br />
          <small>{{ $getJalaliDateTime(createdAt) }}</small>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fileName: {
      type: String,
    },
    createdAt: {
      type: String,
    },
    type: {
      type: String,
    },
    imagePreview: {
      type: String,
    },
  },
  computed: {
    typeIcon() {
      switch (this.type) {
        case "file":
          return "fa fa-file";
        default:
          return "fa fa-file";
      }
    },
  },
};
</script>