import { apiCall, createQueryString } from "../imports"

const PREFIX = "ticketing/tickets"

export default {

    gets(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    get(ticketID) {
        return apiCall('get', `${PREFIX}/${ticketID}`)
    },

    create(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    createReply(ticketID, data) {
        return apiCall('post', `${PREFIX}/${ticketID}/reply`, data);
    },

    update(ticketID, data) {
        return apiCall('patch', `${PREFIX}/${ticketID}/update`, data)
    },

    updateReply(ticketID, replyID, data) {
        return apiCall('patch', `${PREFIX}/${ticketID}/reply/${replyID}/update`, data);
    },

    delete(ticketID) {
        return apiCall('delete', `${PREFIX}/${ticketID}`)
    },
    
    deleteReply(ticketID, replyID) {
        return apiCall('delete', `${PREFIX}/${ticketID}/reply/${replyID}`)
    },
    createNewTicket(data) {
        return apiCall('post', `marketplace/seller/transaction/register/payment`, data)
    },
}