<template>
  <div class="modal fade" :id="id">
    <div
      :class="{
        'modal-dialog': true,
        'modal-lg': modalLg,
        'modal-dialog-centered': true,
        'modal-sm': modalSm,
      }"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{ title }}</h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <bita-ibox :isLoading="isLoading">
          <div slot="body">
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
          </div>
        </bita-ibox>
        <div
          class="modal-footer"
          :class="{ 'no-modal-footer': !this.$slots['footer'] }"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    id: String,
    title: String,
    isLoading: Boolean,
    modalLg: Boolean,
    modalSm: Boolean,
  },
  mounted() {
    var _this = this;

    $(`#${_this.id}`).on("hidden.bs.modal", function(e) {
      _this.$emit("close", _this.id);
    });

    $(`#${_this.id}`).on("shown.bs.modal", function(e) {
      _this.$emit("open", _this.id);
    });
  },
  destroyed() {
    var _this = this;
    $(`#${_this.id}`).off("hidden.bs.modal");

    $(`#${_this.id}`).off("shown.bs.modal");
  },
  data() {
    return {};
  },
  methods: {
    closeModal(e) {
      $(`#${this.id}`).modal("hide");
    },
  },
};
</script>
<style scoped>
.no-modal-footer {
  border-top: 0px solid #e5e5e5 !important;
}
.modal-footer {
  padding: 0.25rem;
}
.modal-header .close {
  margin: -1rem auto -1rem -1rem;
}
@media (prefers-color-scheme: dark) {
  .modal-header .close {
    margin: -1rem auto -1rem -1rem;
    color: #ccc;
  }
}
</style>
