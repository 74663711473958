<template>
  <bita-modal
    :id="modalId"
    :title="title"
    :modal-lg="true"
    :isLoading="isLoading"
    @close="onCloseModal"
  >
    <div slot="body">
      <div class="row">
        <div class="col-6">
          نام و نام خانوادگی:
          {{ formItems.user ? formItems.user.full_name : "-" }}
        </div>
        <div class="col-6">
          زمان آخرین ویرایش:
          {{
            formItems.update_at
              ? $getJalaliDateTime(formItems.updated_at)
              : "-"
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          استان: {{ formItems.user ? formItems.address.province : "-" }}
        </div>
        <div class="col-6">
          شهر: {{ formItems.user ? formItems.address.city : "-" }}
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          ادرس: {{ formItems.user ? formItems.address.address : "-" }}
        </div>
        <div class="col-6">
          کدپستی: {{ formItems.user ? formItems.address.postal_code : "-" }}
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          تلفن همراه: {{ formItems.user ? formItems.user.mobile : "-" }}
        </div>
        <div class="col-6">
          ایمیل: {{ formItems.user ? formItems.user.email : "-" }}
        </div>
      </div>
      <br />
      <table class="table table-bcarted">
        <thead>
          <tr>
            <th>#</th>
            <th>نام</th>
            <th>تعداد</th>
            <th>قیمت واحد</th>
            <th>مبلغ کل</th>
            <th>مبلغ تخفیف</th>
            <th>مبلغ کل پس از تخفیف</th>
            <!-- <th>قیمت با تخفیف</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in formItems.items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.product.name }}</td>
            <td>{{ item.count }}</td>
            <td>{{ $thousandSeprator(item.price) }}</td>
            <td>{{ $thousandSeprator(item.price * item.count) }}</td>
            <td>{{ $thousandSeprator(item.payable_price - item.price) }}</td>
            <td>{{ $thousandSeprator(item.payable_price * item.count) }}</td>
          </tr>
          <tr>
            <td colspan="7" style="text-align: left">جمع کل</td>
            <td colspan="7">{{ $thousandSeprator(formItems.items_payable) }}</td>
          </tr>
          <tr>
            <td colspan="7" style="text-align: left">هزینه حمل</td>
            <td colspan="7">
              {{ $thousandSeprator(formItems.shipping_price) }}
            </td>
          </tr>
          <tr>
            <td colspan="7" style="text-align: left">تخفیف</td>
            <td colspan="7">{{ $thousandSeprator(formItems.discount) }}</td>
          </tr>
          <tr>
            <td colspan="7" style="text-align: left">قابل پرداخت</td>
            <td colspan="7">{{ $thousandSeprator(formItems.payable) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    modalId: {},
    isLoading: {},
    title: {},
    onSubmit: {},
  },
  data() {
    return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
      formItems: {},
      dataForm: {},
    };
  },
  computed: {},
  methods: {
    setInitialData(_data) {
      this.formItems = _data;
    },
    reset() {
      this.$refs.form.reset();

      this.formItems = {};
    },

    onCloseModal(e) {
      this.formItems = {};
      this.reset();
    },
  },
};
</script>
