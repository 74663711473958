<template>
  <div :class="validationContainerClass">
    <v-validate :rules="rules" :vid="name" v-slot="{ errors, classes }" :name="label">
      <div class="form-group">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i>
          </div>
        </label>
        <!-- <kendo-datasource
          ref="autocompleteDataSource"
          :data="dataSource"
        ></kendo-datasource> -->
        <drop-down-list
          :id="inputID"
          :name="name"
          :ref="name"
          :data-source="dataSource"
          :placeholder="placeHolder"
          v-model="content"
          :data-text-field="dataTextField"
          :data-value-field="dataValueField"
          :template="template"
          :disabled="disabled"
          :delay="700"
          :filter="'contains'"
          no-data-template="موردی یافت نشد!"
          @filtering="onFilter"
          @changemodel="handleInput(content)"
          :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
        ></drop-down-list>
        <span v-if="hasSubtitle" class="form-text m-b-none">{{
          subtitle
        }}</span>
        <span class="invalid-feedback">{{ errors[0] }}</span>
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";

import {
  DropDownList,
  DropdownsInstaller,
} from "@progress/kendo-dropdowns-vue-wrapper";
import "@progress/kendo-ui/js/kendo.dropdownlist";
export default {
  extends: InputCommons,
  components: { DropDownList },
  use: { DropdownsInstaller },
  methods: {
    emptyKendo() {
      var test = this.$refs[this.name].kendoWidget();
      test.value("");
    },
    onFilter(e) {
      this.$emit("filtering", e);
    },
    onChange(e) {
      console.log("on chan", e);
    },
  },
};
</script>
<style>
.k-multiselect.form-control {
  height: auto;
  min-height: 37px !important;
}

.k-multiselect .k-multiselect-wrap .k-input,
.k-multiselect-wrap .k-input {
  height: calc(11px + 1.4285714286em) !important;
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-multiselect-wrap .k-button {
  min-height: calc(1.4285714286em + 10px) !important;
}
</style>
