import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route('notifications');

RouteRegistrar.add("notifications/logs/Logs", "logs", "logs", "گزارش اعلان ها");
RouteRegistrar.add("notifications/settings/Settings", "settings", "settings", "تنظیمات اعلان");
RouteRegistrar.add("notifications/templates/Templates", "templates", "templates", "تنظیمات اعلان");

const routes = RouteRegistrar.allRoutes()

export default routes;
