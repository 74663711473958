<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="search"
              label="جستجو"
              validation-container-class="col-4"
              input-class="form-control"
              v-model="search"
            />
            <div class="col-lg-4">
              <a class="margin-top btn btn-success" @click="loadGridData">
                جستجو
              </a>
            </div>
          </div>
          <bita-grid
            ref="grid"
            :has-row-number="true"
            :columns="gridColumns"
            :read-data="readData"
          />
        </div>
      </bita-ibox>
    </div>
    <log-form
      id="log-form"
      ref="logForm"
      title="مشاهده کامل اطلاعات"
      :is-loading="isLoadingModal"
      :props-data="formData"
    />
  </div>
</template>
<script>
import { smsLogApi } from "@/api/apiManagement";
import LogForm from "./components/LogsForm";
export default {
  components: { LogForm },
  data() {
    return {
      isLoadingGrid: false,
      isLoadingModal: false,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "گزارش اعلان ها", link: "/notifications/logs" },
      ],
      search: "",
      formData: {},
      resend:
        "<a role='button' class='k-button k-button-icontext k-grid-send' data-toggle='tooltip' data-placement='bottom' title='ارسال دوباره'><span class='fas fa-retweet text-danger'></span></a>",
    };
  },
  computed: {
    gridColumns() {
      return [
        { field: "from", title: "از" },
        { field: "to", title: "به" },
        { field: "delivery_status", title: "وضعیت" },
        { field: "response", title: "پاسخ" },
        {
          field: "created_at",
          title: "زمان ارسال",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  methods: {
    loadGridData() {
      this.$refs.grid.loadDataSource();
    },
    readData(e) {
      this.isLoadingGrid = true;
      smsLogApi
        .getSmsLogs(this.search)
        .then((res) => {
          e.success(res.data.entity);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    gridCommands() {
      let commands = [];

      commands.push({
        text: "send",
        click: this.sendNotfication,
        template: this.resend,
      });
      commands.push({
        text: "view",
        click: this.view,
        template: this.gridButtons.view,
      });

      return commands;
    },
    sendNotfication(e) {
      let log = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از ارسال مجدد به ${log.to} اطمینان دارید؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          smsLogApi
            .retrySmsLog(log.id)
            .then(({ data }) => {
              if (data.IsSuccessful) {
                this.$izitoast("success", "عملیات موفق", data.Message);
                this.grid.loadDataSource();
              } else {
                this.$izitoast("error", "عملیات نا موفق", data.Message);
                this.isLoadingGrid = false;
              }
            })
            .catch((err) => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    view(e) {
      this.isLoadingModal = true;
      let log = this.$refs.grid.getSelectedGridRow(e);
      this.formData = log;
      this.toggleModal();
      this.isLoadingModal = false;
    },
    toggleModal() {
      this.$toggleModal("#log-form");
    },
  },
};
</script>
