<template>
  <bita-modal
    id="ticket-modal"
    title="ثبت تیکت "
    :is-loading="isLoading"
    :modal-lg="true"
    @open="setFormData"
    @close="reset()"
  >
    >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="titele"
              label="عنوان"
              validation-container-class="col-lg-6"
              input-class="form-control"
              v-model="formData.title"
            />

            <bita-uploader
              name="logo"
              label="فایل پوست"
              rules="size:1000"
              ref="uploader"
              validation-container-class="col-lg-6"
              input-class="form-control"
              v-model="formData.file"
              :icon-url="formData.file"
            />
            <!-- <input
                type="file"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
              /> -->
            <bita-textarea
              type="text"
              name="message"
              label="پیام"
              rules="required"
              validation-container-class="col-lg-12"
              input-class="form-control"
              v-model="formData.message"
            />
          </div>
        </div>
      </bita-form>
    </div>

    <div slot="footer">
      <bita-bootbutton
        title="ثبت"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
        :disabled="isLoading"
      />
    </div>
  </bita-modal>
</template>
<script>
import { ticketApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      isLoading: false,
      largeColWidth: "col-lg-12",
      colWidth: "col-lg-3",
      errors: {},
      formData: {},
    };
  },
  mounted() {},
  methods: {
    setFormData() {
      //this.$refs.products.clear();
      //  this.$refs.products.emptyKendo();
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    handleFileUpload() {
      this.formData.file = this.$refs.file.files[0];
      console.log(this.formData.file);
    },
    reset() {
      this.formData = {};
      this.$refs.form.reset();
    },
  },
};
</script>
