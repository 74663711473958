import Vue from "vue";
import VueRouter from "vue-router";
import flog from "@/scripts/flog"

import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route();


const requireComponent = require.context(
  "@/views",
  true,
  /[router]\w+\.(js)$/
);
let allComponents = [];
var routers = []
requireComponent.keys().forEach((fileName) => {
  flog.success('Route Registered',fileName)
  routers.push(require(`@/views/${fileName.substring(2)}`))
});


Vue.use(VueRouter);

RouteRegistrar.add("general/Login", "login", "login", "ورود")
RouteRegistrar.add("general/Home", "", "home", "داشبورد")
RouteRegistrar.add("general/403", "403", "403", "403")
RouteRegistrar.add("general/404", "*", "404", "404")




const generalRoutes = RouteRegistrar.allRoutes()


var allRoutes = []
for (let router of routers) {
  allRoutes = allRoutes.concat(router.default)
}
allRoutes = allRoutes.concat(generalRoutes)
const routes = allRoutes


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
