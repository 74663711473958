import { apiCall } from "../imports"

const PREFIX = "shop/categories"

export default {

    getCategories(data) {
        return apiCall('get', `${PREFIX}`, data)
    },

    getCategoryDetails(categoryID) {
        return apiCall('get', `${PREFIX}/${categoryID}/details`)
    },

    createCategory(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateCategory(categoryID, data) {
        return apiCall('post', `${PREFIX}/${categoryID}/update`, data)
    },

    deleteCategory(categoryID) {
        return apiCall('delete', `${PREFIX}/${categoryID}`)
    },

    search(data) {
        return apiCall('get', `${PREFIX}`, data)
    },
}