import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/orders"

export default {

    getOrders(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    findOrder(orderID) {
        return apiCall('get', `${PREFIX}/${orderID}`)
    },

    getStatuses() {
        return apiCall('get', `${PREFIX}/statuses`)
    },

    createOrder(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateOrder(orderId, data) {
        return apiCall('patch', `${PREFIX}/${orderId}`, data)
    },
    
    updateOrderPaymentStatus(orderId, data) {
        return apiCall('patch', `${PREFIX}/${orderId}/payed`, data)
    },

    deleteOrder(orderID) {
        return apiCall('delete', `${PREFIX}/${orderID}`)
    },
}