<template>
  <bita-modal
    id="show-modal"
    :title="title"
    :modal-lg="true"
    @close="resetForm"
  >
    <div slot="body">
      <div class="row">
        <div class="col-lg-2 mt-5">
          <label for="message"> نام</label>
          <div>{{ detail.name }}</div>
        </div>
        <div class="col-lg-2 mt-5">
          <label for="message"> نام خانوادگی</label>
          <div>{{ detail.family }}</div>
        </div>
        <div class="col-lg-2 mt-5">
          <label for="message"> موبایل</label>
          <div>{{ detail.mobile }}</div>
        </div>
        <div class="col-lg-2 mt-5">
          <label for="message"> قیمت</label>
          <div>{{ $currencyLabel($thousandSeprator(detail.price)) }}</div>
        </div>
        <div class="col-lg-2 mt-5" v-if="detail.transaction">
          <label for="message"> درگاه پرداخت</label>
          <div>{{ detail.transaction.paymethod_text }}</div>
        </div>
        <div class="col-lg-12 mt-5">
          <label for="message"> توضیحات</label>
          <div>{{ detail.description }}</div>
        </div>
      </div>
    </div>
  </bita-modal>
</template>
<script>
export default {
  data() {
    return {
      title: "",
      detail: {},
    };
  },
  methods: {
    setDetail(detail) {
      this.detail = detail;
      this.title = detail.transaction.transaction_id;
    },
    resetForm() {
      this.title = "";
      this.detail = {};
    },
  },
};
</script>
