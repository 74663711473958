<template>
  <div :class="validationContainerClass">
    <v-validate :rules="rules" :vid="name" v-slot="{ errors, classes }" :name="label">
      <div class="form-group">
        <label :for="id"
          >{{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i></div
        ></label>
        <input
          :id="id"
          :name="name"
          :ref="name"
          :type="type"
          :min="min"
          :max="max"
          :autocomplete="autocomplete"
          :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
          :placeholder="placeHolder"
          :disabled="disabled"
          v-model="content"
          @input="handleInput(content)"
        />
        <span v-if="hasSubtitle" class="form-text m-b-none">{{subtitle}}</span>
        <span class="invalid-feedback">{{ errors[0] }}</span>
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons,
};
</script>
