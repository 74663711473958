<template>
  <bita-modal
    id="product-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="type"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
            />
            <bita-input
              type="number"
              name="order"
              label="اولویت"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              subtitle="اولویت نمایش در صفحه"
              v-model="formData.order"
            />
            <bita-select
              name="display"
              ref="display"
              label="وضعیت نمایش کالکشن "
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="display"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.display"
            />
            <bita-texteditor
              name="description"
              ref="descriptionTextarea"
              label="توضیحات"
              rules=""
              validation-container-class="col-12"
              input-class="form-control"
              v-model="formData.description"
            />
            <bita-uploader
              name="logo"
              label="تصویر"
              ref="icon"
              rules="size:50"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.icon"
              :icon-url="formData.icon_url"
            />
            <bita-uploader
              name="background"
              label="تصویر پس زمینه کالکشن"
              ref="icon"
              rules="size:50"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.background"
              :icon-url="formData.background"
            />
            <bita-select
              v-if="hasbackground(formData)"
              name="backgroundtype"
              ref="backgroundtype"
              label="نوع نمایش"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="backgroundTypes"
              data-text-field="title"
              data-value-field="title"
              v-model="formData.backgroundtype"
            />
            <bita-select
              name="template_id"
              ref="typesTemplate"
              label="قالب"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="typesTemplate"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.template_id"
            />
            <bita-select
              name="type"
              ref="typeSelect"
              label="نوع"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="typesSource"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.type"
            />
            <bita-select
              v-if="formData.type == 2"
              name="type"
              ref="middlePagesSelect"
              label="صفحه واسط"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="middlePages"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.meta_id"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="create"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
import { productCollectionApi,mdidlePagesApi} from "@/api/apiManagement";

export default {
  props: {
    title: String,
  },
  data() {
    return {
      isLoadingModal: false,
      colWidth: "col-lg-4",
      formData: {},
      middlePages: [],
      backgroundTypes: [
        { id: 1, title: "auto" },
        { id: 1, title: "contain" },
        { id: 3, title: "cover" },
      ],
      display: [
        { id: 1, title: "فعال" },
        { id: 0, title: "غیرفعال" },
      ],
    };
  },
  computed:{
    typesSource() {
      return [
        { id: 1, name: "کالکشن صفحه اصلی" },
        { id: 2, name: "کالکشن صفحه واسط" },
      ];
    },

    typesTemplate() {
      return [
        { id: 0, name: "معمولی" },
        { id: 1, name: "شگفت انگیز" },
      ];
    },

    
  },
  methods: {
    setInitialData(_data) {
      // this.formData = _data;
      // this.$refs.descriptionTextarea.setInitialValue(_data.description);

      if (this.$acl.can("view", "product-collections")) {
        this.formData.type = _data.type;
        this.$refs.descriptionTextarea.setInitialValue(_data.description);
        this.isLoadingModal = true;
        mdidlePagesApi
          .gets()
          .then((res) => {
            this.middlePages = res.data.entity;
            setTimeout(() => {
              this.formData = _data;
            }, 300);
          })
          .finally(() => (this.isLoadingModal = false));
      } else {
        setTimeout(() => {
          this.formData = _data;
        }, 200);
      }
    },
    setForm(data = {}) {
     
    },
    create() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.isLoadingModal = true;
          productCollectionApi
            .post(this.$getFormData(this.formData))
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.$emit("load-data");
              this.$toggleModal("#product-form");
            })
            .finally(() => (this.isLoadingModal = false));
        }
      });
    },
    hasbackground(formData) {
      if (formData.background) {
        return formData.background;
      }
    },
    update() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.isLoadingModal = true;

          productCollectionApi
            .patch(this.formData.id, this.$getFormData(this.formData))
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.$emit("load-data");
              this.$toggleModal("#product-form");
            })
            .finally(() => (this.isLoadingModal = false));
        }
      });
    },
    reset() {
      this.formData = {};
      this.$refs.form.reset();
      this.$refs.icon.reset();
    },
  },
};
</script>
