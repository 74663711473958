<template>
  <div :class="validationContainerClass">
    <v-validate :rules="rules" v-slot="{ errors, classes }" :name="label">
      <div class="form-group">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">*</div>
        </label>
        <div>
          <kendo-datasource
            :ref="`${inputID}Datasource`"
            :data="dataSource"
          ></kendo-datasource>
          <drop-down-list
            :id="inputID"
            :name="name"
            :ref="name"
            :type="type"
            :option-label="placeHolder"
            v-model="content"
            :data-source-ref="`${inputID}Datasource`"
            :data-text-field="dataTextField"
            :data-value-field="dataValueField"
            :filter="'contains'"
            :index="null"
            @changemodel="handleInput(content)"
            :class="`${inputClass} ${classes.invalid ? 'k-is-invalid' : ''}`"
            no-data-template="موردی یافت نشد!"
            :disabled="disabled"
            @filtering="getData"
          />
        </div>
        <span v-if="hasSubtitle" class="form-text m-b-none">{{
          subtitle
        }}</span>
        <span class="k-invalid-feedback">{{ errors[0] }}</span>
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import "@progress/kendo-theme-default/dist/all.css";

import {
  DropDownList,
  DropdownsInstaller,
} from "@progress/kendo-dropdowns-vue-wrapper";
import "@progress/kendo-ui/js/kendo.dropdownlist";
export default {
  extends: InputCommons,
  components: { DropDownList },
  use: { DropdownsInstaller },
  methods: {
    clear() {
      if (this.$refs[this.name]) {
        var dropDown = this.$refs[this.name].kendoWidget();
        dropDown.value("");
      }
    },
  },
};
</script>
<style>
.k-is-invalid {
  border: 1px solid red;
}
.k-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}
</style>
