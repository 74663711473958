import Navigation from "@/views/general/Navigation";
import Footer from "@/views/general/Footer";

class RouteRegistrar {
  constructor(_prefix) {
    this.prefix = _prefix;
    this.routes = [];
  }

  preparePath(_path) {
    if (this.prefix) return `/${this.prefix}/${_path}`;
    return `/${_path}`;
  }

  createRoute(_mainModule, _path, _name, _title, _hasProps = false) {
    return {
      path: this.preparePath(_path),
      name: _name,
      components: {
        main: () => import(`@/views/${_mainModule}`),
        top: Navigation,
        bottom: Footer,
      },
      meta: {
        title: _title,
      },
      props: { main: _hasProps },
    };
  }

  add(_mainModule, _path, _name, _title, _hasProps = false) {
    let route = this.createRoute(_mainModule, _path, _name, _title, _hasProps);
    this.routes.push(route);
    return this;
  }

  prepare(_mainModule, _path, _name, _title) {
    return this.createRoute(_mainModule, _path, _name, _title);
  }

  allRoutes() {
    return this.routes;
  }
}

export default RouteRegistrar;
