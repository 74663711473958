<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن شیوه ارسال جدید"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="openModal"
            v-if="$acl.can('create', 'shippings')"
          />
          <hr />
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    </div>
    <shipping-form
      ref="shippingform"
      id="shipping-form"
      title="افزودن شیوه ارسال جدید"
      :is-loading="isLoadingModal"
      :props-data="updateForm"
      :shipping-prices="shippingPrices"
      @on-create="handleCreate"
      @on-update="handleUpdate"
      @on-create-shippingprice="handleCreateShippingPrice"
      @on-update-shippingprice="handleUpdateShippingPrice"
      @on-delete-shippingprice="handleDeleteShippingPrice"
    />
  </div>
</template>
<script>
import ShippingForm from "./components/ShippingForm";
import { shippingApi } from "@/api/apiManagement";
export default {
  components: { ShippingForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "شیوه ارسال", link: "/shop/shippings" },
      ],
      gridColumns: [
        { title: "#", template: this.$getTemplate, width: "40px" },
        { field: "name", title: "نام" },
        { field: "duration", title: "مدت زمان" },
        {
          field: "status",
          title: "وضعیت",
          template: (e) => (e.status_text ? e.status_text : "-"),
        },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: [],
        },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      updateForm: {},
      shippingPrices: {},
    };
  },
  mounted() {
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    readData(e) {
      this.isLoadingGrid = true;
      shippingApi
        .getShippings()
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("edit", "shippings"))
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
      if (this.$acl.can("delete", "shippings"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    edit(e) {
      this.isLoadingModal = true;
      let shipping = this.$refs.grid.getSelectedGridRow(e);
      this.updateForm = shipping;
      this.shippingPrices = shipping.shipping_prices;
      this.toggleForm();
      this.isLoadingModal = false;
    },
    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف شیوه ارسال  ${data.name} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          shippingApi
            .deleteShippings(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    openModal() {
      this.updateForm = {};
      this.$toggleModal("#shipping-form");
    },
    toggleForm() {
      this.$toggleModal("#shipping-form");
    },
    handleCreate(e) {
      this.isLoadingModal = true;
      shippingApi
        .createShippings( this.$getFormData(e))
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.toggleForm();
          this.updateForm = {};
          this.loadData();
        })
        .finally(() => (this.isLoadingModal = false));
    },
    handleUpdate(e) {
      this.isLoadingModal = true;
      shippingApi
        .updateShippings(e.id, this.$getFormData(e))
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.toggleForm();
          this.updateForm = {};
          this.loadData();
        })
        .finally(() => (this.isLoadingModal = false));
    },
    handleCreateShippingPrice(e) {
      this.isLoadingModal = true;
      shippingApi
        .createShippingPrices(e.shipping_id, e)
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.loadData();
          this.$refs.shippingform.loadData();
        })
        .finally(() => (this.isLoadingModal = false));
    },
    handleUpdateShippingPrice(e) {
      this.isLoadingModal = true;
      shippingApi
        .updateShippingPrices(e.shipping_id, e.id, e)
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.loadData();
          this.$refs.shippingform.loadData();
        })
        .finally(() => (this.isLoadingModal = false));
    },
    handleDeleteShippingPrice(e) {
      shippingApi
        .deleteShippingPrices(e.shipping_id, e.id)
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.loadData();
          this.$refs.shippingform.loadData();
        })
        .finally(() => []);
    },
  },
};
</script>
