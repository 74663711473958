<template>
  <div :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="">
        <label :for="id"
          >{{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i></div
        ></label>
        <div :class="{ 'trumbowyg-dark': isDarkmode }">
          <!-- <textarea :id="name"></textarea> -->
          <editor
            :id="name"
            :name="name"
            v-model="content"
            api-key="oztjvpgi6y9zmxy3vextfr9ntnarh7ril6pfaz1flpo3rlvg"
            :init="initTiny"
            @input="handleInput(content)"
          />
        </div>
        <div v-if="showPreview" class="preview" v-html="content"></div>
        <span class="invalid-feedback">{{ errors[0] }}</span>
      </div>
    </v-validate>
    <ul id="toc" style="display: none"></ul>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  extends: InputCommons,
  props: {
    dir: {
      type: String,
      default: "texteditor",
    },
    uploadUrl: {
      type: String,
      default: "/shop/files",
    },
  },
  components: {
    editor: Editor,
  },
  data() {
    return {
      isDarkmode: false,
      showPreview: false,
      editor: {},
      // initTiny: {
      //   height: 300,
      //   ui_container: ".modal",
      //   directionality: "rtl",
      //   language: "fa",
      //   images_upload_handler: this.uploadHandler,
      //   // language_url: `${process.env.VUE_APP_API_BASEPOINT}/js/fa.js`,
      //   // images_upload_url: `${process.env.VUE_APP_API_BASEPOINT}${this.uploadUrl}`,
      //   images_upload_credentials: true,

      //   headers: {
      //     "X-XSRF-TOKEN": this.$store.state.xsrfToken,
      //     "X-Requested-With": "XMLHttpRequest",
      //   },
      //   content_style: "body { font-family: tahoma;;font-size:10pt }",
      //   menubar: "file edit insert view format table tools help",
      //   menu: {
      //     file: { title: "File", items: "newdocument" },
      //     edit: {
      //       title: "Edit",
      //       items: "undo redo | cut copy paste pastetext | selectall",
      //     },
      //     insert: {
      //       title: "Insert",
      //       items: "link | template hr | image | media | toc",
      //     },
      //     view: {
      //       title: "View",
      //       items: "visualaid | code | fullscreen | preview",
      //     },
      //     format: {
      //       title: "Format",
      //       items:
      //         "bold italic underline strikethrough superscript subscript | formats | removeformat",
      //     },
      //     table: {
      //       title: "Table",
      //       items: "inserttable tableprops deletetable | cell row column",
      //     },
      //     tools: { title: "Tools", items: "spellchecker code" },
      //   },
      //   plugins:
      //     "lists link image paste textcolor wordcount autolink media advcode directionality fullscreen spellchecker table preview codesample toc",
      //   toolbar:
      //     "undo redo |ltr rtl | insertdatetime  fontsizeselect formatselect forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | codesample code",
      //   video_template_callback: function (data) {
      //     return (
      //       '<video width="' +
      //       data.width +
      //       '" height="' +
      //       data.height +
      //       '"' +
      //       (data.poster ? ' poster="' + data.poster + '"' : "") +
      //       ' controls="controls">\n' +
      //       '<source src="' +
      //       data.source +
      //       '"' +
      //       (data.sourcemime ? ' type="' + data.sourcemime + '"' : "") +
      //       " />\n" +
      //       (data.altsource
      //         ? '<source src="' +
      //           data.altsource +
      //           '"' +
      //           (data.altsourcemime
      //             ? ' type="' + data.altsourcemime + '"'
      //             : "") +
      //           " />\n"
      //         : "") +
      //       "</video>"
      //     );
      //   },
      // },
    };
  },
  computed: {
    editorSelector() {
      return `textarea#${this.name}`;
    },
    initTiny() {
      return {
        height: 300,
        ui_container: ".modal",
        directionality: "rtl",
        language: "fa",
        images_upload_handler: this.uploadHandler,
        // language_url: `${process.env.VUE_APP_API_BASEPOINT}/js/fa.js`,
        // images_upload_url: `${process.env.VUE_APP_API_BASEPOINT}${this.uploadUrl}`,
        images_upload_credentials: true,

        headers: {
          "X-XSRF-TOKEN": this.$store.state.xsrfToken,
          "X-Requested-With": "XMLHttpRequest",
        },
        content_style: "body { font-family: tahoma;;font-size:10pt }",
        menubar: "file edit insert view format table tools help",
        menu: {
          file: { title: "File", items: "newdocument" },
          edit: {
            title: "Edit",
            items: "undo redo | cut copy paste pastetext | selectall",
          },
          insert: {
            title: "Insert",
            items: "link | template hr | image | media | toc",
          },
          view: {
            title: "View",
            items: "visualaid | code | fullscreen | preview",
          },
          format: {
            title: "Format",
            items:
              "bold italic underline strikethrough superscript subscript | formats | removeformat",
          },
          table: {
            title: "Table",
            items: "inserttable tableprops deletetable | cell row column",
          },
          tools: { title: "Tools", items: "spellchecker code" },
        },
        // remove advcode from plugin because its primium plugin
        plugins:
          "lists link image paste textcolor wordcount autolink media  directionality fullscreen spellchecker table preview codesample toc code",
        toolbar:
          "undo redo |ltr rtl | insertdatetime  fontsizeselect formatselect forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | codesample code",
        video_template_callback: function (data) {
          return (
            '<video width="' +
            data.width +
            '" height="' +
            data.height +
            '"' +
            (data.poster ? ' poster="' + data.poster + '"' : "") +
            ' controls="controls">\n' +
            '<source src="' +
            data.source +
            '"' +
            (data.sourcemime ? ' type="' + data.sourcemime + '"' : "") +
            " />\n" +
            (data.altsource
              ? '<source src="' +
                data.altsource +
                '"' +
                (data.altsourcemime
                  ? ' type="' + data.altsourcemime + '"'
                  : "") +
                " />\n"
              : "") +
            "</video>"
          );
        },
      };
    },
    // btns() {
    //   return [
    //     ["fontsize"],
    //     ["fontfamily"],
    //     ["foreColor", "backColor"],
    //     ["lineheight"],
    //     ["justifyRight", "justifyCenter", "justifyLeft", "justifyFull"],
    //     ["unorderedList", "orderedList"],
    //     ["formatting"],
    //     ["strong", "em", "del"],
    //     ["table"],
    //     ["upload"],
    //     ["link"],
    //     ["insertImage"],
    //     ["noembed"],
    //     ["emoji"],
    //     ["undo", "redo"], // Only supported in Blink browsers
    //     ["superscript", "subscript"],
    //     ["horizontalRule"],
    //     ["removeformat"],
    //     ["viewHTML"],
    //     ["preview"],
    //     ["toc"],
    //     ["fullscreen"],
    //   ];
    // },
    // plugins() {
    //   return {
    //     fontsize: {
    //       sizeList: ["12px", "14px", "16px"],
    //     },
    //     upload: {
    //       data: [{ name: "dir", value: this.dir }],
    //       fileFieldName: "file_to_upload",
    //       headers: {
    //         "X-XSRF-TOKEN": this.$store.state.xsrfToken,
    //         "X-Requested-With": "XMLHttpRequest",
    //       },
    //       serverPath: `${process.env.VUE_APP_API_BASEPOINT}${this.uploadUrl}`,
    //       urlPropertyName: "entity",
    //       statusPropertyName: "is_success",
    //       xhrFields: {
    //         withCredentials: true,
    //       },
    //       // Some upload plugin options, see details below
    //     },
    //     fontfamily: {
    //       fontList: [
    //         { name: "Arial", family: "Arial, Helvetica, sans-serif" },
    //         { name: "Open Sans", family: "'Open Sans', sans-serif" },
    //       ],
    //     },
    //   };
    // },
  },
  mounted() {
    // this.manageDarkmode();
    // this.registrerDarkmodeEventListener();
    // this.initialEditor();
  },
  watch: {
    // content() {
    //   $("textarea").trumbowyg("html", this.content);
    // },
  },
  methods: {
    setInitialValue(_value) {
      $(this.editorSelector).trumbowyg("html", _value);
    },
    reset() {
      $(this.editorSelector).trumbowyg("html", "");
    },
    initialEditor() {
      this.editor = $(this.editorSelector).trumbowyg({
        svgPath: "/img/icons.svg",
        imageWidthModalEdit: true,
        lang: "fa",
        btns: this.btns,
        plugins: this.plugins,
        btnsDef: {
          preview: {
            fn: () => {
              this.showPreview = !this.showPreview;
            },
            ico: "preview",
            title: "پیش نمایش",
            hasIcon: true,
          },
          toc: {
            fn: () => {
              // this.insertAtCaret('.trumbowyg-editor', 'kharkosde');
              $("#toc").empty();
              $("#toc").toc({
                content: ".trumbowyg-editor",
                headings: "h1,h2,h3,h4",
              });
              var toc = '<ul id="toc>' + $("#toc").html() + "</ul>";
              $(this.editorSelector).trumbowyg(
                "html",
                toc.concat("<br/>", this.content)
              );
              console.log($("#toc").clone());
            },
            ico: "preview",
            title: "افزودن جدول عناوین",
            hasIcon: false,
          },
        },
      });

      this.editor.on("tbwchange", () => {
        this.content = $(this.editorSelector).trumbowyg("html");
        this.handleInput(this.content);
      });
    },
    uploadHandler(blobInfo, success, failure, progress) {
      var xhr, formData;

      xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.open("POST", `${process.env.VUE_APP_API_BASEPOINT}${this.uploadUrl}`);

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = function () {
        var json;

        if (xhr.status === 403) {
          failure("HTTP Error: " + xhr.status, { remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure("HTTP Error: " + xhr.status);
          return;
        }

        json = JSON.parse(xhr.responseText);
        if (!json || typeof json.entity != "string") {
          failure("Invalid JSON: " + xhr.responseText);
          return;
        }
        success(json.entity);
      };

      xhr.onerror = function () {
        failure(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      formData = new FormData();
      formData.append("file_to_upload", blobInfo.blob(), blobInfo.filename());
      formData.append("dir", this.dir);

      xhr.send(formData);
    },
    manageDarkmode() {
      this.isDarkmode = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
    },
    registrerDarkmodeEventListener() {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => {
          this.isDarkmode = e.matches ? true : false;
        });
    },
    removeDarkmodeEventListener() {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", (e) => {
          this.isDarkmode = e.matches ? true : false;
        });
    },
  },
  beforeDestroy() {
    $(this.editorSelector).trumbowyg("destroy");
    this.removeDarkmodeEventListener();
  },
};
</script>
<style>
.mce-menu {
  z-index: 65537 !important;
}
.tox-tinymce-aux {
  z-index: 200000 !important;
}
</style>
