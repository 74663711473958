import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route('article');

RouteRegistrar.add("article/categories/Categories", "categories", "categories-articles", "مدیریت دسته بندی ها")

RouteRegistrar.add("article/articles/Articles", "", "articles", "مقالات")
RouteRegistrar.add("article/articles/components/ArticleForm", "create", "articles-form", "افزودن مقالات")
RouteRegistrar.add("article/articles/components/ArticleForm", ":id/update", "articles-form", "ویرایش مقالات")

const routes = RouteRegistrar.allRoutes()


export default routes;
