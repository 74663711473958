<template>
  <div id="app" class="top-navigation k-rtl rtls body">
    <div id="wrapper">
      <side-bar />
      <div id="page-wrapper" class="gray-bg">
        <router-view name="top" />
        <div class="wrapper wrapper-content  animated">
          <div class="">
            <router-view name="main" />
          </div>
        </div>
        <router-view name="bottom" />
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from "@/components/sidebar/SideBar";
export default {
  components: { SideBar },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
