import { apiCall } from "../imports"

const PREFIX = "management/app-icons"

export default {

    getAppIcons(data) {
        return apiCall('get', `${PREFIX}`, data)
    },

    createAppIcon(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateAppIcon(appIconId, data) {
        return apiCall('post', `${PREFIX}/${appIconId}/update`, data)
    },

    deleteAppIcon(appIconId) {
        return apiCall('delete', `${PREFIX}/${appIconId}`)
    }
}