<template>
  <bita-modal
    id="social-media-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="reset"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-select
              name="formtype"
              ref="formtype"
              label="نوع "
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="[
                { id: 3, name: 'آیکن شبکه های اجتماعی' },
                { id: 4, name: 'مرکز تماس و پشتیبانی' },
              ]"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.support"
            />
            <bita-input
              type="text"
              name="title"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
            />

            <bita-input
              type="text"
              name="slug"
              label="نامک"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.slug"
              subtitle="حتما نام لاتین وارد نمایید"
            />

            <bita-input
              type="text"
              name="title"
              label="ترتیب"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.order"
            />

            <bita-select
              name="meta_type"
              ref="meta_type"
              label="نوع آیکن"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="[
                { id: 1, name: 'عکس' },
                { id: 2, name: 'فونت' },
              ]"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.meta_type"
            />

            <bita-input
              type="text"
              name="meata_value"
              label="آیکن"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.meta_value"
              v-show="!isImage"
            />
            <bita-input
              type="text"
              name="expertname"
              label="نام کارشناس"
              :validation-container-class="colWidth"
              v-show="isSupport"
              input-class="form-control"
              v-model="formData.expertname"
            />

            <bita-input
              type="text"
              name="description"
              label="توضیحات"
              v-show="isSupport"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.description"
            />
            <bita-uploader
              name="image"
              label="تصویر"
              ref="uploader"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.image"
              :icon-url="formData.image_url"
              v-show="isImage"
            />

            <bita-input
              type="text"
              name="link"
              label="لینک"
              :validation-container-class="colFullWidth"
              input-class="form-control"
              v-model="formData.link"
            />
            
            <!-- <bita-texteditor
              type="text"
              ref="description"
              name="description"
              label="توضیحات"
              :validation-container-class="colFullWidth"
              input-class="form-control"
              v-model="formData.description"
            /> -->

            <bita-textarea
              type="text"
              name="css"
              label="css کد"
              :validation-container-class="colFullWidth"
              input-class="form-control"
              v-model="formData.meta"
              rowspan="5"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    title: String,
    isLoading: Boolean,
  },
  data() {
    return {
      colWidth: "col-lg-4",
      colFullWidth: "col-lg-12",
      formData: {},
    };
  },
  computed: {
    isProductPic() {
      return this.formData.type == 2;
    },
    isImage() {
      return this.formData.meta_type == 1;
    },
    isSupport() {
      return this.formData.support == 4;
    },
  },
  methods: {
    setForm(data) {
      this.formData = data;
      // if (data.description)
      //   this.$refs.description.setInitialValue(data.description);
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    reset() {
      this.formData = {};
      this.$refs.uploader.reset();
      this.$refs.form.reset();
      this.$refs.meta_type.emptyKendo();
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
