<template>
  <div
    class="btn btn-primary btn-rounded btn-block"
    :style="`${btnWidth ? `width: ${btnWidth}; margin: 0 auto` : ''}`"
    @click="$emit('click')"
  >
    <i :class="icon" style="margin-left: 5px"></i>{{ title }}
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    btnWidth: {
      type: String,
    },
  },
};
</script>
