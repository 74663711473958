<template>
  <div slot="body">
    <bita-form ref="form">
      <div slot="body">
        <div class="row" v-html="this.CompletedProfileSellerText">
  
        </div>
      </div>
    </bita-form>
  </div>
</template>
<script>
import { supplireApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
    largeColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    product: {},
  },
  data() {
    return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
      form: { accept: false },
      CompletedProfileSellerText: "",
    };
  },
  mounted() {
    this.getSuccessCompletedProfileSellerText();
  },
  watch: {},
  computed: {
    formRef() {
      return this.$refs.form;
    },
    isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
  },
  methods: {
    AcceptRule(value) {
      return (this.form.accept = value ? 0 : 1);
    },
    setInitialValue(data) {
      this.form = data;
    },
    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
            if (this.isUpdateMode) {
              // alert('yes')
            } else {
              supplireApi
                .AcceptingTheRules({
                  accept: this.form.accept,
                })

                .then((res) => {
                  this.form = res.data.entity;
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
            }
          } else reject(false);
        });
      });
    },
    getSuccessCompletedProfileSellerText() {
      supplireApi.getSuccessCompletedProfileSellerText().then(({ data }) => {
        this.CompletedProfileSellerText = data.entity.content;
      });
    },

    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      this.form = {};
    },
  },
};
</script>
