<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <span class="d-none d-md-block">
            <div class="row">
              <div class="col-12">
                <div class="float-left"> 
                  <bita-bootbutton
                    title="تکمیل پروفایل"
                    type="primary"
                    icon="fas fa-plus-square"
                    btn-width="200px"
                    @click.native="createsSupplire"
                    style="margin: 5px;"
                    v-if="$user.seller.seller_status_id==3 ? 0 : 1"
                  />
                </div>
              </div>
              <hr />
            </div>
            <hr />
          </span>

          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :page-size="pageSize"
            :schema-total="'total'"
            :schema-data="'data'"
          />
        </div>
      </bita-ibox>
    </div>
    <bita-modal
      id="info-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <info-form
          ref="infoForm"
          @on-update="(e) => infoUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('infoForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>

    <bita-modal
      id="address-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <address-form
          ref="addressForm"
          @on-update="(e) => addressUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('addressForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>

    <bita-modal
      id="financial-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <financial-form
          ref="financialForm"
          @on-update="(e) => financialUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('financialForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>

    <bita-modal
      id="document-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <document-form
          ref="documentForm"
          @on-update="(e) => documentUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('documentForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>

    <bita-modal
      id="rule-form"
      :title="formTitle"
      :modal-lg="true"
      :is-loading="isLoadingModal"
    >
      <div slot="body">
        <rule-form
          ref="ruleForm"
          @on-update="(e) => ruleUpdated(e)"
          @is-loading="(e) => (isLoadingModal = e)"
        />
      </div>
      <div slot="footer">
        <bita-bootbutton
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="save('ruleForm')"
          :disabled="isLoadingModal"
        />
      </div>
    </bita-modal>

    <commission-form ref="commissionForm" :title="formTitle" />
    <feedback-form ref="feedbackForm" :title="formTitle" />
  </div>
</template>
<script>
import InfoForm from "./components/SupplireInfoForm";
import AddressForm from "./components/SupplireAddressForm.vue";

import FinancialForm from "./components/SupplireFinancialForm";
import DocumentForm from "./components/SupplireDocumentForm.vue";
import RuleForm from "./components/SupplireRuleForm.vue";
import CommissionForm from "./components/CommissionForm";
import CompleteForm from "./components/SupplireCompleteForm.vue";
import FeedbackForm from "./components/SupplierFeedback.vue"

import { supplireApi } from "@/api/apiManagement";
import Tools from "../../../components/Tools.vue";
export default {
  components: {
    InfoForm,
    AddressForm,
    FinancialForm,
    DocumentForm,
    RuleForm,
    CommissionForm,
    CompleteForm,

    FeedbackForm,

    Tools,
  },

  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "تامین کنندگان", link: "" },
        {
          title: "مدیریت تامین کنندگان",
          link: "/management/roles",
          active: true,
        },
      ],
      colWith: "col-lg-2",
      pageSize: 30,
      currentPage: 1,
      isLoadingForm: false,
      isLoadingGrid: false,
      isLoadingModal: false,
      formTitle: "",
      permissions: [],
      editForm: {},
      provinces: [],
      banks: [],
    };
  },
  computed: {
    gridColumns() {
      return [
        // { title: "#", template: this.$getTemplate, width: "40px" },

        { field: "name", title: "تامین کننده", template: this.getSellerName },
        { field: "store", title: "نام فروشگاه", template: this.getStoreName },
        {
          field: "phone",
          title: "شماره تماس فروشگاه",
          template: this.getSellerPhone,
          // width: 100,
        },

        {
          field: "is_recommended",
          title: "وضعیت",
          template: ({ seller_status_id }) => (seller_status_id==3 ? '<span class="badge badge-primary">تایید شده </span>' : '<span class="badge badge-secondary">در انتظار تایید</span>'),
          width: 100,
        },

        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
          // width: 150,
        },
        {
          title: "عملیات",
          width: 350,
          command: this.getGridColumns(),
        },
      ];
    },
  },
  mounted() {
  },
  methods: {
    getGridColumns(e) {
      let commands = [];
      if (this.$acl.can("confirmation", "of-seller"))
      commands.push({
        text: "sellerConfirm",
        click: this.editStatus,
        template: this.gridButtons.sellerConfirm,
      });
      if (this.$acl.can("edit", "store-of-seller"))
        commands.push({
          text: "sellerStore",
          click: this.editstore,
          template: this.gridButtons.sellerStore,
        });
      if (this.$acl.can("edit", "address-of-seller"))
        commands.push({
          text: "sellerAddress",
          click: this.editAddress,
          template: this.gridButtons.sellerAddress,
        });

      if (this.$acl.can("edit", "finance-of-seller"))
        commands.push({
          text: "sellerFinace",
          click: this.editFinanc,
          template: this.gridButtons.sellerFinace,
        });
      if (this.$acl.can("edit", "document-of-seller"))
        commands.push({
          text: "images",
          click: this.editDoc,
          template: this.gridButtons.images,
        });
      if (this.$acl.can("create", "commission-of-seller"))
        commands.push({
          text: "sellerCommissions",
          click: this.editCommission,
          template: this.gridButtons.sellerCommissions,
        });
      if (this.$acl.can("view", "feedback"))
        commands.push({
          text: "sellerFeedback",
          click: this.editFeedback,
          template: this.gridButtons.sellerFeedback,
        });
        
      if (this.$acl.can("delete", "seller"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      
      return commands;
    },

    getStoreName(e) {
      let store = e.store.name ? e.store.name : "-";
      return `${store}`;
    },
    getSellerName(e) {
      let full_name = e.user.full_name;
      return `${full_name}`;
    },
    getSellerEmail(e) {
      let full_name = e.user.full_name;
      return `${full_name}`;
    },
    getSellerPhone(e) {
      let phone = e.store.phone ? e.store.phone : "-";
      return `${phone}`;
    },

    save(ref) {
      this.$refs[ref].save();
    },

    saveDoc(slide) {
      this.isLoadingModal = true;
      slide = this.getFormData(slide);
      slideApi
        .createSlide(slide)
        .then((res) => {
          this.formSucceeded(res.data.message);
          this.toggleModal();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    formSucceeded(ref) {
      this.loadData();
      this.formTitle = "";
      this.$refs.infoForm.setInitialValue({});
      this.$toggleModal("#info-form");
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;

      supplireApi
        .getSupplires(this.filters)
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    editAddress(e) {
      let seller = this.$refs.grid.getSelectedGridRow(e);
      if (
        this.$acl.can("view", "admin-of-sellers") ||
        seller.seller_status_id != 1 
      ){
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.addressForm.setInitialValue(supplire.address[0]);
      this.formTitle = `ویرایش ادرس ${supplire.store.name}`;
      this.$toggleModal("#address-form");
    }else
    this.$izitoast(
          "error",
          "تکمیل پروفایل شما انجام نشده ابتدا تکمیل پروفایل نمایید  ",
          "عدم دسترسی"
        );
    },
    editCommission(e) {
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.formTitle = ` کمسیون محصولات فروشگاه ${supplire.store.name} - صاحب امتیاز ${supplire.user.full_name}`;
      this.$refs.commissionForm.setInitialData(supplire);
      this.$toggleModal("#commission-form");
    },
    editFeedback(e){
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.formTitle = ` بازخورد`;
      this.$refs.feedbackForm.setInitialData(supplire);
      this.$toggleModal("#feedback-form");
    },
    editFinanc(e) {
      let seller = this.$refs.grid.getSelectedGridRow(e);
      if (
        this.$acl.can("view", "admin-of-sellers") ||
        seller.seller_status_id != 1 
      ){
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.financialForm.setInitialValue(supplire);
      this.formTitle = `ویرایش اطلاعات حساب ${supplire.user.full_name}`;
      this.$toggleModal("#financial-form");
    }else
    this.$izitoast(
          "error",
          "تکمیل پروفایل شما انجام نشده ابتدا تکمیل پروفایل نمایید  ",
          "عدم دسترسی"
        );
    },
    editDetails(e) {
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.detailForm.setInitialValue(supplire);
      this.formTitle = `ویرایش جزئیات ${supplire.name}`;
      this.$toggleModal("#details-form");
    },

    editDoc(e) {
      let seller = this.$refs.grid.getSelectedGridRow(e);
      if (
        this.$acl.can("view", "admin-of-sellers") ||
        seller.seller_status_id != 1 
      ){
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.documentForm.setInitialValue(supplire);
      this.formTitle = `ویرایش اسناد ${supplire.user.full_name}`;
      this.$toggleModal("#document-form");
    }else
    this.$izitoast(
          "error",
          "تکمیل پروفایل شما انجام نشده ابتدا تکمیل پروفایل نمایید  ",
          "عدم دسترسی"
        );
    },

    editStatus(e) {
      let { id, store, user } = this.$refs.grid.getSelectedGridRow(e);

      let seller = this.$refs.grid.getSelectedGridRow(e);

      let messageOfAlert = seller.seller_status_id == 3 ? ` آیا از عدم تایید کردن فروشنده ‌${user.full_name}  صاحب امتیاز فروشگاه :${store.name}   اطمینان دارید؟` : ` آیا از تایید کردن فروشنده ‌${user.full_name}  صاحب امتیاز فروشگاه :${store.name}   اطمینان دارید؟` ;

      this.$alert(
        "question",
        messageOfAlert,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          supplireApi.sellerConfirmation(id).then(() => {
            this.loadData();
            this.$alert("success", `تغییرات اعمال شد`);
          });
        }
      });

    },
    editstore(e) {
      let seller = this.$refs.grid.getSelectedGridRow(e);
      if (
        this.$acl.can("view", "admin-of-sellers") ||
        seller.seller_status_id != 1 
      ){
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.infoForm.setInitialValue(supplire);
      this.$toggleModal("#info-form");
    }else
    this.$izitoast(
          "error",
          "تکمیل پروفایل شما انجام نشده ابتدا تکمیل پروفایل نمایید  ",
          "عدم دسترسی"
        );
    },
    supplireFeedback(e) {
    
      let supplire = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.supplireFeedback.setInitialData(supplire);
    //  this.formTitle = `بررسی محصول ${product.name}`;
      this.$toggleModal("#supplire-feedback");
    },
    viewProduct(e) {
      let product = this.$refs.grid.getSelectedGridRow(e);
      window.open(product.id);
    },

    infoUpdated(_data) {
      this.loadData();
      this.formTitle = "";
      this.$refs.infoForm.setInitialValue({});
      this.$hideModal("#info-form");
    },

    financialUpdated(_data) {
      this.loadData();
      this.formTitle = "";
      this.$refs.financialForm.setInitialValue({});
      this.$hideModal("#financial-form");
    },
    documentUpdated(_data) {
      this.loadData();
      this.formTitle = "";
      this.$refs.financialForm.setInitialValue({});
      this.$hideModal("#document-form");
    },
    addressUpdated(_data) {
      this.formTitle = "";
      this.$refs.addressForm.setInitialValue({});
      this.$hideModal("#address-form");
    },
    toggleModal() {
      this.$toggleModal("#commission-form");
    },
    sellerIsConfirm(seller) {
      return seller.seller_status_id == 3;
    },
    delete(e) {
      
      let seller= this.$refs.grid.getSelectedGridRow(e);
      console.log(seller);
      this.$alert(
        "question",
        ` با حذف فروشنده کلیه اطلاعات فروشنده پاک خواهد شد آیا از حذف ‌${seller.seller_fullname} صاحب امتیاز :${seller.seller_store_name}  اطمینان دارید؟ `,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          supplireApi.deleteSeller(seller.id)
            .then((res) => {
              this.$izitoast("success", "با موفقیت حذف شد", "موفق");
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },

    createsSupplire() {
      this.$router.push({ path: "/supplire/sellers/create" });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
a.margin-top {
  margin-top: 27px;
}
a.margin-right {
  margin-right: 5px;
}
.margin-rigth {
  margin-right: 16px;
}
</style>
