<template>
  <div slot="body">
    <bita-form ref="form">
      <div slot="body">
        <div class="row">
          <template v-if="$acl.can('edit', 'standards')">
            <bita-multiselect
              name="standards"
              ref="standards"
              label="استانداردها"
              rules=""
              validation-container-class="col-9"
              input-class="form-control"
              :data-source="standards"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.standard_id"
            />
            <div class="col-3 mt-5">
              <a @click="addStrandard"> افزودن استاندارد جدید </a>
            </div>
          </template>
          <bita-typeahead
            type="text"
            name="name"
            label="کلمه های کلیدی"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="matches"
            v-model="tags"
            @on-search="(e) => loadDataSource(e)"
            subtitle="کلمات کلیدی را نوشته کلید اینتر را بزنید"
          />
        </div>
      </div>
    </bita-form>
    <standard-form ref="standardForm" @load-data="getStandards" />
  </div>
</template>
<script>
import { productApi, tagApi, standardApi } from "@/api/apiManagement";
import standardForm from "./../../standards/components/StandardForm";
export default {
  components: {
    standardForm,
  },
  props: {
    title: String,
    isLoading: Boolean,
    largeColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    product: {},
  },
  data() {
    return {
      form: {},
      newTag: "",
      tags: [],
      matches: ["aaa", "bbb", "cccc"],
      formData: {},
      standards: [],
    };
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
  },
  mounted() {
    this.getStandards();
  },
  methods: {
    getStandards() {
      if (this.$acl.can("view", "standards"))
        standardApi.get().then((res) => [(this.standards = res.data.entity)]);
    },
    onChange(event) {
      this.value = event.target.value;
    },
    setInitialValue(_product) {
      this.form = _product;
      if (_product) {
        this.$emit("is-loading", true);
        let getData = this.$acl.can("view", "standards") ?
          productApi.getProducTagsAndStandard(_product.id) :
          productApi.getProductTags(_product.id);
        getData
          .then((res) => {
            let data = res.data.entity;
            if ( this.$acl.can("view", "standards") ) {
                this.tags =
                data.tags.length > 0
                  ? collect(data.tags).pluck("name.fa").toArray()
                  : [];
                this.formData.standard_id =
                  data.standards.length > 0
                    ? collect(data.standards).pluck("id").toArray()
                    : [];
            } else
              this.tags =
                data.length > 0
                  ? collect(data).pluck("name.fa").toArray()
                  : [];
          })
          .finally(() => {
            this.$emit("is-loading", false);
          });
      }
    },

    loadDataSource(tag) {
      tagApi.findTags({ tag: tag }).then((res) => {
        this.matches = collect(res.data.entity).pluck("name.fa").toArray();
      });
    },

    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.formData.tags = this.tags;
            this.$emit("is-loading", true);
            let syncData = this.$acl.can('view', 'standards') ? 
              productApi.syncTagStandard(this.form.id, this.formData) :
              productApi.syncTags(this.form.id, this.formData);
              syncData
                .then((res) => {
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
          } else reject(false);
        });
      });
    },

    addStrandard() {
      this.$toggleModal("#standard-form");
      this.$refs.standardForm.setInitialData({});
    },

    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      this.form = {};
      this.tags = [];
      this.formData = {};
      this.$refs.standards.emptyKendo();
      this.formRef.reset();
    },
  },
};
</script>
<style scoped>
ul.dropdown-list {
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  padding: 0;
  position: absolute;
  width: 87%;
  background: #fff;
}
li {
  list-style: none;
  padding: 5px 12px;
}
li a {
  color: #585858;
}
li:hover {
  background: #adadad;
}
</style>