import { apiCall } from "../imports"

const PREFIX = "management/app-icons/social-media"

export default {

    getSocialMedias(data) {
        return apiCall('get', `${PREFIX}`, data)
    },

    createSocialMedia(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateSocialMedia(appIconId, data) {
        return apiCall('post', `${PREFIX}/${appIconId}/update`, data)
    },

    deleteSocialMedia(appIconId) {
        return apiCall('delete', `${PREFIX}/${appIconId}`)
    }
}