<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },

    getData: { type: Function },

    isLoading: { type: Boolean },

    disabled: {
      type: Boolean,
      default: false,
    },
    inputContainerClass: {
      type: String,
    },
    validationContainerClass: {
      type: String,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    placeHolder: {
      type: String,
    },
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    inputClass: {
      type: String,
    },
    dataSource: {
      default: () => [],
    },
    dataTextField: {
      type: String,
    },
    dataValueField: {
      type: String,
    },
    color: {
      type: String,
    },
    value: {},
    autocomplete: {
      type: String,
      default: "on",
    },
    hasThousandSeprator: {
      type: Boolean,
      default: false,
    },
    jalaliDate: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    template: {
      type: Function,
    },
  },
  watch: {
    value: {
      handler(val) {
        // console.log(val)
        this.content = val;
      },
      deep: true,
    },
  },
  computed: {
    inputID() {
      return this.id != null ? this.id : this.name;
    },
    isRequired() {
      return this.rules.includes("required");
    },
    hasSubtitle() {
      return this.subtitle != "";
    },
    content: {
      get: function () {
        if (this.hasThousandSeprator) {
          return this.thousandSeprator(this.contentTemp);
        }
        return this.contentTemp;
      },
      // setter
      set: function (newValue) {
        let content;
        if (this.hasThousandSeprator) {
          content = Number(String(newValue).replace(/,/g, ""));
        } else {
          content = newValue;
        }

        if (this.jalaliDate) {
          content = this.$getJalaliDate(newValue);
        }
        this.contentTemp = content;
      },
    },
  },
  data() {
    return {
      contentTemp: "",
    };
  },
  methods: {
    handleInput(content) {
      // let content
      if (this.hasThousandSeprator) {
        content = this.contentTemp;
      }
      this.$emit("input", this.contentTemp);
      this.$emit("change", this.contentTemp);
    },
    thousandSeprator(amount) {
      if (amount) {
        // amount = String(amount).replace(",", "");
        amount = String(amount).replace(/,/g, "");
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
  },
};
</script>
<style>
.is-required {
  display: inline-block;
  font-size: 7px;
  color: #ffa5a5;
  position: absolute;
  right: 6px;
  top: 6px;
}
span.form-text.m-b-none {
  font-size: 9px;
}
</style>
