<template>
  <div>
    <kendo-datasource
      v-if="!gridServerSide"
      ref="datasource"
      :transport-read="readData"
      :page-size="gPageSizeLocal"
      :aggregate="aggregateDefinition"
      :schema-model-fields="schemaModelFields"
      :group="groupDefinition"
    ></kendo-datasource>
    <kendo-datasource
      v-if="gridServerSide"
      ref="datasource"
      :transport-read="readData"
      :page-size="gPageSizeLocal"
      :aggregate="aggregateDefinition"
      :schema-model-fields="schemaModelFields"
      :group="groupDefinition"
      :schema-total="schemaTotal"
      :schema-data="schemaData"
      :server-paging="serverPaging"
      :batch="batch"
    ></kendo-datasource>
    <kendo-grid
      :id="gridId"
      ref="grid"
      :page="gridPageLocal"
      @databinding="ddd"
      :data-source-ref="'datasource'"
      :sortable-mode="'single'"
      :pageable="grid.pageable"
      :auto-bind="autoBind"
      :resizable="resizable"
      :no-records="true"
      :mobile="true"
    >
      <kendo-grid-column
        v-if="hasRowNumber"
        title="#"
        width="50px"
        :template="(e) => getTemplateLocal(e)"
      ></kendo-grid-column>
      <kendo-grid-column
        v-for="(column, index) in columns"
        :key="index"
        :title="column.title"
        :template="column.template"
        :field="column.field"
        :width="column.width"
        :command="column.command"
        :filterable="column.filterable == false ? column.filterable : true"
        :aggregates="column.aggregates"
        :footer-template="column.footerTemplate"
        :columns="column.columns"
        :group-footer-template="column.groupFooterTemplate"
        :groupHeaderTemplate="column.groupHeaderTemplate"
      ></kendo-grid-column>
    </kendo-grid>
  </div>
</template>
<script>
export default {
  props: {
    hasRowNumber: {
      type: Boolean,
      default: false,
    },
    gridServerSide: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
    },
    readData: {
      type: Function,
    },
    autoBind: {
      type: Boolean,
      default: true,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    aggregateDefinition: {
      type: Array,
      default: () => [],
    },
    schemaModelFields: {
      type: Object,
      default: () => {},
    },
    groupDefinition: {
      type: Object,
      default: () => {},
    },
    schemaTotal: {
      type: String,
      default: "total",
    },
    schemaData: {
      type: String,
      default: "data",
    },
    serverPaging: {
      type: Boolean,
      default: false,
    },
    batch: {
      type: Boolean,
      default: false,
    },
    onDataBinding: {
      type: Function,
    },
    gridId: {
      type: String,
      default: "grid",
    },
    pageSize: {
      type: Number,
      default: 30,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    pageSize: function(_val){
      this.gPageSizeLocal = _val
    },
    currentPage: function(_val){
      this.gpageLocal = _val
    }
  },
  data() {
    return {
      recordLocal: 0,
      gpageLocal: 1,
      gPageSizeLocal: 30
    };
  },
  methods: {
    ddd(ev) {
      if (this.onDataBinding) {
        return this.onDataBinding(ev);
      }
      return this.onDataBindingLocal(ev);
    },
    loadDataSource() {
      this.$loadGridDataSource();
    },
    getSelectedGridRow(e) {
      e.preventDefault();
      let grid = this.$refs.grid.kendoWidget();
      let rowData = grid.dataItem($(e.currentTarget).closest("tr"));
      return rowData;
    },
    onDataBindingLocal() {
      this.recordLocal = (this.gpageLocal - 1) * this.gPageSizeLocal;
    },
    gridPageLocal(e) {
      this.recordLocal = 0
      this.gpageLocal = e.page;
    },
    getTemplateLocal() {
      return ++this.recordLocal;
    },
  },
};
</script>
