<template>
  <bita-modal
    id="edit-modal"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="resetForm"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
              <bita-input
                type="text"
                name="question"
                label="سوال"
                rules="required"
                validation-container-class="col-lg-9"
                input-class="form-control"
                v-model="form.question"
              />
              <bita-select
                name="status"
                ref="status"
                rules=""
                label="وضعیت"
                :validation-container-class="colWidth"
                input-class="form-control"
                :data-source="statuses"
                data-text-field="title"
                data-value-field="id"
                v-model="form.status"
              />
              <bita-texteditor
                type="text"
                name="answer"
                ref="answer"
                label="پاسخ"
                validation-container-class="col-lg-12"
                input-class="form-control"
                v-model="form.answer"
              />
            </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!form.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>

export default {
  props: {
    title: String,
    isLoading: Boolean,
    brands: Array,
    products: Array,
    categories: Array,
    users: Array,
  },
  data() {
    return {
      priceTitle: "",
      colWidth: "col-lg-3",
      form: {},
      statuses: [
        { id: 1, title: "فعال" },
        { id: 2, title: "غیر فعال" },
      ],
    };
  },
  methods: {
    getId() {
      return this.form.id;
    },
    setFormData(formData) {
      this.$refs.answer.setInitialValue(formData.answer);
      this.form = formData;

    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.form);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.form);
        }
      });
    },
    resetForm() {
      this.$refs.form.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
      this.$refs.answer.reset();
      this.form = {};
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
<style scoped>
.btn.btn-w-m {
  margin-left: 20px !important;
}
</style>
