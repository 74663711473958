<template>
  <div slot="body">
    
    <bita-form ref="form" @close="reset">
      <div slot="body">
      <div class="row">
        <div style="display: none;">
       </div>
        <bita-select
              label="استان"
              name="province"
              ref="provinceSelected"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="provinces"
              @change="getCitiesOfProvince"
              data-text-field="name"
              data-value-field="id"
              v-model="form.province_id"
            />
             <bita-input
             type="text"
              label="شهر"
              name="cities"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="this.cities"
              data-text-field="name"
              data-value-field="name"
              v-model="form.city"
            />
            <bita-input
              type="text"
              name="postal_code"
              label="کد پستی"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.postal_code"

            />
            <bita-textarea
            name="address"
            ref="addressDescriptionTextarea"
            label="آدرس فروشگاه"
            rules=""
            validation-container-class="col-lg-12"
            input-class="form-control"
            v-model="form.address"
          /> 
         
      </div>
    </div>
    </bita-form>
  </div>
</template>
<script>
import BlockButton from "@/components/template/buttons/BlockButton";
import FormButton from "@/components/template/buttons/FormButton";
import { supplireApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
    largeColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    supplire: {},
  },
  components: { BlockButton, FormButton },
  data() {
    return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
      selected:'',
      
      form: {},
        cities:[],  
        provinces:[],
    };

  },
  mounted() {
    this.getProvinces()
  },
  computed: {
    formRef() {
      return this.$refs.form;
      
    },
   isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
  },

  methods: {
    setInitialValue(data) {
     this.form = data;

     
    },
    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
          //  if (this.isUpdateMode) {
              supplireApi
                .updateAddress({ seller_id:this.form.seller_id,
                address_id:this.form.id,
                province_id: this.form.province_id,
                city: this.form.city,
                postal_code: this.form.postal_code,
                address:this.form.address})
                .then((res) => {
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });       
           // }
           /* else {
          
              supplireApi
                .createAddress(this.$getFormData(this.form))
                .then((res) => {
                  this.form = res.data.entity;
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });       

                   }*/
                   
          } else reject(false);
        });
      });
    },
    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      this.form = {};
    
      this.formRef.reset();
    },
    getProvinces() {
       supplireApi.getProvinces().then(({ data }) => {
        this.provinces = data;
      });
    },
    getFormData() {
      let formData = new FormData();
      for (var key in this.formData) {
        console.log(this.formData[key])
        if (this.formData[key] != null) {
          formData.append(key, this.formData[key]);
        }
      }
      return formData;
    },
     
      getCitiesOfProvince(event) {
       supplireApi.getCitiesOfProvince(event).then(({ data }) => {
         this.cities = data;
      });
    },

  },
};
</script>
