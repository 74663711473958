<template>
  <bita-modal
    :id="id"
    :title="title"
    :modal-lg="true"
    :is-loading="loadingModal"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-select
              name="method"
              ref="method"
              id="method"
              label="نحوه پرداخت"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="methods"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.method"
            />
            <bita-select
              name="type"
              ref="type"
              id="type"
              label="نوع"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="types"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.type"
            />
            <bita-textarea
              type="text"
              name="title"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
            />
            <bita-uploader
              name="icon"
              label="تصویر"
              rules="size:50"
              ref="uploader"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.icon"
              :icon-url="formData.icon_url"
            />
            <bita-select
              name="status"
              ref="status"
              rules=""
              label="وضعیت"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="statuses"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.status"
            />
          </div>
          <!-- BANK -->
          <div class="row" v-if="formData.method == 2">
            <bita-select
              name="bank"
              ref="bankselect"
              id="bankselect"
              label="انتخاب بانک"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="propsBank"
              data-text-field="name"
              data-value-field="id"
              v-model="bankData.bank_id"
            />

            <bita-input
              type="text"
              name="account_owner"
              label="نام صاحب حساب"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="bankData.account_owner"
            />

            <bita-input
              type="text"
              name="account_owner"
              label="شماره حساب"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="bankData.account_cart"
            />

            <bita-input
              type="text"
              name="account_number"
              label="شماره کارت"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="bankData.account_number"
            />

            <bita-input
              type="text"
              name="sheba"
              label="شماره شبا"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="bankData.sheba"
            />
          </div>
          <!-- portals  -->
          <div class="row" v-if="formData.method == 3">
            <bita-select
              name="portal"
              ref="portal"
              id="portal"
              label="انتخاب پرتال"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="propsPortal"
              data-text-field="name"
              data-value-field="name"
              v-model="formData.portal"
              @change="handlePortalInput"
            />
            <bita-input
              v-for="(index, key) in showInput"
              :key="key"
              type="text"
              :name="key"
              :label="key"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control k-ltr"
              v-model="portalData[key]"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="formData.id"
        type="primary"
        title="به روزرسانی"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleUpdate"
      />
      <bita-bootbutton
        v-else
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleCreate"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    id: String,
    title: String,
    isLoading: Boolean,
    propsData: {},
    propsBank: {},
    propsPortal: {},
    propsPortalInput: {},
  },
  data() {
    return {
      formData: {},
      portalData: [],
      bankData: {},
      colWidth: "col-lg-3",
      methods: [
        { id: 1, name: "حضوری" },
        { id: 2, name: "فیش بانکی" },
        { id: 3, name: "آنلاین" },
        { id: 4, name: "چک بانکی" },
      ],
      statuses: [
        { id: 1, title: "فعال" },
        { id: 2, title: "غیر فعال" },
      ],
      types: [
        { id: 2, title: "سفارش" },
        { id: 1, title: "فروشنده" },
      ],
      showInput: {},
      portals: {},
      banks: {},
      loadingModal: false,
    };
  },
  watch: {
    isLoading: function(_val) {
      this.loadingModal = _val;
    },
  },
  methods: {
    setFormData(_data) {
      this.loadingModal = true;
      const _this = this;
      this.formData.method = _data.method;
      setTimeout(function() {
        _this.loadingModal = false;
        _this.formData = _data;
        _this.handleUpdateInput(_data.portal);
      }, 100);
    },
    handleUpdateInput(_portal) {
      const _this = this;
      if (_portal) this.handlePortalInput(_portal);
      setTimeout(function() {
        if (_this.formData.bank_id)
          _this.bankData = _this.setBankData(_this.formData);
        if (_this.formData.portal) _this.portalData = _this.formData.options;
      }, 50);
    },
    handlePortalInput(e) {
      this.showInput = {};
      this.portalData = {};
      this.showInput = collect(this.propsPortal)
        .where("name", e)
        .first().input;
    },
    handleCreate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.formData["options"] = JSON.stringify(this.portalData);
          let data = collect(this.formData).merge(this.bankData);
          data.items["icon"] = this.formData.icon;
          this.$emit("on-create", data.all());
        }
      });
    },
    handleUpdate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (this.formData.method == 3)
            this.formData.options = JSON.stringify(this.portalData);
          else {
            this.formData.options = JSON.stringify([]);
            this.formData.portal = "";
          }

          let data = collect(this.formData);
          if (this.formData.method == 2) data = data.merge(this.bankData);
          else data = data.merge(this.setBankData([]));

          data.items["id"] = this.formData["id"];
          data.items["icon"] = this.formData.icon;
          this.$emit("on-update", data.all());
        }
      });
    },
    setBankData(formData) {
      return {
        bank_id: formData.bank_id ? formData.bank_id : "",
        account_owner: formData.account_owner ? formData.account_owner : "",
        account_number: formData.account_number ? formData.account_number : "",
        account_cart: formData.account_cart ? formData.account_cart : "",
        sheba: formData.sheba ? formData.sheba : "",
      };
    },
    reset() {
      this.showInput = {};
      this.formData = {};
      this.bankData = {};
      this.portalData = {};
      this.$refs.form.reset();
      this.$refs.method.emptyKendo();
      this.$refs.uploader.reset();
    },
  },
};
</script>