import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/packings"

export default {

    getPackings() {
        return apiCall('get', `${PREFIX}`)
    },

    findPacking(packingId) {
        return apiCall('get', `${PREFIX}/${packingId}`)
    },

    createPacking(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updatePacking(packingId, data) {
        return apiCall('post', `${PREFIX}/${packingId}/update`, data)
    },
    

    deletePacking(packingId) {
        return apiCall('delete', `${PREFIX}/${packingId}`)
    },
}