<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن صفحه واسط"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="openModal"
            v-if="$acl.can('create', 'static-pages')"
          />
          <hr />
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    </div>
    <middlePagesForm
      ref="shippingform"
      id="middlepages-form"
      title="افزودن صفحه جدید"
      :is-loading="isLoadingModal"
      :props-data="updateForm"
      @on-create="create"
      @on-update="update"
    />
  </div>
</template>
<script>
import { mdidlePagesApi } from "@/api/apiManagement";
import middlePagesForm from "./components/MiddlePagesForm";
export default {
  components: { middlePagesForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "صفحات", link: "/shop/middlePages" },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      updateForm: {},
    };
  },
  computed: {
    gridColumns() {
      return [
        { title: "#", template: this.$getTemplate, width: "40px" },
        { field: "title", title: "عنوان" },
        { field: "category_id", title: "دسته بندی",width: "80px" },
        { field: "type", title: "نوع",width: "60px" },
        {
          field: "link",
          title: "آدرس",
          template: (e) =>
            e.type == 1
              ? "middle-pages/" + e.id + "/" + e.category_id
              : "middle-pages/brand/"+ e.id + "/" + e.brand_id,
        },
        { field: "active", title: "فعال",width: "60px" },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  methods: {
    readData(e) {
      this.isLoadingGrid = true;
      mdidlePagesApi
        .gets()
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("edit", "static-pages"))
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
      if (this.$acl.can("delete", "static-pages"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    edit(e) {
      this.isLoadingModal = true;
      let page = this.$refs.grid.getSelectedGridRow(e);
      console.log("page", page);
      this.updateForm = page;
      this.toggleForm();
      this.isLoadingModal = false;
    },
    delete(e) {
      let page = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف صفحه ${page.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          mdidlePagesApi
            .deleteMiddlePages(page.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    create(e) {
      this.isLoadingModal = true;
      mdidlePagesApi
        .createMiddlePages(e)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.loadData();
          this.toggleForm();
        })
        .finally(() => {
          this.isLoadingModal = false;
        })
        .catch((err) => {
          this.$refs.shippingform.setErrors(err.response.data.errors);
        });
    },
    update(e) {
      this.isLoadingModal = true;
      mdidlePagesApi
        .updateMiddlePages(e.id, e)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.loadData();
          this.toggleForm();
        })
        .finally(() => {
          this.isLoadingModal = false;
        })
        .catch((err) => {
          this.$refs.shippingform.setErrors(err.response.data.errors);
        });
    },
    openModal() {
      this.updateForm = {};
      this.toggleForm();
    },
    toggleForm() {
      this.$toggleModal("#middlepages-form");
    },
  },
};
</script>
