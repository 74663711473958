window.$ = require('jquery')
window.JQuery = require('jquery')
window._ = require('lodash');
try {
  window.Popper = require("popper.js").default;
  window.$ = window.jQuery = require("jquery");
  require("bootstrap");
} catch (e) {}

import "./veeValidate"
import "./axios"
import './formValidation'
import './filters'


import iziToast from "izitoast";
import sweetalert2 from 'sweetalert2/dist/sweetalert2'
import jalaliMoment from "moment-jalaali";
import collect from 'collect.js';

window.collect = collect;
window.Swal = sweetalert2;
window.iziToast = iziToast;
window.moment = jalaliMoment;


