import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/brands"

export default {

    get(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    create(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    update(orderId, data) {
        return apiCall('post', `${PREFIX}/${orderId}/update`, data)
    },

    delete(orderID) {
        return apiCall('delete', `${PREFIX}/${orderID}`)
    }
}