import { apiCall, createQueryString } from "../imports"

const PREFIX = "management/permissions"

export default {

    getPermissions() {
        return apiCall('get', "management/permissions")
    },
    getPermissionsPaginated(filters) {
        return apiCall('get', `${PREFIX}/paginated${createQueryString(filters)}`)
    },
    getSyncPermissions() {
        return apiCall('get', `${PREFIX}/sync`);
    },
    setSyncPermissions(data) {
        return apiCall('post', `${PREFIX}/sync`,data);
    }
}