export default {
  data() {
    return {
      rowIndexTitle: "#",
      kendoUploader: {
        select: "انتخاب فایل",
        retry: "تلاش مجدد",
        remove: "حذف",
        invalidFiles: "فایل نا‌معتبر",
        uploadSelectedFiles: "بارگذاری",
        clearSelectedFiles: "لغو",
        statusUploaded: "بارگذاری با موفقیت انجام شد.",
        statusUploading: "در حال بارگذاری فایل (ها)...",
        statusFailed: "بارگذاری ناموفق",
        invalidFileExtension: "پسوند نامعتبر فایل",
        dropFilesHere: "فایل های خود را به اینجا بکشید و رها کنید",
        headerStatusUploaded: "بارگذاری با موفقیت انجام شد",
        headerStatusUploading: "درحال بارگذاری ...",
      },
      grid: {
        columnMenu: {
          messages: {
            columns: "ستون ها",
            filter: "فیلتر",
            sortAscending: "صعودی",
            sortDescending: "نزولی",
          },
        },
        filterable: {
          messages: {
            clear: "لغو",
            filter: "فیلتر",
            info: "",
            checkAll: "انتخاب همه",
            selectedItemsFormat: "{0} مورد انتخاب شده",
          },
          operators: {
            string: {
              eq: "برابر",
              contains: "شامل",
            },
          },
          extra: false,
        },
        groupable: {
          messages: {
            empty: "تگ ها را انتخاب کرده و به اینجا بکشید!",
          },
        },
        pageable: {
          pageSizes: [10, 30, 50, 70, 90],
          messages: {
            display: "{0} - {1} از {2} مورد",
            empty: "موردی برای نمایش وجود ندارد",
            page: "صفحه",
            allPages: "همه",
            of: "از {0}",
            itemsPerPage: "مورد در هر صفحه",
            first: "Go to the first page",
            previous: "Go to the previous page",
            next: "Go to the next page",
            last: "Go to the last page",
            refresh: "رفرش",
          },
          refresh: true,
        },
        noRecords: "موردی یافت نشد",
      },
      gridButtons: {

        sellerCommissions:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerCommissions' data-toggle='tooltip' data-placement='bottom' title='کمیسیون فروشنده'><span class='fas fa-percentage' style='color:teal'></span></a>",
        sellerStore:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerStore' data-toggle='tooltip' data-placement='bottom' title='اطلاعات فروشگاه فروشنده'><span class='fas fa-store' style='color:teal'></span></a>",
        sellerFinace:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerFinace' data-toggle='tooltip' data-placement='bottom' title='حساب های مالی فروشنده'><span class='fas fa-credit-card' style='color:teal'></span></a>",
        sellerAddress:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerAddress' data-toggle='tooltip' data-placement='bottom' title='آدرس فروشنده'><span class='fas fa-map-marked-alt' style='color:teal'></span></a>",
        sellerConfirm:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerConfirm' data-toggle='tooltip' data-placement='bottom' title='تایید فروشنده'><span class='fas fa-clipboard-check' style='color:teal'></span></a>",
        feedbackConfirm:
          "<a role='button' class='k-button k-button-icontext k-grid-feedbackConfirm' data-toggle='tooltip' data-placement='bottom' title='تایید صحت انجام کار'><span class='fas fa-clipboard-check' style='color:teal'></span></a>",
        sellerProductConfirm:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerProductConfirm' data-toggle='tooltip' data-placement='bottom' title='تایید محصول فروشنده'><span class='fas fa-clipboard-check' style='color:teal'></span></a>",
        sellers:
          "<a role='button' class='k-button k-button-icontext k-grid-sellers' data-toggle='tooltip' data-placement='bottom' title='فروشندگان محصول'><span class='fas fa-users-cog' style='color:teal'></span></a>",
        sellerProductFeedback:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerProductFeedback' data-toggle='tooltip' data-placement='bottom' title='ثبت مشکلات محصول فروشنده'><span class='fas fa-comment-dots' style='color:teal'></span></a>",
        sellerFeedback:
          "<a role='button' class='k-button k-button-icontext k-grid-sellerFeedback' data-toggle='tooltip' data-placement='bottom' title='ثبت مشکلات  فروشنده'><span class='fas fa-comment-dots' style='color:teal'></span></a>",
        defaultFiscalYear:
          "<a role='button' class='k-button k-button-icontext k-grid-defaultFiscalYear' data-toggle='tooltip' data-placement='bottom' title='تغییر سال مالی'><span class='fas fa-shopping-bag' style='color:teal'></span></a>",
        delete:
          "<a role='button' class='k-button k-button-icontext k-grid-delete' data-toggle='tooltip' data-placement='bottom' title='حذف'><span class='fas fa-times' style='color:red'></span></a>",
        edit:
          "<a role='button' class='k-button k-button-icontext k-grid-edit' data-toggle='tooltip' data-placement='bottom' title='ویرایش'><span class='fas fa-pen-square' style='color:teal'></span></a>",
        images:
          "<a role='button' class='k-button k-button-icontext k-grid-images' data-toggle='tooltip' data-placement='bottom' title='تصاویر'><span class='fas fa-images' style='color:teal'></span></a>",
        view:
          "<a role='button' class='k-button k-button-icontext k-grid-view' data-toggle='tooltip' data-placement='bottom' title='مشاهده'><span class='fas fa-eye' style='color:teal'></span></a>",
        assignUser:
          "<a role='button' class='k-button k-button-icontext k-grid-assign' data-toggle='tooltip' data-placement='bottom' title='انتصاب راننده'><span class='fas fa-user-plus' style='color:teal'></span></a>",
        print:
          "<a role='button' class='k-button k-button-icontext k-grid-print' data-toggle='tooltip' data-placement='bottom' title='چاپ'><span class='fas fa-print' style='color:teal'></span></a>",
        checkout:
          "<a role='button' class='k-button k-button-icontext k-grid-checkout' data-toggle='tooltip' data-placement='bottom' title='ترخیص'><span class='fas fa-external-link-square-alt' style='color:teal'></span></a>",
        items:
          "<a role='button' class='k-button k-button-icontext k-grid-items' data-toggle='tooltip' data-placement='bottom' title='موارد'><span class='fas fa-list' style='color:teal'></span></a>",
        prices:
          "<a role='button' class='k-button k-button-icontext k-grid-prices' data-toggle='tooltip' data-placement='bottom' title='قیمت ها'><span class='fas fa-dollar-sign' style='color:teal'></span></a>",
        wholesale:
          "<a role='button' class='k-button k-button-icontext k-grid-wholesale' data-toggle='tooltip' data-placement='bottom' title='عمده فروشی'><i class='fas fa-money-check-alt' style='color:teal'></i></a>",
        video:
          "<a role='button' class='k-button k-button-icontext k-grid-video' data-toggle='tooltip' data-placement='bottom' title='ویدیو محصول'><i class='fas fa-money-check' style='color:green'></i></a>",
        status:
          "<a role='button' class='k-button k-button-icontext k-grid-status' data-toggle='tooltip' data-placement='bottom' title='تغییر وضعیت'><span class='fas fa-lock' style='color:teal'></span></a>",
        saleSatus:
          "<a role='button' class='k-button k-button-icontext k-grid-saleSatus' data-toggle='tooltip' data-placement='bottom' title='تغییر وضعیت فروش'><span class='fas fa-shopping-bag' style='color:teal'></span></a>",
        reply:
          "<a role='button' class='k-button k-button-icontext k-grid-reply' data-toggle='tooltip' data-placement='bottom' title='تغییر وضعیت'><span class='fas fa-reply' style='color:teal'></span></a>",
        seo:
          "<a role='button' class='k-button k-button-icontext k-grid-seo' data-toggle='tooltip' data-placement='bottom' title='تظیمات سئو'><span class='fab fa-searchengin' style='color:teal'></span></a>",
        info:
          "<a role='button' class='k-button k-button-icontext k-grid-info' data-toggle='tooltip' data-placement='bottom' title='اطلاعات'><span class='fas fa-info-circle' style='color:teal'></span></a>",
        relation:
          "<a role='button' class='k-button k-button-icontext k-grid-relation' data-toggle='tooltip' data-placement='bottom' title='محصولات مرتبط'><span class='fas fa-bezier-curve' style='color:teal'></span></a>",
      },
      record: 0,
      gpage: 1,
    };
  },
  computed: {
    $user() {
      return this.$store.state.user;
    },
    $isLoading() {
      return this.$store.getters.isLoading;
    },
    $appName() {
      return process.env.VUE_APP_APP_NAME;
    },
    $appDescription() {
      return process.env.VUE_APP_DESCRIPTION;
    },
    $cashUnit() {
      return "تومان";
    },
    $totalBalance() {
      return this.$store.state.totalBalance;
    },
    $apiURL() {
      return process.env.VUE_APP_API_BASEPOINT;
    },
    $appURL() {
      return process.env.VUE_APP_URL;
    },
    $appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    $showDashboardWarnings() {
      return process.env.VUE_APP_DASHBOARD_WARNINGS == "true";
    },
  },
  methods: {
    $getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    $treeContextMenu(id, options, treeData) {
      let data = {
        key: options,
        ContextId: id,
        treeData,
      };
      console.log("data", data);
      this.$emit("contextMenu:select", data);
    },
    $currencyLabel(_label) {
      return `${_label} (ریال)`;
    },
    $findBgColor(hexcolor) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },
    $setLayout(layout) {
      this.$store.commit("SET_LAYOUT", layout);
    },
    $setIsLoading(layout) {
      this.$store.commit("SET_ISLOADING", layout);
    },
    $resetGrid() {
      this.gpage = 1;
      this.record = 0;
    },
    $loadGridDataSource() {
      var datasource = this.$refs.grid.kendoWidget().dataSource;
      datasource.page(1);
      datasource.read();
    },
    $onDataBinding() {
      this.record = (this.gpage - 1) * this.$refs.datasource.pageSize;
    },
    $gridPage(e) {
      this.gpage = e.page;
    },
    $getTemplate() {
      return ++this.record;
    },
    $getTemplateServerSide(pageSize, curentPage) {
      if (curentPage > 1) {
        return ++pageSize * curentPage - pageSize;
      }

      return ++this.record;
    },
    $toggleModal(id) {
      $(`${id}`)
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("toggle");
    },
    $hideModal(id) {
      $(`${id}`)
        .modal()
        .modal("hide");
    },
    $showModal(id) {
      $(`${id}`)
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("show");
    },
    $getTimeFromNow(dateTime) {
      return moment(dateTime, "YYYY-MM-DD HH:mm:ss").fromNow();
    },
    $getJalaliDateTime(dateTime = null) {
      try {
        if (dateTime)
          return moment(dateTime, "YYYY-MM-DD HH:mm:ss").format(
            "jYYYY/jMM/jDD HH:mm:ss"
          );
        return moment().format("jYYYY/jMM/jDD HH:mm:ss");
      } catch (err) {
        console.log("Error in getJalaliDateTime", dateTime);
      }
    },
    $getJalaliDate(date = null) {
      try {
        if (date) return moment(date, "YYYY-MM-DD").format("jYYYY/jMM/jDD");
        return moment().format("jYYYY/jMM/jDD");
      } catch (err) {
        console.log("Error in getJalaliDateTime", date);
      }
    },
    $getJalaliDateFromDateTime(dateTime = null) {
      try {
        if (dateTime)
          return moment(dateTime, "YYYY-MM-DD HH:mm:ss").format("jMM/jDD");
        return moment().format("jMM/jDD");
      } catch (err) {
        console.log("Error in getJalaliDateTime", dateTime);
      }
    },
    $getJalaliDateTimeShort(dateTime = null) {
      try {
        if (dateTime)
          return moment(dateTime, "YYYY-MM-DD HH:mm:ss").format(
            "HH:mm jMM/jDD"
          );
        return moment().format("HH:mm jMM/jDD");
      } catch (err) {
        console.log("Error in getJalaliDateTime", dateTime);
      }
    },
    $catchErrors(error) {
      let errors = error.response.data;
      if (errors.errors) {
        let error = errors.errors;
        for (const key in error) {
          this.$izitoast("error", error[key][0], "عملیات ناموفق!", {
            position: "topRight",
            displayMode: 2,
            rtl: true,
          });
        }
      } else {
        let err = errors.message ? errors.message : null;
        this.$izitoast("error", err, "عملیات ناموفق!", {
          position: "topRight",
          displayMode: 2,
          rtl: true,
        });
      }
    },
    $setCookie(cname, cvalue, exsecond) {
      var d = new Date();
      d.setTime(d.getTime() + exsecond * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    $round(number, round = 0) {
      if (!number) return 0;
      return Number(Math.round(number + "e" + round) + "e-" + round);
    },
    $ssnIsValid(code) {
      var L = code.length;
      if (L != 10 || parseInt(code, 10) == 0) return false;
      code = ("0000" + code).substr(L + 4 - 10);
      if (parseInt(code.substr(3, 6), 10) == 0) return false;
      var c = parseInt(code.substr(9, 1), 10);
      var s = 0;
      for (var i = 0; i < 9; i++)
        s += parseInt(code.substr(i, 1), 10) * (10 - i);
      s = s % 11;
      return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
    },
    $persistClient(method, uri, data = null) {
      return new Promise((resolve, reject) => {
        axios[method](uri, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error, res) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              this.$store.dispatch("logout");
              // this.$setCookie("token", "");
              this.$router.push("/login");
            }
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              this.$izitoast(
                "error",
                "عملیات ناموفق!",
                error.response.data.message,
                {
                  position: "topRight",
                  displayMode: 2,
                }
              );
              reject(error);
              return;
            }
            let errors =
              error.response && error.response.data
                ? error.response.data
                : null;
            if (errors && errors.errors) {
              let error = errors.errors;
              for (const key in error) {
                this.$izitoast("error", "عملیات ناموفق!", error[key][0], {
                  position: "topRight",
                  displayMode: 2,
                });
              }
            } else {
              let err = errors && errors.message ? errors.message : null;
              this.$izitoast("error", "عملیات ناموفق!", null, {
                position: "topRight",
                displayMode: 2,
              });
            }
            reject(error);
          });
      });
    },
    $izitoast(
      type,
      title = null,
      message = null,
      _option = {
        rtl: true,
      }
    ) {
      let messages = {
        message: message ?? "عملیات موفق",
        title: title ?? "عملیات موفق",
        rtl: true,
      };
      let option = {
        ...messages,
        ..._option,
      };
      if (!title) title = "";
      switch (type) {
        case "success":
          iziToast.success(option);
          break;
        case "error":
          iziToast.error(option);
          break;
        case "info":
          iziToast.info(option);
          break;
        case "question":
          iziToast.question(option);
          break;
      }
    },
    $alert(type, title = null, text = null, options = {}) {
      let notification = [];
      notification["type"] = type;
      notification["showCancelButton"] = false;
      notification["icon"] = type;
      switch (notification["type"]) {
        case "success":
          notification["title"] = title != null ? title : "عملیات موفق";
          notification["text"] =
            text != null ? text : "اطلاعات مورد نظر ثبت شد";
          break;
        case "error":
          notification["title"] = title != null ? title : "عملیات ناموفق";
          notification["text"] =
            text != null ? text : "اطلاعات مورد نظر ثبت نشد";
          break;
        case "question":
          notification["title"] = title != null ? title : "آیا مطمئن هستید؟";
          notification["text"] =
            text != null ? text : "امکان بازگشت عملیات وجود ندارد";
          notification["showCancelButton"] = true;
          notification["cancelButtonText"] = "خیر";
          notification["confirmButtonText"] = options.confirmButtonText
            ? options.confirmButtonText
            : "حذف";
          notification["confirmButtonColor"] = options.confirmButtonColor
            ? options.confirmButtonColor
            : "#3085d6";
          notification["cancelButtonColor"] = "#d33";
          break;
        case "warning":
          notification["title"] = title != null ? title : "عملیات موفق";
          notification["text"] =
            text != null ? text : "اطلاعات مورد نظر ثبت شد";
          break;
        case "info":
          notification["title"] = title != null ? title : "اطلاعات";
          notification["text"] = text != null ? text : "   ***** ";
          break;
      }
      return Swal.fire(notification);
    },
    $ENTPN(number) {
      // return number
      let converted = persianJs(String(number)).englishNumber();
      if (converted == "undefined") return "۰";
      else return converted;
    },
    $btnLoading(name, isLoading) {
      this.$root.$emit("btn-loading", {
        name: name,
        isLoading: isLoading,
      });
    },
    $makeQueryString(mainUrl, data, firstChar = "?") {
      var item;
      if (typeof data == "object") {
        var url = firstChar;
        for (item in data) {
          if (data[item]) url += `${item}=${data[item]}&`;
        }
        url = url.slice(0, -1);
        return mainUrl + url;
      }
      return mainUrl;
    },
    $makeQueryStringForSpatie(mainUrl, data, firstChar = "?") {
      var item;
      if (typeof data == "object") {
        var url = firstChar;
        for (item in data) {
          if (data[item]) url += `filter[${item}]=${data[item]}&`;
        }
        url = url.slice(0, -1);
        return mainUrl + url;
      }
      return mainUrl;
    },
    $thousandSeprator(amount) {
      if (amount) {
        amount = String(amount).replace(/,/g, "");
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return amount;
      }
    },
  },
};
