<template>
  <bita-modal
    id="productinsert-modal"
    title="افزودن محصول از بین محصولات سایت"
    :is-loading="isLoading"
    :modal-lg="true"
    @open="setFormData"
    @close="reset()"
  >
    >

    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <div class="float-left">
              <bita-bootbutton
                title="افزودن محصول جدید"
                type="primary"
                icon="fas fa-plus-square"
                btn-width="200px"
                @click.native="createProduct"
                v-if="$acl.can('create', 'products') && !formExistsProducts"
              />
              &nbsp; &nbsp; &nbsp; &nbsp;
              <bita-bootbutton
                title="افزودن از بین محصولات موجود"
                type="primary"
                icon="fas fa-file-upload"
                btn-width="300px"
                @click.native="productInsertForm"
                v-if="$acl.can('create', 'products') && !formExistsProducts"
              />
            </div>

            <bita-select-server
              name="products"
              ref="products"
              label="محصول"
              rules=""
              validation-container-class="col-lg-12"
              subtitle="لطفا محصول مورد نظر را جستجو نمایید."
              input-class="form-control"
              :data-source="products"
              data-text-field="name"
              data-value-field="id"
              :get-data="getProducts"
              v-model="formData.product_id"
              v-show="formExistsProducts"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        title="افزودن محصول "
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="addExistProductsToSeller()"
        :disabled="isLoading"
        v-if="formExistsProducts"
      />
    </div>
  </bita-modal>
</template>
<script>
import { productApi, supplireApi } from "@/api/apiManagement";
export default {
  data() {
    return {
      isLoading: false,
      largeColWidth: "col-lg-12",
      colWidth: "col-lg-3",
      errors: {},
      formData: [],
      product: [],
      filters: {},
      products: [],
      formExistsProducts: false,
    };
  },
  mounted() {
    this.getProductsAll();
  },
  methods: {
    getProducts(e) {
      let filter = {
        search: e.filter?.value,
      };
      console.log(filter);
      productApi.searchInProductsOffSeller(filter).then((res) => {
        console.log(res);
        this.products = _.unionBy(res.data.entity, this.products, "id");
      });
    },
    getProductsAll() {
      productApi.searchInProductsOffSeller().then((res) => {
        this.products = res.data;
        // this.products = _.unionBy(res.data, this.products, "id")
      });
    },
    setFormData() {
      //this.$refs.products.clear();
      //  this.$refs.products.emptyKendo();
    },

    addExistProductsToSeller() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          supplireApi
            .addExistProductsToSeller(this.formData)
            .then((data) => {
              if(1)
              this.$izitoast("success", "محصولات مورد نظر اضافه شد.");
              this.reset();
              this.$toggleModal("#productinsert-modal");
            })
            .finally(() => {
              this.isLoading = false;
            })
        }
      });
    },
    getFormData() {
      let formData = new FormData();
      for (var key in this.formData) {
        if (this.formData[key] != null) {
          formData.append(key, this.formData[key]);
        }
      }
      return formData;
    },
    createProduct() {
      this.$toggleModal("#productinsert-modal");
      this.$router.push({ path: "/shop/products/create" });
    },
    productInsertForm() {
      this.formExistsProducts = true;
      this.formData = {};
    },

    reset() {
      window.location.reload();
      this.formExistsProducts = false;
    },
  },
};
</script>
