<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن جزئیات محصول"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="toggleForm"
            v-if="$acl.can('create', 'details')"
          />
          <hr />
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="جستجو"
              validation-container-class="col-lg-4"
              input-class="form-control"
              v-model.lazy="filters.search"
              @keyup.enter.prevent="loadData"
            />
            <div class="col-lg-4">
              <a class="margin-top btn btn-success" @click="loadData()">
                جستجو
              </a>
              <a
                class="margin-top margin-right btn btn-warning"
                @click="resetFilters()"
              >
                پاک سازی
              </a>
            </div>
            <more-filter ref="moreFilter">
              <bita-input
                type="text"
                name="name"
                label="نام"
                :validation-container-class="colWidth"
                input-class="form-control"
                v-model.lazy="filters.name"
                @keyup.enter.prevent="loadData"
              />
              <bita-select
                name="type"
                ref="typeSelect"
                label="نوع"
                :validation-container-class="colWidth"
                input-class="form-control"
                :data-source="types"
                data-text-field="name"
                data-value-field="id"
                v-model="filters.type"
              />
              <bita-select
                name="category"
                ref="categorySelect"
                label="دسته‌بندی‌"
                :validation-container-class="colWidth"
                input-class="form-control"
                :data-source="categories"
                data-text-field="name"
                data-value-field="id"
                v-model="filters.category_id"
              />

            </more-filter>
          </div>
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    </div>
    <role-form
      ref="form"
      :propData="editForm"
      :title="formTitle"
      :categories="categories"
      :is-loading="isLoadingModal"
      @on-create="(e) => save(e)"
      @on-update="(e) => update(e)"
      @close="reset()"
    />
  </div>
</template>
<script>
import RoleForm from "./components/DetailForm";
import { detailApi, categoryApi } from "@/api/apiManagement";
import MoreFilter from "@/components/MoreFilter.vue";
export default {
  components: { 
    RoleForm,
    MoreFilter
  },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "فروشگاه", link: "" },
        {
          title: "مدیریت جزئیات محصولات",
          link: "/shop/details",
          active: true,
        },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      formTitle: "",
      editForm: {},
      categories: [],
      gridColumns: [
        { title: "#", template: this.$getTemplate, width: "40px" },
        { field: "name", title: "نام", width: "150px" },
        { field: "type_name", title: "نوع", width: "120px" },
        {
          field: "category",
          title: "دسته بندی",
          width: "120px",
          template: (e) => (e.category ? e.category.name : "همه گروه ها"),
        },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          width: "150px",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          width: "150px",
          command: [],
        },
      ],
      colWidth: "col-3",
      filters: {},
      types: [
        { id: 1, name: 'select' },
        { id: 2, name: 'text' },
        { id: 3, name: 'number' },
        { id: 4, name: 'textarea' }
      ],
    };
  },
  computed: {},
  mounted() {
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
    this.getCategories();
  },
  methods: {
    reset() {
      this.editForm = {};
    },
    getCategories() {
      this.isLoadingGrid = true;
      categoryApi
        .getCategories()
        .then(({ data }) => {
          let categories = data.entity;
          for (let cat of categories) {
            cat.parentId =
              Number(cat.parent_id) == 0 ? null : Number(cat.parent_id);
          }
          this.categories = categories;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    formSucceeded() {
      this.loadData();
      this.formTitle = "";
      this.editForm = {};
      this.$refs.form.reset();
      this.$toggleModal("#detail-form");
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      commands.push({
        text: "items",
        click: this.items,
        template: this.gridButtons.items,
      });
      if (this.$acl.can("edit", "details"))
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      if (this.$acl.can("delete", "details"))
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    readData(e) {
      this.isLoadingGrid = true;
      this.$resetGrid();
      detailApi
        .get(this.filters)
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    save(_data) {
      this.isLoadingModal = true;
      detailApi
        .create(_data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.formSucceeded();
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    items(e) {
      let row = this.$refs.grid.getSelectedGridRow(e);
      let detailID = row.id
      if(!row.valuable){
        this.$alert('warning','هشدار',' به این نوع نمی توانید زیر مجموعه بدهید ')
        return 
      }
      this.$router.push({ name: 'detailvalues', params: { detail: detailID } })
    },
    edit(e) {
      let row = this.$refs.grid.getSelectedGridRow(e);
      this.editForm = row;
      this.formTitle = `ویرایش ${row.name}`;
      this.$toggleModal("#detail-form");
    },
    update(_data) {
      this.isLoadingModal = true;
      detailApi
        .update(_data.id, _data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.formSucceeded();
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    delete(e) {
      let row = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف ${row.name} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          detailApi
            .delete(row.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    toggleForm() {
      this.formTitle = "افزودن جزئیات محصول";
      this.editForm = {};
      this.$toggleModal("#detail-form");
    },
    resetFilters() {
      this.filters = {};
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
    }
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
</style>
