import { apiCall, createQueryString } from "../imports"

const PREFIX = "forum/forums"

export default {

    gets(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    get(_forumID) {
        return apiCall('get', `${PREFIX}/${_forumID}`)
    },

    create(_data) {
        return apiCall('post', `${PREFIX}`, _data)
    },

    createReply(_forumID, _data) {
        return apiCall('post', `${PREFIX}/${_forumID}/reply`, _data);
    },

    update(_forumID, _data) {
        return apiCall('patch', `${PREFIX}/${_forumID}`, _data)
    },

    updateReply(_forumID, _replyID, _data) {
        return apiCall('patch', `${PREFIX}/${_forumID}/reply/${_replyID}`, _data);
    },

    delete(_forumID) {
        return apiCall('delete', `${PREFIX}/${_forumID}`)
    },
    
    deleteReply(_forumID, _replyID) {
        return apiCall('delete', `${PREFIX}/${_forumID}/reply/${_replyID}`)
    }
}