<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingPage">
        <div slot="body">
          <bita-bootbutton
            type="primary"
            title="افزودن"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="create"
          />
          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <product-form ref="productForm" @load-data="loadData" :title="title" />
    <product-item ref="productItem" @load-data="loadData" />
  </div>
</template>
<script>
import { productCollectionApi } from "@/api/apiManagement";
import productForm from "./components/ProductCollectionForm.vue";
import productItem from "./components/ProductItemForm.vue";

export default {
  components: {
    productForm,
    productItem,
  },
  data() {
    return {
      pageSize: 10,
      filters: {},
      currentPage: 1,
      isLoadingPage: false,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت اسلایدر محصولات", link: "/shop/product-sliders", active: true },
      ],
      title: "",
    }
  },
  computed: {
    gridColumns() {
      return [
        { title: "عنوان", template: e => ( e.title ? e.title : '-') },
        { title: "اولویت", template: e => ( e.order ? e.order : '-') },
        { title: "تعداد محصول", template: e => (e.products ? e.products.length : '-') },
        { title: "نوع کالکشن", template: e => (e.type ? this.getType(e.type) : '-') },
        { title: "نوع قالب", template: e => (e.template_id ? this.getTemplate(template_id) : '-') },
        { title: "نام ارتباط", template: e => (e.meta_id ? e.meta_id : '-') },
        { title: "عملیات", command: this.gridCommands() },
      ]
    }
  },
  methods: {
    getType(element) {
      let title = "";

      switch (Number(element)) {
        case 1:
          title = "کالکشن صفحه اصلی";
          break;
        case 2:
          title = "کالکشن صفحه واسط";
          break;
      }
      return title;
    },
    getTemplate(element) {
      let title = "";

      switch (Number(element)) {
        case 0:
          title = "معمولی";
          break;
        case 1:
          title = "شگفت انگیز";
          break;
      }
      return title;
    },
    readData(e) {
      this.isLoadingPage = true;
      productCollectionApi.gets()
        .then( (res) => {
          e.success(res.data.entity);
        })
        .catch( () => e.success([]) )
        .finally( () => this.isLoadingPage = false );
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];

      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      commands.push({
        text: 'items',
        click: this.items,
        template: this.gridButtons.items
      });
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    edit(e) {
      let slide = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.productForm.setInitialData(slide);
      this.$refs.productForm.setForm();
      this.$toggleModal("#product-form");
    },
    items(e) {
      let slide = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.productItem.setInitialData(slide);
      this.$toggleModal("#product-item");
    },
    delete(e) {
      let slide = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف ${slide.title} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingPage = true;
          productCollectionApi.delete(slide.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingPage = false;
            });
        }
      });
    },
    create() {
      this.title = "افزودن کالکشن جدید";
      this.$refs.productForm.setInitialData({});
      this.$toggleModal("#product-form");
      this.$refs.productForm.setForm();
    },
  },
};
</script>
