<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <div class="row">
            <div class="col-4">
            <bita-datepicker
              type="year"
              id="paymentat"
              validation-container-class="col-lg-10"
              name="title"
              rules=""
              label="سال مالی"
              inputClass="form-control"
              v-model="formData.fiscal_year"
              format="jYYYY"
              displayFormat="jYYYY"
            />
          </div>
          <div class="col">
            <br>
            <bita-bootbutton
            v-if="$acl.can('create', 'static-pages')"
              :validation-container-class="colWidth"
              title="ذخیره سال مالی"
              type="primary"
              icon="fas fa-plus-square"
              input-class="form-control"
              @click.native="save"
            />
            &nbsp
                &nbsp
                &nbsp


                <bita-bootbutton
                title="انتقال مانده حساب"
                type="primary"
                  icon="fas fa-plus-square"
                  btn-width="200px"
                  @click.native="Registrationfines"
                  v-if="$acl.can('transfer', 'account-balance-of-sellers')"
                />
          </div>
          </div>
          
          <hr />
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    
    </div>
    <year-modal 
    ref="yearForm"
      :is-loading="isLoadingModal"
      />  
      </div>
</template>
<script>
import { supplireApi, staticpageApi } from "@/api/apiManagement";
import YearModal from "./components/YearModal.vue";


export default {
  components: { YearModal },
  data() {
    return {
      colWidth: "col-lg-3",
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "صفحات", link: "/shop/staticpages" },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      updateForm: {},
      formData: {},
      years:[]
    };
  },
  computed: {
    gridColumns() {
      return [
        { title: "#", template: this.$getTemplate, width: "40px" },
        { field: "year", title: "سال مالی", template: this.getYear },
        { field: "status", title: "وضعیت", template: this.getStatus },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  methods: {
    Registrationfines() {
      this.$toggleModal("#year-modal");
    },
    readData(e) {
      this.isLoadingGrid = true;
      supplireApi
        .getListFiscalYears()
        .then((res) => {
          e.success(res.data.entity);
        
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    getYear(e) {
      let year = e.fiscal_year;
      return `${year}`;
    },
    getStatus(e) {
      let status = e.default == 1 ? "سال مالی فعال" : "-";
      return `${status}`;
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];

      if (this.$acl.can("delete", "static-pages"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
        if (this.$acl.can("delete", "static-pages"))
        commands.push({
          text: "defaultFiscalYear",
          click: this.activeFiscalYear,
          template: this.gridButtons.defaultFiscalYear,
        });
      return commands;
    },

    delete(e) {
      let year = this.$refs.grid.getSelectedGridRow(e);
      
      this.$alert(
        "question",
        `آیا از حذف سال مالی ${year.fiscal_year} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          console.log(year);
          supplireApi
            .deleteFiscalYear(year.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    activeFiscalYear(e){
      let year = this.$refs.grid.getSelectedGridRow(e);
    
      this.$alert(
        "question",
        ` آیا از تغییر سال مالی ییشفرض به ${year.fiscal_year} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          console.log(year.id);
          supplireApi
            .activeDefaultFiscalYear(year.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    transferAccountBalance() {
     
     this.$alert(
       "question",
       ` آیا از انتقال مانده حساب به سال جدید اطمینان دارید؟`,
       "",
       {
         confirmButtonText: "بله",
       }
     ).then((result) => {
       if (result.value) {

       }
     });
   },
    save(formData) {
      this.isLoadingModal = true;
      //documentImage = this.getFormData(this.formData);

      supplireApi
        .createFiscalYear(this.formData)
        .then((res) => {
          console.log(res);
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.loadData();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
  },
};
</script>
