<template>
  <div
    :class="{ 'group-container': true, 'active-group': showChilds }"
    :style="`${isOpen ? 'text-align: right;' : 'text-align: center;'}`"
  >
    <div :class="{ titles: true, 'minimal-title': !isOpen }">
      <i :class="icon"></i>
      <a v-show="isOpen" class="group-name" @click="toggleChild">
        {{ name }}
      </a>
      <div v-show="isOpen" class="icon-container">
        <span v-show="showChilds">
          <i class="fas fa-chevron-down"></i>
        </span>
        <span v-show="!showChilds">
          <i class="fas fa-chevron-left"></i>
        </span>
      </div>
    </div>
    <transition name="slide-fade">
      <ul v-show="showChilds" :id="groupID">
        <template v-for="(child, index) in childs">
          <li v-if="$acl.can('view', child.route_name)" :key="index">
            <router-link v-show="isOpen" :to="child.route">{{ child.name }}</router-link>
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
    },
    name: {},
    icon: {},
    childs: {
      type: Array,
    },
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        var count = $(`#${this.groupID} li a.router-link-exact-active.router-link-active`)
          .length;
        if (count > 0) this.showChilds = true;
      }, 2000);
    });
  },
  mounted() {
    this.$root.$on("side-bar:open-other", (e) => {
      if (this.groupID != e) this.showChilds = false;
    });
  },
  data() {
    return {
      showChilds: false,
      groupID: Math.random().toString(36).substring(7),
    };
  },
  methods: {
    toggleChild() {
      this.showChilds = !this.showChilds;
      this.$root.$emit("side-bar:open-other", this.groupID);
    },
  },
};
</script>
<style scoped>
@media (prefers-color-scheme: dark) {
  ul {
    background: #333333 !important;
  }
  .active-group {
    background: #333333 !important;
  }
}

.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.group-container {
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
}

.group-container.active-group::before {
  content: " ";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #1ab394;
  right: 0;
  top: 0;
}

.group-container svg {
  color: #a7b1c2;
  margin-right: 6px;
}

.group-name {
  color: #a7b1c2;
  font-weight: 600;
  /* padding: 14px 20px 14px 25px; */
  padding-right: 13px;
  display: inline-block;
}

ul {
  list-style: none;
  background: #293846;
  margin: 0;
}

a.router-link-exact-active.router-link-active {
  color: #fff;
}

a {
  color: #a7b1c2;
}

.active-group {
  background: #293846;
}

.minimal-title {
  line-height: 40px;
}

.titles {
  padding: 11px 11px;
  background-color: #242424;

}

.icon-container {
  display: inline-block;
  float: left;
  /* padding: 14px 20px 14px 25px; */
  padding: 0px 17px;
}
.side-bar-items .group-container ul li a {
  display: inline-block;
  width: 100%;
}
</style>
