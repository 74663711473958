<template>
  <bita-modal
    id="role-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام نقش"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.name"
            />
            <bita-multiselect
              name="permissions"
              ref="permisionsMultiselect"
              label="مجوز ها"
              rules="required"
              validation-container-class="col-lg-8"
              input-class="form-control"
              :data-source="permissions"
              data-text-field="fa_name"
              data-value-field="id"
              v-model="form.permissions"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        type="primary"
        title="ذخیره"
        icon="fa fa-check"
        btn-width="150px"
      />
      <bita-bootbutton
        class="mr-2"
        type="primary"
        title="چاپ لیبل"
        icon="fa fa-print"
        btn-width="150px"
      />
      <bita-bootbutton
        class="mr-2"
        type="primary"
        title="چاپ فاکتور"
        icon="fa fa-print"
        btn-width="150px"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    title: String,
    permissions: {},
    isLoading: Boolean,
    role: {
      type: Object,
    },
  },
  data() {
    return {
      colWidth: "col-lg-4",
      form: {},
    };
  },
  mounted() {},
  watch: {
    role: function (_role) {
      console.log("watch role", _role.permissions);
      this.form = _role;
      // this.form.permissions = collect(_role.permissions).pluck("id").toArray();
    },
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
  },
  methods: {
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.form);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.form);
        }
      });
    },
    reset() {
      this.form = {};
      this.$refs.permisionsMultiselect.emptyKendo();
      this.formRef.reset();
    },
  },
};
</script>
