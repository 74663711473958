<template>
  <bita-modal
    :id="id"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="small-chat-box">
            <div class="content">
              <div :class="isMyComment(propsData) ? 'right' : 'left'">
                <div class="author-name">
                  {{ propsData.user ? propsData.user.full_name : "" }}
                  <small class="chat-date">{{
                    $getJalaliDateTime(propsData.created_at)
                  }}</small>
                </div>
                <div
                  :class="
                    isMyComment(propsData) ? 'chat-message' : 'chat-message active'
                  "
                >
                  {{ propsData.comment }}
                </div>
              </div>
              <div
                :class="isMyComment(comment) ? 'right' : 'left'"
                v-for="comment in propsData.replies"
                :key="comment.id"
              >
                <div class="author-name">
                  {{ comment.user.full_name }}
                  <small class="chat-date">{{
                    $getJalaliDateTime(comment.created_at)
                  }}</small>
                </div>
                <div
                  :class="
                    isMyComment(comment)
                      ? 'chat-message'
                      : 'chat-message active'
                  "
                >
                  {{ comment.comment }}
                </div>
              </div>
            </div>
            <div class="form-chat">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" v-model="reply"/>
                <span class="input-group-btn">
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="sendReply()"
                  >
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    id: String,
    title: String,
    isLoading: Boolean,
    propsData: {},
  },
  data() {
    return {
      colWidth: "col-lg-12",
      reply: '',
      comment: String,
    };
  },
  methods: {
    isMyComment(comment) {
      return comment.parent_id != 0;
    },
    handleUpdate() {},
    sendReply() {
      this.$emit("on-reply", {
        comment_id: this.propsData.id,
        reply: this.reply
      });
    },
    handleDelete(e) {
      this.$emit("on-delete", e);
    },
    reset() {
      this.reply = '';
    },
  },
};
</script>
<style>
.label-success {
  background-color: #5cb85c !important;
}
.chat .content .left {
  text-align: left;
  min-width: 20%;
}


.author-name {
  font-size: 10px;
  color: #939393;
}
.input-group-btn button{
  border-radius: 3px 0 0 3px;
  line-height: 1.04567889 !important;
}

.input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after{
  border-radius: 0 3px 3px 0 !important;
}

</style>
