<template>
  <div slot="body">
    <bita-form ref="form">
      <div slot="body">
        <div class="row">
          <bita-select name="type" ref="typeSelect" label="نوع ویدیو" rules="required"
            :validation-container-class="largeColWidth" input-class="form-control" :data-source="type"
            data-text-field="title" data-value-field="id" v-model="form.type" />

          <bita-input v-if="!uploadvideo(form.type)" type="text" name="videourl" label="آدرس url ویدیو"
            :validation-container-class="largeColWidth" input-class="form-control" subtitle="کد ویدیو"
            v-model="form.videourl" />



        </div>
      </div>
    </bita-form>

    <div v-if="uploadvideo(form.type)" class="wrapper">
      <div id="photos">
        <div class="photo" v-for="(vid, index) in video" :key="index">
          <span class="delete-image" @click="deleteProductVideo(vid)"><i class="fas fa-times-circle fa-2x"></i></span>
          <img src="/img/video.png" style="width:140px" />
        </div>
      </div>
      <div class="dropZoneElement">
        <div class="textWrapper">
          <p><span>+</span>ویدیو جدید</p>
          <p class="dropImageHereText">ویدیو خود را بکشید به اینجا بکشید</p>
        </div>
      </div>
      <upload ref="upload" name="files" :async="asyncUploader" :upload="onUpload"
        :validation-allowed-extensions="['.mp4']" :localization="kendoUploader" :show-file-list="true"
        :drop-zone="'.dropZoneElement'" @success="onSuccess" @error="onError">
      </upload>
    </div>

  </div>
</template>
<script>
import { Upload, UploadInstaller } from "@progress/kendo-upload-vue-wrapper";

import { productApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    largeColWidth: {
      default: "col-lg-12",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
  },
  use: { UploadInstaller },
  components: {
    Upload,
  },
  data() {
    return {
      form: {},
      type: [
        { id: 11, title: "آدرس ویدیو خارجی" },
        { id: 10, title: "آپلود ویدیو در سایت" }
      ],
      isLoading: false,
      categories: [],
      brands: [],
      product: {},
      kendoUploader: {
        select: "انتخاب فایل",
        retry: "تلاش مجدد",
        remove: "حذف",
        invalidFiles: "فایل نا‌معتبر",
        uploadSelectedFiles: "بارگذاری",
        uploadFail: "خطا در بارگذاری",
        uploadSuccess: "بارگذاری شد",
        clearSelectedFiles: "لغو",
        statusUploaded: "بارگذاری با موفقیت انجام شد.",
        statusUploading: "در حال بارگذاری فایل (ها)...",
        statusFailed: "بارگذاری ناموفق",
        invalidFileExtension: "پسوند نامعتبر فایل",
        dropFilesHere: "فایل های خود را به اینجا بکشید و رها کنید",
        headerStatusUploaded: "بارگذاری با موفقیت انجام شد",
        headerStatusUploading: "درحال بارگذاری ...",
      },
      video: [],
    };
  },
  mounted() { },

  computed: {
    formRef() {
      return this.$refs.form;
    },
    asyncUploader() {
      return {
        withCredentials: true,
        saveUrl: `${this.$apiURL}/shop/products/${this.product.id}/video`,
        autoUpload: true,
      };
    },
  },
  methods: {

    setInitialValue(_data) {
      this.product = _data;
      this.getProduct();
      this.getProductVideo(_data.id);
    },

    /*     save() {
          return new Promise((resolve, reject) => {
            resolve(true);
          });
        },
     */
    save() {
      return new Promise((resolve, reject) => {

        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
            productApi
              .insertProductVideoEmbedCode(this.product.id, {
                url: this.form.videourl,
                product_id: this.product.id,
                video_id: this.form.video_id,
                type : this.form.type
              })
              .then((res) => {
                this.$emit("on-update", this.form);
                resolve(true);
              })
              .finally(() => {
                this.$emit("is-loading", false);
              })
              .catch((err) => {
                this.setErrors(err.response.data.errors);
                reject(err);
              });
          } else reject(false);
        });
      });
    },

    getProduct() {
      productApi.getProduct(this.product.id).then((res) => {
        this.product = res.data.entity;
      });
    },

    getProductVideo(_productID) {
      this.$emit("is-loading", true);
      productApi
        .getProductVideo(_productID)
        .then((res) => {

          this.video = res.data.entity.video
          if (res.data.entity.video_embed_code.length) {

            this.form = res.data.entity.video_embed_code[0]

            if (this.form && this.form.type == 11) {

              this.form.videourl = res.data.entity.video_embed_code[0].file_url
              this.form.video_id = res.data.entity.video_embed_code[0].id

            }
          } else if(res.data.entity.video.length) {
            this.form = res.data.entity.video[0]
             
          }
          console.log( this.form.type)
        })
        .finally(() => this.$emit("is-loading", false));
    },

    deleteProductVideo: function (video) {

      let question =
        "از حذف ویدیو محصول اطمینان دارید";
      this.$alert("question", question, null, {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.$emit("is-loading", true);
          productApi
            .deleteProductVideo(video.id)
            .then((res) => {
              this.getProductVideo(this.product.id);
              this.$izitoast("success", "عملیات موفق", res.data.message);
            })
            .finally(() => {
              this.$emit("is-loading", false);
            });
        }
      });
    },


    onUpload: function (e) {
      this.$emit("is-loading", true);
      var xhr = e.XMLHttpRequest;
      if (xhr) {
        var _this = this;
        xhr.addEventListener("readystatechange", function (e) {
          if (xhr.readyState == 1 /* OPENED */) {
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader("X-XSRF-TOKEN", _this.$store.state.xsrfToken);
          }
        });
      }
    },
    onSuccess: function () {
      this.$emit("is-loading", false);
      this.getProductVideo(this.product.id);
    },
    onError(e) {
      this.$emit("is-loading", false);
      this.$izitoast("error", "خطا در بارگذاری", e.XMLHttpRequest.statusText, {
        timeout: 0,
      });
    },
    uploadvideo(type) {
      return type == 10;
    },
    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      this.product = {};
      this.images = [];
      this.form = {};
      this.$refs.typeSelect.emptyKendo();
      this.$emit("is-loading", false);

    },
  },
};
</script>
<style scoped>
.set-thumbnail {
  position: absolute;
  right: 2px;
  bottom: -1px;
}

.delete-image {
  color: red;
  position: absolute;
  right: 2px;
  top: 2px;
}

.photo {
  float: right;
  position: relative;
  margin: 0 10px 10px 0;
  padding: 0;
}

.photo img {
  object-fit: contain;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* height: 200px; */
  /* background-color: #ccc; */
}

.dropZoneElement {
  font-family: iranyekan;
  position: relative;
  display: inline-block;
  background-color: #f8f8f8;
  border: 1px solid #c7c7c7;
  width: 100%;
  height: 110px;
  text-align: center;
}

.textWrapper {
  font-family: iranyekan !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font-size: 24px;
  line-height: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}

.dropImageHereText {
  font-family: iranyekan;
  color: #c7c7c7;
  text-transform: uppercase;
  font-size: 12px;
}

.delete-image:hover i {
  color: red;
}
</style>
