import { Upload } from "@progress/kendo-upload-vue-wrapper"
import { apiCall, createQueryString } from "../imports"

const PREFIX = "marketplace/sellers"

export default {

    getSupplires(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
        
    },
    createSupplire(data) {
        return apiCall('post', `marketplace/seller/store`, data)
    },
    updateSupplire(sellerId, data) {
        return apiCall('patch', `marketplace/seller/store/${sellerId}/update`, data)
    },
    createAddress(data) {
        return apiCall('post', `marketplace/seller/addresses`, data)
    },
    updateAddress(data) {
        return apiCall('post', `marketplace/seller/addresses`, data)
    },
   /* updateAddressOfSeller(addressId, data) {
        return apiCall('post', `marketplace/seller/addresses?address_id=${addressId}/update`, data)
    },*/
    createFinance(data) {
        return apiCall('post', `marketplace/seller/finance`, data)
    },
    updateSellerFinance(sellerId,data) {
        return apiCall('post', `marketplace/seller/finance?seller_id=${sellerId}`, data)
    },
    updateSellerFinanceByAdmin(data) {
        return apiCall('post', `marketplace/seller/finance`, data)
    },
    getProvinces(data) {
        return apiCall('get', `management/provinces`,data)
    },
    getCitiesOfProvince(provinceId) {
        return apiCall('get', `management/cities?province=${provinceId}`)
     
    },
    AcceptingTheRules(data) {
        return apiCall('post', `marketplace/seller/check/rules`,data)

    },
    getBanks() {
        return apiCall('get', `payment/payment-methods/banks`)
    },
    getDocumentsTitle() {
        return apiCall('get', `marketplace/seller/document/titles`)
    },
    createDocument(data) {
        return apiCall('post', `marketplace/seller/document`, data)
    },
    getDocumentsOfSeller(sellerId) {
        return apiCall('get', `marketplace/seller/document/get/${sellerId}`)
    },
    getDocumentsOfSellerAuth() {
        return apiCall('get', `marketplace/seller/document`)
    },
    getProductsOfSeller(sellerId) {
        return apiCall('get', `marketplace/seller/products/get/${sellerId}`)
    
    },
    getSellerOfProduct(productID) {
        return apiCall('get', `marketplace/sellers/product/${productID}`)
    
    },
    sellerConfirmation(sellerId) {
        return apiCall('get', `marketplace/seller/confirmation/${sellerId}`)
    
    },
    
    getSellerFinances(sellerId) {
        return apiCall('get', `marketplace/seller/finance/${sellerId}`)
    },

    getRuleText() {
        return apiCall('get', `marketplace/seller/rules`)
    },
    getSuccessCompletedProfileSellerText() {
        return apiCall('get', `/marketplace/seller/welcome`)
    },

    getCommissionOfseller(sellerId) {
        return apiCall('get', `marketplace/seller/commission/${sellerId}`)
     
    },
    insertCommissionForSeller(data) {
        return apiCall('post', `marketplace/seller/commission`, data)

    },
    deleteCommission(commissionID) {
        return apiCall('delete', `marketplace/seller/commission/${commissionID}`)
    },
    getActiveSupplire() {
        return apiCall('get', `marketplace/sellers/verified`)
    },
    addExistProductsToSeller(data) {
        return apiCall('post', `marketplace/seller/products`, data)

    },
    
    deleteDocumentWithImage(documentID) {
        return apiCall('delete', `marketplace/seller/document/${documentID}`)
    },
    
    activeOrInactiveProductForSale(productID,sellerID) {
        return apiCall('get', `marketplace/seller/product/activeOrInactive/${productID}/${sellerID}`)
    },

    deleteDocumentWithImage(documentID) {
        return apiCall('delete', `marketplace/seller/document/${documentID}`)
    },
    savePenaltyReward(data) {
        return apiCall('post', `marketplace/seller/transaction/register/penalty`, data)
    },
    savePayments(data) {
        return apiCall('post', `marketplace/seller/transaction/register/payment`, data)
    },
   
    // getTransactions() {
    //     return apiCall('get', `marketplace/seller/transaction/get`)

    // },
    getTransactions(filters) {
        return apiCall('get', `marketplace/seller/transaction/get${createQueryString(filters)}`)

    },
    deleteTransactions(transaction_id){
        return apiCall('delete', `marketplace/seller/transaction/${transaction_id}/delete`);
    },

    getOrdersOfSupplire(sellerID) {
        return apiCall('get', `marketplace/seller/orders/get/${sellerID}`);
        
    },
    createFiscalYear(data) {
        return apiCall('post', `marketplace/seller/fiscal/year/create`, data)   
    },
    getListFiscalYears() {
        return apiCall('get', `marketplace/seller/fiscal/year/get`)        
    },
    deleteFiscalYear(fiscalYear) {
        return apiCall('delete', `marketplace/seller/fiscal/year/delete/${fiscalYear}`)

    },
    activeDefaultFiscalYear(fiscalYear)
    {
        return apiCall('patch', `marketplace/seller/fiscal/year/activation/${fiscalYear}`)
    },

    changeStatusOfFeedBack(productFeedbackOfSeller) {
        return apiCall('patch', `marketplace/seller/feedback/${productFeedbackOfSeller}/flag`)

    },
    transferAccountBalance(data) {
        return apiCall('post', `marketplace/seller/transaction/transfer/balance`, data)          
    },
    // edit needed
    savePriorityOfSupplierProduct(data){
        
        return apiCall('patch', `marketplace/seller/product/priority/${data.product_id}/${data.seller_id}/update`, data)

    },
    // edit needed
    removeAllPriorityOfSupplierProduct(data){
        return apiCall('patch', `marketplace/seller/product/priority/reset/${data.product_id}`, data)
    },
    getDocOfSeller(document_id)
    {
        return apiCall('get', `/marketplace/seller/document/${document_id}/download`,)
    },
    UploadImageOfStoreSeller(data){
        return apiCall('post', `marketplace/seller/store/image/upload`, data)
    },
    getImagesOfSellerStoreAuth(store_id){
        return apiCall('get', `/marketplace/seller/store/images?store_id=${store_id}`)
    }, 
    deleteImageOfStoreSeller(data){
        return apiCall('delete', `/marketplace/seller/store/image/delete?file_id=${data.file_id}&store_id=${data.store_id}`)
    },  
    deleteSeller(data)
    {
        return apiCall('delete', `/marketplace/seller/${data}`)   
    }

}
