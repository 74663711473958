<template>
    <bita-modal
      id="feedback-form"
      :is-loading="isLoadingModal"
      :modal-lg="true"
      title="بازخورد به فروشنده"
      @close="reset()"
    >
      <div slot="body">
        <bita-form
          ref="feedbackForm"
          v-if="$acl.can('create', 'feedback')"
        >
          <div slot="body">
            <div class="row">
              <bita-select
                label="نام فیلد"
                ref="fields"
                name="fields"
                :validation-container-class="colWidth"
                :data-source="fields"
                input-class="form-control"
                data-text-field="fa_field_name"
                data-value-field="id"
                v-model="myform.seller_feedback_field_id"
                />
              <bita-textarea
                label="علت "
                type="text"
                validation-container-class="col-lg-12"
                input-class="form-control"
                v-model="myform.description"
              />
            </div>
            <bita-bootbutton
              title="ثبت"
              type="primary mb-3"
              icon="fas fa-save"
              btn-width="150px"
              @click.native="submit"
              :disabled="isLoadingModal"
            />
          </div>
        </bita-form>
        <bita-grid
          v-if="showGrid && $acl.can('view', 'feedback')"
          ref="feedbackGrid"
          :columns="gridColumns"
          :read-data="readData"
          :page-size="pageSize"
          :has-row-number="true"
        />
      </div>
    </bita-modal>
  </template>
  <script>
  import { productApi, supplireApi } from "@/api/apiManagement";
  export default {
    data() {
      return {

        seller:'',
        colWidth: "col-lg-3",
        colFullWidth: "col-lg-12",
        formData: {},
        myform: {
          type:1,
        },
        feedbackDefaulttype:1,
        supplire: [],
        fields: [],
        filters: {},
        pageSize: 10,
        isLoadingModal: false,
        showGrid: false,
      };
    },
    mounted() {
      if (this.$acl.can("view", "feedbacks-fields")) {
        this.getProductTableFiledsname();
      }
    },
    computed: {
      gridColumns() {
        return [
          { title: "نام فیلد", template: this.getFieldName },
          { title: "عنوان", template: this.getPersianFieldLabel },
          { title: "علت", template: this.getReason },
          { title: "نوع", template: this.getFieldType },
          { title: "وضعیت", template: this.getStatusField },
          { title: "عملیات", command: this.gridCommands() },
        ];
      },
      typesSource() {
        return [
          { id: 1, name: "بازخورد فروشنده" },
          { id: 2, name: "بازخورد محصول" },
        ];
      },
    },
    methods: {
      setInitialData(_data) {
        this.showGrid = true;
        this.seller = _data;
        this.myform.seller_id = this.seller.id;
        this.loadData();
      },
      getFieldType(e){
          let title = "";
  
          switch (Number(e.type)) {
          case 1:
          title = "بازخورد فروشنده";
          break;
          case 2:
          title = "بازخورد محصول";
          break;
          };
          return `${title}`;
      },
      getFieldName(e) {
        let fieldName = e.field_name;
        return `${fieldName}`;
      },
      getPersianFieldLabel(e) {
        let persianLabel = e.fa_field_name;
        return `${persianLabel}`;
      },
      getStatusField(e) {
        let resolve_flag = '';
        switch (Number(e.resolve_flag)) {
          case 0:
          resolve_flag = "در انتظار تکمیل";
          break;
          case 1:
          resolve_flag = "انجام شده";
          break;
          };
        return `${resolve_flag}`;
      },
      getReason(e) {
        let reason = e.description;
        return `${reason}`;
      },
  
      isCategory(selectValue) {
        return selectValue == 2;
      },
      readData(e) {
        this.isLoadingModal = true;
        productApi
          .getProductFeedback(this.myform.seller_id,this.feedbackDefaulttype)
          .then((res) => {
            e.success(res.data.entity);
          })
          .catch(() => e.success([]))
          .finally(() => (this.isLoadingModal = false));
      },
      loadData() {
        this.$refs.feedbackGrid.loadDataSource();
      },
      gridCommands() {
        let commands = [];
        if (this.$acl.can("delete", "feedback"))
          commands.push({
            text: "delete",
            click: this.delete,
            template: this.gridButtons.delete,
          });
        if (this.$acl.can("change", "flag-of-feedback"))
          commands.push({
            text: "sellerConfirm",
            click: this.changeStatusOfFeedBack,
            template: this.gridButtons.sellerConfirm,
          });
        return commands;
      },
  
      delete(e) {
        let feedback = this.$refs.feedbackGrid.getSelectedGridRow(e);
        this.$alert("question", `آیا از حذف اطمینان دارید؟`, "", {
          confirmButtonText: "بله",
        }).then((result) => {
          if (result.value) {
            this.isLoadingModal = true;
            productApi
              .deleteFeedBack(feedback.id)
              .then((res) => {
                this.$izitoast("success", "موفق!", res.data.message);
                this.loadData();
              })
              .finally(() => (this.isLoadingModal = false));
          }
        });
      },
      changeStatusOfFeedBack(e) {
        let feedback = this.$refs.feedbackGrid.getSelectedGridRow(e);
  
        console.log(feedback);
        this.$alert(
          "question",
          `بازخورد مورد نظر انجام شده است !؟`,
          "",
          {
            confirmButtonText: "بله",
          }
        ).then((result) => {
          if (result.value) {
            this.isLoadingGrid = true;
            console.log(feedback);
            supplireApi
              .changeStatusOfFeedBack(feedback.id)
              .then((res) => {
                this.$izitoast("success", "عملیات موفق", res.data.message);
                this.loadData();
              })
              .finally(() => {
                this.isLoadingGrid = false;
              });
          }
        });
      },
  
      submit() {
        this.$refs.feedbackForm.validate().then((res) => {
          if (res) {
            console.log(this.myform);
            this.isLoadingModal = true;
            productApi
              .insertProductFeedback(this.myform)
              .then((res) => {
                this.$izitoast("success", "موفق!", res.data.message);
                this.loadData();
                this.reset();
              })
              .finally(() => (this.isLoadingModal = false));
          }
        });
      },
  
      getProductTableFiledsname() {
        productApi.getProductTableFiledsname().then(({ data }) => {
          this.fields = data.entity;
        });
      },
      resetSelect() {
        this.$refs.products.clear();
        this.$refs.myform.clear();
        this.$refs.value_type.emptyKendo();
      },
      reset() {
        this.myform.description="";
        this.$refs.fields.clear();
        this.showGrid = false;
      },
      getFormData() {
        let formData = new FormData();
        for (var key in this.formData) {
          if (this.formData[key] != null) {
            formData.append(key, this.formData[key]);
          }
        }
        return formData;
      },
    },
  };
  </script>
  