<template>
  <div class="bita-color-picker" :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="form-group k-rtl">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i>
          </div>
        </label>
        <div>
          <color-picker
            :id="inputID"
            :name="name"
            :ref="name"
            v-model="content"
            @changemodel="handleInput(content)"
            :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
            :disabled="disabled"
          />
        </div>
        <span v-if="hasSubtitle" class="form-text m-b-none">{{
          subtitle
        }}</span>
        <span
          class="invalid-feedback"
          :style="`display: ${errors ? 'inline-block' : 'none'}`"
          >{{ errors[0] }}</span
        >
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";

import {
  ColorPicker,
  InputsInstaller,
} from "@progress/kendo-inputs-vue-wrapper";
import "@progress/kendo-ui/js/kendo.colorpicker";
export default {
  extends: InputCommons,
  components: { ColorPicker },
  use: { InputsInstaller },
  mounted() {
    if (!this.content) {
      this.content = "#fff";
    }
  },
  methods: {
    emptyKendo() {
      var test = this.$refs[this.name].kendoWidget();
      test.value("");
    },
  },
};
</script>
<style>
/* .k-dropdown{
  display: inline !important;
} */
.k-dropdown .k-dropdown-wrap .k-input,
.k-dropdowntree .k-dropdown-wrap .k-input {
  height: calc(8px + 1.8285714286em);
}
</style>

<style lang="scss">
.bita-color-picker {
  span.k-widget.k-colorpicker.form-control {
    border: none;
  }
  span.k-selected-color,
  span.k-picker-wrap.k-state-default {
    height: 100% !important;
  }
}
</style>
