<template>
  <bita-modal
    id="change-password"
    :title="title"
    :modal-md="true"
    @close="reset"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="password"
              name="old_password"
              label="رمزعبور"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.old_password"
            />
            <bita-input
              type="password"
              name="password"
              label="رمزعبور جدید"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.password"
            />
            <bita-input
              type="password"
              name="password_confirmation"
              label="تکرار رمزعبور جدید"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.password_confirmation"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!isLoading"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="changePasswordH"
      />
      <bita-spinner
        v-show="isLoading"
        :is-loading="isLoading"
        styleName="align-self: center; display: inline-block; width: 200px"
      />
    </div>
  </bita-modal>
</template>
<script>
import { userApi } from "@/api/apiManagement";

export default {
  props: {
    title: String,
  },
  data() {
    return {
      colWidth: "col-12",
      formData: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      isLoading: false,
    };
  },
  methods: {
    changePasswordH() {
      this.isLoading = true;

      userApi
        .changePassword(this.formData)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.$toggleModal("#change-password");
          this.$root.$emit("app:logout");
          this.$store.dispatch("logout");
          this.$router.push("/login");
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    reset() {
      this.formData = {
        old_password: "",
        password: "",
        password_confirmation: "",
      };
      this.$refs.formData.reset();
    },
    setErrors(_errors) {
      this.$refs.formData.setErrors(_errors);
    },
  },
};
</script>