import { apiCall, createQueryString } from "../imports"

const PREFIX = "management/static-pages"

export default {

    getStaticPages() {
        return apiCall('get', `${PREFIX}`)
    },

    createStaticPages(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateStaticPages(staticPageId, data) {
        return apiCall('patch', `${PREFIX}/${staticPageId}`, data)
    },
    
    deleteStaticPages(staticPageId) {
        return apiCall('delete', `${PREFIX}/${staticPageId}`)
    },
}