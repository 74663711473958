<template>
    <div class="ibox k-rtl">
        <slot name="header"></slot>
        <div
            :class="{
                'ibox-content': true,
                'sk-loading': isLoading,
                'no-border': !box
            }"
        >
            <div
                :class="
                    `sk-spinner ${
                        loadingType ? loadingType : 'sk-spinner-circle'
                    }`
                "
                v-html="
                    getSppinerContent(
                        loadingType ? loadingType : 'sk-spinner-circle'
                    )
                "
            ></div>
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Ibox",
    props: {
        isLoading: Boolean,
        noBox: Boolean,
        loadingType: String
    },
    data() {
        return {};
    },
    computed: {
        box: function() {
            return !this.noBox || this.noBox == false ? true : false;
        }
    },
    mounted() {},
    methods: {
        getSppinerContent(name) {
            switch (name) {
                case "sk-spinner-double-bounce":
                    return "<div class='sk-double-bounce1'></div><div class='sk-double-bounce2'></div>";
                case "sk-spinner-wandering-cubes":
                    return "<div class='sk-cube1'></div><div class='sk-cube2'></div>'";
                case "sk-spinner-chasing-dots":
                    return "<div class='sk-dot1'></div><div class='sk-dot2'></div>";
                case "sk-spinner-three-bounce":
                    return "<div class='sk-bounce1'></div><div class='sk-bounce2'></div><div class='sk-bounce3'></div>";
                case "sk-spinner-circle":
                    return "<div class='sk-circle1 sk-circle'></div><div class='sk-circle2 sk-circle'></div><div class='sk-circle3 sk-circle'></div><div class='sk-circle4 sk-circle'></div><div class='sk-circle5 sk-circle'></div><div class='sk-circle6 sk-circle'></div><div class='sk-circle7 sk-circle'></div><div class='sk-circle8 sk-circle'></div><div class='sk-circle9 sk-circle'></div><div class='sk-circle10 sk-circle'></div><div class='sk-circle11 sk-circle'></div><div class='sk-circle12 sk-circle'></div>";
                case "sk-spinner-cube-grid":
                    return "<div class='sk-cube'></div><div class='sk-cube'></div><div class='sk-cube'></div><div class='sk-cube'></div><div class='sk-cube'></div><div class='sk-cube'></div><div class='sk-cube'></div><div class='sk-cube'></div><div class='sk-cube'></div>";
                case "sk-spinner-fading-circle":
                    return "<div class='sk-circle1 sk-circle'></div><div class='sk-circle2 sk-circle'></div><div class='sk-circle3 sk-circle'></div><div class='sk-circle4 sk-circle'></div><div class='sk-circle5 sk-circle'></div><div class='sk-circle6 sk-circle'></div><div class='sk-circle7 sk-circle'></div><div class='sk-circle8 sk-circle'></div><div class='sk-circle9 sk-circle'></div><div class='sk-circle10 sk-circle'></div><div class='sk-circle11 sk-circle'></div><div class='sk-circle12 sk-circle'></div>";
                default:
                    return "";
            }
        }
    }
};
</script>
<style scoped>
.no-border {
    border: 0 !important;
}
.ibox-content{
    /* min-height: 250px; */
}
</style>
