<template>
  <bita-modal
    id="slide-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading || isLoadingModal"
    @close="reset"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="title"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
            />
            <bita-uploader
              name="icon"
              label="تصویر"
              ref="uploader"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.image"
              :icon-url="formData.image_url"
            />
            <bita-input
              type="number"
              name="order"
              min="0"
              label="ترتیب"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.order"
            />
            <bita-input
              type="text"
              name="link"
              label="آدرس"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.link"
            />
            <bita-select
              name="type"
              ref="typeSelect"
              label="نوع"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="typesSource"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.type"
            />
            <bita-select
              v-if="formData.type == 5 || formData.type == 11 "
              name="type"
              ref="collectionSelect"
              label="کالکشن"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="collections"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.meta_id"
            />
            <bita-select
              name="size"
              ref="sizeSelect"
              label="اندازه"
              :rules="isProductPic ? 'required' : ''"
              v-show="isProductPic"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="[
                { id: 1, name: 'کوچک' },
                { id: 2, name: 'متوسط' },
                { id: 3, name: 'بزرگ' },
                { id: 4, name: 'کوچک دوتایی' },
              ]"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.size"
            />
            <bita-select
              v-if="formData.type == 12 || formData.type == 13 || formData.type == 14 || formData.type == 15 || formData.type == 16"
              name="type"
              ref="middlePagesSelect"
              label="صفحه واسط"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="middlePages"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.meta_id"
            />
          </div>
          <div class="row" v-if="formData.id">
            <img :src="formData.image_url" alt="..." class="img-thumbnail" />
          </div>
          <bita-textarea
              type="text"
              name="css"
              label="css کد"
              :validation-container-class="colFullWidth"
              input-class="form-control"
              v-model="formData.meta_css"
              rowspan="5"
            />
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
import { productCollectionApi,mdidlePagesApi} from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
    slide: Object,
  },
  data() {
    return {
      colWidth: "col-lg-4",
      formData: {},
      collections: [],
      isLoadingModal: false,
    };
  },
  computed: {
    isProductPic() {
      return this.formData.type == 2 || this.formData.type == 5;
    },
    typesSource() {
      return [
        { id: 1, name: "اسلایدر" },
        { id: 2, name: "بنر وسط" },
        { id: 3, name: "بنر ساید بار" },
        { id: 4, name: "بنر سرصفحه" },
        { id: 5, name: "بنر کالکشن محصول" },
        { id: 6, name: "بنر سرصفحه موبایل" },
        { id: 7, name: "دسته بندی سر صفحه" },
        { id: 8, name: "اسلایدر حالت موبایل" },
        { id: 9, name: "بنر تخفیف ویژه موبایلی" },
        { id: 10, name: "بنر تخفیف ویژه دسکتاپ" },
        { id: 11, name: "دسته بندی بالای  کالکشن" },
        { id: 12, name: "اسلایدر صفحه واسط" },
        { id: 13, name: "دسته بندی سرصفحه واسط" },
        { id: 14, name: "اسلایدر صفحه واسط حالت موبایل" },
        { id: 15, name: "بنر تخفیف ویژه موبایلی صفحه واسط" },
        { id: 16, name: " بنر تخفیف ویژه دسکتاپ صفحه واسط" },
      ];
    },
  },
  methods: {
    setForm(data = {}) {
      if (this.$acl.can("view", "product-collections")) {
        this.formData.type = data.type;
        this.isLoadingModal = true;
        productCollectionApi
          .gets()
          .then((res) => {
            this.collections = res.data.entity;
            setTimeout(() => {
              this.formData = data;
            }, 300);
          })
          .finally(() => (this.isLoadingModal = false));
          mdidlePagesApi
          .gets()
          .then((res) => {
            this.middlePages = res.data.entity;
            setTimeout(() => {
              this.formData = data;
            }, 300);
          })
          .finally(() => (this.isLoadingModal = false));
      } else {
        setTimeout(() => {
          this.formData = data;
        }, 200);
      }
    },

    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    reset() {
      this.formData = {};
      this.$refs.uploader.reset();
      this.$refs.typeSelect.emptyKendo();
      if (this.$refs.sizeSelect) this.$refs.sizeSelect.emptyKendo();
      this.$refs.form.reset();
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
