<template>
  <div>
    <change-password ref="form" :title="`تغیر رمز عبور`" />
    <div class="row border-bottom white-bg notselectable">
      <nav class="navbar navbar-expand-lg navbar-static-top" role="navigation">
        <!-- <a href="#" class="navbar-brand">{{ $user.model.name }}</a> -->
        <!-- <a
          class="navbar-minimalize minimalize-styl-2 btn btn-primary "
          @click="toggleSlider()"
          ><i class="fa fa-bars"></i>
        </a> -->
        <button
          class="navbar-toggler"
          style="display: inline-block"
          type="button"
          @click="toggleSlider()"
        >
          <i class="fa fa-bars"></i>
        </button>

        <!-- <router-link class="navbar-brand" to="/">{{
          $user.model.name
        }}</router-link> -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fa fa-bars"></i>
        </button>
        <div class="navbar-collapse collapse" id="navbar">
          <ul class="nav navbar-nav mr-auto" v-if="permissionsLoaded">
            <!-- <li class="dropdown" v-for="(item, index) in menus" :key="index">
              <a
                aria-expanded="false"
                role="button"
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                v-if="item.childs && checkParentPermissions(item.childs)"
                >{{ item.title }}</a
              >
              <ul
                v-if="item.childs && checkParentPermissions(item.childs)"
                role="menu"
                class="dropdown-menu"
              >
                <li
                  v-for="(child, childIndex) in getAllowedSubMenus(item.childs)"
                  :key="childIndex"
                >
                  <router-link :to="child.to">{{ child.title }}</router-link>
                </li>
              </ul>
              <router-link
                v-else-if="$acl.can('view', item.name)"
                :to="item.to"
                aria-expanded="false"
                role="button"
                >{{ item.title }}</router-link
              >
            </li> -->
          </ul>

          <ul class="nav navbar-top-links navbar-right">
            <li class="dropdown" v-if="$acl.can('view','admin-of-sellers')">
              <a
                class="dropdown-toggle count-info"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <i class="fa fa-bell"></i>
                <span
                  class="label label-primary"
                  v-if="notifications.length > 0"
                  >{{ notifications.length }}</span
                >
              </a>
              <ul
                class="dropdown-menu dropdown-alerts notifications notification-dropdown"
                v-if="notifications.length > 0 "
              >
                <li class="d-flex justify-content-end p-0">
                  <a class="p-0" @click="clearAllNotif">
                    <i class="fas fa-trash fa-fw"></i>
                  </a>
                </li>
                <template v-for="(notification, index) in notifications">
                  <li
                    v-if="
                      notification.type.includes(
                        'AdminOrderCreationNotification'
                      )
                    "
                    :key="notification.id"
                    class="d-flex align-items-center justify-content-between"
                  >
                    <router-link to="/shop/orders" class="pl-0 pr-2">
                      <div>
                        <i class="fas fa-shopping-cart fa-fw"></i> سفارش جدید از
                        {{ notification.data.order.full_name }}
                        <span class="float-right text-muted small mr-2">{{
                          $getTimeFromNow(notification.data.order.created_at)
                        }}</span>
                      </div>
                    </router-link>
                    <a class="p-0" @click="clearNotif(notification.id)">
                      <i class="fas fa-trash fa-fw"></i>
                    </a>
                  </li>
                  <li
                    v-if="
                      notification.type.includes(
                        'AdminSendManualPriceNotification'
                      )
                    "
                    :key="notification.id"
                    class="d-flex align-items-center justify-content-between"
                  >
                    <router-link to="/payment/manual-transactions" class="pl-0 pr-2">
                      <div>
                        <i class="fas fa-shopping-cart fa-fw"></i> پرداخت مستقیم از
                        {{ notification.data.transaction.name}} {{ notification.data.transaction.family}}
                        <span class="float-right text-muted small mr-2">{{
                          $getTimeFromNow(notification.data.transaction.created_at)
                        }}</span>
                      </div>
                    </router-link>
                    <a class="p-0" @click="clearNotif(notification.id)">
                      <i class="fas fa-trash fa-fw"></i>
                    </a>
                  </li>
                  <li
                    :key="notification.id"
                    v-if="notifications.length < index + 1"
                    class="dropdown-divider"
                  ></li>
                  <li
                    v-if="
                      notification.type.includes('AdminNotifyOnPriceUpdate')
                    "
                    :key="notification.id"
                    class="d-flex align-items-center justify-content-between"
                  >
                    <i class="fas fa-check fa-fw"></i>
                    <router-link to="/shop/orders" class="pl-0 pr-2">
                      <div>
                        قیمت ها با موفقیت بروز شدند
                        <span class="float-right text-muted small mr-2">{{
                          $getTimeFromNow(notification.created_at)
                        }}</span>
                      </div>
                    </router-link>
                    <a class="p-0" @click="clearNotif(notification.id)">
                      <i class="fas fa-trash fa-fw"></i>
                    </a>
                  </li>
                  <li
                    :key="notification.id"
                    v-if="notifications.length < index + 1"
                    class="dropdown-divider"
                  ></li>
                </template>
              </ul>
            </li>
            <li class="dropdown">
              <a
                class="dropdown-toggle count-info"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
                v-if="$user"
              >
                {{ `${$user.name} ${$user.family}` }}
              </a>
              <ul class="dropdown-menu dropdown-alerts dropPanel">
                <template>
                  <li>
                    <router-link to="/management/profile">
                      <div>
                        <i class="fas fa-user fa-fw"></i>
                        پنل کاربری
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <a href="#" @click="toggleChangePassword">
                      <div>
                        <i class="fas fa-lock fa-fw"></i>
                        تغیر رمز عبور
                      </div>
                    </a>
                  </li>
                </template>
              </ul>
            </li>
            <li>
              <span class="m-r-sm text-muted welcome-message">
                <span class="label">{{
                  $user && $user.roles && $user.roles[0]
                    ? $user.roles[0].fa_name
                    : "هویتی یافت نشد"
                }}</span>
              </span>
            </li>
            <li>
              <a data-toggle="dropdown" href="#">
                <i class="fa fa-sign-out-alt"></i> خروج
              </a>
              <ul class="dropdown-menu dropdown-alerts" id="exit-dropdown">
                <li class="text-center">
                  <i class="fas fa-question-circle fa-3x"></i>
                </li>
                <li class="text-center mt-3">
                  آیا تمایل به خروج از سامانه دارید؟
                </li>
                <li class="mt-3">
                  <button
                    @click="signOut"
                    class="btn btn-default w-25 float-left mr-5"
                  >
                    بله
                  </button>
                  <button class="btn btn-default w-25">خیر</button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { userApi } from "@/api/apiManagement";
import ChangePassword from "../management/profile/components/ChangePassword";

export default {
  components: { ChangePassword },
  data() {
    return {
      menus: [
        {
          title: "فروشگاه",
          childs: [
            { title: "مدیریت سفارش‌ها", to: "/store/orders", name: "order" },
          ],
        },
        {
          title: "مدیریت سیستم",
          childs: [
            { title: "کاربران", to: "/management/users", name: "users" },
            { title: "نقش", to: "/management/roles", name: "roles" },
          ],
        },
      ],
      notifications: [],
      settings: {},
    };
  },
  computed: {
    permissionsLoaded() {
      return this.$acl.isReady;
    },
  },
  mounted() {
    // var _this = this;
    if (this.$user && this.$user.full_name) {
      this.getUserNotifications();
      setInterval(() => {
        this.getUserNotifications();
      }, 1000 * 60 * 3);
    }
  },
  methods: {
    clearNotif(notifID) {
      this.$setIsLoading(true);
      userApi
        .readNotification(notifID)
        .then(() => this.getUserNotifications())
        .finally(() => this.$setIsLoading(false));
    },
    clearAllNotif() {
      this.$setIsLoading(true);
      userApi
        .readAllNotifications()
        .then(() => this.getUserNotifications())
        .finally(() => this.$setIsLoading(false));
    },
    // playSound() {
    //   //should upload on server
    //   console.log("audio played");
    //   var audio = new Audio(
    //     "http://soundbible.com/mp3/Elevator%20Ding-SoundBible.com-685385892.mp3"
    //   );
    //   audio.play();
    // },
    getUserNotifications() {
      userApi.getNotifications().then((res) => {
        let notifs = res.data.entity;
        this.notifications = notifs;
        // if (this.notifications.length < notifs.length) this.playSound();
      });
    },
    signOut() {
      this.$setIsLoading(true);
      this.$persistClient("post", "management/auth/logout")
        .then((res) => {
          this.$root.$emit("app:logout");
          this.$store.dispatch("logout");
          this.$izitoast("success", "با موفقیت خارج شدید", "موفق");
          this.$router.push("/login");
        })
        .finally(() => this.$setIsLoading(false));
    },
    checkParentPermissions(_childs) {
      let permitted = false;
      for (let item of _childs) {
        permitted = permitted || this.$acl.can("view", item.name);
      }
      return permitted;
    },
    getAllowedSubMenus(_childs) {
      let allowed = [];
      for (let item of _childs) {
        if (this.$acl.can("view", item.name)) allowed.push(item);
      }
      return allowed;
    },
    toggleSlider() {
      this.$root.$emit("side-bar:toggle");
    },
    toggleChangePassword() {
      this.$toggleModal("#change-password");
    },
  },
};
</script>
<style scoped>
.notification-dropdown{
  height: 500px;
  overflow-y: scroll;
}
.notselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
a.router-link-exact-active.router-link-active {
  /* border: 1px solid #51b99c; */
  /* color: #51b99c !important; */
}
.body.rtls .navbar-static-top .navbar-right .dropdown-menu {
  left: 0 !important;
  right: auto !important;
}
.count-info .label {
  right: -13px !important;
  top: 7px !important;
}
.dropPanel {
  width: 170px !important;
}
</style>
