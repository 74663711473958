<template>
  <bita-modal
    id="edit-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="onModalClose()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.name"
            />
            <bita-input
              type="text"
              name="slug"
              label="نام انگلیسی"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="form.slug"
            />
            <bita-colorpicker
              v-if="Number(form.type) == 2"
              ref="colorPicker"
              name="color"
              label="رنگ"
              rules=""
              validation-container-class="col-lg-2"
              input-class="form-control"
              v-model="color"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!form.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    title: String,
    isLoading: Boolean,
    categories: {},
  },
  data() {
    return {
      colWidth: "col-lg-4",
      form: {},
      color: "#ffffff",
    };
  },
  mounted() {},
  computed: {
    formRef() {
      return this.$refs.form;
    },
  },
  methods: {
    initialData(_id, _name, _slug, _color, _type) {
      this.form = {
        id: _id,
        name: _name,
        slug: _slug,
        type: _type,
      };
      this.$nextTick(() => {
        this.color = _color ? _color : "#ffffff";
      });
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          let d = this.form;
          if (this.form.type == 2) d = { ...this.form, color: this.color };
          this.$emit("on-create", d);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          let d = this.form;
          if (this.form.type == 2) d = { ...this.form, color: this.color };
          this.$emit("on-update", d);
        }
      });
    },
    reset() {
      this.form = {};
      this.color = "#ffffff";
      this.formRef.reset();
    },
    onModalClose() {
      this.reset();
      this.$emit("close");
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
