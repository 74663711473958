<template>
  <div class="ibox-tools">
    <a class="dropdown-toggle" data-toggle="dropdown" href="#">
      <i class="fas fa-cloud-download-alt"></i>
    </a>
    <ul class="dropdown-menu dropdown-user">
      <li>
        <a class="dropdown-item" :href="exportExcel" target="_blank"
          ><i class="fa fa-file-excel"></i>excel</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    excel: {
      type: String,
    },
  },
  computed: {
    exportExcel() {
      return `${this.$apiURL}/${this.excel}`;
    },
  },
};
</script>
