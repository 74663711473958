<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن زیر مجموعه شاخص"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="toggleForm"
          />
          <hr />
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    </div>
    <edit-form
      ref="editForm"
      :propData="editForm"
      :title="formTitle"
      :is-loading="isLoadingModal"
      @on-create="(e) => save(e)"
      @on-update="(e) => update(e)"
      @close="reset()"
    />
  </div>
</template>
<script>
import editForm from "./components/FeatureValueForm";
import { featureApi } from "@/api/apiManagement";
export default {
  components: { editForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "فروشگاه", link: "" },
        {
          title: "مدیریت آیتم های قیمت گذاری",
          link: "/shop/features",
        },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      formTitle: "",
      editForm: {},
      detail: {},
      gridColumns: [
        { title: "#", template: this.$getTemplate, width: "40px" },
        { field: "name", title: "نام" },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: [],
        },
      ],
    };
  },
  computed: {
    featureID() {
      return this.$route.params.feature;
    },
  },
  mounted() {
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
    featureApi.find(this.featureID).then((res) => {
      this.detail = res.data.entity;
      this.breadCrumbs.push({
      title: `مقادیر ${this.detail.name}`,
      link: `/shop/details/${this.featureID}/values`,
      active: true,
    });
    });
  },
  methods: {
    reset() {
      this.editForm = {};
    },
    formSucceeded() {
      this.loadData();
      this.formTitle = "";
      this.$refs.editForm.reset();
      this.$toggleModal("#edit-form");
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      // commands.push({
      //   text: "items",
      //   click: this.items,
      //   template: this.gridButtons.items,
      // });
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    readData(e) {
      this.isLoadingGrid = true;
      this.$resetGrid();
      featureApi
        .getFeatureValues(this.featureID)
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    save(_data) {
      this.isLoadingModal = true;
      featureApi
        .createFeatureValue(this.featureID,_data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.formSucceeded();
        })
        .catch((err) => {
          this.$refs.editForm.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    items(e) {
      let row = this.$refs.grid.getSelectedGridRow(e);
    },
    edit(e) {
      let row = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.editForm.initialData(row.id,row.name, row.slug, row.meta ? row.meta.color : '#ffffff', row.feature.type)
      this.formTitle = `ویرایش ${row.name}`;
      this.$toggleModal("#edit-form");
    },
    update(_data) {
      this.isLoadingModal = true;
      featureApi
        .updateFeatureValue(_data.id, _data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.formSucceeded();
        })
        .catch((err) => {
          this.$refs.editForm.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    delete(e) {
      let row = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف ${row.name} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          featureApi
            .deleteFeatureValue(row.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    toggleForm() {
      this.formTitle = "افزودن مقدار شاخص قیمت گذاری محصول";
      this.editForm = {};
      this.$toggleModal("#edit-form");
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
</style>
