<template>
  <ul>
    <li :class="{ 'nav-header': true, small: !isOpen }">
      <div v-if="$user" class="dropdown profile-element" v-show="isOpen">
        <img
          alt="image"
          class="rounded-circle profile-img"
          :src="$user.avatar_url"
          @click="imageClick()"
        />
        <div class="d-none">
          <label for="file" class="btn">انتخاب فایل</label>
          <input id="file" type="file" ref="imageInput" @change="changeAvatar()" />
        </div>
        <span class="block m-t-xs font-bold text-light">{{
          $user.name + " " + $user.family
        }}</span>
        <span class="text-muted text-xs block">
          {{ $user.roles && $user.roles[0] ? $user.roles[0].fa_name : "کاربر بدون نقش" }}
          <b class="caret"></b
        ></span>
      </div>
      <!-- <transition name="fade"> -->
      <div class="nav-logo-small" v-show="!isOpen">پ</div>
      <!-- </transition> -->
    </li>
  </ul>
</template>
<script>
import { userApi } from "@/api/apiManagement";

export default {
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  methods: {
    imageClick() {
      this.$refs.imageInput.click();
    },
    changeAvatar() {
      this.imageUrl = this?.$refs?.imageInput?.files[0];

      let formData = new FormData();

      formData.append("avatar", this.imageUrl);

      userApi
        .updateAvatar(formData)
        .then(({ data }) => {
          this.$izitoast(
            "success",
            "عملیات موفق",
            "اطلاعات شما با موفقیت ویرایش یافت"
          );

          this.$store.dispatch("userUpdate", res.data.entity);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.profile-img {
  width: 100px;
  height: 100px;
}

.nav-header.small {
  padding: 0;
}
.nav-logo-small {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 12px 10px;
  transition: all 1s;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 100ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (prefers-color-scheme: dark) {
  .nav-header {
    background: #2d2d2d !important;
  }
}
</style>