import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/standards"

export default {

    get(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    create(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    update(standardID, data) {
        return apiCall('post', `${PREFIX}/${standardID}/update`, data)
    },

    delete(standardID) {
        return apiCall('delete', `${PREFIX}/${standardID}`)
    }
}