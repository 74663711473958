<template>
  <div slot="body">
    <bita-form @close="reset()" ref="form">
      <div slot="body">
        <div class="row">
          <div class="col-12">
            <bita-select
              v-if="$acl.can('view', 'admin-of-sellers')"
              name="supplires"
              ref="supplires"
              label="فروشنده"
              rules=""
              validation-container-class="col-lg-7"
              input-class="form-control"
              :data-source="supplires"
              data-text-field="seller_fullname"
              @change="getProductWholeSalesOfSeller"
              data-value-field="id"
              v-model="seller_id"
            />
          </div>
          <bita-checkbox
            name="enablewholesale"
            @change="checkWholesale"
            v-model="checked"
            label="فعال سازی عمده فروشی "
            :validation-container-class="colWidth"
            input-class="form-control"
            color="success"
          />
        </div>
        <div class="row">
          <bita-formgroup
            title="عمده فروشی"
            id="wholesale"
            class="row"
            v-show="this.checked"
          >
            <div v-for="(myform, index) in wholeSales" :key="index" class="row">
              <bita-formgroup
                title="رکورد جدید"
                id="wholesale_record"
                class="row"
              >
                <form-button
                  title="-"
                  button-type="btn-danger"
                  v-if="deletable()"
                  @click="deleteRow(index, myform)"
                />
                <bita-input
                  type="number"
                  name="form_count + index"
                  label="از تعداد"
                  rules="required"
                  :validation-container-class="largeColWidth"
                  input-class="form-control"
                  v-model="myform.from_count"
                />
                <bita-input
                  type="number"
                  label="تا تعداد"
                  rules="required"
                  :validation-container-class="largeColWidth"
                  input-class="form-control"
                  v-model="myform.to_count"
                />

                <bita-select
                  name="value_type"
                  ref="value_type"
                  label="نوع کاهش هزینه"
                  rules="required"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :data-source="priceTypes"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="myform.value_type"
                />
                <bita-input
                  type="text"
                  name="value"
                  :label="$currencyLabel(' مقدار کاهش قیمت')"
                  :has-thousand-seprator="true"
                  min="0"
                  rules="required"
                  v-if="!isPercentage(myform)"
                  :validation-container-class="largeColWidth"
                  input-class="form-control"
                  v-model="myform.value"
                />

                <bita-input
                  name="value"
                  type="number"
                  label="درصد کاهش قیمت"
                  v-else
                  min="0"
                  max="100"
                  rules="required"
                  :validation-container-class="largeColWidth"
                  input-class="form-control"
                  v-model="myform.value"
                />

                <bita-input
                  type="text"
                  name="preparation_time"
                  label="مدت زمان آماده سازی"
                  rules="required"
                  :validation-container-class="largeColWidth"
                  input-class="form-control"
                  v-model="myform.preparation_time"
                />

                <div class="row col-lg-12">
                  <bita-datepicker
                    type="datetime"
                    id="paymentat"
                    :validation-container-class="colWidthHalf"
                    name="start_at"
                    rules=""
                    label="شروع تخفیف"
                    inputClass="form-control"
                    v-model="myform.start_at"
                    format="YYYY/MM/DD HH:mm:ss"
                    display-format="jYYYY/jMM/jDD"
                  />
                  <bita-datepicker
                    type="datetime"
                    id="paymentat"
                    :validation-container-class="colWidthHalf"
                    name="end_at"
                    rules=""
                    label="پایان تخفیف"
                    inputClass="form-control"
                    v-model="myform.end_at"
                    format="YYYY/MM/DD HH:mm:ss"
                    display-format="jYYYY/jMM/jDD"
                  />

                  <form-button
                    title="+"
                    icon="fas fa-plus-circle"
                    @click="addWholeRecord()"
                    btn-width="150px"
                  />
                </div>
              </bita-formgroup>
            </div>
          </bita-formgroup>
        </div>
      </div>
    </bita-form>
  </div>
</template>
<script>
import BlockButton from "@/components/template/buttons/BlockButton";
import FormButton from "@/components/template/buttons/FormButton";
import { productApi, supplireApi } from "@/api/apiManagement";
export default {
  props: {
    propsSupplires: {
      type: Array,
    },
    title: String,
    isLoading: Boolean,
    largeColWidth: {
      default: "col-lg-2",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    product: {},
  },
  components: { BlockButton, FormButton },
  data() {
    return {
      seller_id: "",

      colWidth: "col-lg-3",
      colWidthHalf: "col-lg-3",
      checked: false,
      wholeSales: [
        {
          from_count: "",
          to_count: "",
          value_type: "",
          value: "",
          preparation_time: "",
          start_at: "",
          end_at: "",
        },
      ],
      productId: "",
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
      supplires: [],
    };
  },
  mounted() {},
  computed: {
    formRef() {
      return this.$refs.form;
    },
    isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
  },
  methods: {
    setInitialValue(_data) {
      this.form = _data;
      if (this.form.id) this.loadData();

      if (this.form.id  && this.$acl.can('view', 'admin-of-sellers')) this.getSellerOfProduct(this.form.id);
    },
    loadData() {
      this.$emit("is-loading", true);
      this.resetWholesale();
      this.seller_id = "";

      Promise.all([
        productApi.getProductWholeSales(this.form.id /*,this.seller_id*/),
      ])
        .then((res) => {
          for (let a of res[0].data.entity.whole_sales) {
            this.wholeSales.push({});
          }

          setTimeout(() => {
            this.productId = res[0].data.entity.id;
            if (res[0].data.entity.whole_sales.length) {
              this.wholeSales = res[0].data.entity.whole_sales;
              this.checked = res[0].data.entity.is_wholesale;
            }
          }, 50);
          if (this.wholeSales.length === 0)
            this.wholeSales.push({
              from_count: "",
              to_count: "",
              value_type: "",
              value: "",
              preparation_time: "",
              start_at: "",
              end_at: "",
            });
        })
        .finally(() => {
          setTimeout(() => {
            this.$emit("is-loading", false);
          }, 120);
        });
    },

    getProductWholeSalesOfSeller() {
      this.$emit("is-loading", true);
      this.resetWholesale();
      Promise.all([
        productApi.getProductWholeSalesOfSeller(this.form.id, this.seller_id),
      ])
        .then((res) => {
          for (let a of res[0].data.entity.whole_sales) {
            this.wholeSales.push({});
          }

          setTimeout(() => {
            this.productId = res[0].data.entity.id;
            if (res[0].data.entity.whole_sales.length) {
              this.wholeSales = res[0].data.entity.whole_sales;
              this.checked = res[0].data.entity.is_wholesale;
            } else {
              this.wholeSales.push({});
            }
          }, 50);
        })
        .finally(() => {
          setTimeout(() => {
            this.$emit("is-loading", false);
          }, 120);
        });
    },
    isPercentage(myform) {
      return myform.value_type == 1;
    },
    checkWholesale(value) {
      return (this.checked = value ? 0 : 1);
    },
    addWholeRecord() {
      this.wholeSales.push({});
    },
    deletable() {
      return this.wholeSales.length > 1;
    },
    deleteRow(index, myform) {
      if (!myform.id) return this.$delete(this.wholeSales, index);
      this.$emit("is-loading", true);
      productApi
        .deleteWholeSale(myform.id)
        .then(() => {
          this.$delete(this.wholeSales, index);
          this.$izitoast("success", "عملیات موفق", res.data.message);
        })
        .finally(() => this.$emit("is-loading", false));
    },

    save() {
      return new Promise((resolve, reject) => {
        if (!this.checked) {
          resolve(true);
        }
        this.$refs.form.validate().then((result) => {
          if (result) {
            if (this.seller_id == "") {
              productApi
                .updateWholeSale(this.form.id, {
                  wholesales: this.wholeSales,
                  product_id: this.productId,
                  status: this.checked,
                })
                .then((res) => {
                  this.$emit("on-update", this.form);
                  this.resetWholesale();
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
            } else {
              productApi
                .updateWholeSaleOfSeller(this.form.id, this.seller_id, {
                  wholesales: this.wholeSales,
                  product_id: this.productId,
                  status: this.checked,
                })
                .then((res) => {
                  this.$emit("on-update", this.form);
                  this.resetWholesale();
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
            }
          } else reject(false);
        });
      });
    },

    getSellerOfProduct(productId) {
      supplireApi.getSellerOfProduct(productId).then((res) => {
        this.supplires = res.data.entity;
        console.log(this.supplires);
        // this.products = _.unionBy(res.data, this.products, "id")
      });
    },
    reset() {
      this.form = {};
      this.formRef.reset();
    },
    resetWholesale() {
      this.wholeSales = [];
      this.checked = false;
      //this.wholeSales.push({});
      // this.seller_id = '';
    },
  },
};
</script>
<style>
#wholesale {
  padding: 5px 25px 0 0;
}
#wholesale_record {
  margin: 25px 0 0 20px;
}
</style>
