import axios from "axios";


export default {

    deleteRole(role) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`management/roles/${role}`)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //find roles | RoleController
    getRoles(query = null) {
        return new Promise((resolve, reject) => {
            axios
                .get(`management/roles?${query}`)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //create role | RoleController
    addRole(data) {
        return new Promise((resolve, reject) => {
            axios
                .post("management/roles", data)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //update role | RoleController
    updateRole(role) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`management/roles/${role.id}`, role)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //create permission | PermissionController | Permissions.vue
    addPermission(options) {
        return new Promise((resolve, reject) => {
            axios
                .post(`management/permissions`, options)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //find permissions | PermissionController | Permission.vue
    getPermissions(options) {
        return new Promise((resolve, reject) => {
            axios
                .get(`management/permissions`, options)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //delete permissions | PermissionController | Permission.vue
    deletePermission(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`management/permissions/${id}`)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //patch permissions | PermissionController | Permission.vue
    updatePermission(data) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`management/permissions/${data.id}`, data)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //find application modules | ApplicationModeleController | Permission.vue
    getApplicationModules() {
        return new Promise((resolve, reject) => {
            axios
                .get(`management/modules`)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};
