<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            :title="title"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="openModal"
            v-if="$acl.can('create', 'paymethods')"
          />
          <hr />
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    </div>
    <paymethod-form
      ref="paymethodform"
      id="paymethod-form"
      :props-data="updateForm"
      :props-portal="portals"
      :props-bank="banks"
      :title="title"
      :is-loading="isLoadingModal"
      @on-create="handleCreate"
      @on-update="handleUpdate"
    />
  </div>
</template>
<script>
import PaymethodForm from "./components/PaymethodForm";
import { paymethodApi } from "@/api/apiManagement";
import { collect } from "collect.js";
export default {
  components: { PaymethodForm },
  data() {
    return {
      title: "افزودن",
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "شیوه های پرداخت", link: "/shop/paymethods" },
      ],
      isLoadingModal: false,
      isLoadingGrid: false,
      updateForm: {},
      portals: {},
      banks: {},
      options: {},
    };
  },
  computed: {
    gridColumns() {
      return [
        { title: "#", template: this.$getTemplate, width: "40px" },
        { field: "title", title: "عنوان" },
        { field: "type", title: "نوع" , template : this.getTypePayment },
        { field: "method_text", title: "نحوه پرداخت", template: (e) => (e.method_text ? e.method_text : "-"), },
        {
          field: "status",
          title: "وضعیت",
          template: (e) => (e.status_text ? e.status_text : "-"),
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  mounted() {
    Promise.all([this.getPortals(), this.getBanks()]).then((res) => {
      this.portals = this.serializePortals(res[0].data.entity);
      this.banks = res[1].data.entity;
    });
  },
  methods: {
    getTypePayment(e) {
        let type = e.type == 1 ? "فروشنده" : "سفارش" ;
        return `${type}`;

    },
    toggleModal() {
      this.$toggleModal("#paymethod-form");
    },
    openModal() {
      this.updateForm = {};
      this.toggleModal();
    },
    readData(e) {
      this.isLoadingGrid = true;
      paymethodApi
        .getPayMethods("")
        .then((res) => {
          e.success(res.data.entity);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("edit", "paymethods"))
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });

      if (this.$acl.can("delete", "paymethods"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });

      return commands;
    },
    edit(e) {
      this.toggleModal();
      let paymethod = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.paymethodform.setFormData(paymethod);
    },
    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف  ${data.title} اطمینان دارید ؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          paymethodApi
            .deletePayMethods(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },

    serializePortals(portals) {
      let portalCollection = collect(portals);
      let portalSelects = [];
      let key = portalCollection.keys();
      let i = 0;
      key.map(function(_val) {
        portalSelects[i] = {
          id: i,
          name: _val,
          input: portals[_val],
        };
        i++;
      });
      return portalSelects;
    },
    translatePortal(portal) {
      let translate = {
        asanpardakht: "آسان پرداخت",
        behpardakht: "به پرداخت",
        idpay: "آی دی پی",
        irankish: "ایران کیش",
        nextpay: "نکست پی",
        parsian: "پارسیان",
        pasargad: "پاسارگارد",
        payir: "پی ای ار",
        payping: "پی  پینگ",
        paystar: "پی استار",
        poolam: "پولام",
        sadad: "صداد",
        saman: "سامان",
        sepehr: "سپهر",
        yekpay: "یک پی",
        zarinpal: "زرین پال",
        zibal: "زیبال",
        fanavacard:'فن آوا',
      };
      return translate[portal];
    },
    getBanks() {
      return paymethodApi.getBanks();
    },
    getPortals() {
      return paymethodApi.getPaymentMethods("");
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    handleCreate(e) {
      this.isLoadingModal = true;
      paymethodApi
        .createPayMethods(this.getFormData(e))
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.toggleModal();
          this.loadData();
          this.reset();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    handleUpdate(e) {
      this.isLoadingModal = true;
      paymethodApi
        .updatePayMethods(e.id, this.getFormData(e))
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.toggleModal();
          this.loadData();
          this.reset();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    reset() {
      this.updateForm = {};
    },
  },
};
</script>
