<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            v-if="!showForm && $acl.can('create', 'coupons')"
            type="primary"
            title="کوپن جدید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="openModal"
          />
          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :auto-bind="false"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <form-modal
      ref="editModal"
      :isLoading="isLoadingModal"
      :props-roles="roles"
      :title="title"
      :users="users"
      :brands="brands"
      :products="products"
      :get-products="getProducts"
      :get-users="getUsers"
      :categories="categories"
      @on-create="onCreate"
      @on-update="onUpdate"
      @on-change-meta-type="changeMetaType"
      @on-search-brands="getBrands"
    />
  </div>
</template>
<script>
import CouponsForm from "./components/CouponForm";
import {
  couponApi,
  userApi,
  categoryApi,
  productApi,
  brandApi,
} from "@/api/apiManagement.js";

export default {
  components: { "form-modal": CouponsForm },
  data() {
    return {
      brands: [],
      products: [],
      categories: [],
      pageSize: 10,
      currentPage: 1,
      productId: null,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت کوپن ها ",
          link: "/shop/coupons",
          active: true,
        },
      ],
      roles: [],
      users: [],

      isLoadingGrid: false,
      isLoadingModal: false,
      showForm: false,
      productTemp: {},

      gridColumns: [
        { field: "title", title: "عنوان" },
        { field: "code", title: "کد تخفیف" },
        {
          field: "value",
          title: "مقدار تخفیف",
          template: this.offerValueTemlate,
        },
        {
          field: "used_count",
          title: "تعداد تخفیف ها",
          template: this.userdCountTemlate,
        },
        { field: "status", title: "وضعیت", template: this.statusTemlate },
        { title: "عملیات", command: [] },
      ],
    };
  },
  computed: {
    title() {
      let title = "افزودن";
      if (this.$refs.editModal && this.$refs.editModal.getId())
        title = "ویرایش";
      return title + " کوپن ";
    },
  },
  mounted() {
    this.loadData();
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    isProduct(metaValue) {
      return metaValue == 1;
    },
    isCategory(metaValue) {
      return metaValue == 2;
    },
    isBrand(metaValue) {
      return metaValue == 3;
    },
    changeMetaType(value) {
      if (this.isCategory(value)) return this.getCategories({});
      else if (this.isBrand(value)) return this.getBrands({});
    },
    getUsers(e = null) {
      if (!e || !e.filter || !e.filter.value) return;
      let filter = e && e.filter ? e.filter.value : "";
      return userApi.getUsers({ search: filter }).then(({ data }) => {
        this.users = data.data;
      });
    },
    getProducts(e) {
      if (!e.filter) return;
      productApi.getProducts({ search: e.filter.value }).then((res) => {
        this.products = _.unionBy(res.data, this.products, "id");
      });
    },
    getProductInfo(productId) {
      return productApi.getProductInfo(productId);
    },
    getBrands() {
      return brandApi.get().then(({ data }) => {
        this.brands = data.entity;
      });
    },
    getCategories() {
      return categoryApi.getCategories().then(({ data }) => {
        this.categories = data.entity;
      });
    },
    getUser(input = null) {
      let filter = null;

      if (input && input.filter) filter = input.filter.value;

      userApi.search(filter).then(({ data }) => {
        this.users = data.entity;
      });
    },
    userdCountTemlate(e) {
      return (
        e.used_count +
        " / " +
        (e.available_count == null ? "∞" : e.available_count)
      );
    },
    currency(amount, valueType) {
      let result = "";

      if (valueType == 1) result = amount + " " + "%";
      else result = this.$currencyLabel(amount);

      return result;
    },
    offerValueTemlate(e) {
      return this.$thousandSeprator(this.currency(e.value, e.value_type));
    },
    statusTemlate(e) {
      return e.status == 1 ? "فعال" : "غیر فعال";
    },
    onCreate(data) {
      this.isLoadingModal = true;

      couponApi
        .createCoupon(data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        })

        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    successForm() {
      this.loadData();
      this.$refs.editModal.resetForm();

      this.$toggleModal("#edit-modal");
    },
    onUpdate(data) {
      this.isLoadingModal = true;

      couponApi
        .updateCoupon(data.id, data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;

      couponApi
        .getCoupons()
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف کوپن  ${data.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          couponApi
            .deleteCoupon(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];

      if (this.$acl.can("edit", "coupons"))
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
      if (this.$acl.can("delete", "coupons"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    openModal() {
      this.$toggleModal("#edit-modal");
      this.getUsers();
    },
    edit(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      console.log(data);
      let promisList = [];
      this.isLoadingModal = true;
      this.openModal();

      promisList.push(this.changeMetaType(data.meta_type));

      if (this.isProduct(data.meta_type))
        promisList.push(this.getProductInfo(data.meta_value));

      promisList.push(
        this.getUsers({ filter: { value: data?.user?.full_name } })
      );

      Promise.all(promisList).then((result) => {
        if (this.isProduct(data.meta_type))
          this.products = [result[1].data.entity];

        setTimeout(() => {
          this.$refs.editModal.setForm(data);
          this.isLoadingModal = false;
        }, 1000);
      });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
