<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <tools class="d-none d-md-block" excel="shop/orders/export/excel" />
          <bita-form ref="filterForm">
            <div slot="body">
              <div class="row">
                <bita-input
                  type="text"
                  name="name"
                  label="جستجو"
                  validation-container-class="col-lg-4"
                  input-class="form-control"
                  v-model.lazy="filters.search"
                  @keyup.enter.prevent="loadData"
                />
                <div class="col-lg-4">
                  <a class="margin-top btn btn-success" @click="loadData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>
                <more-filter>
                  <bita-select
                    name="status"
                    label="وضعیت سفارش"
                    ref="statusDropDown"
                    rules="required"
                    validation-container-class="col-lg-2"
                    input-class="form-control"
                    :data-source="statusOrder"
                    data-text-field="title"
                    data-value-field="id"
                    v-model="filters.status_order"
                  />
                  <bita-select
                    name="status"
                    label="وضعیت پرداخت"
                    ref="statusDropDown"
                    rules="required"
                    validation-container-class="col-lg-2"
                    input-class="form-control"
                    :data-source="statusesPayment"
                    data-text-field="title"
                    data-value-field="id"
                    v-model="filters.payment_status"
                  />
                  <bita-datepicker
                    type="datetime"
                    id="paymentat"
                    validation-container-class="col-lg-2"
                    name="start_at"
                    rules=""
                    label="شروع تاریخ ایجاد"
                    inputClass="form-control"
                    v-model="filters.start_at"
                    format="YYYY-MM-DD HH:mm:ss"
                    display-format="jYYYY/jMM/jDD HH:mm:ss"
                  />
                  <bita-datepicker
                    type="datetime"
                    id="paymentat"
                    validation-container-class="col-lg-2"
                    name="end_at"
                    rules=""
                    label="پایان تاریخ ایجاد"
                    inputClass="form-control"
                    v-model="filters.end_at"
                    format="YYYY-MM-DD HH:mm:ss"
                    display-format="jYYYY/jMM/jDD HH:mm:ss"
                  />
                </more-filter>
              </div>
            </div>
          </bita-form>
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :hasRow="false"
            :grid-server-side="true"
            :schema-total="'total'"
            :schema-data="'data'"
            :has-row-number="true"
            :server-paging="true"
            :batch="true"
            v-if="$acl.can('view', 'orders')"
          />
        </div>
      </bita-ibox>
    </div>
    <form-modal
      ref="editModal"
      :isLoading="isLoadingModal"
      modal-id="edit-modal"
      title="ویرایش درخواست"
      @on-submit="handleEdit"
      @on-update="handleUpdatePaymentStatus"
    />
  </div>
</template>
<script>
import RequestsForm from "./components/OrderForm";
import { orderApi } from "@/api/apiManagement";
import MoreFilter from "@/components/MoreFilter.vue";
import Tools from '../../../components/Tools.vue';

export default {
  components: { "form-modal": RequestsForm, MoreFilter,Tools },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت سفارش‌ها",
          link: "/shop/orders",
          active: true,
        },
      ],
      filters: {},
      isLoadingGrid: false,
      isLoadingModal: false,
      showForm: false,

      createForm: {},
      pageSize: 30,
      currentPage: 1,
      statusOrder: [],
      statusesPayment: [
        { id: 0, title: "پرداخت نشده" },
        { id: 1, title: "پرداخت شده" },
      ],
      updateForm: {},
    };
  },
  computed: {
    gridColumns() {
      return [
        {
          field: "tracking_code",
          title: "کد پیگیری",
          template: (e) => (e.tracking_code ? e.tracking_code : " - "),
        },
        {
          field: "user",
          title: "نام شخص",
          width: "150px",
          template: (e) => (e.full_name ? e.full_name : " - "),
        },
        {
          field: "payable",
          title: "قابل پرداخت",
          template: (e) => (e.total ? this.$thousandSeprator(e.payable) : " - "),
        },
        {
          field: "payed",
          title: "پرداخت شده",
          width: "120px",
          template: (e) => (e.payed ? this.$thousandSeprator(e.payed) : " - "),
        },
        {
          field: "payable",
          title: "مانده",
          template: (e) => (e.payable ? this.$thousandSeprator(e.payable - e.payed) : " - "),
        },
        {
          field: "status_text",
          title: "وضعیت",
          template: (e) => (e.status_text ? e.status_text : " - "),
        },
        {
          field: "status_text",
          title: "وضعیت پرداخت",
          width: "120px",
          template: (e) => this.paymentTemplate(e),
        },
        {
          field: "paymethod",
          title: "شیوه پرداخت",
          width: "120px",
          template: (e) => (e.paymethod ? e.paymethod.title : ""),
        },
        {
          field: "ins_date",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          width: "100px",
          command: this.gridCommands(),
        },
      ];
    }
  },
  mounted() {
    this.loadData();
    this.getStatuses();
    // this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    resetFilters() {
      this.$refs.filterForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
      this.filters = {};
    },
    getStatuses() {
      orderApi.getStatuses().then(({ data }) => (this.statusOrder = data));
    },
    paymentTemplate(e) {
      if (!e) return "-";
      return Number(e.payment_status) == 1
        ? `<i style="color: green" class="fas fa-check-circle"></i>`
        : `<i style="color: red" class="fas fa-exclamation-circle"></i>

`;
    },
    readData(e) {
      this.pageSize = e.data.pageSize;
      this.currentPage = e.data.page;

      this.$resetGrid();
      this.isLoadingGrid = true;
      let filters = {};

      Object.entries(this.filters).forEach(([key, value]) => {
        if (value) filters[key] = value;
      });

      orderApi
        .getOrders({
          page: this.currentPage,
          page_size: this.pageSize,
          ...filters,
        })
        .then((res) => {
          e.success(res.data);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    statusChangeModalEdit() {
      $("#edit-modal")
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("toggle");
    },
    gridCommands() {
      let commands = [];
      
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      if (this.$acl.can("delete", "orders"))
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    edit(e) {
      let request = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.editModal.setInitialData(request)
      this.statusChangeModalEdit();
    },
    delete(e) {
      let order = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف درخواست شماره ${order.tracking_code} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          orderApi
            .deleteOrder(order.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق");
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    view(e) {
      let request = this.$refs.grid.getSelectedGridRow(e);

      this.$router.push(`/management/request/${request.id}/detail`);
    },

    updateOrder(data) {
      this.isLoadingModal = true;

      orderApi
        .updateOrder(data.id, data.form_data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق");

          this.updateForm = {
            name: "",
            parent_id: "",
          };

          this.loadData();
          this.$refs.editModal.reset();

          this.statusChangeModalEdit();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    handleEdit(_data) {
      this.$alert("question", `از انجام عملیات بروزرسانی اطمینان دارید؟`, "", {
        confirmButtonText: "تایید",
      }).then((result) => {
        if (result.value) {
          this.updateOrder(_data);
        }
      });
    },
    handleUpdatePaymentStatus(e) {
      this.isLoadingModal = true;

      this.$alert("question", `از تغییر وضعیت این سفارش به پرداخت موفق مطمئن هستید ؟`, "", {
        confirmButtonText: "تایید",
      }).then((result) => {
        if (result.value) {
          orderApi
            .updateOrderPaymentStatus(e.id, e)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق");
              this.loadData();
              this.statusChangeModalEdit();
            })
            .finally(() => {
              this.isLoadingModal = false;
            });
        }
      });

    }
  },
};
</script>
<style scoped>
a.margin-top {
  margin-top: 27px;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
</style>
