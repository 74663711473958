<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <tools excel="management/newsletters/export/excel" />
          <hr />
          <bita-grid
            ref="grid"
            :auto-bind="false"
            :columns="gridColumns"
            :read-data="readData"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { newsletterUserApi } from "@/api/apiManagement.js";
import Tools from '../../../components/Tools.vue';

export default {
  components: { Tools },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت خبرنامه ها",
          link: "/shop/newsletters",
          active: true,
        },
      ],

      isLoadingGrid: false,
      gridColumns: [
        {
          field: "full_name",
          title: "نام کامل",
          template: ({ full_name }) => (full_name ? full_name : " - "),
        },
        {
          field: "email",
          title: "ایمیل",
          template: ({ email }) => (email ? email : " - "),
        },
        // {
        //   field: "mobile",
        //   title: "موبایل",
        //   template: ({ mobile }) => (mobile ? mobile : " - "),
        // },
        {
          field: "is_active",
          title: "وضعیت",
          template: this.activeRender,
        },
        {
          title: "عملیات",
          command: [],
        },
      ],
    };
  },
  computed: {
    title() {
      let title = "افزودن";
      if (this.updateForm.id) title = "ویرایش";
      return title + " برند ";
    },
  },
  mounted() {
    this.loadData();
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    successResponse(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.loadData();
    },
    activeRender(e) {
      let view = e.is_active == 1 ? "fas fa-lock-open" : "fas fa-lock";
      let color = e.is_active == 1 ? "green" : "red";
      return `<span class="${view}" style="color:${color}"></span>`;
    },
    toggleStatus(e) {
      this.isLoadingGrid = true;
      let data = this.$refs.grid.getSelectedGridRow(e);

      newsletterUserApi
        .toggleUserStatuser(data.id)
        .then((res) => {
          this.successResponse(res.data.message);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;

      newsletterUserApi
        .getUsers()
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);

      this.$alert("question", `آیا از حذف ${data.email} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          newsletterUserApi
            .delete(data.id)
            .then((res) => {
              this.successResponse(res.data.message);
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];

      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });

      commands.push({
        text: "view",
        click: this.toggleStatus,
        template: this.gridButtons.view,
      });
      return commands;
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
