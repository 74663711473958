<template>
  <div :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="form-group">
        <label :for="id"
          >{{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i></div
        ></label>
        <bita-spinner
          v-if="isLoading"
          :is-loading="isLoading"
          styleName="align-self: center;"
        />
        <multi-select
          v-if="!isLoading"
          :id="inputID"
          :name="name"
          :ref="name"
          :type="type"
          :placeholder="placeHolder"
          v-model="content"
          :disabled="disabled"
          :data-source="dataSource"
          :data-text-field="dataTextField"
          :data-value-field="dataValueField"
          :change="handleInput(content)"
          :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
        />
      </div>
      <span class="invalid-feedback">{{ errors[0] }}</span>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";

import {
  MultiSelect,
  DropdownsInstaller,
} from "@progress/kendo-dropdowns-vue-wrapper";
import "@progress/kendo-ui/js/kendo.multiselect";
export default {
  extends: InputCommons,
  components: { MultiSelect },
  use: { DropdownsInstaller },
    data() {
    return {
      contentTemp: [],
    };
  },
  methods: {
    emptyKendo() {
      var test = this.$refs[this.name].kendoWidget();
      test.value("");
    },
  },
};
</script>
<style>
.k-multiselect.form-control,
.form-control.k-dropdowntree {
  height: auto;
  min-height: 37px !important;
}

.k-multiselect .k-multiselect-wrap .k-input,
.k-dropdowntree .k-multiselect-wrap .k-input {
  height: calc(11px + 1.4285714286em) !important;
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-dropdowntree .k-multiselect-wrap .k-button {
  min-height: calc(1.4285714286em + 10px) !important;
}
</style>
