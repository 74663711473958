import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/shippings"

export default {

    getShippings(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    createShippings(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateShippings(shippingID, data) {
        return apiCall('post', `${PREFIX}/${shippingID}/update`, data)
    },
    

    deleteShippings(shippingID) {
        return apiCall('delete', `${PREFIX}/${shippingID}`)
    },

    createShippingPrices(shippingID,data) {
        return apiCall('post', `${PREFIX}/${shippingID}/shipping-prices`, data)
    },

    updateShippingPrices(shippingID, shippingPriceID, data) {
        return apiCall('patch', `${PREFIX}/${shippingID}/shipping-prices/${shippingPriceID}`, data)
    },

    deleteShippingPrices(shippingID, shippingPriceID) {
        return apiCall('delete', `${PREFIX}/${shippingID}/shipping-prices/${shippingPriceID}`)
    },
    getProductFeedbackField() {
        return apiCall('get', `marketplace/seller/feedback/fields`)

    },
    deleteFeedBack(feedbackId) {
        return apiCall('delete', `marketplace/seller/product/feedback/${feedbackId}/delete`)

    }
}