import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/promotions"

export default {

    getPromotions(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    createPromotion(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updatePromotion(promotionID, data) {
        return apiCall('patch', `${PREFIX}/${promotionID}`, data)
    },

    deletePromotion(promotionID) {
        return apiCall('delete', `${PREFIX}/${promotionID}`)
    }
}