import { apiCall } from "./../imports"

export default {

    getSlides() {
        return apiCall('get', "management/slides")
    },

    createSlide(data) {
        return apiCall('post', `management/slides`, data)
    },

    updateSlide(slideId, data) {
        return apiCall('post', `management/slides/${slideId}/update`, data)
    },

    deleteSlide(slideId) {
        return apiCall('delete', `management/slides/${slideId}`)
    }
}