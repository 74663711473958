import { apiCall, createQueryString } from "../imports"

const PREFIX = "management/faq"

export default {

    getFaq(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`);
    },

    updateFaq(faqId,data) {
        return apiCall('patch', `${PREFIX}/${faqId}`, data);
    },

    createFaq(data) {
        return apiCall('post', `${PREFIX}`, data);
    },

    deleteFaq(faqId) {
        return apiCall('delete', `${PREFIX}/${faqId}`);
    },
}