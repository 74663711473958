<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            type="primary"
            title="استاندارد جدید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="create"
          />
          <hr />
          <bita-form ref="filterForm">
            <div slot="body">
              <!-- <div class="row">
                <bita-input
                  type="text"
                  name="name"
                  label="جستجو"
                  validation-container-class="col-lg-4"
                  input-class="form-control"
                  v-model.lazy="filters.search"
                  @keyup.enter="loadData"
                />
                <div class="col-lg-4">
                  <a class="margin-top btn btn-success" @click="loadData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>
              </div> -->
            </div>
          </bita-form>
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <standard-form ref="standardForm" @load-data="loadData" />
  </div>
</template>
<script>
import { standardApi } from "@/api/apiManagement.js";
import standardForm from "./components/StandardForm";

export default {
  components: { standardForm },
  data() {
    return {
      pageSize: 10,
      filters: {},
      currentPage: 1,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت استانداردها",
          link: "/shop/standards",
          active: true,
        },
      ],
      isLoadingGrid: false,
      title: "",
    };
  },
  computed: {
    gridColumns() {
      return [
        { title: "نام", template: e => ( e.name ? e.name : '-' ) },
        // { title: "توضیحات", template: e => (e.description ? e.description : '-' ) },
        { title: "عملیات", command: this.gridCommands() }
      ]
    }
  },
  methods: {
    readData(e) {
      this.isLoadingGrid = true;
      standardApi.get(this.filters)
        .then ( (res) => {
          e.success(res.data.entity);
        })
        .catch( () => e.success([]) )
        .finally( () => this.isLoadingGrid = false );
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];

      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    edit(e) {
      this.isLoadingGrid = true;
      let standard = this.$refs.grid.getSelectedGridRow(e);
      this.$refs.standardForm.setInitialData(standard);
      this.$toggleModal("#standard-form");
      this.isLoadingGrid = false;
    },
    delete(e) {
      let standard = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف ${standard.name} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          standardApi
            .delete(standard.id)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    create() {
      this.isLoadingGrid = true;
      this.title = "افزودن استاندارد";
      this.$toggleModal("#standard-form");
      this.$refs.standardForm.setInitialData({});
      this.isLoadingGrid = false;
    },
  }
}
</script>