<template>
  <div :class="`${btnWidth}`">
    <span>
      <div class="form-group">
        <label style="opacity: 0">-</label>
        <button
          :class="`btn form-control ${buttonType}`"
          type="button"
          @click="$emit('click')"
        >
          {{ title }}
        </button>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    btnWidth: {
      type: String,
      default: "col-lg-1",
    },
    buttonType: {
      type: String,
      default: "btn-primary",
    },
  },
};
</script>
