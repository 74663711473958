<template>
  <div
    :class="[`btn btn-w-m btn-${type}`, { disabled: disabled }]"
    :style="`${btnWidth ? `width: ${btnWidth}; margin: 0 auto` : ''}`"
  >
    <i :class="icon" style="margin-left: 1px"></i>
    {{ title }}
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    btnWidth: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
