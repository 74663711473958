<template>
  <div class="seller-transaction-table">
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <span class="d-none d-md-block">
            <tools excel='marketplace/seller/transaction/export/excel' />

            <div class="row">
              <div class="col-12">
                <div class="float-left">
                  <bita-bootbutton
                    title="ثبت جریمه / مرجوعی"
                    type="primary"
                    icon="fas fa-plus-square"
                    btn-width="200px"
                    @click.native="Registrationfines"
                    v-if="$acl.can('create', 'finance-penalty-of-seller')"
                  />
                </div>
                <div class="float-right">
                  <bita-bootbutton
                    title="ثبت پرداخت"
                    type="warning"
                    icon="fas fa-plus-square"
                    btn-width="200px"
                    @click.native="registrationPayment"
                    v-if="$acl.can('create', 'payment-of-seller')"
                  />
                </div>
              </div>
            </div>
            <hr />
          </span>
          <bita-form ref="filterForm">
            <div slot="body">
              <div class="row">
                <bita-select
                  label=" انتخاب تامین کننده"
                  name="seller_id"
                  ref="sellerSelect"
                  validation-container-class="col-lg-3"
                  rules="required"
                  input-class="form-control"
                  :data-source="supplires"
                  data-text-field="seller_fullname"
                  data-value-field="id"
                  v-model="filters.seller_id"
                  v-if="$acl.can('view', 'sellers')"
                />
                <bita-datepicker
                  type="datetime"
                  format="YYYY/MM/DD HH:mm:ss"
                  id="date_start_at"
                  validation-container-class="col-lg-3"
                  name="start_at"
                  rules=""
                  label="شروع تاریخ "
                  inputClass="form-control"
                  v-model="filters.startDate"
                />
                <bita-datepicker
                  type="datetime"
                  format="YYYY/MM/DD HH:mm:ss"
                  id="date_end_at"
                  validation-container-class="col-lg-3"
                  name="end_at"
                  rules=""
                  label=" تاریخ پایان"
                  inputClass="form-control"
                  v-model="filters.endDate"
                />
                <div class="col-lg-3">
                  <a class="margin-top btn btn-success" @click="readData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>
              </div>
            </div>
          </bita-form>

          <table
            id="transaction"
            class="table table-bordered"
            style="    display: block;
            height: 519px;
            overflow-y: scroll;
            width: 100%;
            border-collapse: separate;"
          >
            <thead>
              <tr>
                <th style="width: 3%;">#</th>
                <th style="width: 10%;">شماره تراکنش</th>
                <th style="width: 25%;">شرح</th>
                <th style="width: 5%;">نوع تراکنش</th>
                <th style="width: 10%;">تاریخ</th>
                <th style="width: 15%;">بدهکار</th>
                <th style="width: 15%;">بستانکار</th>
                <!-- <th style="width: 15%;">مانده حساب</th> -->
                <th v-if="$acl.can('delete', 'finance-transaction-of-seller')">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in transactions.records">
                <td>{{ index + 1 }}</td>
                <td>
                  <span class="badge badge-info">{{
                    transaction.tracking_code
                  }}</span>
                </td>
                <td>{{ transaction.description }}</td>
                <td>
                  <span class="badge badge-info" v-if="transaction.type == 1"
                    >سفارش</span
                  ><span class="badge badge-info" v-if="transaction.type == 2"
                    >پرداخت</span
                  ><span class="badge badge-danger" v-if="transaction.type == 3"
                    >جریمه
                  </span>
                  <span class="badge badge-warning" v-if="transaction.type == 4"
                    >انتقال مانده</span
                  >
                  <span class="badge badge-warning" v-if="transaction.type == 5"
                    >مرجوعی کالا</span
                  >
                </td>
                <td>{{ $getJalaliDateTime(transaction.created_at) }}</td>
                <!-- <td>{{transaction.seller_finance_transaction}}</td> -->
                <td>
                  <p v-if="transaction.price < 0">
                    {{$thousandSeprator(Math.abs( parseInt(transaction.price))) }}
                  </p>
                </td>

                <td>
                  <p v-if="transaction.price > 0">
                    {{ $thousandSeprator(Math.abs(parseInt(transaction.price))) }}
                  </p>
                </td>
                <!-- <td>mande</td> -->
                <td v-if="$acl.can('delete', 'finance-transaction-of-seller')">
                  <bita-bootbutton
                    type="danger"
                    icon="fas fa-times"
                    btn-width="25px"
                    style="min-width: 35px !important;"
                    @click.native="deleteTransaction(transaction)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="table table-bordered"
            style="font-weight: bold;
            font-size: 15px;"
          >
            <thead>
              <tr>
                <th>خلاصه گزارش</th>
                <th>بدهکار</th>
                <th>بستانکار</th>
                <th>مانده</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align: center">
                  تراز گزارش گرفته شده
                </td>
                <td>
                  {{$thousandSeprator(Math.abs( transactions.summuryOfReport.totalYearDebtor)) }}
                </td>
                <td>
                  {{$thousandSeprator(Math.abs( transactions.summuryOfReport.totalYearCreditor)) }}
                </td>
                <td>
                  {{$thousandSeprator(Math.abs( transactions.summuryOfReport.totalYearBalance)) }}
                  <span v-if="transactions.summuryOfReport.totalYearBalance > 0">(بس)</span>
                  <span v-if="transactions.summuryOfReport.totalYearBalance < 0">(بد)</span>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;vertical-align:inherit">
                  مانده حساب کل فروش
                </td>

                <td>
                  {{ $thousandSeprator(Math.abs(transactions.summuryOfReport.totalDebtor)) }}
                </td>
                <td>
                  {{ $thousandSeprator(Math.abs(transactions.summuryOfReport.totalCreditor)) }}
                </td>

                <td rowspan="5">
                  {{ $thousandSeprator(Math.abs(transactions.summuryOfReport.totalBalance)) }}
                  <span v-if="transactions.summuryOfReport.totalBalance > 0">(بس)</span>
                  <span v-if="transactions.summuryOfReport.totalBalance < 0">(بد)</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </bita-ibox>
    </div>
    <fines-modal ref="form" :is-loading="isLoadingModal" @on-create="save" />
    <payments-modal :is-loading="isLoadingModal" @on-create="savePayments" />
  </div>
</template>
<script>
import MoreFilter from "@/components/MoreFilter.vue";
import { supplireApi } from "@/api/apiManagement";
import FinesModal from "./components/FinesModal.vue";
import PaymentsModal from "./components/PaymentsModal.vue";
import Tools from "../../../components/Tools.vue";

export default {
  components: {
    PaymentsModal,
    FinesModal,
    Tools,
  },
  data() {
    return {
      filters: {},

      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "لیست تراکنش ها", link: "/shop/transactions" },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      pageSize: 30,
      currentPage: 1,
      supplires: [],
      transactions: [],
    };
  },
  computed: {},

  mounted() {
    this.readData();
    if (this.$acl.can("view", "sellers")) {
      this.getActiveSupplire();
    }
  },
  methods: {
    resetFilters() {
      this.$refs.filterForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
      this.filters = {};
    },
    Registrationfines() {
      this.$toggleModal("#fines-modal");
    },
    registrationPayment() {
      this.$toggleModal("#payments-modal");
    },

    readData() {
      this.isLoadingGrid = true;

      supplireApi
        .getTransactions({
          ...this.filters,
        })
        .then((res) => {
          this.transactions = res.data.entity;
        })
        .catch((res) => {})
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    save() {
      if (result) {
        this.isLoading = true;
        supplireApi
          .savePenaltyReward(this.formData)
          .then(() => {
            this.readData();
            this.formSucceeded(res.data.message);
            this.toggleModal();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    savePayments(payments) {
      this.isLoading = true;
      supplireApi
        .savePayments(payments)
        .then(() => {
          this.formpaymentSucceeded();
          this.readData();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    save(appIcon) {
      this.isLoadingModal = true;
      appIcon = this.getFormData(appIcon);

      supplireApi
        .savePenaltyReward(appIcon)
        .then((res) => {
          this.readData();
          this.formSucceeded(res.data.message);
          this.$toggleModal("#fines-modal");
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    formSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$refs.form.reset();
      this.toggleModal();
      this.loadData();
    },
    formpaymentSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$refs.form.reset();
      this.togglePaymentModal();
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    toggleModal() {
      this.$toggleModal("#fines-modal");
    },
    togglePaymentModal() {
      this.$toggleModal("#payments-modal");
    },

    getActiveSupplire() {
      supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },

    deleteTransaction(tr) {
      let transaction = tr;
      this.$alert(
        "question",
        `آیا از حذف درخواست شماره ${transaction.id} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          supplireApi
            .deleteTransactions(transaction.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق");
              this.readData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
  },
};
</script>
<style scope>
#transaction {
  text-align: center;
}
#transaction tbody {
  width: 100%;
  height: 100px;
  overflow: scroll;
}
</style>
