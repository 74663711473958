import Vue from "vue";

Vue.filter("jalaliDate", dateTime => {
    if (!dateTime) return "";
    return moment(dateTime, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jMM/jDD");
});
Vue.filter("jalaliDateTime", dateTime => {
    if (!dateTime) return "";
    return moment(dateTime, "YYYY-MM-DD HH:mm:ss").format(
        "jYYYY/jMM/jDD HH:mm:ss"
    );
});
Vue.filter("jalaliDateShort", dateTime => {
    if (!dateTime) return "";
    return moment(dateTime, "YYYY-MM-DD HH:mm:ss").format(
        "jMM/jDD"
    );
});
Vue.filter("timeTohhmm", time => {
    if (!time) return "";
    if (time == "24:00:00") return "24:00";
    return moment(time, "HH:mm:ss.SSS").format("HH:mm");
});

Vue.filter("minsToHHmm", mins => {
    if (!mins) return "";
    return mins == 1440 ?
        "24:00" :
        moment
        .utc()
        .startOf("day")
        .add(mins, "minutes")
        .format("HH:mm");
});

Vue.filter("fullName", user => {
    if (!user) return "";
    return `${user.name?user.name:''} ${user.family?user.family:''}`;
});
Vue.filter("reverseFullName", user => {
    if (!user) return "";
    return user.family + " " + user.name;
});
Vue.filter("getGroupName", groupID => {
    if (!groupID) return "";
    switch (Number(groupID)) {
        case 1:
            return "مجتمع";
        case 2:
            return "آسفالت طوس";
        case 3:
            return "آسفالت طوس";
        case 4:
            return "آپادانا";
        case 5:
            return "بهاوند";
        default:
            return groupID;
    }
});
Vue.filter("numberFormat", number => {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
});

Vue.filter("excpert", (value,maxLength) => {
    if(value.length<maxLength)
        return value;
    return value.substring(0,maxLength) + ' ...'
});

Vue.filter("phoneFormat", phone => {
    if(!phone)return "";
    return phone.replace(/^(?=[0-9]{11})([0-9]{4})([0-9]{3})([0-9]{4})$/, "$1-$2-$3");
});