<template>
  <div :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="form-group k-rtl">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i>
          </div>
        </label>
        <div>
          <bita-spinner
            v-if="isLoading"
            :is-loading="isLoading"
            styleName="align-self: center;"
          />
          <div class="typeahead-container">
            <ul class="tag-lists">
              <li v-for="(tag, index) in content" :key="index">
                <span @click="deleteItem(index)" class="remove-tag"
                  ><i class="fas fa-times"></i></span
                >{{ tag }}
              </li>
            </ul>
            <input
              class="form-control"
              style="width: 100%"
              v-if="!isLoading"
              :id="inputID"
              autocomplete="off"
              :name="name"
              :ref="name"
              v-model="newTag"
              @input="loadSuggestions"
              @keydown.enter="selectItem(newTag)"
              @keydown.esc="openResult = false"
            />
            <ul class="dropdown-list" v-show="openResult">
              <li
                v-for="(suggestion, index) in dataSource"
                :key="index"
                @click="selectItem(suggestion)"
              >
                <a>{{ suggestion }}</a>
              </li>
            </ul>
          </div>
        </div>
        <span v-if="hasSubtitle" class="form-text m-b-none">{{
          subtitle
        }}</span>
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import _ from "lodash";
export default {
  extends: InputCommons,
  data() {
    return {
      openResult: false,
      newTag: "",
    };
  },
  methods: {
    loadSuggestions() {
      var vm = this;
      this.debounceLoadSuggestion(vm);
    },
    debounceLoadSuggestion: _.debounce((vm) => {
      console.log(213123,vm.newTag)
      if (vm.newTag) {
        vm.openResult = true;
        vm.$emit("on-search", vm.newTag);
      } else vm.openResult = false;
    }, 500),
    selectItem(selected) {
      if (selected) {
        this.openResult = false;
        if (this.content == "") this.content = [];
        this.content.push(selected);
        this.handleInput(this.content);
        this.newTag = "";
        this.$emit("item-selected", selected);
        this.$emit("items", this.content);
      }
    },
    deleteItem(index) {
      this.$delete(this.content, index);
      this.handleInput(this.content);
      this.$emit("items", this.content);
    },
  },
};
</script>
<style scoped>
.typeahead-container > ul {
  padding: 0;
}

.typeahead-container ul.tag-lists li {
  list-style: none;
  padding: 5px 8px;
  float: right;
  background: #1ab394;
  margin: 3px;
  border-radius: 10px;
  position: relative;
}
span.remove-tag {
  position: absolute;
  background: red;
  border-radius: 120px;
  padding: 0px 3px;
  line-height: 10px;
  right: -6px;
  top: -4px;
  color: #fff;
}
ul.dropdown-list {
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  padding: 0;
  position: absolute;
  width: 87%;
  background: #fff;
}
li {
  list-style: none;
  padding: 5px 12px;
}
li a {
  color: #585858;
}
li:hover {
  background: #adadad;
}
</style>

