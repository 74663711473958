<template>
  <div id="post-form">
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoading">
        <div slot="body">
          <bita-form ref="postsForm">
            <div slot="body">
              <div class="row">
                <div class="col-lg-12">
                  <bita-formgroup title="اطلاعات" class="row">
                    <bita-input
                      type="text"
                      name="title"
                      label="عنوان"
                      rules="required"
                      :validation-container-class="colWidthHalf"
                      input-class="form-control"
                      v-model="form.title"
                    />
                    <bita-treeselect
                      name="category_id"
                      ref="categoryTreeselect"
                      label="گروه"
                      rules="required"
                      :validation-container-class="colWidthHalf"
                      input-class="form-control"
                      :data-source="categories"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.category_id"
                    />
                    <bita-textarea
                      type="text"
                      name="introduction"
                      label="مقدمه"
                      :validation-container-class="colWidth"
                      input-class="form-control"
                      v-model="form.introduction"
                      rowspan="5"
                    />
                    <bita-texteditor
                      type="text"
                      name="content"
                      ref="content"
                      label="توضیحات"
                      :validation-container-class="colWidth"
                      input-class="form-control"
                      v-model="form.content"
                    />
                  </bita-formgroup>
                  <bita-formgroup title="تاریخ" class="row">
                    <bita-datepicker
                      type="datetime"
                      id="paymentat"
                      :validation-container-class="colWidthforth"
                      name="start_at"
                      label="تاریخ نوشته"
                      inputClass="form-control"
                      v-model="form.date"
                      format="YYYY-MM-DD HH:mm:ss"
                      display-format="jYYYY/jMM/jDD HH:mm:ss"
                    />
                    <bita-datepicker
                      type="datetime"
                      id="paymentat"
                      :validation-container-class="colWidthforth"
                      name="start_at"
                      label="تاریخ انتشار"
                      inputClass="form-control"
                      v-model="form.publish_at"
                      format="YYYY-MM-DD HH:mm:ss"
                      display-format="jYYYY/jMM/jDD HH:mm:ss"
                    />
                    <bita-datepicker
                      type="datetime"
                      id="paymentat"
                      :validation-container-class="colWidthforth"
                      name="start_at"
                      label="تاریخ انقضا"
                      inputClass="form-control"
                      v-model="form.expire_at"
                      format="YYYY-MM-DD HH:mm:ss"
                      display-format="jYYYY/jMM/jDD HH:mm:ss"
                    />
                  </bita-formgroup>
                  <bita-formgroup title="بیشتر" class="row">
                    <bita-input
                      type="text"
                      name="source"
                      label="منبع"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      v-model="form.source"
                    />
                    <bita-select
                      name="type"
                      ref="categories"
                      label="وضعیت"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      :data-source="[
                        { id: 1, name: 'فعال' },
                        { id: 2, name: 'غیرفعال' },
                      ]"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.active"
                    />
                    <bita-select
                      name="is_special"
                      ref="is_special"
                      label="ویژه"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      :data-source="[
                        { id: 1, name: 'بله' },
                        { id: 2, name: 'خیر' },
                      ]"
                      data-text-field="name"
                      data-value-field="id"
                      v-model="form.is_special"
                    />
                    <bita-uploader
                      name="thumbnail"
                      label="تصویر"
                      ref="uploader"
                      rules="size:50"
                      :validation-container-class="colWidthThird"
                      input-class="form-control"
                      v-model="form.thumbnail"
                      :icon-url="form.thumbnail_url"
                    />
                    <bita-typeahead
                      type="text"
                      name="name"
                      label="کلمه های کلیدی"
                      :validation-container-class="colWidthforth"
                      input-class="form-control"
                      :data-source="matches"
                      v-model="form.tags"
                      @on-search="(e) => loadDataSource(e)"
                    />
                  </bita-formgroup>
                  <div class="row">
                    <div class="col-lg-12">
                      <bita-bootbutton
                        v-if="!form.id"
                        type="primary"
                        title="ذخیره"
                        icon="fas fa-save"
                        btn-width="150px"
                        @click.native="save"
                      />
                      <bita-bootbutton
                        v-else
                        title="بروزرسانی"
                        type="primary"
                        icon="fas fa-save"
                        btn-width="150px"
                        @click.native="update"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </bita-form>
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { postCategoryApi, postApi, tagPostApi } from "@/api/apiManagement";

export default {
  data() {
    return {
      isLoading: false,
      priceTitle: "",
      colWidth: "col-lg-12",
      colWidthHalf: "col-lg-6",
      colWidthThird: "col-lg-3",
      colWidthforth: "col-lg-4",
      form: {},
      categories: [],
      matches: [],
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت نوشته ها",
          link: "/shop/posts",
          active: true,
        },
        {
          title: "افزودن نوشته",
          link: "",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    loadDataSource(tag) {
      tagPostApi.findTags({ tag: tag }).then((res) => {
        this.matches = collect(res.data.entity)
          .pluck("name.fa")
          .toArray();
      });
    },
    getForm(id) {
      let postId = this.$route.params.id;
      postApi.findPost(postId).then(({ data }) => {
        data.entity.category_id = String(data.entity.category_id);
        this.form = data.entity;

        this.form.tags = collect(this.form.tags)
          .pluck("name.fa")
          .toArray();

        this.$refs.content.setInitialValue(this.form.content);
      });
    },
    getCategories() {
      this.isLoading = true;
      return postCategoryApi
        .getCategories({})
        .then(({ data }) => {
          this.categories = data.entity;
        })
        .finally(() => {
          this.isLoading = false;
          let id = this.$route.params.id;
          if (id) this.getForm(id);
        });
    },
    resetForm() {
      this.$refs.postsForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
      this.form = {};
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    successForm() {
      this.resetForm();
      this.$router.push({ path: "/blog/posts" });
    },
    save() {
      this.$refs.postsForm.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          postApi
            .createPost(this.getFormData(this.form))
            .then(({ data }) => {
              this.successForm();
            })
            .catch((err) => {
              this.$refs.postsForm.setErrors(err.response.data.errors);
            })
            .finally(() => (this.isLoading = false));
        }
      });
    },
    setErrors(_errors) {
      this.$refs.postsForm.setErrors(_errors);
    },
    update() {
      this.$refs.postsForm.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          postApi
            .updatePost(this.form.id, this.getFormData(this.form))
            .then(({ data }) => {
              this.successForm();
            })
            .catch((err) => {
              this.$refs.form.setErrors(err.response.data.errors);
            })
            .finally(() => (this.isLoading = false));
        }
      });
    },
  },
};
</script>
<style>
#post-form #introduction {
  height: 82px;
}
</style>
