<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>مشخصات پروفایل</h5>
    </div>
    <div class="ibox-content no-padding border-left-right">
      <div class="ibox-content profile-content mt-2 pb-4">
        <bita-spinner
            v-show="isLoading"
            :is-loading="isLoading"
            styleName="align-self: center;"
        />
        <bita-form ref="form">
          <div slot="body">
            <div class="row" v-show="!isLoading">
              <bita-input
                  type="text"
                  name="name"
                  label="نام"
                  v-model="userData.name"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :disabled="statusInput"
              />
              <bita-input
                  type="text"
                  name="family"
                  label="نام خانوادگی"
                  v-model="userData.family"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :disabled="statusInput"
              />
              <bita-input
                  type="text"
                  name="username"
                  label="نام کاربری"
                  v-model="userData.username"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :disabled="statusInput"
              />
              <bita-input
                  type="text"
                  name="email"
                  label="آدرس ایمیل"
                  rules="email"
                  v-model="userData.email"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :disabled="statusInput"
              />
              <bita-input
                  type="text"
                  name="mobile"
                  rules="mobile"
                  label="شماره همراه"
                  v-model="userData.mobile"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :disabled="statusInput"
              />
              <bita-input
                  type="text"
                  name="ssn"
                  label="کد ملی"
                  rules="irani"
                  v-model="userData.ssn"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :disabled="statusInput"
              />
            </div>
          </div>
        </bita-form>
        <div class="mt-4 h5 text-center text-danger" v-show="!isLoading" v-if="!canEdit($user)">
          برای تغیر اطلاعات کاربری با پشتیبانی تماس بگیرید
        </div>
        <div class="row mt-4" v-else v-show="!isLoading">
          <div v-if="statusInput">
            <bita-bootbutton
                title="ویرایش اطلاعات"
                type="primary"
                icon="fas fa-edit"
                btn-width="200px"
                class="mr-3 mt-2"
                @click.native="chnageStatusInput(!statusInput)"
            />
          </div>
          <div v-if="!statusInput">
            <bita-bootbutton
                v-if="!isLoadingUpdate"
                title="ویرایش"
                type="primary"
                btn-width="200px"
                class="mr-3 mt-2"
                @click.native="updateProfile()"
            />
            <bita-spinner
                v-show="isLoadingUpdate"
                :is-loading="isLoadingUpdate"
                styleName="align-self: center; display: inline-block; width: 200px"
            />
            <bita-bootbutton
                title="انصراف"
                type="warning"
                btn-width="200px"
                class="mr-2 mt-2"
                @click.native="chnageStatusInput(!statusInput)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: Object,
    isLoading: Boolean,
    isLoadingUpdate: Boolean,
    statusInput: Boolean,
  },
  data() {
    return {
      colWidth: "col-lg-4",
      userData: false,
    };
  },
  watch: {
    user: function (user) {
      this.userData = user;
    },
  },
  methods: {
    canEdit(user){

      return user.roles[0].name === `super-admin` || user.roles[0].name === `admin` || user.seller.seller_status_id === 2
    },
    chnageStatusInput(status) {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-change-status-input", status);
        }
      });
    },
    updateProfile() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update-profile", this.userData);
        }
      });
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
