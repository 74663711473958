<template>
  <v-validate-observer ref="gForm">
    <slot name="body" />
  </v-validate-observer>
</template>
<script>
export default {
  methods: {
    validate() {
      return this.$refs.gForm.validate();
    },
    reset() {
      if (this.$refs.gForm && this.$refs.gForm.reset())
        this.$refs.gForm.reset();
    },
    setErrors(_errors) {
      if (_errors) this.$refs.gForm.setErrors(_errors);
      else console.log("Error in form setError");
    },
  },
};
</script>
