<template>
  <bita-modal
    id="product-supplires"
    :is-loading="isLoadingModal"
    :modal-lg="true"
    title="فروشندگان محصول"
    @close="reset()"
  >
    <div slot="body">
      <div class="row">
        <bita-select
          label=" انتخاب  فروشنده"
          name="seller_id"
          ref="sellerSelect"
          :validation-container-class="colWidth"
          rules="required"
          input-class="form-control"
          :data-source="this.supplireForPriority"
          data-text-field="seller_fullname"
          data-value-field="id"
          v-model="formSupplierOrder.seller_id"
        />
        <bita-input
          type="number"
          name="priority"
          label="اولویت نمایش"
          :validation-container-class="colWidth"
          input-class="form-control"
          v-model="formSupplierOrder.priority"
        />
        <!-- <bita-input
          type="text"
          name="performance"
          label="عملکرد"
          :validation-container-class="colWidth"
          input-class="form-control"
          v-model="formSupplierOrder.performance"
        /> -->
        <div slot="footer">
          <bita-bootbutton
            title="بروزرسانی"
            type="primary"
            icon="fas fa-save"
            btn-width="130px"
            style="margin: 25px 5px 5px 5px;"
            @click.native="savePriority()"
            :disabled="isLoadingModal"
          />
          <bita-bootbutton
            title="حذف اولویت ها"
            type="danger"
            icon="fas fa-times"
            btn-width="130px"
            style="margin: 25px 5px 5px 5px;"
            @click.native="removeAllPriority()"
            :disabled="isLoadingModal"
          />
        </div>
      </div>
      <bita-form ref="suppliresForm"> </bita-form>
      <bita-grid
        v-if="showGrid"
        ref="productsupplireGrid"
        :columns="gridColumns"
        :read-data="readData"
        :page-size="pageSize"
        :has-row-number="true"
      />
    </div>
  </bita-modal>
</template>
<script>
import { productApi, supplireApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
  },
  data() {
    return {
      product: [],
      formData: {},
      categories: [],
      colWidth: "col-lg-4",
      colFullWidth: "col-lg-12",
      formSupplierOrder: {},
      myform: {},
      supplire: [],
      supplireForPriority: [],
      products: [],
      filters: {},
      selectValue: 2,
      pageSize: 10,
      isLoadingModal: false,
      showGrid: false,
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
    };
  },
  mounted() {
    this.getProductsAll();
  },
  computed: {
    gridColumns() {
     
      return [
        { field: "name", title: "فروشگاه", template: this.getStoreName },
        {
          field: "name",
          title: "تامین کننده",
          template: this.getSellerFullName,
        },
        { field: "name", title: "اولویت فروش", template: this.getPrority  },
        // { field: "name", title: "عملکرد فروشنده", template: this.getPerformance  },
        { field: "name", title: "وضعیت فروش", template: this.getProductStatus },

        { title: "عملیات", command: this.gridCommands() },
      ];
    },
  },
  methods: {
    savePriority(){
        this.isLoadingModal = true;
          supplireApi
            .savePriorityOfSupplierProduct(this.formSupplierOrder)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.loadData();
            })
            .finally(() => (this.isLoadingModal = false));
    },
    removeAllPriority(){
        this.isLoadingModal = true;
          supplireApi
            .removeAllPriorityOfSupplierProduct(this.formSupplierOrder)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.loadData();
            })
            .finally(() => (this.isLoadingModal = false));
    },
    setInitialData(_data) {
      console.log(_data);
      this.showGrid = true;
      this.product = _data;
      this.formSupplierOrder.product_id=this.product.id;
      console.log(this.product.id);
    },
    getPerformance(e){
      let performance = e.pivot.performance;
      return `${performance}`;
    },
    getSellerFullName(e) {
      let seller = e.seller_fullname;
      return `${seller}`;
    },
    getPrority(e){
      let priority = e.pivot.priority;
      return `${priority}`;
    },
    getStoreName(e) {
      let store = e.store.name;
      return `${store}`;
    },
    getProductStatus(e) {
      let status = e.pivot.active == 1 ? "قابل فروش" : "امکان فروش ندارد";
      return `${status}`;
    },
    readData(e) {
      this.isLoadingModal = true;
      supplireApi
        .getSellerOfProduct(this.product.id)
        .then((res) => {
          e.success(res.data.entity);
          this.supplireForPriority = res.data.entity;
        })
        .catch(() => e.success([]))
        .finally(() => (this.isLoadingModal = false));
    },
    loadData() {
      this.$refs.productsupplireGrid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("change", "sales-status-of-product-for-seller"))
        commands.push({
          text: "saleSatus",
          click: this.changeSaleStatus,
          template: this.gridButtons.saleSatus,
        });

      return commands;
    },
    isPercentage(myform) {
      return myform.value_type == 1;
    },
    changeSaleStatus(e) {
      let supplire = this.$refs.productsupplireGrid.getSelectedGridRow(e);
      let status = supplire.pivot.active == 1 ? "غیر فعال" : "فعال";
      this.$alert(
        "question",
        `ایا امکان فروش این  محصول برای فروشنده ${supplire.seller_fullname} ${status} شود`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingModal = true;
          supplireApi
            .activeOrInactiveProductForSale(this.product.id, supplire.id)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.loadData();
            })
            .finally(() => (this.isLoadingModal = false));
        }
      });
    },
    viewCommissions(e) {
      let supplire = this.$refs.productsupplireGrid.getSelectedGridRow(e);

      // this.formTitle = ` کمسیون محصولات فروشگاه ${supplire.store.name} - صاحب امتیاز ${supplire.seller_fullname}`;
      // this.$refs.commissionForm.setInitialData(supplire);
    },
    getProducts(e) {
      let filter = {
        search: e.filter?.value,
      };
      productApi.getProducts(filter).then((res) => {
        this.products = _.unionBy(res.data, this.products, "id");
      });
    },
    getProductsAll() {
      productApi.getProducts().then((res) => {
        this.products = res.data;
        // this.products = _.unionBy(res.data, this.products, "id")
      });
    },

    submit() {
      this.$refs.suppliresForm.validate().then((res) => {
        if (res) {
          this.isLoadingModal = true;
          productApi
            .postProductRelation(this.product.id, this.formData)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.loadData();
              this.resetSelect();
            })
            .finally(() => (this.isLoadingModal = false));
        }
      });
    },
    resetSelect() {
      this.$refs.products.clear();
      this.$refs.value_type.emptyKendo();
    },
    reset() {
      this.product = {};

      this.showGrid = false;
    },
  },
};
</script>
