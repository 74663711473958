import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route('shop');

RouteRegistrar.add("shop/prints/InvoicePrint", "orders/:id/print", "invoiceprint", "پرینت سفارش")
RouteRegistrar.add("shop/orders/Orders", "orders", "orders", "مدیریت سفارشات")


RouteRegistrar.add("shop/order-statuses/OrderStatuses", "order-statuses", "order-status", "مدیریت وضعیت های سفارش")


RouteRegistrar.add("shop/products/Products", "products", "products", "مدیریت محصولات")
RouteRegistrar.add("shop/products/ProductCreate", "products/create", "productcreation", "ایجاد محصول جدید")


RouteRegistrar.add("shop/packings/Packings", "packings", "packings", "مدیریت بسته بندی")
RouteRegistrar.add("shop/brands/Brands", "brands", "products", "مدیریت برند ها")
RouteRegistrar.add("shop/categories/Categories", "categories", "categories", "مدیریت دسته بندی ها")
RouteRegistrar.add("shop/coupons/Coupons", "coupons", "coupons", "مدیریت کوپن ها")
RouteRegistrar.add("shop/standards/Standard", "standards", "standards", "استانداردها");


RouteRegistrar.add("shop/details/Details", "details", "details", "جزئیات محصولات")
RouteRegistrar.add("shop/details/DetailValues", "details/:detail/values", "detailvalues", "موارد جزئیات", true)

RouteRegistrar.add("shop/promotions/Promotions", "promotions", "promotions", "حراج");

RouteRegistrar.add("shop/shipping/Shipping", "shippings", "shippings", "شیوه ارسال")

RouteRegistrar.add("shop/paymethods/Paymethods", "paymethods", "paymethods", "شیوه ها پرداخت")
RouteRegistrar.add("shop/measures/Measures", "measures", "measures", "واحدهای اندازه گیری")

RouteRegistrar.add("shop/transactions/Transactions", "transactions", "transactions", "لیست تراکنش ها")

RouteRegistrar.add("shop/features/Features", "features", "features", "شاخص قیمت گذاری")
RouteRegistrar.add("shop/features/FeatureValues", "features/:feature/values", "featurevalues", "آیتم های شاخص", true)

RouteRegistrar.add("shop/carts/Carts", "carts", "carts", "سبد خرید کاربران")
RouteRegistrar.add("shop/product-collections/ProductCollection", "product-collections", "product-collections", "مدیریت کالکشن محصولات");
RouteRegistrar.add("shop/middlePages/MiddlePages", "middlePages", "middle-pages", "صفحات واسط");

const routes = RouteRegistrar.allRoutes()


export default routes;
