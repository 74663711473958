<template>
  <div :class="validationContainerClass">
    <v-validate :rules="rules" v-slot="{ errors, classes }" :name="label">
      <div class="form-group">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">*</div>
        </label>
        <kendo-datasource
          :ref="`${inputID}Datasource`"
          :data="dataSource"
        ></kendo-datasource>
        <bita-spinner
          v-if="isLoading"
          :is-loading="isLoading"
          styleName="align-self: center;"
        />
        <multi-select
          v-if="!isLoading"
          :id="inputID"
          :delay="700"
          :name="name"
          :ref="name"
          :type="type"
          :placeholder="placeHolder"
          :auto-bind="false"
          v-model="content"
          :data-source-ref="`${inputID}Datasource`"
          :data-text-field="dataTextField"
          :data-value-field="dataValueField"
          :change="handleInput(content)"
          :filter="'contains'"
          :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
          no-data-template="موردی یافت نشد!"
          @filtering="getData"
        />
      </div>
      <span class="invalid-feedback">{{ errors[0] }}</span>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import "@progress/kendo-theme-default/dist/all.css";

import {
  MultiSelect,
  DropdownsInstaller,
} from "@progress/kendo-dropdowns-vue-wrapper";
import "@progress/kendo-ui/js/kendo.multiselect";
export default {
  extends: InputCommons,
  components: { MultiSelect },
  use: { DropdownsInstaller },
};
</script>
