<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoading">
        <div slot="body">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9 animated fadeInRight">
              <div class="row">
                <div class="col-lg-12">
                  <file-box
                    file-name="تست"
                    created-at="2020-10-10 20:10:10"
                    type="file"
                    image-preview="/img/farshad.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import FileBox from "./components/FileBox";
export default {
  components: { FileBox },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت فایل ها",
          link: "/management/filemanager",
          active: true,
        },
      ],
      isLoading: false,
    };
  },
};
</script>