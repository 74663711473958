<template>
  <div slot="body">
    <bita-form ref="form">
      <div slot="body">
        <div class="row">

          <bita-select
            v-if="productPrices && productPrices.length > 1"
            name="feature_id"
            ref="featureSelect"
            label="شاخص"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="features"
            data-text-field="name"
            data-value-field="id"
            v-model="featureID"
          />
        </div>
        <div class="row" v-for="(price, index) in productPrices" :key="index">
          <form-button
            title="-"
            button-type="btn-danger"
            v-if="deletable()"
            @click="deletePrice(index, price)"
          />
          <bita-input
            type="text"
            :name="'code' + index"
            label="کد"
            rules=""
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="price.code"
          />
          <bita-input
            type="text"
            :name="'barcode' + index"
            label="بارکد"
            rules=""
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="price.barcode"
          />
          <bita-input
            type="text"
            :name="'shipping_time' + index"
            label="مدت زمان ارسال"
            rules=""
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="price.shipping_time"
          />
          <bita-input
            type="text"
            :name="'description' + index"
            label="توضیحات"
            rules=""
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="price.description"
          />
          <bita-input
            type="text"
            :name="'price' + index"
            :label="$currencyLabel('قیمت')"
            :has-thousand-seprator="true"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="price.price"
          />
          <bita-input
            type="text"
            :name="'stock' + index"
            label="موجودی"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="price.stock"
          />
          <bita-select
            v-if="productPrices && productPrices.length > 1"
            :name="'feature_value_id' + index"
            ref="featureValueSelect"
            label="مقدار شاخص"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="findFeatureValues(featureID)"
            data-text-field="name"
            data-value-field="id"
            v-model="price.feature_value"
          />
          <block-button 
            v-if="productPrices.length == index + 1"
            icon="fas fa-plus-circle"
            @click="addPrice()"
          />
        </div>
      </div>
    </bita-form>
  </div>
</template>
<script>
import BlockButton from "@/components/template/buttons/BlockButton";
import FormButton from "@/components/template/buttons/FormButton";
import { productApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
    largeColWidth: {
      default: "col-lg-2",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    product: {},
  },
  components: { BlockButton, FormButton },
  data() {
    return {
      form: {},
      productPrices: [],
      formData: [{}],
      featureID: "",
      productId: "",

    };
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
    isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
  },
  methods: {
    setInitialValue(_data) {
      this.form = _data;
      if (this.form.id) this.loadData();
    },
    deletable() {
      return this.productPrices.length > 1;
    },
    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);

            productApi
              .updateProductPrices(this.form.id, {
                prices: this.productPrices,
                feature_id: this.featureID,
                product_id: this.productId,
              })
              .then((res) => {
                this.$emit("on-update", this.form);
                resolve(true);
              })
              .finally(() => {
                this.$emit("is-loading", false);
              })
              .catch((err) => {
                this.setErrors(err.response.data.errors);
                reject(err);
              });
          } else reject(false);
        });
      });
    },

    addPrice() {
      this.productPrices.push({});
    },

    findFeatureValues(featureID) {
      let res = collect(this.features).where("id", Number(featureID)).first();
      return res ? res.feature_values : [];
    },

    deletePrice(index, price) {
      
      if (!price.id) return this.$delete(this.productPrices, index);
      this.$emit("is-loading", true);

      productApi
        .deleteProductPrice(price.id)
        .then(() => {
          this.$delete(this.productPrices, index);
          this.$izitoast("success", "عملیات موفق", res.data.message);
        })
        .finally(() => this.$emit("is-loading", false));
    },

    loadData() {
      this.$emit("is-loading", true);
      Promise.all([
        productApi.getProductPrices(this.form.id),
        productApi.getProductFeaturables(this.form.id),
      ])
        .then((res) => {
         // this.features = res[1].data.entity;
          for (let a of res[1].data.entity) {
            this.productPrices.push({});
          }
          setTimeout(() => {
            this.featureID = res[0].data.entity[0].feature_id;
            this.productId = res[0].data.entity[0].product_id;
          }, 50);
          setTimeout(() => {
            this.productPrices = res[0].data.entity;
          }, 100);
          this.$forceUpdate();
        })
        .finally(() => {
          setTimeout(() => {
            this.$emit("is-loading", false);
          }, 120);
        });
    },

    // getProductPrices() {
    //   this.$emit("is-loading", true);
    //   productApi
    //     .getProductPrices(this.form.id)
    //     .then((res) => {
    //       this.productPrices = res.data.entity;
    //     })
    //     .finally(() => {
    //       this.$emit("is-loading", false);
    //     });
    // },

    // getProductFeaturables() {
    //   getProductFeaturables;
    // },

    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      this.form = {};
      this.productPrices = [];
      this.$refs.categoryTreeselect.emptyKendo();
      this.$refs.brandSelect.emptyKendo();
      this.formRef.reset();
    },
  },
};
</script>
