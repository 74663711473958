<template>
  <div class="filter-btn-container">
    <div class="btn-primary bbt" @click="$emit('click')">
      <i :class="icon"></i><span :style="`${title && icon ? 'margin-right: 5px !important' : ''}`">{{ title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
};
</script>
<style scoped>
.filter-btn-container {
  all: unset;
  list-style: none;
  margin: 0 10px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bbt {
  float: right;
  padding: 6px 16px;
  background: #ccc;
  border-radius: 5px;
}
.bbt:active {
  background-color: #0f8970 !important;
}
</style>
