<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن آیکن"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="toggleModal"
            v-if="$acl.can('create', 'app-icons')"
          />
          <hr />
          <bita-grid
          
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :schema-total="'total'"
            :schema-data="'data'"
          />
        </div>
      </bita-ibox>
    </div>
    <app-icon-form
      ref="form"
      :title="formTitle"
      :is-loading="isLoadingModal"
      @on-create="save"
      @on-update="update"
    />
  </div>
</template>
<script>
import AppIconForm from "./components/AppIconForm";
import { appIconApi } from "@/api/apiManagement";

export default {
  components: { AppIconForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت آیکن ها", link: "" },
      ],
      isLoadingModal: false,
      isLoadingGrid: false,
      formTitle: "",
      appIcons: [],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    gridColumns() {
      let commands = [];
      
      if (this.$acl.can("edit", "app-icons"))
        commands.push({
          text: "ویرایش",
          name: "edit",
          template: this.gridButtons.edit,
          click: this.edit,
        });
      if (this.$acl.can("delete", "app-icons"))
        commands.push({
          text: "حذف",
          name: "delete",
          template: this.gridButtons.delete,
          click: this.delete,
        });

      return [
        { field: "slug", title: "عنوان" },
        { field: "title", title: "نام" },
        { field: "meta_type_text", title: "نوع" },
        { field: "type_text", title: "مکان" },
        { field: "order", title: "ترتیب" },
        {
          title: "عملیات",
          command: commands,
        },
      ];
    },
  },

  methods: {
    getSize(element) {
      return this.isAppIconr(element.type)
        ? "-"
        : element.size == 1
        ? "کوچک"
        : "بزرگ";
    },
    save(appIcon) {
      this.isLoadingModal = true;
      appIcon = this.getFormData(appIcon);

      appIconApi
        .createAppIcon(appIcon)
        .then((res) => {
          this.formSucceeded(res.data.message);
          this.toggleModal();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    formSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$refs.form.reset();
      this.toggleModal();
      this.loadData();
    },
    update(appIcon) {
      this.isLoadingModal = true;
      let formData = this.getFormData(appIcon);

      appIconApi
        .updateAppIcon(appIcon.id, formData)
        .then((res) => {
          this.formSucceeded(res.data.message);
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    toggleModal() {
      this.$toggleModal("#app-icon-form");
    },
    edit(e) {
      e.preventDefault();
      let appIcon = this.$refs.grid.getSelectedGridRow(e);
      let editForm = {
        id: appIcon.id,
        title: appIcon.title,
        slug: appIcon.slug,
        description: appIcon.description,
        meta_type: appIcon.meta_type,
        meta_value: appIcon.meta_value,
        meta: appIcon.meta,
        type: appIcon.type,
        order: appIcon.order,
        image_url: appIcon.image_url,
        link: appIcon.link,
      };
      this.formTitle = `ویرایش ${appIcon.title}`;
      this.toggleModal();
      this.$refs.form.setForm(editForm);
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    readData(e) {
      this.isLoadingGrid = true;
      // this.$resetGrid();
      appIconApi
        .getAppIcons()
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    delete(e) {
      e.preventDefault();
      let appIcon = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف ${appIcon.title} اطمینان دارید؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          appIconApi
            .deleteAppIcon(appIcon.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
  },
};
</script>