<template>
  <div class="grigio-from-group">
    <div class="grigio-title">{{ title }}</div>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    size: "",
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.grigio-from-group {
  position: relative;
  border: 1px solid #ccc;
  padding: 13px 0px;
  margin-bottom: 20px;
}
.grigio-title {
  position: absolute;
  top: -11px;
  right: 14px;
  background: #ccc;
  padding: 1px 8px;
  color: #000;
}

</style>
