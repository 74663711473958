var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ibox k-rtl"},[_vm._t("header"),_c('div',{class:{
            'ibox-content': true,
            'sk-loading': _vm.isLoading,
            'no-border': !_vm.box
        }},[_c('div',{class:("sk-spinner " + (_vm.loadingType ? _vm.loadingType : 'sk-spinner-circle')),domProps:{"innerHTML":_vm._s(
                _vm.getSppinerContent(
                    _vm.loadingType ? _vm.loadingType : 'sk-spinner-circle'
                )
            )}}),_vm._t("body")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }