const SHOW_LOGS = process.env.VUE_APP_LOGS == "true"

function log(_arguments, _color) {
    if (SHOW_LOGS) {
        var parameters = ''
        for (var i = 1; i < _arguments.length; i++) {
            parameters += _arguments[i] + ' ';
        }
        console.info(
            `%c${_arguments[0]}`,
            `color: ${_color}; font-weight: 600`,
            parameters
        );
    }
}

export default {
    success() {
        log(arguments, "green")
    },
    warning() {
        log(arguments, "yellow")
    },
    error() {
        log(arguments, "red")
    },
}



