<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <table class="table table-striped">
            <thead>
              <th>#</th>
              <th>نام</th>
              <th>نام انگلیسی</th>
              <th>وابستگی ها</th>
            </thead>
            <tbody>
              <tr v-for="{ name, fa_name, appends } in permissions" :key="name">
                <td>
                  <input
                    type="checkbox"
                    name="permission[]"
                    v-model="permissionsSync"
                    :value="name"
                  />
                </td>
                <td>
                  {{ fa_name }}
                </td>
                <td>{{ name }}</td>
                <td>
                  <template v-for="item in appends">
                    <strong
                      class="label label-warning"
                      v-if="!item.fa_name_custom"
                      :key="item.name"
                      >{{ item.fa_name }} - {{ item.name }}
                    </strong>
                    <strong
                      class="label label-danger"
                      v-else
                      :key="item.name_custom"
                      >{{ item.fa_name_custom }} - {{ item.name_custom }}
                    </strong>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <bita-bootbutton
            type="primary"
            title="تایید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="save"
          />
        </div>
      </bita-ibox>
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-form ref="filterForm">
            <div slot="body">
              <div class="row">
                <bita-input
                  type="text"
                  name="name"
                  label="جستجو"
                  validation-container-class="col-lg-4"
                  input-class="form-control"
                  v-model.lazy="filters.search"
                  @keyup.enter.prevent="loadData"
                />
                <div class="col-lg-4">
                  <a class="margin-top btn btn-success" @click="loadData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>
              </div>
            </div>
          </bita-form>
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readGridData"
            :grid-server-side="true"
            :schema-total="'total'"
            :schema-data="'data'"
            :server-paging="true"
            :auto-bind="true"
            :batch="true"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { permissionApi } from "@/api/apiManagement.js";

export default {
  data() {
    return {
      productId: null,
      pageSize: 10,
      currentPage: 1,
      permissionsSync: [],
      filters: {},
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت کوپن ها ",
          link: "/shop/permissions",
          active: true,
        },
      ],
      permissions: [],
      isLoadingGrid: false,
      isLoadingModal: false,
    };
  },

  mounted() {
    this.readData();
  },
  computed: {
    gridColumns() {
      return [
        { field: "fa_name", title: "نام", width: 300 },
        {
          field: "name",
          title: "نامک",
        },
      ];
    },
  },
  methods: {
    readGridData(e) {
      this.pageSize = e.data.pageSize;
      this.currentPage = e.data.page;
      this.isLoadingGrid = true;
      this.$resetGrid();
      permissionApi
        .getPermissionsPaginated({
          page_size: this.pageSize,
          page: this.currentPage,
          ...this.filters,
        })
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    readData() {
      this.isLoadingGrid = true;

      permissionApi
        .getSyncPermissions()
        .then(({ data }) => {
          this.permissions = data;
        })
        .catch(() => {
          this.permissions = [];
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    resetFilters() {
      this.filters = {};
      this.$refs.filterForm.reset();
    },
    save() {
      this.isLoadingGrid = true;
      let permissions = [];
      this.permissionsSync.forEach((element) => {
        permissions.push(this.permissions[element]);
      });
      permissionApi
        .setSyncPermissions({ permissions })
        .then(() => {
          this.readData()
          this.permissionsSync = [];
          }
        );
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
