<template>
    <div>
        <bita-breadcrumb :items="breadCrumbs" />
            <div class="wrapper wrapper-content animated fadeInRight">
                <bita-ibox :is-loading="isLoadingPage">
                    <div slot="body">
                        <bita-grid
                            ref="grid"
                            :columns="gridColumns"
                            :read-data="readData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :has-row-number="true"
                        />
                    </div>
                </bita-ibox>
            </div>
        <forum-form ref="forumForm" :title="title" @load-data="loadData" />
    </div>
</template>
<script>
import { forumApi } from "@/api/apiManagement";
import forumForm from "./components/ForumForm.vue";

export default {
    components: {
        forumForm
    },
    data() {
        return {
            breadCrumbs: [
                { title: "صفحه اصلی", link: "/" },
                {
                    title: "مدیریت پرسش و پاسخ",
                    link: "/forum/messages",
                    active: true,
                },
            ],
            isLoadingPage: false,
            pageSize: 10,
            currentPage: 1,
            title: "",
        }
    },
    computed: {
        gridColumns() {
            return [
                { title: "عنوان", template: e => (e.title ? e.title : '-') },
                { title: "ایجاد کننده", template: e => ( e.user_id && e.user && e.user.full_name ? e.user.full_name : '-' ) },
                { title: "وضعیت", template: e => ( (e.status || e.status === 0 ) && e.status_text ? e.status_text : '-' ) },
                { title: "زمان ایجاد", template: e => (e.created_at ? this.$getJalaliDateTime(e.created_at) : '-') },
                { title: "عملیات", command: this.gridCommands() }
            ]
        }
    },
    methods: {
        readData(e) {
            this.isLoadingPage = true;
            forumApi.gets()
                .then( (res) => {
                    e.success(res.data.entity);
                })
                .catch( () => e.success([]) )
                .finally( () => this.isLoadingPage = false );
        },
        loadData() {
            this.$refs.grid.loadDataSource();
        },
        gridCommands() {
            let commands = [];
            commands.push({
                text: "edit",
                click: this.edit,
                template: this.gridButtons.edit
            });
            commands.push({
                text: "delete",
                click: this.delete,
                template: this.gridButtons.delete
            });
            return commands;
        },
        edit(e) {
            let forum = this.$refs.grid.getSelectedGridRow(e);
            this.title = `ویرایش ${forum.title} `;
            this.$refs.forumForm.setInitialData(forum);
            this.$toggleModal("#forum-form");
        },
        delete(e) {
            let forum = this.$refs.grid.getSelectedGridRow(e);
            this.$alert(
                "question",
                `آیا از حذف درخواست شماره ${forum.title} اطمینان دارید ؟`,
                "",
                {
                confirmButtonText: "بله",
                }
            ).then((result) => {
                if (result.value) {
                this.isLoadingPage = true;
                forumApi.delete(forum.id)
                    .then((res) => {
                        this.$izitoast("success", "موفق!", res.data.message);
                        this.loadData();
                    })
                    .finally(() => this.isLoadingPage = false )
                }
            });
        },
    }
}
</script>