<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          
          <bita-bootbutton
            title="افزودن آیکن شبکه اجتماعی"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="toggleModal"
            v-if="$acl.can('create', 'social-media')"
          />

          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :schema-total="'total'"
            :schema-data="'data'"
          />
        </div>
      </bita-ibox>
    </div>
    <social-media-form
      ref="form"
      :title="formTitle"
      :is-loading="isLoadingModal"
      @on-create="save"
      @on-update="update"
    />
  </div>
</template>
<script>
import SocialMediaForm from "./components/SocialMediaForm";
import { socialMediaApi } from "@/api/apiManagement";

export default {
  components: { SocialMediaForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت شبکه های اجتماعی", link: "/management/social-media" },
      ],
      isLoadingModal: false,
      isLoadingGrid: false,
      formTitle: "",
      socialMedias: [],
      isSupport:false
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    gridColumns() {
      let commands = [];
      
      if (this.$acl.can("edit", "social-media"))
        commands.push({
          text: "ویرایش",
          name: "edit",
          template: this.gridButtons.edit,
          click: this.edit,
        });
      if (this.$acl.can("delete", "social-media"))
        commands.push({
          text: "حذف",
          name: "delete",
          template: this.gridButtons.delete,
          click: this.delete,
        });

      return [
        { field: "slug", title: "عنوان" },
        { field: "title", title: "نام" },
        { field: "meta_type_text", title: "نوع" },
        { field: "order", title: "ترتیب" },
        {
          title: "عملیات",
          command: commands,
        },
      ];
    },
  },

  methods: {
    getSize(element) {
      return this.isSocialMediar(element.type)
        ? "-"
        : element.size == 1
        ? "کوچک"
        : "بزرگ";
    },
    save(socialMedia) {
      this.isLoadingModal = true;
      socialMedia = this.getFormData(socialMedia);

      socialMediaApi
        .createSocialMedia(socialMedia)
        .then((res) => {
          this.formSucceeded(res.data.message);
          this.toggleModal();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    formSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$refs.form.reset();
      this.toggleModal();
      this.loadData();
    },
    update(socialMedia) {
      this.isLoadingModal = true;
      let formData = this.getFormData(socialMedia);

      socialMediaApi
        .updateSocialMedia(socialMedia.id, formData)
        .then((res) => {
          this.formSucceeded(res.data.message);
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    toggleModal() {
      this.$toggleModal("#social-media-form");

    },
    edit(e) {
      e.preventDefault();
      let socialMedia = this.$refs.grid.getSelectedGridRow(e);
      let editForm = {
        id: socialMedia.id,
        title: socialMedia.title,
        slug: socialMedia.slug,
        description: socialMedia.description,
        meta_type: socialMedia.meta_type,
        meta_value: socialMedia.meta_value,
        meta: socialMedia.meta,
        type: socialMedia.type,
        order: socialMedia.order,
        image_url: socialMedia.image_url,
        link: socialMedia.link,
        support:socialMedia.type,
        expertname : socialMedia.expertname,
        description : socialMedia.description

      };
      this.formTitle = `ویرایش ${socialMedia.title}`;
      this.toggleModal();
      this.$refs.form.setForm(editForm);
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    readData(e) {
      this.isLoadingGrid = true;
      // this.$resetGrid();
      socialMediaApi
        .getSocialMedias()
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    delete(e) {
      e.preventDefault();
      let socialMedia = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف ${socialMedia.title} اطمینان دارید؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          socialMediaApi
            .deleteSocialMedia(socialMedia.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
  },
};
</script>