<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            v-if="!showForm && $acl.can('create', 'promotions')"
            type="primary"
            title="حراج جدید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="openModal"
          />
          <hr />
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="جستجو"
              validation-container-class="col-lg-4"
              input-class="form-control"
              v-model.lazy="filters.search"
              @keyup.enter.prevent="loadData"
            />
            <bita-select
              v-if="$acl.can('view', 'admin-of-sellers')"
              label=" انتخاب تامین کننده"
              name="seller_id"
              ref="sellerSelect"
              validation-container-class="col-lg-4"
              rules="required"
              input-class="form-control"
              :data-source="supplires"
              data-text-field="seller_fullname"
              data-value-field="id"
              v-model="filters.seller_id"
            />
            <div class="col-lg-4">
              <a class="margin-top btn btn-success" @click="loadData()">
                جستجو
              </a>
              <a
                class="margin-top margin-right btn btn-warning"
                @click="resetFilters()"
              >
                پاک سازی
              </a>
            </div>
          </div>
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :auto-bind="false"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <form-modal
      ref="editModal"
      :isLoading="isLoadingModal"
      :props-roles="roles"
      :title="title"
      :brands="brands"
      :products="products"
      :get-products="getProducts"
      :categories="categories"
      @on-create="onCreate"
      @on-update="onUpdate"
      @on-change-meta-type="changeMetaType"
      @on-search-products="getProducts"
      @on-search-brands="getBrands"
    />
  </div>
</template>
<script>
import PromotionsForm from "./components/PromotionForm";
import {
  promotionApi,
  userApi,
  categoryApi,
  productApi,
  brandApi,
  supplireApi,
} from "@/api/apiManagement.js";

export default {
  components: { "form-modal": PromotionsForm },
  data() {
    return {
      supplires: [],
      brands: [],
      products: [],
      categories: [],
      pageSize: 10,
      filters: {},
      currentPage: 1,
      productId: null,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت حراج ها ",
          link: "/shop/promotions",
          active: true,
        },
      ],
      roles: [],

      isLoadingGrid: false,
      isLoadingModal: false,
      showForm: false,
      productTemp: {},
      gridColumns: [
        { field: "title", title: "عنوان" },
        {
          field: "value",
          title: "مقدار تخفیف",
          template: this.offerValueTemlate,
        },
        {
          field: "seller_id",
          title: "فروشنده",
          template: this.sellerinfo,
        },
        // {
        //   field: "used_count",
        //   title: "تعداد تخفیف ها",
        //   template: this.userdCountTemlate,
        // },
        { field: "status", title: "وضعیت", template: this.statusTemlate },
        { title: "عملیات", command: [] },
      ],
    };
  },
  computed: {
    title() {
      let title = "افزودن";
      if (this.$refs.editModal && this.$refs.editModal.getId())
        title = "ویرایش";
      return title + " حراج ";
    },
  },
  mounted() {
    this.loadData();
    if(this.$acl.can('view', 'admin-of-sellers'))
    this.getActiveSupplire();

    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    resetFilters() {
      this.filters = {};
      this.$refs.filterForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
    },
    isProduct(metaValue) {
      return metaValue == 3;
    },
    isCategory(metaValue) {
      return metaValue == 2;
    },
    isBrand(metaValue) {
      return metaValue == 1;
    },
    changeMetaType(value) {
      if (this.isCategory(value)) return this.getCategories({});
      else if (this.isBrand(value)) return this.getBrands({});
    },
    getProducts(e) {
      if (!e.filter) return;
      productApi.getProducts({ search: e.filter.value }).then((res) => {
        this.products = _.unionBy(res.data, this.products, "id");
      });
    },
    getProductInfo(productId) {
      return productApi.getProductInfo(productId);
    },
    getBrands() {
      return brandApi.get().then(({ data }) => {
        this.brands = data.entity;
      });
    },
    getCategories() {
      return categoryApi.getCategories().then(({ data }) => {
        this.categories = data.entity;
      });
    },
    userdCountTemlate(e) {
      return (
        e.used_count +
        " / " +
        (e.available_count == null ? "∞" : e.available_count)
      );
    },
    currency(amount, valueType) {
      let result = "";

      if (valueType == 1) result = amount + " " + "%";
      else result = this.$currencyLabel(amount);

      return result;
    },
    offerValueTemlate(e) {
      return this.$thousandSeprator(this.currency(e.value, e.value_type));
    },
    sellerinfo(e) {
      return e.seller.seller_fullname;
    },
    statusTemlate(e) {
      return e.status ? "فعال" : "غیر فعال";
    },
    onCreate(data) {
      this.isLoadingModal = true;

      promotionApi
        .createPromotion(data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    successForm() {
      this.loadData();
      this.$refs.editModal.resetForm();

      this.$toggleModal("#edit-modal");
    },
    onUpdate(data) {
      this.isLoadingModal = true;

      promotionApi
        .updatePromotion(data.id, data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;

      promotionApi
        .getPromotions(this.filters)
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف حراج  ${data.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          promotionApi
            .deletePromotion(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    getActiveSupplire() {
      supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },
    gridCommands() {
      let commands = [];

      if (this.$acl.can("edit", "promotions"))
        commands.push({
          text: "edit",
          click: this.edit,
          template: this.gridButtons.edit,
        });
      if (this.$acl.can("delete", "promotions"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    openModal() {
      this.$toggleModal("#edit-modal");
    },
    edit(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      let promisList = [];

      this.isLoadingModal = true;
      this.openModal();

      promisList.push(this.changeMetaType(data.meta_type));

      if (this.isProduct(data.meta_type))
        promisList.push(this.getProductInfo(data.meta_value));

      Promise.all(promisList).then((result) => {
        if (this.isProduct(data.meta_type))
          this.products = [result[1].data.entity];

        setTimeout(() => {
          this.$refs.editModal.setForm(data);
          this.isLoadingModal = false;
        }, 1000);
      });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
