<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingJobs">
        <div slot="body">
          <!-- <bita-bootbutton
            title="افزودن صفحه جدید"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="openModal"
            v-if="$acl.can('create', 'static-pages')"
          />
          <hr /> -->
          <h4>موارد در دست اقدام</h4>
          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
      <bita-ibox :is-loading="isLoadingFailedJobs">
        <div slot="body">
          <h4>موارد شکست خورده</h4>
          <bita-bootbutton
            title="تلاش مجدد"
            type="primary"
            icon="fas fa-retweet"
            btn-width="100px"
            class="ml-2"
            @click.native="retryAll"
          />

          <bita-bootbutton
            title="پاک کردن همه"
            type="danger"
            icon="fas fa-times"
            btn-width="100px"
            @click.native="flush"
          />

          <hr />
          <bita-grid
            ref="failedGrid"
            :columns="failedGridColumns"
            :read-data="failedReadData"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <edit-form ref="payloadForm" id="payload-form" title="payload" />
  </div>
</template>
<script>
import { jobsApi } from "@/api/apiManagement";
import editForm from "./components/JobsMonitoringForm";
export default {
  components: { editForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت", link: "/" },
        { title: "مانیتورینگ صف ها", link: "/management/jobs" },
      ],
      isLoadingJobs: false,
      isLoadingFailedJobs: false,
      isLoadingModal: false,
      updateForm: {},
      retryBtn:
        "<a role='button' class='k-button k-button-icontext k-grid-retry' data-toggle='tooltip' data-placement='bottom' title='تلاش مجدد'><span class='fas fa-retweet text-info'></span></a>",
    };
  },
  computed: {
    gridColumns() {
      return [
        { field: "queue", title: "queue" },
        {
          field: "payload",
          title: "payload",
          template: (e) =>
            e.payload ? e.payload.substring(1, 100) + "..." : "-",
        },
        { field: "attempts", title: "attempts" },
        { field: "reserved_at", title: "reserved_at" },
        { field: "available_at", title: "available_at" },
        { field: "created_at", title: "created_at" },
      ];
    },
    failedGridColumns() {
      return [
        { field: "id", title: "id" },
        { field: "connection", title: "connection" },
        { field: "queue", title: "queue" },
        {
          field: "payload",
          title: "payload",
          template: (e) =>
            e.payload ? e.payload.substring(1, 100) + "..." : "-",
        },
        {
          field: "exception",
          title: "exception",
          template: (e) =>
            e.exception ? e.exception.substring(1, 100) + "..." : "-",
        },
        { field: "failed_at", title: "failed_at" },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  methods: {
    readData(e) {
      this.isLoadingJobs = true;
      jobsApi
        .get()
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingJobs = false;
        });
    },
    failedReadData(e) {
      this.isLoadingFailedJobs = true;
      jobsApi
        .getFailed()
        .then((res) => {
          e.success(res.data.entity);
        })
        .catch((res) => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingFailedJobs = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
      this.$refs.failedGrid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      commands.push({
        text: "retry",
        click: this.retry,
        template: this.retryBtn,
      });
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    // edit(e) {
    //   this.isLoadingModal = true;
    //   let page = this.$refs.grid.getSelectedGridRow(e);
    //   console.log("page", page);
    //   this.updateForm = page;
    //   this.toggleForm();
    //   this.isLoadingModal = false;
    // },
    delete(e) {
      let page = this.$refs.grid.getSelectedGridRow(e);
      this.$alert(
        "question",
        `آیا از حذف صفحه ${page.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          // staticpageApi
          //   .deleteStaticPages(page.id)
          //   .then((res) => {
          //     this.$izitoast("success", "عملیات موفق", res.data.message);
          //     this.loadData();
          //   })
          //   .finally(() => {
          //     this.isLoadingGrid = false;
          //   });
        }
      });
    },
    retry(e) {
      this.isLoadingFailedJobs = true;
      let failedJob = this.$refs.failedGrid.getSelectedGridRow(e);
      jobsApi
        .retry(failedJob.id)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق");
          this.loadData();
        })
        .finally(() => {
          this.isLoadingFailedJobs = false;
        })
        .catch((err) => {});
    },
    retryAll() {
      this.isLoadingFailedJobs = true;
      jobsApi
        .retryAll()
        .then((res) => {
          this.$izitoast("success", "عملیات موفق");
          this.loadData();
        })
        .finally(() => {
          this.isLoadingFailedJobs = false;
        })
        .catch((err) => {});
    },
    flush() {
      this.isLoadingFailedJobs = true;
      jobsApi
        .flush()
        .then((res) => {
          this.$izitoast("success", "عملیات موفق");
          this.loadData();
        })
        .finally(() => {
          this.isLoadingFailedJobs = false;
        })
        .catch((err) => {});
    },
  },
};
</script>