<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            type="primary"
            title="مقاله جدید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="createProduct"
          />
          <hr />
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { articleApi } from "@/api/apiManagement.js";
import { mdidlePagesApi } from "@/api/apiManagement.js" 

export default {
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت مقالات",
          link: "/shop/article",
          active: true,
        },
      ],

      isLoadingGrid: false,
      showForm: false,
      middlePages:[]
    };
  },

  mounted() {
    this.editModal = this.$refs.editModal;
  },

  computed: {
    gridColumns() {
      return [
        {
          field: "title",
          title: "عنوان سایت",
          template: ({ title }) => (title ? title : " - "),
        },
        {
          field: "category_text",
          title: "دسته بندی",
          template: ({ category_text }) =>
            category_text ? category_text : " - ",
        },
        {
          field: "seller_article_flag",
          title: "نمایش در پنل",
          template: ({ seller_article_flag }) => (seller_article_flag ? "بله" : "خیر"),
        },
        {
          field: "source",
          title: "منبع",
          template: ({ source }) => (source ? source : " - "),
        },
        {
          field: "expire_at",
          title: "تاریخ انتشار",
          template: ({ publish_at }) => (publish_at ? publish_at : " - "),
        },
        {
          field: "expire_at",
          title: "تاریخ انقضا",
          template: ({ expire_at }) => (expire_at ? expire_at : " - "),
        },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  methods: {
    createProduct() {
      this.$router.push({ path: "/article/create" });
    },
    onCreate(data) {},
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;
      articleApi
        .getArticles()
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });

        mdidlePagesApi
          .gets()
          .then((res) => {
            this.middlePages = res.data.entity;
          })
          .finally(() => (this.isLoadingModal = false));
    },

    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);

      this.$alert("question", `آیا از حذف ${data.title} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          articleApi
            .deleteArticle(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];

      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },

    edit(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);
      this.$router.push({ path: `/article/${data.id}/update` });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
