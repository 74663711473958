<template>
  <div>
    <div class="wrapper">
      <div id="photos">
        <div class="photo" v-for="(image, index) in images" :key="index">
          <span class="delete-image" @click="deletePhoto(image)"
            ><i class="fas fa-times-circle fa-2x"></i
          ></span>
          <span
            class="set-thumbnail"
            :style="`color: ${imageIsThumbnail(image) ? 'green' : 'gray'}`"
            @click="setThumbnail(image)"
            ><i class="fas fa-check-circle fa-2x"></i
          ></span>
          <img :src="image.full_url" />
        </div>
      </div>
      <div class="dropZoneElement">
        <div class="textWrapper">
          <p><span>+</span>تصویر جدید</p>
          <p class="dropImageHereText">تصاویر خود را بکشید به اینجا بکشید</p>
        </div>
      </div>
    </div>
    <upload
      ref="upload"
      name="files"
      :async="asyncUploader"
      :upload="onUpload"
      :validation-allowed-extensions="['.jpg', '.jpeg', '.png', '.bmp', '.gif','.webp']"
      :localization="kendoUploader"
      :show-file-list="true"
      :drop-zone="'.dropZoneElement'"
      @success="onSuccess"
      @error="onError"
    >
    </upload>
  </div>
</template>
<script>
import { Upload, UploadInstaller } from "@progress/kendo-upload-vue-wrapper";
import { productApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
    largeColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
  },
  use: { UploadInstaller },
  components: {
    Upload,
  },
  data() {
    return {
      form: {},
      isLoading: false,
      categories: [],
      brands: [],
      product: {},
      kendoUploader: {
        select: "انتخاب فایل",
        retry: "تلاش مجدد",
        remove: "حذف",
        invalidFiles: "فایل نا‌معتبر",
        uploadSelectedFiles: "بارگذاری",
        uploadFail: "خطا در بارگذاری",
        uploadSuccess: "بارگذاری شد",
        clearSelectedFiles: "لغو",
        statusUploaded: "بارگذاری با موفقیت انجام شد.",
        statusUploading: "در حال بارگذاری فایل (ها)...",
        statusFailed: "بارگذاری ناموفق",
        invalidFileExtension: "پسوند نامعتبر فایل",
        dropFilesHere: "فایل های خود را به اینجا بکشید و رها کنید",
        headerStatusUploaded: "بارگذاری با موفقیت انجام شد",
        headerStatusUploading: "درحال بارگذاری ...",
      },
      images: [],
    };
  },
  mounted() {},
  computed: {
    formRef() {
      return this.$refs.form;
    },
    asyncUploader() {
      return {
        withCredentials: true,
        saveUrl: `${this.$apiURL}/shop/products/${this.product.id}/images`,
        autoUpload: true,
      };
    },
  },
  methods: {
    setInitialValue(_data) {
      this.product = _data;
      this.getProduct();
      this.getProductImages(_data.id);


    },
    save() {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    },

    imageIsThumbnail(image) {
      return Number(image.id) == Number(this.product.thumbnail_file_id);
    },
    getProduct() {
      productApi.getProduct(this.product.id).then((res) => {
        this.product = res.data.entity;
      });
    },
    getProductImages(_productID) {
      this.$emit("is-loading", true);
      productApi
        .getProductImages(_productID)
        .then((res) => {
          this.images = res.data.entity;
        })
        .finally(() => this.$emit("is-loading", false));
    },
    deletePhoto: function (image) {
      let question = this.imageIsThumbnail(image)
        ? "آیا از حذف تصویر اصلی محصول اطمینان دارید؟"
        : "از حذف تصویر اطمینان دارید";
      this.$alert("question", question, null, {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.$emit("is-loading", true);
          productApi
            .deleteProductImage(image.id)
            .then((res) => {
              this.getProductImages(this.product.id);
              this.$izitoast("success", "عملیات موفق", res.data.message);
            })
            .finally(() => {
              this.$emit("is-loading", false);
            });
        }
      });
    },
    thumbnailImageDeletationQuestion() {
      new Promise((resolve, reject) => {
        this.$alert(
          "question",
          "این تصویر اصلی محصول شما هست از حذف آن اطمینان دارید؟",
          null,
          {
            confirmButtonText: "بله",
          }
        ).then((result) => {
          if (result.value) {
            resolve(true);
          } else reject(false);
        });
        reject(false);
      });
    },
    setThumbnail: function (image) {
      this.$emit("is-loading", true);
      productApi
        .setProductThumbnail(this.product.id, { file_id: image.id })
        .then((res) => {
          this.getProduct();
          this.$izitoast("success", "عملیات موفق", res.data.message);
        })
        .finally(() => {
          this.$emit("is-loading", false);
        });
    },
    onUpload: function (e) {
      this.$emit("is-loading", true);
      var xhr = e.XMLHttpRequest;
      if (xhr) {
        var _this = this;
        xhr.addEventListener("readystatechange", function (e) {
          if (xhr.readyState == 1 /* OPENED */) {
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader("X-XSRF-TOKEN", _this.$store.state.xsrfToken);
          }
        });
      }
    },
    onSuccess: function () {
      this.$emit("is-loading", false);
      this.getProductImages(this.product.id);
    },
    onError(e) {
      this.$emit("is-loading", false);
      this.$izitoast("error", "خطا در بارگذاری", e.XMLHttpRequest.statusText, {
        timeout: 0,
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    setErrors(err) {
      this.$refs.form.setErrors(err);
    },
    reset() {
      this.product = {};
      this.images = [];
      this.$emit("is-loading", false);
      this.$refs.upload.kendoWidget().clearAllFiles();
    },
  },
};
</script>
<style scoped>
.set-thumbnail {
  position: absolute;
  right: 2px;
  bottom: -1px;
}
.delete-image {
  color: red;
  position: absolute;
  right: 2px;
  top: 2px;
}
.photo {
  float: right;
  position: relative;
  margin: 0 10px 10px 0;
  padding: 0;
}
.photo img {
  object-fit: contain;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* height: 200px; */
  /* background-color: #ccc; */
}
.dropZoneElement {
  font-family: iranyekan;
  position: relative;
  display: inline-block;
  background-color: #f8f8f8;
  border: 1px solid #c7c7c7;
  width: 100%;
  height: 110px;
  text-align: center;
}
.textWrapper {
  font-family: iranyekan !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font-size: 24px;
  line-height: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}
.dropImageHereText {
  font-family: iranyekan;
  color: #c7c7c7;
  text-transform: uppercase;
  font-size: 12px;
}
.delete-image:hover i {
  color: red;
}
</style>