import { apiCall } from "../imports"

const PREFIX = "notification/templates"

export default {

    getTemplates() {
        return apiCall('get', `${PREFIX}`)
    },

    createTemplates(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateTemplates(templateID, data) {
        return apiCall('patch', `${PREFIX}/${templateID}`, data)
    },

    deleteTemplates(templateID) {
        return apiCall('delete', `${PREFIX}/${templateID}`)
    }
}