<template>
    <bita-modal id="commission-form" :is-loading="isLoadingModal" :modal-lg="true"
        @close="reset()">
        <div slot="body">
            <bita-form ref="commissionForm">
        <div slot="body">
          <div class="row">
            <bita-multiselectserver
                        label="محصول"
                        ref="products"
                        name="products"
                        rules="required"
                        :validation-container-class="colWidth"
                        :data-source="products"
                        data-text-field="name"
                        data-value-field="id"
                        :get-data="getProducts"
              
                        v-model="myform.product_id"
                        />
             <bita-input
             type="text" 
             name="value_type"
                  ref="value_type"
             label="نوع هزینه"
              rules="required"
              :validation-container-class="colWidth" 
              input-class="form-control"
              v-model="myform.value_type" />
            <!-- <bita-select
                  name="value_type"
                  ref="value_type"
                  label="نوع هزینه"
                  rules="required"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :data-source="priceTypes"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="myform.value_type"
                /> -->

                <bita-input
             type="text" 
             name="value" 
             :label="$currencyLabel(' مقدار کمسیون قیمت')"
              :has-thousand-seprator="true" 
              min="0" 
              rules="required"
              v-if="!isPercentage(myform)" 
              :validation-container-class="colWidth" 
              input-class="form-control"
              v-model="myform.value" />

            <bita-input name="value" type="number" label="درصد کمسیون" v-else min="0" max="100" rules="required"
              :validation-container-class="colWidth" input-class="form-control" v-model="myform.value" />
</div>
<bita-bootbutton title="ثبت" type="primary mb-3" icon="fas fa-save" btn-width="150px"
                        @click.native="submit" :disabled="isLoadingModal" />
</div>
</bita-form>
<bita-grid v-if="showGrid" ref="commissionGrid" :columns="gridColumns" :read-data="readData"
                :page-size="pageSize" :has-row-number="true" />
  

        </div>
    </bita-modal>
</template>
<script>
import {
    productApi,
    supplireApi,

} from "@/api/apiManagement";
export default {
    data() {
        return {
            product: [],
            formData: {},
            categories: [],
            colWidth: "col-lg-4",
      colFullWidth: "col-lg-12",
      formData: {},
      myform:{},
      supplire: [],
      products: [],
      filters: {},
      selectValue: 2,
      pageSize: 10,
      isLoadingModal: false,
            showGrid: false,
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
        }
    },
    mounted() {
        this.getProductsAll();
    },
    computed: {
        gridColumns() {
            return [
            { field: "name", title: "نوع کمسیون",template: this.getCommissionType},
                { title: "مقدار کمسیون",template: this.getCommissionValue},
                { title: "عملیات", command: this.gridCommands() }
            ]
        }
    },
    methods: {
        setInitialData(_data) {
            console.log(_data)
            this.showGrid = true;
            this.supplire = _data;    
            console.log(this.supplire.id)
            },
            getCommissionType(e) {
      let commissionType = e.value_type ;
       commissionType == 1 ? 'مبلغ' : 'درصد';
      return `${commissionType}`;
    },
    getCommissionValue(e) {
        let commissionValue = e.value ;
        return `${commissionValue}`;

    },
        isCategory(selectValue) {
            return selectValue == 2;
        },
        readData(e) {
           
            this.isLoadingModal = true;
            supplireApi.getCommissionOfseller(this.supplire.id)
                .then((res) => {
                    e.success(res.data.entity);
                })
                .catch(() => e.success([]))
                .finally(() => this.isLoadingModal = false);
        },
        loadData() {
            this.$refs.commissionGrid.loadDataSource();
        },
        gridCommands() {
            let commands = [];
            commands.push({
                text: "delete",
                click: this.delete,
                template: this.gridButtons.delete
            });
            return commands;
        },
        isPercentage(myform) {
      return myform.value_type == 1;
    },
    delete(e) {
            let relation = this.$refs.commissionGrid.getSelectedGridRow(e);
            this.$alert("question", `آیا از حذف ${relation.product.name} اطمینان دارید؟`, "", {
                confirmButtonText: "بله",
            }).then((result) => {
                if (result.value) {
                    this.isLoadingModal = true;
                    productApi.deleteProductRelation(this.product.id, relation.id)
                        .then((res) => {
                            this.$izitoast("success", "موفق!", res.data.message);
                            this.loadData();
                        })
                        .finally(() => this.isLoadingModal = false);
                }
            });
        },
        getProducts(e) {
            let filter = {
                search: e.filter?.value
            };
            productApi.getProducts(filter)
                .then((res) => {
                    this.products = _.unionBy(res.data, this.products, "id")
                })
        },
        getProductsAll(){
            productApi.getProducts()
                .then((res) => {
                    this.products = res.data
                    // this.products = _.unionBy(res.data, this.products, "id")
                })
        },
       
        submit() {
            this.$refs.commissionForm.validate()
                .then((res) => {
                    if (res) {
                        this.isLoadingModal = true;
                        productApi.postProductRelation(this.product.id, this.formData)
                            .then((res) => {
                                this.$izitoast("success", "موفق!", res.data.message);
                                this.loadData();
                                this.resetSelect();
                            })
                            .finally(() => this.isLoadingModal = false);
                    }
                })
        },
        resetSelect() {
            this.$refs.products.clear();
            this.$refs.value_type.emptyKendo();

        },
        reset() {
            this.product = {};

            this.showGrid = false;
        }
    }
}
</script>