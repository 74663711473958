<template>
<router-link :to="link">
    <button :class="btnClass" type="button" :disabled="isLoading">
        <i :class="btnIcon"></i>
    </button>
</router-link>
</template>
<script>
export default {
    name: "DimButton",
    props: {
        /**
         * Font icon class
         * @example 'fas fa-edit'
         */
        icon: { type: String, default: "fas fa-edit" },
        /**
         * Button style class
         * @example "primary" "success" "danger" "warning" "info"
         */
        type: { type: String, default: "primary" },
        /**
         * Button Name
         */
        name: { type: String, default: "" },
        /**
         * Link
         */
        link: { type: String, default: "" }
    },
    data() {
        return {
            isLoading: false
        };
    },
    mounted() {
        this.$root.$on("btn-loading", e => {
            if(e.name == this.name){
                this.isLoading = e.isLoading
            }
        });
    },
    computed: {
        btnClass() {
            return `btn btn-${this.type} dim`;
        },
        btnIcon() {
            if (this.isLoading) return "fa fa-spinner fa-spin fa-fw";
            return this.icon;
        },
        changeIsLoading: function() {
            this.isLoading = !this.isLoading;
        }
    },
    beforeDestroy(){
        this.$root.$off("btn-loading")
    }
};
</script>
<style scoped>
.btn.dim {
    line-height: 0.5;
}
button.dim {
    margin-bottom: 6px !important;
}
</style>
