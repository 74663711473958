<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoading">
        <div slot="body">
          <form-wizard
            ref="wizard"
            title
            subtitle
            next-button-text="مرحله بعد"
            back-button-text="مرحله قبل"
            finish-button-text="پایان"
            color="#1ab394"
            shape="circle"
            @on-complete="onComplete"
          >
            <tab-content
              title="اطلاعات"
              icon="fab fa-product-hunt"
              :before-change="() => validateInfo('infoForm', 'detailForm')"
            >
              <product-info-form
                ref="infoForm"
                :product="createdProduct"
                @on-update="(e) => (createdProduct = e)"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>
            <tab-content
              title="ویژگی ها"
              icon="fas fa-info-circle"
              :before-change="() => validateInfo('detailForm', 'featureForm')"
            >
              <product-detail-form
                ref="detailForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>
            <tab-content
              title="شاخصه های کالا"
              icon="fas fa-info"
              :before-change="() => validateInfo('featureForm', 'imageForm')"
            >
              <product-feature-form
                ref="featureForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>
            <tab-content
              title="تصاویر"
              icon="fas fa-images"
              :before-change="() => validateInfo('imageForm', 'pricesForm')"
            >
              <product-image-form
                ref="imageForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>
            <tab-content
              title="قیمت"
              icon="fas fa-dollar-sign"
              :before-change="() => validateInfo('pricesForm', 'wholesaleForm')"
            >
              <product-prices-form
                ref="pricesForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>

            <tab-content
              title="عمده فروشی"
              icon="fas fa-dollar-sign"
              :before-change="() => validateInfo('wholesaleForm', 'productSeo')"
            >
              <product-wholesale-form
                ref="wholesaleForm"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>


            <tab-content
              title="سئو"
              icon="fab fa-searchengin"
              :before-change="() => validateInfo('productSeo')"
            >
              <product-seo
                ref="productSeo"
                @is-loading="(e) => (isLoading = e)"
              />
            </tab-content>
          </form-wizard>
        </div>
      </bita-ibox>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { productApi, categoriyApi } from "@/api/apiManagement";
import ProductInfoForm from "./components/ProductInfoForm";
import ProductImageForm from "./components/ProductImagesForm";
import ProductDetailForm from "./components/ProductDetailForm";
import ProductPricesForm from "./components/ProductCombinationPricesForm";
import ProductFeatureForm from "./components/ProductFeatureForm";
import ProductWholesaleForm from "./components/ProductWholesaleForm";
import ProductSeo from "./components/ProductSeo";

export default {
  components: {
    FormWizard,
    TabContent,
    ProductInfoForm,
    ProductImageForm,
    ProductDetailForm,
    ProductPricesForm,
    ProductFeatureForm,
    ProductWholesaleForm,
    ProductSeo,
  },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "فروشگاه", link: "" },
        { title: "مدیریت محصولات", link: "/shop/products" },
        {
          title: "ایجاد محصول جدید",
          link: "/shop/products/create",
          active: true,
        },
      ],
      createdProduct: {},
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    validateInfo(_ref, _nextRef) {
      return new Promise((resolve, reject) => {
        this.$refs[_ref]
          .save()
          .then((res) => {
            this.$flog.success("WIZARD MOVE:", _ref, "TO", _nextRef);
            this.setInitials(_nextRef);
            resolve(true);
          })
          .catch((err) => {
            this.$flog.error("WIZARD MOVE FAILED:", _ref, "TO", _nextRef);
            reject(false);
          });
      });
    },

    setInitials(_ref) {
      switch (_ref) {
        case "detailForm":
          this.$refs.detailForm.setInitialValue(this.createdProduct);
          break;
        case "imageForm":
          this.$refs.imageForm.setInitialValue(this.createdProduct);
          break;
        case "featureForm":
          this.$refs.featureForm.setInitialValue(this.createdProduct);
          break;
        case "pricesForm":
          this.$refs.pricesForm.setInitialValue(this.createdProduct);
          break;
          case "wholesaleForm":
          this.$refs.wholesaleForm.setInitialValue(this.createdProduct);
          break;
        case "productSeo":
          this.$refs.productSeo.setInitialValue(this.createdProduct);
          break;
        default:
          break;
      }
    },
    onComplete() {
      this.$alert('success',"محصول شما با موفقیت ثبت شد برای ویرایش می توانید به قسمت محصولات تایید نشده مراجعه نمایید")
      this.createdProduct = {};
      let refsToReset = ["wizard", "infoForm","detailForm","imageForm","featureForm","pricesForm","productSeo"];
      for (let toreset of refsToReset) {
        if (this.$refs[toreset]) this.$refs[toreset].reset();
      }
    },
  },
};
</script>
<style>
.vue-form-wizard .wizard-progress-bar {
  float: right;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left {
  float: right;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-right {
  float: left;
}

.vue-form-wizard .wizard-icon {
  height: inherit;
}
</style>
