<template>
  <bita-modal
    id="measure-form"
    :title="title"
    :is-loading="isLoading"
    :modal-sm="true"
    @close="reset"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="واحد"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.name"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    title: String,
    isLoading: Boolean,
    measure: Object,
  },
  data() {
    return {
      colWidth: "col-lg-12",
      formData: {},
    };
  },
  computed: {

  },
  methods: {
    setForm(data) {
      this.formData = data;
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    reset() {
      this.formData = {};
      this.$refs.form.reset();
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
