import { apiCall } from "../imports";

const PREFIX = "shop/collections"

export default {

    gets() {
        return apiCall('get', `${PREFIX}`);
    },

    getProducts(_productCollection) {
        return apiCall('get', `${PREFIX}/${_productCollection}/products`);
    },

    post(_data) {
        return apiCall('post', `${PREFIX}`, _data);
    },

    postProduct(_productCollection, _productID) {
        return apiCall('post', `${PREFIX}/${_productCollection}/products/${_productID}`);
    },

    patch(_productCollection, _data) {
        return apiCall('post', `${PREFIX}/${_productCollection}/update`, _data);
    },

    delete(_productCollection) {
        return apiCall('delete', `${PREFIX}/${_productCollection}`);
    },

    deleteProduct(_productCollection, _productID) {
        return apiCall('delete', `${PREFIX}/${_productCollection}/products/${_productID}`);
    }
}