<template>
  <div :class="validationContainerClass">
    <v-validate
      :rules="rules"
      :vid="name"
      v-slot="{ errors, classes }"
      :name="label"
    >
      <div class="form-group k-rtl">
        <label :for="id">
          {{ label }}
          <div v-if="isRequired" class="is-required">
            <i class="fas fa-star-of-life"></i>
          </div>
        </label>
        <div>
          <bita-spinner
            v-if="isLoading"
            :is-loading="isLoading"
            styleName="align-self: center;"
          />
          <drop-down-list
            v-if="!isLoading"
            :id="inputID"
            :name="name"
            :ref="name"
            :type="type"
            :option-label="placeHolder"
            v-model="content"
            :data-source="dataSource"
            :data-text-field="dataTextField"
            :data-value-field="dataValueField"
            :disabled="disabled"
            :filter="'contains'"
            :index="null"
            @changemodel="handleInput(content)"
            :class="`${inputClass} ${classes.invalid ? 'is-invalid' : ''}`"
            @filtering="filterData"
          />
        </div>
        <span v-if="hasSubtitle" class="form-text m-b-none">{{
          subtitle
        }}</span>
        <span
          class="invalid-feedback"
          :style="`display: ${errors ? 'inline-block' : 'none'}`"
          >{{ errors[0] }}</span
        >
      </div>
    </v-validate>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";

import {
  DropDownList,
  DropdownsInstaller,
} from "@progress/kendo-dropdowns-vue-wrapper";
import "@progress/kendo-ui/js/kendo.dropdownlist";
export default {
  extends: InputCommons,
  components: { DropDownList },
  use: { DropdownsInstaller },
  methods: {
    emptyKendo() {
      var test = this.$refs[this.name].kendoWidget();
      test.value("");
    },
    filterData(e) {
      if (this.getData) this.getData(e);
    },
  },
};
</script>
<style>
/* .k-dropdown{
  display: inline !important;
} */
.k-dropdown .k-dropdown-wrap .k-input,
.k-dropdowntree .k-dropdown-wrap .k-input {
  height: calc(8px + 1.8285714286em);
}
</style>
