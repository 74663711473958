import { apiCall, createQueryString } from "./../imports";
const PREFIX = "management/users";

export default {
  getMe() {
    return apiCall("get", `${PREFIX}/me`);
  },

  changePassword(data) {
    return apiCall("post", `${PREFIX}/password`, data);
  },

  updateProfile(data) {
    return apiCall("post", `${PREFIX}/update`, data);
  },

  getUsers(data) {
    return apiCall("get", `${PREFIX}${createQueryString(data)}`);
  },

  updateAvatar(data) {
    return apiCall("post", `${PREFIX}/avatar`, data);
  },

  createUser(data) {
    return apiCall("post", `${PREFIX}`, data);
  },

  updateUser(userID, data) {
    return apiCall("post", `${PREFIX}/${userID}/update`, data);
  },

  deleteUser(userID) {
    return apiCall("delete", `${PREFIX}/${userID}`);
  },
  removeAvatar(userId) {
    return apiCall("get", `${PREFIX}/${userId}/avatar`);
  },

  getNotifications() {
    return apiCall("get", `${PREFIX}/notifications`);
  },

  readAllNotifications() {
    return apiCall("delete", `${PREFIX}/notifications/all`);
  },

  readNotification(notifID) {
    return apiCall("delete", `${PREFIX}/notifications/${notifID}`);
  },
};
