<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="toggleModal"
            v-if="$acl.can('create', 'order-status')"
          />
          <hr />
          <bita-grid ref="grid" :columns="gridColumns" :read-data="readData" />
        </div>
      </bita-ibox>
    </div>
    <order-status-form
      ref="form"
      :title="formTitle"
      :is-loading="isLoadingModal"
      @on-create="(e) => save(e)"
      @on-update="(e) => update(e)"
    />
  </div>
</template>
<script>
import OrderStatusForm from "./components/OrderStatusForm";
import { orderStatusesApi } from "@/api/apiManagement";

export default {
  components: { OrderStatusForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت وضعیت های سفارش", link: "" },
      ],
      isLoadingModal: false,
      isLoadingGrid: false,
      formTitle: "",
      measures: [],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    gridColumns() {
      let commands = [];

      if (this.$acl.can("edit", "order-status"))
        commands.push({
          text: "ویرایش",
          name: "my",
          // template: this.gridButtons.edit,
          click: this.editRow,
        });

      if (this.$acl.can("delete", "order-status"))
        commands.push({
          text: "حذف",
          name: "delete",
          // template: this.gridButtons.delete,
          click: this.delete,
        });
      return [
        { field: "title", title: "نام وضعیت" },
        { field: "send_email", title: "ارسال ایمیل", template: e => e.send_email ? 'بله' : 'خیر' },
        { field: "send_sms", title: "ارسال پیامک", template: e => e.send_sms ? 'بله' : 'خیر'  },
        {
          title: "عملیات",
          command: commands,
        },
      ];
    },
  },

  methods: {
    save(measure) {
      this.isLoadingModal = true;

      orderStatusesApi
        .create(measure)
        .then((res) => {
          this.formSucceeded(res.data.message);
          this.toggleModal();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    formSucceeded(message) {
      this.$izitoast("success", "عملیات موفق", message);
      this.$refs.form.reset();
      this.toggleModal();
      this.loadData();
    },
    update(measure) {
      this.isLoadingModal = true;

      orderStatusesApi
        .update(measure.id, measure)
        .then((res) => {
          this.formSucceeded(res.data.message);
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    toggleModal() {
      this.$toggleModal("#measure-form");
    },
    editRow(e) {
      e.preventDefault();
      let item = this.$refs.grid.getSelectedGridRow(e);
      let editForm = {
        id: item.id,
        title: item.title,
        send_email: item.send_email,
        send_sms: item.send_sms
      };
      this.formTitle = `ویرایش ${item.title}`;
      this.toggleModal();
      this.$refs.form.setForm(editForm);
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    readData(e) {
      this.isLoadingGrid = true;
      // this.$resetGrid();
      orderStatusesApi
        .get()
        .then(({ data }) => {
          e.success(data);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    delete(e) {
      e.preventDefault();
      let item = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف ${item.title} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          orderStatusesApi
            .delete(item.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
table td {
  text-align: center;
}
</style>
