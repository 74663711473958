import Route from "@/scripts/routeRegistrar"
const RouteRegistrar = new Route('management');

RouteRegistrar.add("management/users/Users", "users", "users", "مدیریت کاربران")
RouteRegistrar.add("management/roles/Roles", "roles", "roles", "نقش ها")
RouteRegistrar.add("management/comments/Comments", "comments", "comments", "کامنت ها");
RouteRegistrar.add("management/settings/Settings", "settings", "settings", "تنظیمات")
RouteRegistrar.add("management/files/FileManager", "files/filemanager", "filemanager", "مدیریت فایل ها")
RouteRegistrar.add("management/slides/Slides", "slides", "slides", "مدیریت اسلاید ها")
RouteRegistrar.add("management/staticpages/StaticPages", "static-pages", "static-pages", "صفحات");
RouteRegistrar.add("management/profile/Profile", "profile", "profile", "پروفایل");
RouteRegistrar.add("management/menus/Menus", "menus", "menus", "منوها");
RouteRegistrar.add("management/menus/MenuItems", "menus/:menu/items", "menu-items", "زیرمنوها", true);
RouteRegistrar.add("management/faq/Faq", "faq", "faq", "پرسش و پاسخ");
RouteRegistrar.add("management/permissions/Permissions", "permissions", "permissions", "دسترسی ها");
RouteRegistrar.add("management/jobsmonitoring/JobsMonitoring", "jobs", "jobs", "مانیتورینگ صف ها", true);
RouteRegistrar.add("management/appIcon/AppIcons", "app-icons", "app-icons", "آیکن ها");
RouteRegistrar.add("management/socialMedia/SocialMedia", "social-media", "social-media", "شبکه های اجتماعی");
RouteRegistrar.add("management/fiscalyear/FiscalYear", "fiscalyear", "fiscal-years", "سال مالی")

const routes = RouteRegistrar.allRoutes()


export default routes;
