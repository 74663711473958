<template>
  <div slot="body">
    <bita-bootbutton v-show="this.isUpdateMode"
                  title="کپی از  محصول"
                  type="primary"
                  icon="fas fa-plus-square"
                  btn-width="200px"
                  @click.native="createCopyOfProduct(form.id)"
                  v-if="$acl.can('view', 'admin-of-sellers')"
                />
    <bita-form ref="form">
      <div slot="body">
        <div class="row">
          <bita-input
            type="text"
            name="name"
            label="نام محصول"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="form.name"
          />
          <bita-input
            type="text"
            name="second_name"
            label="نام دوم محصول"
            subtitle="عنوان لاتین"
            rules=""
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="form.second_name"
          />
          <bita-input
            type="text"
            name="min_order_quantity"
            label="حداقل تعداد قابل سفارش"
            subtitle="مقدار صفر یا تهی به معنای غیر فعال بودن است"
            :validation-container-class="largeColWidth"
            :has-thousand-seprator="true"
            input-class="form-control"
            v-model="form.min_order_quantity"
          />
          <bita-input
            type="text"
            name="max_order_quantity"
            label="حداکثر تعداد قابل سفارش"
            subtitle="مقدار صفر یا تهی به معنای غیر فعال بودن است"
            :has-thousand-seprator="true"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="form.max_order_quantity"
          />
          <bita-treeselect
            name="category_id"
            ref="categoryTreeselect"
            label="گروه"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="categories"
            data-text-field="name"
            data-value-field="id"
            v-model="form.category_id"
          />
          <bita-select
            name="brand_id"
            ref="brandSelect"
            label="برند"
            rules="required"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="brands"
            data-text-field="name"
            data-value-field="id"
            v-model="form.brand_id"
          />
          <bita-select
            name="measure_id"
            ref="measureSelect"
            label="واحد اندازه گیری"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="measures"
            data-text-field="name"
            data-value-field="id"
            v-model="form.measure_id"
          />

          <bita-select
            name="is_recommendation"
            ref="recommendedSelect"
            label="پیشنهاد لحظه ای"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="[
              { id: 0, name: 'خیر' },
              { id: 1, name: 'بله' },
            ]"
            data-text-field="name"
            data-value-field="id"
            v-model="form.is_recommended"
          />
          <bita-input
            type="number"
            name="coefficient"
            label="ضریب محصول"
            :has-thousand-seprator="true"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            v-model="form.coefficient"
            subtitle="تعداد محصول انتخابی در این عدد ضرب می شود"
          />
          <bita-select
            name="active"
            ref="activeSelect"
            label="وضعیت"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="[
              { id: 0, name: 'غیر فعال' },
              { id: 1, name: 'فعال' },
            ]"
            data-text-field="name"
            data-value-field="id"
            v-model="form.active"
          />
          <bita-uploader
              name="uploader"
              label="فایل راهنما"
              rules="size:50"
              ref="uploader"
              :validation-container-class="largeColWidth"
              input-class="form-control"
              v-model="form.help"
              :preview="false"
              :icon-url="form.help_file ? form.help_file.full_url : null"
            />
            <bita-select
            name="shaow_lastupdate"
            ref="activeSelect"
            label="نمایش آخرین بروزرسانی قیمت"
            :validation-container-class="largeColWidth"
            input-class="form-control"
            :data-source="[
              { id: 0, name: 'غیر فعال' },
              { id: 1, name: 'فعال' },
            ]"
            data-text-field="name"
            data-value-field="id"
            v-model="form.show_lastupdate"
          />
          <bita-input
            type="number"
            name="product_stock_limit_alert"
            label="هشدار اتمام موجودی"
            subtitle="در صورتی که موجودی محصول به این مقدار رسید "
            :validation-container-class="largeColWidth"
            :has-thousand-seprator="true"
            input-class="form-control"
            v-model="form.product_stock_limit_alert"
          />
          <bita-textarea
            name="short_description"
            ref="shortDescriptionTextarea"
            label="توضیح مختصر"
            rules=""
            validation-container-class="col-lg-12"
            input-class="form-control"
            v-model="form.short_description"
          />
          <bita-texteditor
            name="description"
            ref="descriptionTextarea"
            label="توضیح"
            rules=""
            validation-container-class="col-lg-12"
            input-class="form-control"
            v-model="form.description"
          />
          <bita-texteditor
            name="baner"
            ref="banerTextarea"
            label="اطلاعات اضافه و بنر"
            rules=""
            validation-container-class="col-lg-12"
            input-class="form-control"
            v-model="form.baner"
          />
        </div>
      </div>
    </bita-form>
  </div>
</template>
<script>
import {
  categoryApi,
  brandApi,
  productApi,
  measureApi,
} from "@/api/apiManagement";
export default {
  props: {
    title: String,
    isLoading: Boolean,
    largeColWidth: {
      default: "col-lg-3",
    },
    smallColWidth: {
      default: "col-lg-2",
    },
    product: {},
  },
  data() {
    return {
      form: {},
      categories: [],
      brands: [],
      measures: [],
    };
  },
  mounted() {
    var _this = this;
    Promise.all([
      categoryApi.getCategories(),
      brandApi.get(),
      measureApi.getMeasures(),
    ]).then((res) => {
      this.categories = res[0].data.entity;
      this.brands = res[1].data.entity;
      this.measures = res[2].data.entity;
    });
  },
  watch: {
    product: function (_data) {
      this.form = _data;
      // this.$refs.shortDescriptionTextarea.setInitialValue(
      //   _data.short_description
      // );
      this.$refs.descriptionTextarea.setInitialValue(_data.description);
      this.$refs.banerTextarea.setInitialValue(_data.baner);
    },
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
    isUpdateMode() {
      return this.form.id && this.form.id > 0;
    },
  },
  methods: {
    setInitialValue(_data) {
      this.form = _data;
      console.log('id product')
      console.log(this.form.id)
      // this.$refs.shortDescriptionTextarea.setInitialValue(
      //   _data.short_description ? _data.short_description : ""
      // );
      this.$refs.descriptionTextarea.setInitialValue(
        _data.description ? _data.description : ""
      );
      this.$refs.banerTextarea.setInitialValue(
        _data.baner ? _data.baner : ""
      );
    },

    save() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          if (result) {
            this.$emit("is-loading", true);
            if (this.isUpdateMode) {
              console.log(this.$getFormData(this.form))
              productApi
                .updateProductInfo(this.form.id, this.$getFormData(this.form))
                .then((res) => {
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
            } else {
              productApi
                .createProduct(this.$getFormData(this.form))
                .then((res) => {
                  this.form = res.data.entity;
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
            }
          } else reject(false);
        });
      });
    },

    validate() {
      return this.$refs.form.validate();
    },

    setErrors(err) {
      this.$refs.form.setErrors(err);
    },

    reset() {
      this.form = {};
      this.$refs.categoryTreeselect.emptyKendo();
      this.$refs.brandSelect.emptyKendo();
      this.$refs.recommendedSelect.emptyKendo();
      this.$refs.activeSelect.emptyKendo();
      // this.$refs.shortDescriptionTextarea.reset();
      this.$refs.descriptionTextarea.reset();
      this.formRef.reset();
    },

    createCopyOfProduct(id) {
      productApi
                .createCopyOfProduct(this.form.id)
                .then((res) => {
                  this.$emit("on-update", this.form);
                  resolve(true);
                })
                .finally(() => {
                  this.$emit("is-loading", false);
                })
                .catch((err) => {
                  this.setErrors(err.response.data.errors);
                  reject(err);
                });
    }
  },
};
</script>
