<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <!-- <bita-bootbutton
            title="افزودن قالب"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="toggleModal"
          />
          <hr /> -->
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <templates-form
      id="template-form"
      ref="templateForm"
      :title="title"
      :is-loading="isLoadingModal"
      @on-update="update"
    />
  </div>
</template>
<script>
import { notificationTemplateApi } from "@/api/apiManagement";
import TemplatesForm from "./components/TemplatesForm.vue";
export default {
  components: { TemplatesForm },
  data() {
    return {
      isLoadingGrid: false,
      isLoadingModal: false,
      title: "مدیریت قالب",
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "تنظیم قالب اعلان", link: "/notifications/templates" },
      ],
    };
  },
  computed: {
    gridColumns() {
      return [
        { field: "name", title: "نام" },
        {
          field: "type",
          title: "نوع",
          template: (e) => this.typeTemplate(e),
        },
        { field: "slug", title: "زمان ارسال" },
        {
          title: "عملیات",
          command: this.gridCommands(),
        },
      ];
    },
  },
  methods: {
    typeTemplate(e) {
      if (!e || !e.template_type) return "-";
      switch (e.template_type) {
        case 1:
          return "SMS";
        case 2:
          return "E-Mail";
        case 3:
          return "Verification";
      }
    },
    readData(e) {
      this.isLoadingGrid = true;
      notificationTemplateApi
        .getTemplates()
        .then((res) => {
          e.success(res.data.entity);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      return commands;
    },
    edit(e) {
      this.isLoadingModal = true;
      let template = this.$refs.grid.getSelectedGridRow(e);
      this.toggleModal();
      this.$refs.templateForm.setInitialData(template);
      this.isLoadingModal = false;
    },
    update(e) {
      this.isLoadingModal = true;
      notificationTemplateApi
        .updateTemplates(e.id, e)
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.reset();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    toggleModal() {
      this.$toggleModal("#template-form");
    },
    reset() {
      this.toggleModal();
      this.$refs.templateForm.setInitialData([]);
      this.loadData();
    },
  },
};
</script>
