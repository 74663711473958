<template>
  <bita-modal
    :id="id"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="title"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
            />
            <bita-input
              type="text"
              name="slug"
              label="آدرس"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.slug"
            />
            <bita-textarea
              name="description"
              ref="descriptionTextarea"
              label="توضیح مختصر"
              rules=""
              validation-container-class="col-lg-12"
              input-class="form-control"
              v-model="formData.description"
            />
            <bita-texteditor
              name="content"
              ref="contentTextarea"
              label="محتوای صفحه"
              rules=""
              validation-container-class="col-lg-12"
              input-class="form-control"
              v-model="formData.content"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleCreate"
      />
      <bita-bootbutton
        v-if="formData.id"
        type="primary"
        title="به روزرسانی"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="handleUpdate"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    isLoading: Boolean,
    title: String,
    id: String,
    propsData: {},
  },
  data() {
    return {
      colWidth: "col-lg-4",
      formData: {},
      isLoadingModal: false,
    };
  },
  watch: {
    propsData: function (_val) {
      this.formData = _val;
      this.setInitialValue(_val.content);
    },
    isLoading: function (_val) {
      this.isLoadingModal = _val;
    },
  },
  methods: {
    setInitialValue(_data) {
      this.form = _data;
      this.$refs.contentTextarea.setInitialValue(_data ? _data : "");
    },
    handleCreate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    handleUpdate() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    setErrors(err) {
      this.$refs.form.setErrors(err);
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>