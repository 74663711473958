<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            v-if="!showForm && $acl.can('create', 'brands')" 
            type="primary"
            title="برند جدید"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="addModal"
          />
          <hr />
          <bita-form ref="filterForm">
            <div slot="body">
              <div class="row">
                <bita-input
                  type="text"
                  name="name"
                  label="جستجو"
                  validation-container-class="col-lg-4"
                  input-class="form-control"
                  v-model.lazy="filters.search"
                  @keyup.enter.prevent="loadData"
                />
                <div class="col-lg-4">
                  <a class="margin-top btn btn-success" @click="loadData()">
                    جستجو
                  </a>
                  <a
                    class="margin-top margin-right btn btn-warning"
                    @click="resetFilters()"
                  >
                    پاک سازی
                  </a>
                </div>
              </div>
            </div>
          </bita-form>
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :page-size="pageSize"
            :current-page="currentPage"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <form-modal
      ref="editModal"
      :isLoading="isLoadingModal"
      :title="title"
      :form-data="this.updateForm"
      :countries="this.countries"
      @on-create="onCreate"
      @on-update="onUpdate"
    />
  </div>
</template>
<script>
import BrandsForm from "./components/BrandForm";
import { brandApi, areaApi } from "@/api/apiManagement.js";

export default {
  components: { "form-modal": BrandsForm },
  data() {
    return {
      pageSize: 10,
      filters: {},
      currentPage: 1,

      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        {
          title: "مدیریت برندها",
          link: "/shop/brands",
          active: true,
        },
      ],

      isLoadingGrid: false,
      isLoadingModal: false,
      showForm: false,
      updateForm: {},
      countries: [],
      gridColumns: [
        {
          field: "name",
          title: "نام",
          template: ({ name }) => (name ? name : " - "),
        },
        {
          field: "country",
          title: "کشور",
          template: ({ country }) =>
            country && country.name ? country.name : " - ",
        },
        {
          field: "website",
          title: "وبسایت",
          template: ({ website }) => (website ? website : " - "),
        },
        {
          title: "عملیات",
          command: [],
        },
      ],
    };
  },
  computed: {
    title() {
      let title = "افزودن";
      if (this.updateForm.id) title = "ویرایش";
      return title + " برند ";
    },
  },
  mounted() {
    this.loadData();
    this.getCountries();
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    resetFilters() {
      this.filters = {};
      this.$refs.filterForm.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
    },
    getCountries() {
      areaApi.getCountries().then(({ data }) => {
        this.countries = data;
      });
    },
    onCreate(data) {
      this.isLoadingModal = true;

      brandApi
        .create(data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .catch((err) => {
          this.$refs.editModal.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    successForm() {
      this.updateForm = {};

      this.loadData();
      this.$refs.editModal.reset();

      this.$toggleModal("#edit-modal");
    },
    onUpdate(data) {
      this.isLoadingModal = true;

      brandApi
        .update(data.get("id"), data)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);

          this.successForm();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    readData(e) {
      this.$resetGrid();
      this.isLoadingGrid = true;

      brandApi
        .get(this.filters)
        .then(({ data }) => {
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },

    delete(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);

      this.$alert("question", `آیا از حذف ${data.name} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          brandApi
            .delete(data.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("edit", "brands")) {
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
    }
    if (this.$acl.can("delete", "brands")) {
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
    }
      return commands;
    },
    openModal() {
      this.$toggleModal("#edit-modal");
    },
    addModal() {
      this.updateForm = {};
      this.openModal();
    },
    edit(e) {
      let data = this.$refs.grid.getSelectedGridRow(e);

      var newData = {
        id: data.id,
        description: data.description,
        icon: data.icon,
        icon_url: data.icon_url,
        name: data.name,
        country_id: data.country_id,
        website: data.website,
      };

      this.$refs.editModal.setForm(newData);

      this.openModal();
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
.badge {
  margin: 2px;
  font-size: 13px !important;
  font-weight: 800;
}
</style>
