<template>
  <bita-modal
    :id="id"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <!-- <bita-input
              type="text"
              name="tracking_code"
              label="کد پیگیری"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="propsData.tracking_code"
              :disabled="true"
            /> -->
            <bita-input
              type="text"
              name="from"
              label="از"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="propsData.from"
              :disabled="true"
            />
            <bita-input
              type="text"
              name="to"
              label="به"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="propsData.to"
              :disabled="true"
            />
            <!-- <bita-input
              type="text"
              name="delivery_status"
              label="وضعیت"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="propsData.delivery_status"
              :disabled="true"
            /> -->
            <bita-input
              type="text"
              name="created_at"
              label="زمان ارسال"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              :value="this.$getJalaliDateTime(propsData.created_at)"
              :disabled="true"
            />
            <div class="col-lg-12">
                <label for="message"> وضعیت </label>
                <div> {{ propsData.response }} </div>
            </div>
            <br/>
            <div class="col-lg-12 mt-5">
                <label for="message"> پیام </label>
                <div> {{ propsData.message }} </div>
            </div>
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
    props: {
        isLoading: Boolean,
        id: String,
        title: String,
        propsData: {}
    },
    data() {
        return {
            colWidth: 'col-lg-4',
        }
    },
    watch: {
        // propsData: function(_val) {
        //     this.$refs.messageTextarea.setInitialValue(
        //         this.propsData.message ? this.propsData.message : ""
        //     );
        // }
    },
    methods: {
      reset() {

      }
    }
}
</script>