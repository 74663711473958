<template>
  <bita-modal
    id="product-item"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
  >
    <div slot="body">
        <bita-form ref="form">
          <div slot="body">
            <div class="row">
              <bita-select-server
                  name="products"
                  ref="productSelect"
                  label="محصول"
                  rules="required"
                  :validation-container-class="colWidth"
                  subtitle="لطفا محصول مورد نظر را جستجو نمایید."
                  input-class="form-control"
                  :data-source="products"
                  data-text-field="name"
                  data-value-field="id"
                  :get-data="getProducts"
                  v-model="product_id"
              />
            </div>
          </div>
        </bita-form>
        <bita-bootbutton
          type="primary"
          title="افزودن محصول"
          icon="fas fa-plus-square"
          btn-width="200px"
          @click.native="create"
        />
      <hr />
      <bita-grid
        v-if="slide.id"
        ref="productGrid"
        :columns="gridColumns"
        :read-data="readData"
        :page-size="pageSize"
        :current-page="currentPage"
        :has-row-number="true"
      />
    </div>
  </bita-modal>
</template>
<script>
import { productCollectionApi, productApi } from "@/api/apiManagement";

export default {
  data() {
    return {
      isLoadingModal: false,
      colWidth: "col-lg-4",
      products: [],
      slide: {},
      product_id: "",
      title: "",
      pageSize: 10,
      currentPage: 1,
    };
  },
  computed: {
    gridColumns() {
      return [
        { title: "نام محصول", template: e => ( e.name ? e.name : '-') },
        { title: "عملیات", command: this.gridCommands() }
      ]
    }
  },
  methods: {
    setInitialData(_data) {
      this.slide = _data;
      this.title = `افزودن محصول به ${_data.title}`;
    },
    readData(e) {
      this.isLoadingModal = true;
      productCollectionApi.getProducts(this.slide.id)
        .then( (res) => {
          e.success(res.data.entity);
        })
        .catch( () => e.success([]))
        .finally( () => this.isLoadingModal = false );

    },
    loadData() {
      this.$refs.productGrid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      commands.push({
        text: 'delete',
        click: this.delete,
        template: this.gridButtons.delete
      });
      return commands;
    },
    create() {
      this.$refs.form.validate()
        .then( (res) => {
          if ( res ) {
            this.isLoadingModal = true;
            productCollectionApi.postProduct(this.slide.id, this.product_id)
              .then( (res) => {
                this.$izitoast("success", "موفق!", res.data.message);
                this.$refs.productSelect.clear();
                this.loadData();
                this.product_id = "";
                this.$refs.form.reset();
              })
              .finally( () => this.isLoadingModal = false );
          }
        })
    },
    delete(e) {
      let product = this.$refs.productGrid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف ${product.name} اطمینان دارید؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingModal = true;
          productCollectionApi.deleteProduct(this.slide.id, product.pivot.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingModal = false;
            });
        }
      });
    },
    getProducts(e) {
        let filter = {
            search: e.filter?.value
        };
        productApi.getProducts(filter)
            .then( (res) => {
                this.products = _.unionBy(res.data, this.products, "id")
            })
    },
    reset() {
      this.slide = {};
      this.product_id = "";
      this.$refs.productSelect.clear();
      this.$emit("load-data");
      this.$refs.form.reset();
    },
  },
};
</script>
