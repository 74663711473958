<template>
  <bita-modal
    id="shipping-form"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <bita-input
              type="text"
              name="name"
              label="نام"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.name"
            />
            <bita-input
              type="text"
              name="duration"
              label="مدت زمان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.duration"
            />
            <bita-uploader
              name="icon"
              label="تصویر"
              ref="uploader"
              rules="size:50"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.icon"
              :icon-url="formData.icon_url"
            />
            <bita-select
              name="status"
              label="وضعیت"
              ref="statusDropDown"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="statuses"
              data-text-field="title"
              data-value-field="id"
              v-model="formData.status"
            />
            <bita-textarea
              name="description"
              label="توضیحات"
              rules=""
              validation-container-class="col-lg-12"
              input-class="form-control"
              v-model="formData.description"
            />
          </div>
        </div>
      </bita-form>
      <div class="row mt-2 mb-2">
        <div class="col-lg-3">
          <bita-bootbutton
            v-if="formData.id"
            :title="shippingPriceButton.text"
            :type="shippingPriceButton.type"
            :icon="shippingPriceButton.icon"
            btn-width="200px"
            @click.native="addShippingPrice"
          />
        </div>
      </div>
      <bita-form v-if="addShippingPriceInput" ref="shippingpriceform">
        <div slot="body">
          <div class="row mt-3 mb-3">
            <bita-input
              type="text"
              name="from"
              label="از"
              subtitle="مبلغ سفارش"
              rules="required"
              :has-thousand-seprator="true"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="shippingPricesData.from"
            />
            <bita-input
              type="text"
              name="to"
              label="تا"
              rules="required"
              subtitle="مبلغ سفارش"
              :has-thousand-seprator="true"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="shippingPricesData.to"
            />
            <bita-input
              type="text"
              name="price"
              label="هزینه"
              rules="required"
              :has-thousand-seprator="true"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="shippingPricesData.price"
            />
          </div>
          <bita-bootbutton
            v-if="!shippingPricesData.id"
            type="primary mt-2 mb-2"
            title="ذخیره"
            icon="fas fa-save"
            btn-width="150px"
            @click.native="saveShippingPrice"
          />
          <bita-bootbutton
            v-if="shippingPricesData.id"
            type="primary mt-2 mb-2"
            title="به روزرسانی"
            icon="fas fa-save"
            btn-width="150px"
            @click.native="updateShippingPrice"
          />
        </div>
      </bita-form>
      <div class="row">
        <bita-grid
          v-show="formData.id"
          ref="modalgrid"
          :columns="gridColumns"
          :read-data="readData"
        />
      </div>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!formData.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="save"
      />
      <bita-bootbutton
        v-if="formData.id"
        type="primary"
        title="به روزرسانی"
        icon="fas fa-save"
        btn-width="150px"
        @click.native="update"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
export default {
  props: {
    isLoading: Boolean,
    title: String,
    id: String,
    propsData: {},
    shippingPrices: {},
  },
  data() {
    return {
      colWidth: "col-lg-4",
      formData: {},
      shippingPricesGrid: {},
      shippingPricesData: {},
      addShippingPriceInput: false,
      statuses: [
        { id: 1, title: "فعال" },
        { id: 2, title: "غیر فعال" },
      ],
      shippingPriceButton: {
        text: "افزودن بازه قیمت جدید",
        type: "primary",
        icon: "fas fa-plus-square",
      },
      gridColumns: [
        { title: "#", template: this.$getTemplate, width: "40px" },
        {
          title: "از",
          template: (e) => (e.from ? this.$thousandSeprator(e.from) : "-"),
        },
        {
          title: "تا",
          template: (e) => (e.to ? this.$thousandSeprator(e.to) : "-"),
        },
        {
          title: "هزینه ارسال",
          template: (e) => (e.price ? this.$thousandSeprator(e.price) : "-"),
        },
        {
          title: "عملیات",
          command: [],
        },
      ],
      isLoadingModal: false,
    };
  },
  watch: {
    propsData: function (_val) {
      this.formData = _val;
      this.loadData();
    },
    shippingPrices: function (_val) {
      this.shippingPricesGrid = _val;
    },
    isLoading: function (_val) {
      this.isLoadingModal = _val;
    },
  },
  mounted() {
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
  },
  methods: {
    readData(e) {
      const _this = this;
      setTimeout(function () {
        if (_this.shippingPricesGrid.length > 0)
          e.success(_this.shippingPricesGrid);
        else e.success([]);
      }, 400);
    },
    loadData() {
      this.$refs.modalgrid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      commands.push({
        text: "edit",
        click: this.edit,
        template: this.gridButtons.edit,
      });
      commands.push({
        text: "delete",
        click: this.delete,
        template: this.gridButtons.delete,
      });
      return commands;
    },
    addShippingPrice() {
      if (this.addShippingPriceInput) return this.addShippingPricebutton();
      return this.removeShippingPriceButton();
    },
    removeShippingPriceButton() {
      this.addShippingPriceInput = true;
      this.shippingPriceButton = {
        text: "حذف",
        type: "danger",
        icon: "fas fa-minus-square",
      };
      this.shippingPricesData = { from: 0, to: 0, price: 0 };
    },
    addShippingPricebutton() {
      this.addShippingPriceInput = false;
      this.shippingPriceButton = {
        text: "افزودن شیوه ارسال جدید",
        type: "primary",
        icon: "fas fa-plus-square",
      };
      this.shippingPricesData = {};
    },
    edit(e) {
      this.isLoadingModal = true;
      let shipping = this.$refs.modalgrid.getSelectedGridRow(e);
      this.addShippingPriceInput = true;
      const _this = this;
      setTimeout(function () {
        _this.shippingPricesData = shipping;
      }, 100);
      this.isLoadingModal = false;
    },
    delete(e) {
      let shippingPrices = this.$refs.modalgrid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف این مورد اطمینان دارید ؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.$emit("on-delete-shippingprice", shippingPrices);
        }
      });
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.formData);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.formData);
        }
      });
    },
    saveShippingPrice() {
      this.isLoadingModal = true;
      this.$refs.shippingpriceform.validate().then((result) => {
        if (result) {
          let data = {
            price: this.shippingPricesData.price,
            from: this.shippingPricesData.from,
            to: this.shippingPricesData.to,
            shipping_id: this.formData.id,
          };
          this.$emit("on-create-shippingprice", data);
        }
      });
    },
    updateShippingPrice() {
      this.isLoadingModal = true;
      this.$refs.shippingpriceform.validate().then((result) => {
        if (result) {
          this.$emit("on-update-shippingprice", this.shippingPricesData);
        }
      });
    },
    reset() {
      this.shippingPricesData = {};
      this.addShippingPriceInput = false;
      this.addShippingPricebutton();
      this.$refs.form.reset();
      this.$refs.uploader.reset();
    },
  },
};
</script>
