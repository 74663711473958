import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/orders/statuses"

export default {

    get() {
        return apiCall('get', PREFIX)
    },

    create(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    update(id, data) {
        return apiCall('patch', `${PREFIX}/${id}`, data)
    },

    delete(id) {
        return apiCall('delete', `${PREFIX}/${id}`)
    }
}