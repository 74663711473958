import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/carts"

export default {

    getCarts(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },
    
    getCart(_cartID) {
        return apiCall('get', `/${_cartID}`)
    }
}