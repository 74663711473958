import { apiCall, createQueryString } from "../imports"

const PREFIX = "management/comments"

export default {

    getComments(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    replyComment(commentId,data) {
        return apiCall('post', `${PREFIX}/${commentId}/reply`, data)
    },

    toggleCommentStatus(commentId) {
        return apiCall('post', `${PREFIX}/${commentId}/toggle-status`)
    },
    
    deleteComments(commentId) {
        return apiCall('delete', `${PREFIX}/${commentId}`)
    },
}