<template>
  <bita-modal
    id="ticketing-form"
    :modal-lg="true"
    :is-loading="isLoadingModal"
    @close="reset()"
    :title="title"
  >
    <div slot="body">
      <bita-form ref="form" v-if="$acl.can('edit', 'tickets')"
>
        <div slot="body">
          <div class="row">
            
            <bita-input
              type="text"
              name="name"
              label="نام ایجاد کننده"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="user.name"
              :disabled="true"
            />
            <bita-input
              type="text"
              name="email"
              label="پست الکترونیک"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="user.email"
              :disabled="true"
            />
            <bita-input
              type="text"
              name="mobile"
              label="تلفن همراه"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="user.mobile"
              :disabled="true"
            />
            <bita-select
              name="type"
              ref="categories"
              label="وضعیت"
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="statuses"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.status"
            />
            <bita-input
              type="text"
              name="title"
              label="عنوان"
              rules="required"
              :validation-container-class="colWidth"
              input-class="form-control"
              v-model="formData.title"
              :disabled="true"
            />
            <bita-textarea
              v-if="edit"
              type="text"
              name="message"
              label="پیام"
              validation-container-class="col-12"
              input-class="form-control"
              v-model="formData.message"
              rowspan="5"
            />
          </div>
        </div>
      </bita-form>
      <div class="my-2">
        <bita-bootbutton
          v-if="$acl.can('edit','tickets')"
          title="بروزرسانی"
          type="primary"
          icon="fas fa-save"
          btn-width="150px"
          @click.native="update"
        />
      </div>
      <div class="small-chat-box">
        <div class="content">
          <!-- <div :class="isMyComment(formData) ? 'right' : 'left'">
            <small
              v-if="isMyComment(replay)"
              class="chat-date chat-icon text-success"
              @click="editTicket(formData)"
            >
              <i class="fas fa-pen-square"> </i>
            </small>
            <small
              v-if="isMyComment(replay)"
              class="chat-date chat-icon text-danger"
              @click="deleteTicket(formData)"
            >
              <i class="fas fa-times"> </i>
            </small>
            <div class="author-name">
              {{ formData.user ? formData.user.full_name : "" }}
              <small class="chat-date">{{
                $getJalaliDateTime(formData.created_at)
              }}</small>
            </div>
            <div
              :class="
                isMyComment(formData) ? 'chat-message' : 'chat-message active'
              "
            >
              {{ formData.message }}
            </div>
          </div> -->
          
        
            <div class="small-chat-box">
                <div class="content">
                <div :class="isMyComment(formData) ? 'right' : 'left'">
                    <small
                        v-if="formData.files && formData.files.length > 0"
                        class="chat-date chat-icon text-success"
                        @click="openFiles(formData)"
                        data-toggle="tooltip" title="نمایش فایل پیوست"
                    >
                        <i class="fa fa-link"> </i>
                    </small>

                    <small v-if="$acl.can('edit','tickets') && formData.status !=3" class="chat-date chat-icon text-success" @click="editTicket(formData)" data-toggle="tooltip" title="ویرایش">
                        <i class="fas fa-pen-square"> </i>
                    </small>
                    <small v-if="$acl.can('delete','tickets') && formData.status !=3" class="chat-date chat-icon text-danger" @click="deleteTicket(formData)" data-toggle="tooltip" title="حذف">
                        <i class="fas fa-times"> </i>
                    </small>
                    <div class="author-name">
                    {{ formData.user ? formData.user.full_name : "" }}
                    <small class="chat-date">{{
                        $getJalaliDateTime(formData.created_at)
                    }}</small>
                    </div>
                    <div 
                    :class="
                        isMyComment(formData) ? 'chat-message' : 'chat-message active'
                    "
                    >
                    {{ formData.message }}
                    </div>
                </div>
                
                <div 
                    :class="isMyComment(replay) ? 'right' : 'left'"
                    v-for="replay in formData.replies"
                    :key="replay.id"
                >
                
                    <small 
                        v-if="replay.files && replay.files.length > 0" 
                        class="chat-date chat-icon text-success" 
                        @click="openFiles(replay)"
                        data-toggle="tooltip" title="نمایش فایل پیوست"
                    >
                        <i class="fa fa-link"> </i>
                    </small>
                    <small class="chat-date chat-icon text-success" v-if="$acl.can('edit','tickets') && replay.status !=3" @click="editReply(replay)" data-toggle="tooltip" title="ویرایش">
                        <i class="fas fa-pen-square"> </i>
                    </small>
                    <small class="chat-date chat-icon text-danger" v-if="$acl.can('delete','tickets') && replay.status !=3" @click="deleteReply(replay)" data-toggle="tooltip" title="حذف">
                        <i class="fas fa-times"> </i>
                    </small>
                    <div class="author-name">
                    {{ replay.user.full_name }}
                    <small class="chat-date">{{
                        $getJalaliDateTime(replay.created_at)
                    }}</small>
                    </div>
                    <div
                    :class="
                        isMyComment(replay)
                        ? 'chat-message'
                        : 'chat-message active'
                    "
                    >
                    
                    {{ replay.message }}
                    </div>
                </div>
                </div>
                <!-- <div class="form-chat">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" v-model="form.message" style="height: 33px;"/>
                    <span class="input-group-btn">
                    <button
                        v-if="form.id"
                        class="btn btn-primary"
                        type="button"
                        @click="updateReply"
                    >
                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                    <button
                        v-else
                        class="btn btn-primary"
                        type="button"
                        @click="sendReply"
                    >
                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                    <button
                        v-if="form.id"
                        class="btn btn-danger"
                        type="button"
                        @click="resetReply"
                    >
                        <i class="fas fa-times" aria-hidden="true"></i>
                    </button>
                    </span>
                </div>
                </div> -->
            </div>
          </div>
        </div>
        <div class="form-chat">
          <div class="input-group input-group-sm" v-if="formData.status !=3">
            <input
              type="text"
              class="form-control"
              v-model="form.message"
              style="height: 33px;"
            />
            <span class="input-group-btn">
              <button
                v-if="form.id"
                class="btn btn-primary"
                type="button"
                @click="updateReply"
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
              
              <button
                v-else
                class="btn btn-primary"
                type="button"
                @click="sendReply"
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
              <button
                v-if="form.id"
                class="btn btn-danger"
                type="button"
                @click="resetReply"
              >
                <i class="fas fa-times" aria-hidden="true"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </bita-modal>
</template>
<script>
import { ticketApi } from "@/api/apiManagement";
export default {
  props: {
    title: String,
  },
  data() {
    return {
      isLoadingModal: false,
      formData: {},
      form: {},
      colWidth: "col-4",
      statuses: [
        { id: 1, name: "در انتظار پاسخ" },
        { id: 2, name: "پاسخ داده شده" },
        { id: 3, name: "بسته شده است" },
      ],
      edit: false,
    };
  },
  computed: {
    user() {
      return this.formData && this.formData.user ? this.formData.user : {};
    },
  },
  methods: {
    setInitialData(_data) {
      this.formData = _data;
    },
    getTicket(_ticketID) {
      this.isLoadingModal = true;
      ticketApi
        .get(_ticketID)
        .then((res) => {
          this.formData = res.data.entity;
        })
        .finally(() => (this.isLoadingModal = false));
    },
    isMyComment(_reply) {
      if (_reply && _reply.user && this.formData && this.formData.user)
        return _reply.user.id == this.formData.user.id ? false : true;
    },
    editTicket(_ticket) {
      this.form = _ticket;
    },
    deleteTicket(_ticket) {
      this.$alert(
        "question",
        `آیا از حذف تیکت  ${_ticket.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingPage = true;
          ticketApi
            .delete(_ticket.id)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.$toggleModal("#ticketing-form");
              this.loadData();
            })
            .finally(() => (this.isLoadingPage = false));
        }
      });
    },
    editReply(_reply) {
      this.form = {
        id: _reply.id,
        message: _reply.message,
      };
    },
    deleteReply(_reply) {
      this.$alert(
        "question",
        `آیا از حذف تیکت  ${_reply.title} اطمینان دارید ؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingPage = true;
          ticketApi
            .deleteReply(this.formData.id, _reply.id)
            .then((res) => {
              this.$izitoast("success", "موفق!", res.data.message);
              this.getTicket(this.formData.id);
            })
            .finally(() => (this.isLoadingPage = false));
        }
      });
    },
  
     

 
        openFiles(_ticket) {
            let url = _ticket.files && _ticket.files.length > 0 && _ticket.files[0] ? _ticket.files[0].full_url : null;
            window.open(url, '_blank');
        },
       

        sendReply() {
            if ( this.form && this.form.message ) {
                this.isLoadingModal = true;
                ticketApi.createReply(this.formData.id, this.form)
                    .then( (res) => {
                        this.$izitoast("success", "موفق!", res.data.message);
                        this.getTicket(this.formData.id);
                        this.resetReply();
                    })
                    .finally( () => this.isLoadingModal = false );
            } else {
                this.$izitoast('error', 'ناموفق!', 'پیام الزامی است');
            }

        },
        updateReply() {
            if ( this.form && this.form.message ) {
                this.isLoadingModal = true;
                let data = {
                    message: this.form.message
                };
                ticketApi.updateReply(this.formData.id, this.form.id, data)
                    .then( (res) => {
                        this.$izitoast("success", "موفق!", res.data.message);
                        this.getTicket(this.formData.id);
                        this.resetReply();
                    })
                    .finally( () => this.isLoadingModal = false );
            }
        },
        resetReply() {
            this.form = {}
        },
        update() {
            this.$refs.form.validate()
                .then( (res) => {
                    if ( res ) {
                        let data = {
                            status: this.formData.status,
                            message: this.formData.message,
                            title: this.formData.title,
                            department_id: this.formData.department_id,
                        }
                        this.isLoadingModal = true;
                        ticketApi.update(this.formData.id, data)
                            .then( (res) => {
                                this.$izitoast("success", "موفق!", res.data.message);
                                this.getTicket(this.formData.id);
                            })
                            .finally( () => this.isLoadingModal = false );
                    }
                })
        },
        reset() {
            this.$emit("load-data");
        },
        
  }
}
</script>
<style scoped>
.chat-icon {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 10px;
}
</style>
