<template>
  <bita-modal
    id="edit-modal"
    :title="title"
    :modal-lg="true"
    :is-loading="isLoading"
    @close="resetForm"
  >
    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
          <div class="row">
            <div class="col-lg-12">
              <bita-formgroup title="مقادیر اولیه" class="row">
                <bita-input
                  type="text"
                  name="title"
                  label="عنوان"
                  rules="required"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  v-model="form.title"
                />

                <bita-select
                  name="value_type"
                  ref="value_type"
                  label="نوع تخفیف"
                  rules="required"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :data-source="priceTypes"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="form.value_type"
                />

                <bita-input
                  name="value"
                  label="مقدار تخفیف"
                  :has-thousand-seprator="true"
                  min="0"
                  rules="required"
                  v-if="!isPercentage()"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  v-model="form.value"
                />

                <bita-input
                  name="value"
                  type="number"
                  label="مقدار تخفیف"
                  v-else
                  min="0"
                  max="100"
                  rules="required"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  v-model="form.value"
                />

                <bita-select
                  name="status"
                  ref="status"
                  rules=""
                  label="وضعیت"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :data-source="statuses"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="form.status"
                />
                <bita-select
                  name="display_position"
                  ref="display_position"
                  rules=""
                  label="در صفحه اصلی نمایش داده شود؟"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  :data-source="diplayPositions"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="form.display_position"
                  v-if="$acl.can('view','admin-of-sellers')"
                />
                <bita-select
                  v-if="$acl.can('create', 'admin-of-sellers')"
                  label=" انتخاب  فروشنده"
                  name="seller_id"
                  ref="sellerSelect"
                  :validation-container-class="colWidth"
                  rules="required"
                  input-class="form-control"
                  :data-source="supplires"
                  data-text-field="seller_fullname"
                  data-value-field="id"
                  v-model="form.seller_id"
                />
              </bita-formgroup>
            </div>
            <div class="col-lg-6">
              <bita-formgroup class="row" title="انتخاب محصول">
                <bita-select
                  name="meta_type"
                  ref="meta_type"
                  label="نوع"
                  rules=""
                  :validation-container-class="colWidthHalf"
                  input-class="form-control"
                  :data-source="this.getMetaTypes()"
                  data-text-field="title"
                  data-value-field="id"
                  v-model="form.meta_type"
                  @change="changeMetaType"
                />
                <bita-select-server
                  v-show="isProduct(form.meta_type)"
                  name="products"
                  ref="products"
                  label="محصول"
                  subtitle="لطفا محصول مورد نظر را جستجو نمایید."
                  rules=""
                  :validation-container-class="colWidthHalf"
                  input-class="full-width full-height"
                  :data-source="products"
                  data-text-field="name"
                  data-value-field="id"
                  :get-data="getProducts"
                  v-model="form.meta_value"
                />
                <bita-treeselect
                  name="categories"
                  ref="categoryTreeselect"
                  label="دسته بندی"
                  rules=""
                  v-show="isCategory(form.meta_type)"
                  :validation-container-class="colWidthHalf"
                  input-class="form-control"
                  :data-source="categories"
                  data-text-field="name"
                  data-value-field="id"
                  v-model="form.meta_value"
                  :get-data="(e) => this.$emit('on-search-category', e)"
                />
                <bita-select
                  label="برند"
                  name="brands"
                  ref="brands"
                  v-show="isBrand(form.meta_type)"
                  :validation-container-class="colWidthHalf"
                  rules=""
                  input-class="form-control"
                  :data-source="brands"
                  data-text-field="name"
                  data-value-field="id"
                  v-model="form.meta_value"
                  :get-data="changeMetaType"
                />
              </bita-formgroup>
            </div>
            <div class="col-lg-6">
              <bita-formgroup title="زمان" class="row" size="col-lg-6">
                <bita-datepicker
                  type="datetime"
                  id="paymentat"
                  :validation-container-class="colWidthHalf"
                  name="start_at"
                  rules=""
                  label="شروع تخفیف"
                  inputClass="form-control"
                  v-model="form.start_at"
                  format="YYYY-MM-DD HH:mm:ss"
                  display-format="jYYYY/jMM/jDD HH:mm:ss"
                />

                <bita-datepicker
                  type="datetime"
                  id="paymentat"
                  :validation-container-class="colWidthHalf"
                  name="end_at"
                  rules=""
                  label="پایان تخفیف"
                  inputClass="form-control"
                  v-model="form.end_at"
                  format="YYYY-MM-DD HH:mm:ss"
                  display-format="jYYYY/jMM/jDD HH:mm:ss"
                />
              </bita-formgroup>
            </div>
            <div class="col-lg-12">
              <bita-formgroup title="اعمال تخفیف" class="row">
                <bita-input
                  name="min_price"
                  label="حداقل مبلغ شروع تخفیف"
                  :has-thousand-seprator="true"
                  min="0"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  v-model="form.min_price"
                />

                <bita-input
                  name="max_price"
                  label="حداکثر مبلغ تخفیف"
                  :has-thousand-seprator="true"
                  min="0"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  v-model="form.max_price"
                />

                <!-- <bita-input
                  type="number"
                  name="available_count"
                  label="تعداد تخفیف"
                  rules=""
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  min="0"
                  max="255"
                  v-model="form.available_count"
                />
                <bita-checkbox
                  name="is_disposable"
                  label="یکبار مصرف"
                  :validation-container-class="colWidth"
                  input-class="form-control"
                  @change="changeDisposable"
                  v-model="form.is_disposable"
                /> -->
              </bita-formgroup>
            </div>
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer">
      <bita-bootbutton
        v-if="!form.id"
        type="primary"
        title="ذخیره"
        icon="fas fa-save"
        btn-width="150px"
        :disabled="isLoading"
        @click.native="save"
      />
      <bita-bootbutton
        v-else
        title="بروزرسانی"
        type="primary"
        icon="fas fa-save"
        btn-width="150px"
        :disabled="isLoading"
        @click.native="update"
      />
    </div>
  </bita-modal>
</template>
<script>
import {
  supplireApi,
  categoryApi,
  brandApi,
  userApi,
  productApi,
} from "@/api/apiManagement.js";

export default {
  props: {
    title: String,
    isLoading: Boolean,
    brands: Array,
    categories: Array,
    products: Array,
    getProducts: Function,
  },
  data() {
    return {
      priceTitle: "",
      colWidth: "col-lg-3",
      colWidthHalf: "col-lg-6",
      form: {
        title: "",
        value_type: "",
        value: "",
        status: 1,
        meta_type: "",
        meta_value: "",
        start_at: "",
        end_at: "",
        min_price: 0,
        max_price: 0,
        display_position: 1,
        seller_id:"",
      },
      supplires:[],
      priceTypes: [
        { id: 1, title: "درصد" },
        { id: 2, title: "قیمت" },
      ],
      metaTypes: [
        { id: 3, title: "محصول" },
        { id: 2, title: "دسته بندی" },
        { id: 1, title: "برند" },
      ],
      statuses: [
        { id: 1, title: "فعال" },
        { id: 0, title: "غیر فعال" },
      ],
      diplayPositions: [
        { id: 1, title: "بله" },
        { id: 3, title: "خیر" },
      ],
      metaValues: [],
      searchApi: null,
      tempSelect: {
        products: [],
        brands: [],
        categories: [],
      },
    };
  },
  mounted() {
    if (this.$acl.can("create", "admin-of-sellers")) {
    this.getActiveSupplire();

    }
  },
  methods: {
    getActiveSupplire() {
       supplireApi.getActiveSupplire().then(({ data }) => {
        this.supplires = data.entity;
      });
    },
    getMetaTypes() {
      if (this.$acl.can("create", "admin-of-sellers"))
        return [
          { id: 3, title: "محصول" },
          { id: 2, title: "دسته بندی" },
          { id: 1, title: "برند" },
        ];
      return [{ id: 3, title: "محصول" }];
    },
    isPercentage() {
      return this.form.value_type == 1;
    },
    setForm(data) {
      this.form = {
        id: data.id,
        title: data.title,
        value_type: data.value_type,
        value: data.value,
        status: data.status,
        meta_type: data.meta_type,
        meta_value: String(data.meta_value),
        start_at: data.start_at,
        end_at: data.end_at,
        min_price: data.min_price,
        max_price: data.max_price,
        display_position: data.display_position,
        seller_id:data.seller_id
            };
    },
    getId(data) {
      return this.form.id;
    },
    changeDisposable(value) {
      this.form.is_disposable = value ? 0 : 1;
    },
    changeMetaType(value) {
      this.$emit("on-change-meta-type", value);
    },
    isProduct(metaValue) {
      return metaValue == 3;
    },
    setProductId(id) {
      this.form.meta_value = id;
    },
    isCategory(metaValue) {
      return metaValue == 2;
    },
    isBrand(metaValue) {
      return metaValue == 1;
    },
    save() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-create", this.form);
        }
      });
    },
    update() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-update", this.form);
        }
      });
    },
    resetForm() {
      this.$refs.form.reset();
      let selects = Object.values(this.$refs);
      selects.forEach((el) => {
        if (el && el.emptyKendo) el.emptyKendo();
      });
      this.form = {
        value: "",
        min_price: "",
        max_price: "",
      };
    },
    setErrors(_errors) {
      this.$refs.form.setErrors(_errors);
    },
  },
};
</script>
<style scoped>
.btn.btn-w-m {
  margin-left: 20px !important;
}
</style>
