<template>
  <div style="height: 100%">
    <loading
      :active.sync="$isLoading"
      :is-full-page="true"
    ></loading>
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import AppLayout from "./layouts/AppLayout";
import SimpleLayout from "./layouts/SimpleLayout";
export default {
  data() {
    return {

    };
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
  components: {
    "app-layout": AppLayout,
    "simple-layout": SimpleLayout,
    loading: Loading,
    // define as many layouts you want for the application
  },
};
</script>
