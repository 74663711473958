<template>

    <div slot="body">
      <bita-form ref="form">
        <div slot="body">
        <div class="row">
          <bita-select
              label="نوع سند: تصویر کارت ملی و مجوز"
              name="document_id"
              ref="document_id"
              rules=""
              :validation-container-class="colWidth"
              input-class="form-control"
              :data-source="documentsTitle"
              data-text-field="name"
              data-value-field="id"
              v-model="formData.document_id"
            />
            <label for="file" class="btn">انتخاب فایل</label>
            <input
                type="file"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
              />
              
                    <div class="col-lg-12">
                      <bita-bootbutton
                       
                        type="primary"
                        title="ذخیره"
                        icon="fas fa-save"
                        btn-width="150px"
                        @click.native="save"
                                              />
          
                    </div>
                    
                  
                    <div id="photos">
        <div class="photo"  v-for="(image, index) in documents" :key="index">
          
          <span class="delete-image" @click="deleteDocumentWithImage(documents[index])"
            ><i class="fas fa-times-circle"></i
          ></span>
            <a @click="downloadFile(image.id,image.document_title.name,image.images[0].mime_type)" style="    font-size: xxx-large;margin: 16px;"><i class="fas fa-download"></i></a>
          <p class="documentname">{{image.document_title.name}}</p>   
        </div>
      </div>

        </div>
      </div>
      </bita-form>
    </div>
    
  </template>
 <script>
 import BlockButton from "@/components/template/buttons/BlockButton";
 import FormButton from "@/components/template/buttons/FormButton";
 import { supplireApi } from "@/api/apiManagement";
 export default {
   props: {
     title: String,
     isLoading: Boolean,
   },
   components: { BlockButton, FormButton },
   data() {
     return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
        largeColWidth:  "col-lg-12",
      
        documentsTitle:[],
        formData: {
          document_id:'',
          file:''
        },
        documents:[],
        images:[],
        supplire:{},

       form:{}
       ,  

       banks: [],
       sellerId:''
 
 
     };
   },
   computed: {
     formRef() {
       return this.$refs.form;
       
     },
     isUpdateMode() {
       return this.form.id && this.form.id > 0;
     },
   },
   mounted() {
  
    this.getDocumentsTitle()
     },
   methods: {
     setInitialValue(data) {
         this.getDocumentsOfSellerAuth();
 
     },
     handleFileUpload() {
      this.formData.file = this.$refs.file.files[0];
    },
     save(formData) {
      this.isLoadingModal = true;
      //documentImage = this.getFormData(this.formData);
      supplireApi
        .createDocument(this.getFormData(this.form))
        .then((res) => {
          this.getDocumentsOfSellerAuth()
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    }, 
     
     getDocumentsTitle() {
      supplireApi.getDocumentsTitle().then(({ data }) => {
        this.documentsTitle = data.entity;
      });
    },
    getDocumentsOfSeller(sellerId) {
       supplireApi.getDocumentsOfSeller(sellerId).then(({ data }) => {
        this.documents = data.entity;
  
      });
    },
    getDocumentsOfSellerAuth() {
      supplireApi.getDocumentsOfSellerAuth().then(({ data }) => {
        this.documents = data.entity;
      });
    },
     validate() {
       return this.$refs.form.validate();
     },
 
     setErrors(err) {
       this.$refs.form.setErrors(err);
     },
 
     reset() {
       this.form = {};
     
       this.formRef.reset();
     },
 

     deleteDocumentWithImage: function (document) {

      let question = "آیا از حذف تصویر سند اطمینان دارید؟";

      this.$alert("question", question, null, {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.$emit("is-loading", true);
          supplireApi
            .deleteDocumentWithImage(document.id)
            .then((res) => {
              this.getDocumentsOfSellerAuth()
             // this.getDocumentsOfSeller(document.seller_id);
              this.$izitoast("success", "عملیات موفق", res.data.message);
            })
            .finally(() => {
              this.$emit("is-loading", false);
            });
        }
      });
    },
    downloadFile(document_id,doc_name,doc_type) {
      
      const result=doc_type.split("/");
       let type=result[1];
      // supplireApi
      //   .getDocOfSeller(document_id)
      //   .then((res) => {
      //     console.log(res)
      //           var FILE = window.URL.createObjectURL(new Blob([res.data]));
      //           console.log(FILE);
      //           var docUrl = document.createElement('a');
      //           docUrl.href = FILE;
      //           docUrl.setAttribute('download',   'file.' + type);
      //           document.body.appendChild(docUrl);
      //           docUrl.click();
      //   })
      //   .finally(() => {
      //     this.isLoadingModal = false;
      //   });

        axios({
                url: `/marketplace/seller/document/${document_id}/download`, // Download File URL Goes Here
                method: 'GET',
                responseType: 'blob'
            }).then((res) => {
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                docUrl.setAttribute('download', doc_name+  '.' + type);
                document.body.appendChild(docUrl);
                docUrl.click();
            });
  
            // axios({
            //     url: `/marketplace/seller/document/${document_id}/download`, // Download File URL Goes Here
            //     method: 'GET',
                
            //     responseType: 'blob',
            // }).then((res) => {
            //   console.log(res)
            //     var FILE = window.URL.createObjectURL(new Blob([res.data]));
            //     console.log(FILE)

            //     var docUrl = document.createElement('x');
            //     docUrl.href = FILE;
            //     docUrl._target = 'blank'
            //     docUrl.setAttribute('download', 'file.png');
            //     document.body.appendChild(docUrl);
            //     docUrl.click();
            // });
          },
    getFormData() {
      let formData = new FormData();
      for (var key in this.formData) {
        if (this.formData[key] != null) {
          formData.append(key, this.formData[key]);
        }
      }
      return formData;
    },
 
   },
 };
 </script>
  <style scoped>
.photo {
  float: right;
  position: relative;
  margin: 0 10px 10px 0;
  padding: 5px;
  border: 2px darkblue solid;
  width: 110px;
  height: 110px;

}
.photo img {
  object-fit: contain;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* height: 200px; */
  /* background-color: #ccc; */
}
.documentname {
  text-align: center;
}
.delete-image {
  color: red;
  position: absolute;
  right: 2px;
  top: 2px;
}
.delete-image:hover i {
  color: red;
}
#photos {
  margin-top: 30px;;
}
</style>