<template>
  <bita-modal
    :id="modalId"
    :title="title"
    :modal-lg="true"
    :isLoading="isLoading"
    @close="onCloseModal"
  >
    <div slot="body">
      <div class="row" v-if="$acl.can('view', 'admin-of-sellers')">
        <div class="col-6">
          نام و نام خانوادگی:
          {{ formItems.full_name ? formItems.full_name : "-" }}
        </div>
        <div class="col-6">
          زمان ثبت سفارش:
          {{
            formItems.created_at
              ? $getJalaliDateTime(formItems.created_at)
              : "-"
          }}
        </div>
      </div>
      <div class="row" v-if="$acl.can('view', 'admin-of-sellers')">
        <div class="col-6">
          استان: {{ formItems.province ? formItems.province : "-" }}
        </div>
        <div class="col-6">
          شهر: {{ formItems.city ? formItems.city : "-" }}
        </div>
      </div>
      <div class="row" v-if="$acl.can('view', 'admin-of-sellers')">
        <div class="col-6">
          ادرس: {{ formItems.address ? formItems.address : "-" }}
        </div>
        <div class="col-6">
          کدپستی: {{ formItems.postal_code ? formItems.postal_code : "-" }}
        </div>
      </div>
      <div class="row" v-if="$acl.can('view', 'admin-of-sellers')">
        <div class="col-6">
          تلفن همراه: {{ formItems.mobile ? formItems.mobile : "-" }}
        </div>
        <div class="col-6">
          تلفن ثابت: {{ formItems.tel ? formItems.tel : "-" }}
        </div>
        
      </div>
      <div class="row" v-if="$acl.can('view', 'admin-of-sellers')">
        <div class="col-6">
          ایمیل: {{ formItems.email ? formItems.email : "-" }}
        </div>
      </div>
      <br />
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>کد</th>
            <th>نام</th>
            <th>فروشنده</th>
            <th>تعداد</th>
            <th>قیمت واحد</th>
            <th>مبلغ کل</th>
            <th>مبلغ تخفیف</th>
            <th>مبلغ کل پس از تخفیف</th>
            <!-- <th>قیمت با تخفیف</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in formItems.items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.product_price ? item.product_price.code : "-" }}</td>
            <td>{{ item.product.name }} <br/> <small>{{ item.combination_text }}</small></td>
            <td>{{ item.seller.seller_store_name }}</td>
            <td>{{ item.count }}</td>
            <td>{{ $thousandSeprator(item.price) }}</td>
            <td>{{ $thousandSeprator(item.price * item.count) }}</td>
            <td>{{ $thousandSeprator(item.payable_price - item.price) }}</td>
            <td>{{ $thousandSeprator(item.payable_price * item.count) }}</td>
          </tr>
          <tr>
            <td  v-if="$acl.can('view', 'admin-of-sellers')" colspan="7" style="text-align: left">جمع کل</td>
            <td v-if="$acl.can('view', 'admin-of-sellers')" colspan="7">{{ $thousandSeprator(formItems.total) }}</td>
          </tr>
          <tr v-if="$acl.can('view', 'admin-of-sellers')">
            <td colspan="7" style="text-align: left">هزینه حمل</td>
            <td colspan="7">
              {{ $thousandSeprator(formItems.shipping_price) }}
            </td>
          </tr>
          <tr v-if="$acl.can('view', 'admin-of-sellers')">
            <td colspan="7" style="text-align: left">تخفیف</td>
            <td colspan="7">{{ $thousandSeprator(formItems.discount) }}</td>
          </tr>
          <tr v-if="$acl.can('view', 'admin-of-sellers')">
            <td colspan="7" style="text-align: left">قابل پرداخت</td>
            <td colspan="7">{{ $thousandSeprator(formItems.payable) }}</td>
          </tr>
          <tr v-if="$acl.can('view', 'admin-of-sellers')">
            <td colspan="7" style="text-align: left">پرداخت شده</td>
            <td colspan="7">{{ $thousandSeprator(formItems.payed) }}</td>
          </tr>
          <tr v-if="$acl.can('view', 'admin-of-sellers')">
            <td colspan="7" style="text-align: left">مانده</td>
            <td colspan="7">{{ $thousandSeprator(formItems.payable - formItems.payed) }}</td>
          </tr>
        </tbody>
      </table>
      <bita-form ref="form" v-if="$acl.can('view', 'admin-of-sellers')">
        <div slot="body">
          <div class="row">
            <bita-select
              name="status"
              label="وضعیت"
              ref="statusDropDown"
              rules="required"
              validation-container-class="col-lg-3 col-md-6 col-sm-6"
              input-class="form-control"
              :data-source="statuses"
              data-text-field="title"
              data-value-field="id"
              v-model="formItems.status"
            />
            <bita-input
              type="text"
              name="tonnage"
              label="پیگیری پست"
              rules=""
              validation-container-class="col-lg-3 col-md-6 col-sm-6"
              input-class="form-control"
              v-model="formItems.shipping_tracking_code"
            />
          </div>
          <div class="row">
            <bita-bootbutton
              v-if="formItems.paymethod && formItems.paymethod.method == 2"
              type="primary mr-2 ml-2"
              title="نمایش فیش"
              icon="fa fa-images"
              btn-width="200px"
              @click.native="showFullSizeImage"
            />
            <bita-bootbutton
              v-if="formItems.payment_status != 1"
              type="primary mr-2 ml-2"
              title="تایید پرداخت موفق"
              icon="fa fa-check"
              btn-width="200px"
              @click.native="paymentSuccessfully"
            />
          </div>
        </div>
      </bita-form>
    </div>
    <div slot="footer" v-if="$acl.can('view', 'admin-of-sellers')">
      <bita-bootbutton
        v-if="$acl.can('edit', 'orders')"
        type="primary"
        title="ذخیره"
        icon="fa fa-check"
        btn-width="150px"
        @click.native="handleSubmit()"
      />
      <bita-bootbutton
        class="mr-2"
        type="primary"
        title="چاپ لیبل پست"
        icon="fa fa-print"
        btn-width="150px"
        @click.native="labelPrint()"
      />
      <bita-bootbutton
        class="mr-2"
        type="primary"
        title="چاپ فاکتور"
        icon="fa fa-print"
        btn-width="150px"
        @click.native="printInvoice()"
      />
      <bita-bootbutton
        class="mr-2"
        type="danger"
        title="بستن"
        icon="fas fa-times"
        btn-width="150px"
        data-dismiss="modal"
      />
    </div>
  </bita-modal>
</template>
<script>
import { orderApi } from "@/api/apiManagement";

export default {
  props: {
    modalId: {},
    isLoading: {},
    title: {},
    onSubmit: {},
  },
  data() {
    return {
      colWidth: "col-lg-4 col-md-6 col-sm-6",
      formItems: {},
      dataForm: {},
      statuses: [],
    };
  },
  computed: {},
  mounted() {
    this.getStatuses();
  },
  methods: {
    setInitialData(_data) {
      // this.formItems = _data;
      orderApi.findOrder(_data.id).then(res => {
        this.formItems = res.data
      })
      .finally(() => {  })
    },
    getStatuses() {
      orderApi.getStatuses().then(({ data }) => (this.statuses = data));
    },
    handleSubmit() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit("on-submit", {
            form_data: this.formItems,
            id: this.formItems.id,
          });
        }
      });
    },
    printInvoice() {
      let baseUrl = process.env.VUE_APP_API_BASEPOINT;
      window.open(`orders/${this.formItems.id}/print`, "_blank");
    },
    printTemplate() {
      if (this.formItems) {
        let cities = `${this.formItems.province}، ${this.formItems.city}`;
        let address = this.formItems.address;
        let fullName = this.formItems.full_name;
        let tel = this.formItems.mobile;
        let postalcode = this.formItems.postal_code;
        var template = "";
        template += "<div>";
        template +=
          "<div style='direction:rtl; text-align=right;line-height: 25px; font-size:15px; font-weigth: bold; font-family: iranyekan' >";
        template +=
          '<h4 style="padding: 5px 0; margin: 5px 0;line-height:10px;">گیرنده: ' +
          cities +
          "</h4>";
        template += '<div style="width: 280px">' + address + "</div>";
        template += "<div> اقای/خانم" + " " + fullName + "</div>";
        template += "<div>تلفن: " + tel + "</div>";
        template += "<div>کدپستی: " + postalcode + "</div>";
        template += "</div>";
        template += "</div>";
        return template;
      }
    },
    reset() {
      this.$refs.form.reset();

      this.formItems = {};
    },

    labelPrint() {
      $("<iframe>", {
        name: "myiframe",
        class: "printFrame",
      })
        .appendTo("body")
        .contents()
        .find("body")
        .append(this.printTemplate());

      window.frames["myiframe"].focus();
      window.frames["myiframe"].print();

      setTimeout(() => {
        $(".printFrame").remove();
      }, 1000);
    },

    showFullSizeImage() {
      var url = this.formItems.receipt_file_url;
      window.open(url, "receipt image");
    },

    clearKendos(_refs) {
      for (let ref of _refs) {
        if (this.$refs[ref]) this.$refs[ref].emptyKendo();
      }
    },
    onCloseModal(e) {
      this.formItems = {};
      let dropDowns = ["statusDropDown"];
      this.clearKendos(dropDowns);
      this.reset();
    },
    paymentSuccessfully(e) {
      this.$emit("on-update", this.formItems);
    },
  },
};
</script>
