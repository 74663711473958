<template>
  <div v-if="$acl.can('view', 'home')">
    <div class="row">
      <div class="col-lg-12">
        <!-- <bita-ibox :is-loading="isLoading"> -->
        <!-- <div slot="header">
            <div class="ibox-title">
              <h4>اطلاعات یک هفته اخیر</h4>
            </div>
          </div> -->
        <!-- <div slot="body"> -->
        <div class="k-rtl">
          <div class="row">
            <!-- <div v-if="$acl.can('view','admin-of-sellers')" class="col-lg-3">
              <div class="widget style1 red-bg">
                <div class="row">
                  <div class="col-4 text-right">
                    <i class="fas fa-user-plus fa-5x"></i>
                  </div>
                  <div class="col-8 text-left">
                    <span>کاربران جدید</span>
                    <h2 class="font-bold">
                      <div v-if="isLoading">
                        <i class="fas fa-circle-notch fa-spin"></i>
                      </div>
                      {{ total.users_count }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="widget style1 navy-bg">
                <div class="row">
                  <div class="col-4 text-right">
                    <i class="fas fa-cart-plus fa-5x"></i>
                  </div>
                  <div class="col-8 text-left">
                    <span>محصول جدید</span>
                    <h2 class="font-bold">
                      <div v-if="isLoading">
                        <i class="fas fa-circle-notch fa-spin"></i>
                      </div>
                      {{ total.products_count }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="widget style1 lazur-bg">
                <div class="row">
                  <div class="col-4 text-right">
                    <i class="fas fa-shopping-basket fa-5x"></i>
                  </div>
                  <div class="col-8 text-left">
                    <span>تعداد سفارش </span>
                    <h2 class="font-bold">
                      <div v-if="isLoading">
                        <i class="fas fa-circle-notch fa-spin"></i>
                      </div>
                      {{ total.orders_count }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$acl.can('view','admin-of-sellers')"  class="col-lg-3">
              <div class="widget style1 yellow-bg">
                <div class="row">
                  <div class="col-4 text-right">
                    <i class="fas fa-comments fa-5x"></i>
                  </div>
                  <div class="col-8 text-left">
                    <span> نظر ثبت شده </span>
                    <h2 class="font-bold">
                      <div v-if="isLoading">
                        <i class="fas fa-circle-notch fa-spin"></i>
                      </div>
                      {{ total.comments_count }}
                    </h2>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-lg-3">
              <div class="widget style1 navy-bg">
                <div class="row">
                  <div class="col-4 text-right">
                    <i class="fas fa-cart-plus fa-5x"></i>
                  </div>
                  <div class="col-8 text-left">
                    <span>موجودی پیامک</span>
                    <h2 class="font-bold">
                      <div v-if="isLoading">
                        <i class="fas fa-circle-notch fa-spin"></i>
                      </div>
                      {{ total.products_count }}
                    </h2>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- </bita-ibox> -->
      <!-- </div> -->
    </div>
    <!-- <div class="row" v-if="$showDashboardWarnings">
      <div v-if="$acl.can('view','admin-of-sellers')" class="col-lg-6">
        <div>
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <button type="button" class="btn btn-danger m-r-sm">
                    <div v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    {{ total.jobs_count }}
                  </button>
                  فعالیت های در صف
                </td>
                <td>
                  <button type="button" class="btn btn-danger m-r-sm">
                    <div v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    {{ total.orders_count_new }}
                  </button>
                  بررسی نشده
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="btn btn-warning m-r-sm">
                    <div v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    {{ total.failed_jobs_count }}
                  </button>
                  فعالیت های ناموفق
                </td>
                <td>
                  <button type="button" class="btn btn-warning m-r-sm">
                    <div v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    {{ total.orders_count_cargochecking }}
                  </button>
                  بررسی انبار
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="btn btn-info m-r-sm">
                    <div v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    {{ sysStatus.sms_credit ? sysStatus.sms_credit.value : "" }}
                  </button>
                  موجودی پیامک
                </td>
                <td>
                  <button type="button" class="btn btn-success m-r-sm">
                    <div v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    {{ total.orders_count_sent }}
                  </button>
                  ارسال شده
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="col-2"
        v-if="
          sysStatus.last_job_runtime && sysStatus.last_job_runtime.alarm_status && $acl.can('view','admin-of-sellers')
        "
      >
        <div v-if="$acl.can('view','admin-of-sellers')" class="widget yellow-bg p-lg text-center">
          <div class="m-b-md">
            <i class="fas fa-exclamation-triangle fa-4x"></i>
            <h1 class="m-xs">هشدار</h1>
            <h3 class="font-bold no-margins">ایجاد</h3>
            <small>اشکال در سیستم وظایف</small>
          </div>
        </div>
      </div>
      <div
        class="col-2"
        v-if="
          sysStatus.last_sms_delivery_check &&
          sysStatus.last_sms_delivery_check.alarm_status &&
          $acl.can('view','admin-of-sellers')
        "
      >
        <div class="widget yellow-bg p-lg text-center">
          <div class="m-b-md">
            <i class="fas fa-exclamation-triangle fa-4x"></i>
            <h1 class="m-xs">هشدار</h1>
            <h3 class="font-bold no-margins">ایجاد</h3>
            <small>اشکال در سیستم چک دلیوری</small>
          </div>
        </div>
      </div>
      <div
        class="col-2"
        v-if="sysStatus.sms_credit && sysStatus.sms_credit.alarm_status && $acl.can('view','admin-of-sellers')" 
      >
        <div class="widget yellow-bg p-lg text-center">
          <div class="m-b-md">
            <i class="fas fa-exclamation-triangle fa-4x"></i>
            <h1 class="m-xs">هشدار</h1>
            <h3 class="font-bold no-margins">کمبود</h3>
            <small>شارژ حساب پیامک ({{ sysStatus.sms_credit.value }}) </small>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row mt-2 mb-2">

      <div class="col-12">
        <div class="card mb-3">

          <div class="card-header">
            اطلاعیه عمومی
          </div>

          <div class="card-body">
            <div id="carouselPublicArticle" class="carousel slide" data-ride="carousel" data-interval="9000">

              <div class="carousel-inner">
                <div :class="[ index == 0 ? `active` : '', `carousel-item`]" v-for="(item, index) in articles.article_public">
                  <img v-if="item.thumbnail != null" :src="item.thumbnail_url" :alt="item.title" class="w-100 announcement-border-radius px-5 bg-dark">
                  <div v-else class="p-3 bg-dark announcement-border-radius"> </div>
                  <div class="background-notification">
                    <h5 class="pt-2 px-5 bg-dark m-0 text-white" v-html="item.title"></h5>
                    <p class="p-1 px-5 bg-dark text-white text-justify" v-html="item.content"></p>
                  </div>
                </div>
              </div>

              <button class="carousel-control-prev carousel-announcement-prev" v-if="articles.article_public.length > 1" type="button" data-target="#carouselPublicArticle" data-slide="prev">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                <defs>
                </defs>
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(45.02412451361867 45.024124513618645) scale(1.83 1.83)">
                  <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                  <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                </g>
                </svg>
              </button>
              
              <button class="carousel-control-next carousel-announcement-next" v-if="articles.article_public.length > 1" type="button" data-target="#carouselPublicArticle" data-slide="next">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                <defs>
                </defs>
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(209.979766536965 209.97976653696495) rotate(180) scale(1.83 1.83) matrix(1 0 0 -1 0 90)">
                  <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                  <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                </g>
                </svg>
              </button>

            </div>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card mb-3">

          <div class="card-header">
            اطلاعیه شخصی
          </div>

          <div class="card-body">
            <div id="carouselPrivateArticle" class="carousel slide" data-ride="carousel" data-interval="9000">

              <div class="carousel-inner">
                <div :class="[ index == 0 ? `active` : '', `carousel-item`]" v-for="(item, index) in articles.article_private">

                  <a class="close-announcement" @click="readedPrivateArticleBySeller(item.id , item.seller_article_type)" >
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                      <defs>
                      </defs>
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                        <path d="M 28.902 66.098 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 32.196 -32.196 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 32.438 64.633 C 31.461 65.609 30.182 66.098 28.902 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                        <path d="M 61.098 66.098 c -1.279 0 -2.56 -0.488 -3.535 -1.465 L 25.367 32.438 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 32.195 32.196 c 1.953 1.952 1.953 5.118 0 7.07 C 63.657 65.609 62.377 66.098 61.098 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                        <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 10 c -19.299 0 -35 15.701 -35 35 s 15.701 35 35 35 s 35 -15.701 35 -35 S 64.299 10 45 10 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                      </g>
                    </svg>
                  </a>

                  <img v-if="item.thumbnail != null" :src="item.thumbnail_url" :alt="item.title" class="w-100 announcement-border-radius px-5 bg-dark">
                  <div v-else class="pt-5 pb-3 bg-dark announcement-border-radius"></div>
                  <div>
                    <h5 class="pt-2 px-5 bg-dark m-0 text-white" v-html="item.title"></h5>
                    <p class="p-1 px-5 bg-dark text-white text-justify" v-html="item.content"></p>
                  </div>
                </div>
              </div>

              <button class="carousel-control-prev carousel-announcement-prev" v-if="articles.article_private.length > 1" type="button" data-target="#carouselPrivateArticle" data-slide="prev">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                <defs>
                </defs>
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(45.02412451361867 45.024124513618645) scale(1.83 1.83)">
                  <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                  <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                </g>
                </svg>
              </button>
              
              <button class="carousel-control-next carousel-announcement-next" v-if="articles.article_private.length > 1" type="button" data-target="#carouselPrivateArticle" data-slide="next">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">
                <defs>
                </defs>
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(209.979766536965 209.97976653696495) rotate(180) scale(1.83 1.83) matrix(1 0 0 -1 0 90)">
                  <path d="M 31.67 58.799 l 42.333 -42.333 c 1.059 -1.059 1.059 -2.776 0 -3.835 L 62.166 0.794 c -1.059 -1.059 -2.776 -1.059 -3.835 0 L 15.998 43.127 c -1.059 1.059 -1.059 2.776 0 3.835 l 11.837 11.837 C 28.893 59.858 30.61 59.858 31.67 58.799 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                  <path d="M 31.67 31.201 l 42.333 42.333 c 1.059 1.059 1.059 2.776 0 3.835 L 62.166 89.206 c -1.059 1.059 -2.776 1.059 -3.835 0 L 15.998 46.873 c -1.059 -1.059 -1.059 -2.776 0 -3.835 l 11.837 -11.837 C 28.893 30.142 30.61 30.142 31.67 31.201 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(10,226,173); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                </g>
                </svg>
              </button>

            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- <div class="row mt-2 mb-5">
      <div class="col-lg-6">
        <bita-ibox :is-loading="isLoading"> -->
          <!-- <div slot="header">
            <div class="ibox-title">
              <h4>آمار درامد روزانه هفته اخیر</h4>
            </div>
          </div> -->
          <!-- <div slot="body">
            <div class="k-rtl">
              <h4>آمار درامد روزانه هفته اخیر</h4>
              <table class="table table-hover margin bottom">
                <thead>
                  <tr>
                    <th style="width: 1%" class="text-center">ردیف</th>
                    <th class="text-center">روز</th>
                    <th class="text-center">تعداد سفارش</th>
                    <th class="text-center">{{ $currencyLabel("درامد") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) of total.week_income" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center">{{ $getJalaliDate(item.day) }}</td>
                    <td class="text-center">{{ item.count }}</td>
                    <td class="text-center">
                      <span
                        :class="{
                          label: true,
                          'label-primary':
                            Number(item.total_income) >
                            Number(total.avg_income),
                          'label-warning':
                            Number(item.total_income) <=
                            Number(total.avg_income),
                        }"
                        >{{
                          $thousandSeprator($round(item.total_income))
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </bita-ibox>
      </div>
      <div class="col-lg-6">
        <bita-ibox :is-loading="isLoading"> -->
          <!-- <div slot="header">
            <div class="ibox-title">
              <h4>سفارش های جدید</h4>
            </div>
          </div> -->
          <!-- <div slot="body">
            <div class="k-rtl">
              <h4 v-if="total && total.orders && total.orders.length > 0">سفارش جدید ثبت شده</h4>
              <table
                v-if="total && total.orders && total.orders.length > 0"
                class="table table-hover margin bottom"
              >
                <thead>
                  <tr>
                    <th style="width: 1%" class="text-center">ردیف</th>
                    <th>نام</th>
                    <th class="text-center">زمان ثبت سفارش</th>
                    <th class="text-center">{{ $currencyLabel("مبلغ") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) of total.orders" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>{{ item.full_name }}</td>
                    <td class="text-center small">
                      {{ $getJalaliDateTime(item.created_at) }}
                    </td>
                    <td class="text-center">
                      <span class="label label-primary">{{
                        $thousandSeprator(item.payable)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" v-else>سفارش جدیدی ثبت نشده است</div>
            </div>
          </div>
        </bita-ibox>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { dashboardApi , articleApi } from "@/api/apiManagement.js";
export default {
  name: "Home",
  data() {
    return {
      dashboard: {},
      total: {},
      sysStatus: {},
      isLoading: false,
      articles: [],
      formData: {
        article_id: "",
        type: "",
      },
    };
  },
  mounted() {
    this.getDashboardData();
    if (this.$store.state.layout != "app-layout") this.$setLayout("app-layout");
  },
  methods: {

    /*
     * readed private article by seller
     *
     * @param int article_id
     * @param int type
    */
    readedPrivateArticleBySeller (article_id,type) {
      
      this.formData.article_id = article_id;
      this.formData.type = type;

      articleApi
        .readedPrivateArticleBySeller(this.formData)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          window.location.reload();
        })
        .finally(() => {
          this.$emit("is-loading", false);
        });

    },

    getDashboardData() {
      this.isLoading = true;
      dashboardApi.getInfo().then(({ data }) => {
        this.dashboard = data;
        this.total = data.total;
        this.sysStatus = data.system_statuses;
        this.articles = data.articles;
        this.isLoading = false;
      });
    },
  },
};
</script>