import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/products"

export default {
    importPricesExcel(data) {
        return apiCall('post', `${PREFIX}/prices/excel`, data)
    },

    getProductsPaginated(filters) {
        return apiCall('get', `${PREFIX}/paginated${createQueryString(filters)}`)
    },

    normalizeDatabase() {
        return apiCall('get', 'shop/normlize')
    },

    getProducts(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    getProduct(productID) {
        return apiCall('get', `${PREFIX}/${productID}`)
    },

    getProductRelations(productID) {
        return apiCall('get', `${PREFIX}/${productID}/relations`)
    },

    postProductRelation(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/relations`, data)
    },

    deleteProductRelation(productID, relationID) {
        return apiCall('delete', `${PREFIX}/${productID}/relations/${relationID}`)
    },

    getProductInfo(productId) {
        return apiCall('get', `${PREFIX}/${productId}/info`)
    },

    createProduct(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    updateProductInfo(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/info`, data)
    },

    toggleStatus(productID) {
        return apiCall('patch', `${PREFIX}/${productID}/status`)
    },
    toggleProductStatus(productID) {
        return apiCall('patch', `${PREFIX}/${productID}/status`)
    },
    deleteProduct(productID) {
        return apiCall('delete', `${PREFIX}/${productID}`)
    },

    updateProductDetail(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/details`, data)
    },

    getProductDetail(productID) {
        return apiCall('get', `${PREFIX}/${productID}/details`)
    },

    getProductImages(productID) {
        return apiCall('get', `${PREFIX}/${productID}/images`)
    },

    deleteProductImage(imageID) {
        return apiCall('delete', `${PREFIX}/images/${imageID}`)
    },

    setProductThumbnail(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/thumbnail`, data)
    },

    getProductPrices(productID) {
        return apiCall('get', `${PREFIX}/${productID}/prices`)
    },
    getProductPricesOfSeller(productID,sellerId){
        return apiCall('get', `${PREFIX}/${productID}/prices?seller_id=${sellerId}`)
    },
    updateProductPrices(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/prices`, data)
    },
    updateProductPricesOfSelelr(productID, sellerId,data) {
        return apiCall('post', `${PREFIX}/${productID}/prices?seller_id=${sellerId}`, data)
    },
    deleteProductPrice(priceID) {
        return apiCall('delete', `${PREFIX}/prices/${priceID}`)
    },

    getProductFeaturables(productID) {
        return apiCall('get', `${PREFIX}/${productID}/featurables`)
    },

    getProductFeatureIds(productID) {
        return apiCall('get', `${PREFIX}/${productID}/featureids`)
    },

    updateProductFeatures(productID, data){
        return apiCall('post', `${PREFIX}/${productID}/features`,data)
    },

    getProductTags(productID) {
        return apiCall('get', `${PREFIX}/${productID}/tags`)
    },

    getProducTagsAndStandard(productID)
    {
        return apiCall('get', `${PREFIX}/${productID}/tags/standard`);
    },

    syncTags(productID,data){
        return apiCall('post', `${PREFIX}/${productID}/tags`,data)
    },

    syncTagStandard(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/tags/standard`, data);
    },
    getProductWholeSales(productID) {
        return apiCall('get', `${PREFIX}/${productID}/wholesale/`)
    },
    getProductWholeSalesOfSeller(productID,sellerId) {
        return apiCall('get', `${PREFIX}/${productID}/wholesale?seller_id=${sellerId}`)
    },
     updateWholeSaleOfSeller(productID,sellerId, data) {
         return apiCall('post', `${PREFIX}/${productID}/wholesale?seller_id=${sellerId}`, data)
     },
    updateWholeSale(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/wholesale`, data)
    },
    deleteWholeSale(myformId) {
        return apiCall('delete', `${PREFIX}/wholesale/${myformId}`)
    },
    insertProductVideoEmbedCode(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/embed`, data)
    },
    getProductVideo(productID) {
        return apiCall('get', `${PREFIX}/${productID}/video`)
    },

    deleteProductVideo(videoId) {
        return apiCall('delete', `${PREFIX}/video/${videoId}`)
    },

    createCopyOfProduct(productID, data) {
        return apiCall('post', `${PREFIX}/${productID}/replicate`, data)
    },

    searchInProductsOffSeller(filters) {
        return apiCall('get', `marketplace/seller/products/available${createQueryString(filters)}`)
    },

    getProductFeedback(productID,Type) {
        return apiCall('get', `/marketplace/seller/feedback/${Type}/${productID}`)

    },
    insertProductFeedback(data) {
        return apiCall('post', `marketplace/seller/feedback`, data)
    },
    getProductTableFiledsname() {
        return apiCall('get', `/marketplace/seller/feedbacks/fields`)

    },
    exportExcelTRansaction() {
        return apiCall('get', `marketplace/seller/transaction/export/excel`)

    },
    deleteFeedBack(feedbackId) {
        return apiCall('delete', `marketplace/seller/feedback/${feedbackId}/delete`)

    },
    activeProductOfSeller(productId) {
        return apiCall('get', `marketplace/seller/product/accept/${productId} `)

    },
    getTypeOfSellerAuth() {
        return apiCall('get', `/marketplace/seller/auth`)        
    }
    

}