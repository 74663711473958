import { apiCall } from "../imports"

const PREFIX = "notification/sms-logs"

export default {

    getSmsLogs(search) {
        return apiCall('get', `${PREFIX}?search=${search}`)
    },
    retrySmsLog(id) {
        return apiCall('post', `${PREFIX}/${id}/retry`);
    }

    // createCoupon(data) {
    //     return apiCall('post', `${PREFIX}`, data)
    // },

    // updateCoupon(couponID, data) {
    //     return apiCall('patch', `${PREFIX}/${couponID}`, data)
    // },

    // deleteCoupon(couponID) {
    //     return apiCall('delete', `${PREFIX}/${couponID}`)
    // }
}