import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";


if (process.env.VUE_APP_SENTRY == "true")
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

import "./scripts/bootstrap";

import "trumbowyg/dist/ui/trumbowyg.min.css";
import "trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css";
import "trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css";
import "trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css";

import "trumbowyg/dist/trumbowyg.min.js";
import "trumbowyg/dist/langs/fa.min.js";
import "trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js";
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js";
import "trumbowyg/dist/plugins/noembed/trumbowyg.noembed.min.js";
import "trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min.js";
import "trumbowyg/dist/plugins/table/trumbowyg.table.min.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js";

var VueCookie = require("vue-cookie");

import mixin from "./mixin/mixin";

//Kendo
import "@progress/kendo-ui";
import "@progress/kendo-ui/js/kendo.grid";
import "@progress/kendo-theme-default/dist/all.css";
import "@progress/kendo-ui/js/messages/kendo.messages.fa-IR.js";

import { Grid, GridInstaller } from "@progress/kendo-grid-vue-wrapper";
import { DataSourceInstaller } from "@progress/kendo-datasource-vue-wrapper";
Vue.use(GridInstaller);
Vue.use(DataSourceInstaller);

import LiquorTree from "liquor-tree";
Vue.use(LiquorTree);
//End Kendo

//copy to clipboard
import "./scripts/bootstrap";
import Clipboard from "v-clipboard";
Vue.use(Clipboard);

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@/assets/sass/app.scss";
import "@/assets/vendor/style.css";
import "@/assets/vendor/slimscroll/jquery.slimscroll.min.js";
import "@/assets/vendor/base.js";

import VuePersianDatetimePicker from "vue-persian-datetime-picker";
Vue.component("date-picker", VuePersianDatetimePicker);

// Tell Vue to use the plugin
Vue.use(VueCookie);

import Ibox from "./components/template/Ibox";
import Modal from "./components/template/Modal";
import BreadCrumb from "./components/template/BreadCrumb";
import DimButton from "./components/template/buttons/DimButton";
import BlockButton from "./components/template/buttons/BlockButton";
import RoundedButton from "./components/template/buttons/RoundedButton";
import FormButton from "./components/template/buttons/FormButton";
import GGrid from "./components/GGrid";
import BootButton from "./components/template/buttons/BootButton";
import FiltersButton from "./components/template/buttons/FiltersButton";
import FormGroup from "./components/FormGroup";

import Spinner from "./components/Spinner";

Vue.mixin(mixin);

Vue.component("bita-grid", GGrid);
Vue.component("bita-ibox", Ibox);
Vue.component("bita-modal", Modal);
Vue.component("bita-breadcrumb", BreadCrumb);
Vue.component("bita-dbutton", DimButton);
Vue.component("bita-bbutton", BlockButton);
Vue.component("bita-rbutton", RoundedButton);
Vue.component("bita-bootbutton", BootButton);
Vue.component("bita-formbutton", FormButton);
Vue.component("bita-filtersbutton", FiltersButton);
Vue.component("bita-spinner", Spinner);
Vue.component("bita-formgroup", FormGroup);

Vue.config.productionTip = false;
var _that = this;
import acl from "./scripts/acl";
import flog from "./scripts/flog";
let _acl = new Vue(acl);
Vue.prototype.$acl = _acl;
Vue.prototype.$flog = flog;
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title)
    document.title = `${to.meta.title} | ${process.env.VUE_APP_APP_NAME}`;
  else document.title = process.env.VUE_APP_APP_NAME;
  return _acl.handleRoutes(to, from, next);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    _acl.initialApp(this.$router, this.$store);
  },
  components: {
    Grid,
  },
}).$mount("#app");
