<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-bootbutton
            title="افزودن دسته بندی"
            type="primary"
            icon="fas fa-plus-square"
            btn-width="200px"
            @click.native="toggleForm"
            v-if="$acl.can('create', 'categories-articles')"
          />
          <hr />
          <tree-list
            :data-source="categories"
            :filterable="true"
            :sortable="true"
            :columns="gridColumns"
            ref="grid"
          >
          </tree-list>
        </div>
      </bita-ibox>
    </div>
    <category-form
      ref="form"
      :category="editForm"
      :categories="categories"
      :title="formTitle"
      :is-loading="isLoadingModal"
      @on-create="(e) => save(e)"
      @on-update="(e) => update(e)"
    />
  </div>
</template>
<script>
import "@progress/kendo-ui/js/kendo.treelist";
import {
  TreeList,
  TreeListColumn,
  TreeListInstaller,
} from "@progress/kendo-treelist-vue-wrapper";

import CategoryForm from "./components/CategoryForm";
import { articleCategoryApi } from "@/api/apiManagement";

export default {
  components: { CategoryForm, TreeList, TreeListColumn },
  use: { TreeListInstaller },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت مقالات", link: "" },
        {
          title: "مدیریت دسته بندی",
          link: "/management/category",
          active: true,
        },
      ],
      isLoadingForm: false,
      isLoadingGrid: false,
      isLoadingModal: false,
      formTitle: "",
      editForm: {},
      categories: [],
    };
  },
  computed: {
    gridColumns() {
      let commands = [];
      if (this.$acl.can("edit", "categories-articles"))
        commands.push({
          text: "ویرایش",
          name: "my",
          // template: this.gridButtons.edit,
          click: this.editRow,
        });
      if (this.$acl.can("delete", "categories-articles"))
        commands.push({
          text: "حذف",
          name: "delete",
          // template: this.gridButtons.delete,
          click: this.delete,
        });
      return [
        { field: "name", title: "نام فارسی" },
        { field: "slug", title: "نام انگلیسی" },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: commands,
        },
      ];
    },
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    formSucceeded() {
      this.getCategories();
      this.formTitle = "";
      this.editForm = {};
      this.$refs.form.reset();
      this.$toggleModal("#category-form");
    },
    getCategories() {
      this.isLoadingGrid = true;
      // this.$resetGrid();
      articleCategoryApi
        .getCategories()
        .then(({ data }) => {
          let categories = data.entity;
          for (let cat of categories) {
            cat.parentId =
              Number(cat.parent_id) == 0 ? null : Number(cat.parent_id);
          }
          this.categories = categories;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    readData(e) {
      this.isLoadingGrid = true;
      this.$resetGrid();
      articleCategoryApi
        .getCategories()
        .then(({ data }) => {
          let categories = data.entity;
          for (let cat of categories) {
            cat.parentId =
              Number(cat.parent_id) == 0 ? null : Number(cat.parent_id);
            this.categories.push(cat);
          }
          e.success(data.entity);
        })
        .catch(() => {
          e.success([]);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    getFormData(data) {
      let formData = new FormData();
      for (var key in data) {
        if (data[key] != null) formData.append(key, data[key]);
      }
      return formData;
    },
    save(_category) {
      this.isLoadingModal = true;
      _category = this.getFormData(_category);

      articleCategoryApi
        .createCategory(_category)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.formSucceeded();
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },

    editRow(e) {
      e.preventDefault();
      let grid = this.$refs.grid.kendoWidget();
      let category = grid.dataItem($(e.currentTarget).closest("tr"));
      this.editForm = {
        id: category.id,
        name: category.name,
        slug: category.slug,
        icon_url: category.icon_url,
        parent_id: category.parent_id > 0 ? String(category.parent_id) : null,
      };
      this.formTitle = `ویرایش گروه ${category.name}`;
      this.$toggleModal("#category-form");
    },
    update(_category) {
      this.isLoadingModal = true;
      let formData = this.getFormData(_category);

      articleCategoryApi
        .updateCategory(_category.id, formData)
        .then((res) => {
          this.$izitoast("success", "عملیات موفق", res.data.message);
          this.formSucceeded();
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors);
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    delete(e) {
      e.preventDefault();
      let grid = this.$refs.grid.kendoWidget();
      let category = grid.dataItem($(e.currentTarget).closest("tr"));
      this.$alert(
        "question",
        `آیا از حذف ${category.name} اطمینان دارید؟`,
        "",
        {
          confirmButtonText: "بله",
        }
      ).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          articleCategoryApi.deleteCategory(category.id).then((res) => {
            this.$izitoast("success", "عملیات موفق", res.data.message);
            this.getCategories();
          }).catch(() => (this.isLoadingGrid = false));
        }
      });
    },
    toggleForm() {
      this.formTitle = "افزودن دسته بندی";
      this.editForm = {};
      this.$toggleModal("#category-form");
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
</style>
