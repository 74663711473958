import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/details"

export default {

    find(detailID) {
        return apiCall('get', `${PREFIX}/${detailID}`)
    },

    get(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    create(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    update(id, data) {
        return apiCall('patch', `${PREFIX}/${id}`, data)
    },
    
    delete(id) {
        return apiCall('delete', `${PREFIX}/${id}`)
    },
    

    getDetailValues(detailID) {
        return apiCall('get', `${PREFIX}/${detailID}/values`)
    },
    createDetailValue(detailID,data) {
        return apiCall('post', `${PREFIX}/${detailID}/values`, data)
    },
    updateDetailValue(id, data) {
        return apiCall('patch', `${PREFIX}/values/${id}`, data)
    },
    deleteDetailValue(id) {
        return apiCall('delete', `${PREFIX}/values/${id}`)
    },
}