<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoadingGrid">
        <div slot="body">
          <bita-grid
            ref="grid"
            :columns="gridColumns"
            :read-data="readData"
            :has-row-number="true"
          />
        </div>
      </bita-ibox>
    </div>
    <comment-form
      ref="commentForm"
      id="comment-form"
      title="مشاهده کامنت"
      :props-data="formData"
      :replay-data="replayData"
      :is-loading="isLoadingModal"
      @on-reply="(e) => reply(e)"
      @on-delete="(e) => handleDelete(e)"
    />
  </div>
</template>
<script>
import CommentForm from "./components/CommentForm";
import { commentApi } from "@/api/apiManagement";
export default {
  components: { CommentForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "کامنت ها", link: "/management/comments" },
      ],
      isLoadingGrid: false,
      isLoadingModal: false,
      formData: {},
      replayData: {},
      gridColumns: [
        { title: "نام کاربری", template: (e) => (e.user ? e.user.name : "-") },
        {
          field: "status",
          title: "وضعیت",
          template: (e) => (e.status_text ? e.status_text : "-"),
        },
        {
          field: "commentable",
          title: "در پاسخ به",
          template: (e) =>
            e.commentable && e.commentable.name ? e.commentable.name : "-",
        },
        {
          field: "commenttable_type_text",
          title: "جایگاه",
          template: (e) =>
            e.commentable_type_text ? e.commentable_type_text : "-",
        },
        {
          field: "created_at",
          title: "تاریخ ایجاد",
          template: (e) =>
            e.created_at ? this.$getJalaliDateTime(e.created_at) : "",
        },
        {
          title: "عملیات",
          command: [],
        },
      ],
    };
  },
  mounted() {
    this.gridColumns[this.gridColumns.length - 1].command = this.gridCommands();
    this.loadData();
  },
  methods: {
    readData(e) {
      this.isLoadingGrid = true;
      commentApi
        .getComments({ parent_id: 0 })
        .then((res) => {
          e.success(res.data.entity);
        })
        .finally(() => {
          this.isLoadingGrid = false;
        });
    },
    loadData() {
      this.$refs.grid.loadDataSource();
    },
    gridCommands() {
      let commands = [];
      if (this.$acl.can("create", "comments"))
        commands.push({
          text: "view",
          click: this.edit,
          template: this.gridButtons.view,
        });
      commands.push({
        text: "status",
        click: this.status,
        template: this.gridButtons.status,
      });
      if (this.$acl.can("delete", "comments"))
        commands.push({
          text: "delete",
          click: this.delete,
          template: this.gridButtons.delete,
        });
      return commands;
    },
    toggleModal() {
      this.$toggleModal("#comment-form");
    },
    edit(e) {
      let comment = this.$refs.grid.getSelectedGridRow(e);
      this.formData = comment;
      this.toggleModal();
    },
    status(e) {
      let comment = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `ایا از تغییر وضعیت این مورد مطمئن هستید`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          commentApi
            .toggleCommentStatus(comment.id)
            .then((res) => {
              this.$izitoast("success", "موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    delete(e) {
      let comment = this.$refs.grid.getSelectedGridRow(e);
      this.$alert("question", `آیا از حذف این کامنت اطمینان دارید ؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          commentApi
            .deleteComments(comment.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    handleDelete(e) {
      this.$alert("question", `آیا از حذف این کامنت اطمینان دارید ؟`, "", {
        confirmButtonText: "بله",
      }).then((result) => {
        if (result.value) {
          this.isLoadingGrid = true;
          commentApi
            .deleteComments(e.id)
            .then((res) => {
              this.$izitoast("success", "عملیات موفق", res.data.message);
              this.toggleModal();
              this.loadData();
            })
            .finally(() => {
              this.isLoadingGrid = false;
            });
        }
      });
    },
    reply(data) {
      this.isLoadingModal = true;
      commentApi
        .replyComment(data.comment_id, { reply: data.reply })
        .then((res) => {
          this.$izitoast("success", "موفق", res.data.message);
          this.$refs.commentForm.reset();
          this.toggleModal();
          this.loadData();
        })
        .finally(() => {
          this.isLoadingModal = false;
        });
    },
    update(e) {},
  },
};
</script>
