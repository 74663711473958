<template>
  <div>
    <bita-breadcrumb :items="breadCrumbs" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <bita-ibox :is-loading="isLoading">
        <div slot="body">
          <div class="row">
            <div class="col-lg-6">
              <bita-form ref="form">
                <div slot="body">
                  <div class="form-group row">
                    <bita-input
                      type="text"
                      name="name"
                      label="دسترسی"
                      rules="required"
                      validation-container-class="col-lg-4 col-md-6 col-sm-6"
                      input-class="form-control"
                      v-model="data.name"
                    />
                    <bita-input
                      type="text"
                      name="name"
                      label="نام"
                      rules="required"
                      validation-container-class="col-lg-4 col-md-6 col-sm-6"
                      input-class="form-control"
                      v-model="data.fa_name"
                    />
                    <div class="col-sm-2">
                      <button
                        class="btn btn-w-m btn-primary"
                        type="button"
                        v-if="!updating"
                        @click="add"
                        style="margin-top: 27px"
                      >
                        ذخیره
                      </button>
                      <button
                        class="btn btn-w-m btn-success"
                        type="button"
                        v-if="updating"
                        @click="update"
                        style="margin-top: 27px"
                      >
                        بروزرسانی
                      </button>
                    </div>
                  </div>
                </div>
                <div class="hr-line-dashed"></div>
              </bita-form>
            </div>
            <div class="col-lg-6" style="direction: ltr">
              <tree
                ref="permissionTree"
                v-model="permissionTree"
                :data="treePermissions"
                :options="treeOptions"
                v-if="treePermissions && treePermissions.length > 1"
              />
            </div>
          </div>
        </div>
      </bita-ibox>
      <div class="wrapper wrapper-content animated fadeInRight">
        <bita-ibox :isLoading="isLoading">
          <div slot="header">
            <div class="ibox-title">
              <h5>لیست نقش ها</h5>
            </div>
          </div>
          <div slot="body">
            <div class="col-lg-12">
              <div class="k-rtl">
                <kendo-datasource
                  ref="datasource"
                  :transport-read="getDataSource"
                  :batch="true"
                  :page-size="25"
                ></kendo-datasource>
                <kendo-grid
                  id="grid"
                  ref="grid"
                  :data-source-ref="'datasource'"
                  :groupable="grid.groupable"
                  @databinding="$onDataBinding"
                  :resizable="true"
                  :filterable="grid.filterable"
                  :column-menu="grid.columnMenu"
                  :pageable="grid.pageable"
                  :page="$gridPage"
                  :height="400"
                >
                  <kendo-grid-column
                    :title="'ردیف'"
                    :template="$getTemplate"
                    width="100px"
                  ></kendo-grid-column>
                  <kendo-grid-column
                    :field="'fa_name'"
                    :title="'نام'"
                  ></kendo-grid-column>
                  <kendo-grid-column
                    :field="'name'"
                    :title="'دسترسی'"
                  ></kendo-grid-column>
                  <kendo-grid-column
                    :command="[
                      { text: 'edit', click: edit, template: gridButtons.edit },
                      {
                        name: 'delete',
                        click: remove,
                        template: gridButtons.delete,
                      },
                    ]"
                    title="عملیات"
                  ></kendo-grid-column>
                </kendo-grid>
              </div>
            </div>
          </div>
        </bita-ibox>
      </div>
    </div>
  </div>
</template>
<script>
// import RoleForm from "./../../../components/management/RoleForm";
import BaseService from "@/scripts/BaseRoleService";
import { collect } from "collect.js";
export default {
  // components: { RoleForm },
  data() {
    return {
      breadCrumbs: [
        { title: "صفحه اصلی", link: "/" },
        { title: "مدیریت سیستم", link: "" },
        {
          title: "مدیریت نقش",
          link: "/management/roles",
          active: true,
        },
      ],
      modules: {},
      treeOptions: {
        checkbox: true,
      },
      permissionTree: [],
      treePermissions: [],
      permissionGrouped: {},
      isLoading: false,
      deleteButton:
        "<a role='button' class='k-button k-button-icontext k-grid-delete trip-confirm' style='background-color:lightcoral'><span class='fa fa-times' style='color:red'></span>حذف</a>",
      updateButton:
        "<a role='button' class='k-button k-button-icontext k-grid-edit' style='background-color:lightseagreen'><span class='fas fa-pencil' style='color:teal'></span>ویرایش</a>",
      data: {},
      permissions: [],
      updating: false,
    };
  },
  watch: {
    permissionTree(newValue) {
      let per = collect(newValue.checked)
        .where("children.length", 0)
        .pluck("id")
        .toArray();
      this.data.permissions = per.filter((i) => i);
    },
  },
  computed: {},
  mounted() {
    this.loadTree();
  },
  methods: {
    loadTree() {
      this.isLoading = true;
      Promise.all([
        BaseService.getApplicationModules(),
        BaseService.getPermissions(),
      ]).then((res) => {
        this.permissions = res[1].data.entity;
        let appModules = res[0].data.entity;
        appModules = collect(appModules)
          .groupBy("parent")
          .all();
        this.permissionGrouped = collect(this.permissions)
          .groupBy("module_id")
          .all();
        this.treePermissions = this.renderModule(appModules);

        this.isLoading = false;
      });
    },
    renderPermission(module_id) {
      let result = [];

      if (!this.permissionGrouped[module_id]) return;

      for (let [key, element] of Object.entries(
        this.permissionGrouped[module_id].items
      )) {
        result.push({
          id: element.id,
          text: element.fa_name,
        });
      }

      return result;
    },
    renderModule(modules, parent = 0) {
      let tree = [];

      if (!modules[parent]) return this.renderPermission(parent);

      for (let [key, element] of Object.entries(modules[parent].items)) {
        tree.push({
          id: element.id,
          text: element.fa_name,
          children: this.renderModule(modules, element.id),
        });
      }
      return tree;
    },
    add() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          BaseService.addRole({
            name: this.data.name,
            fa_name: this.data.fa_name,
            permissions: this.data.permissions,
          })
            .then(() => {
              this.reloadDataSource();
              this.data = {};
              this.uncheckTree();
              this.$izitoast("success", "عملیات موفق!", null, {
                position: "topRight",
                displayMode: 2,
              });
              this.$refs.form.reset();
            })
            .catch((err) => {
              this.$catchErrors(err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    // update() {
    //   this.isLoading = true;
    //   BaseService.updateRole({
    //     id: this.data.id,
    //     name: this.data.name,
    //     permissions: this.data.permissions,
    //   })
    //     .then(() => {
    //       this.$izitoast("success", "عملیات موفق!", null, {
    //         position: "topRight",
    //         displayMode: 2,
    //       });
    //       this.reloadDataSource();
    //       this.data = {};
    //       this.uncheckTree();
    //       this.updating = false;
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
    reloadDataSource() {
      this.$refs.grid.kendoWidget().dataSource.read();
    },
    uncheckTree() {
      let selection = this.$refs.permissionTree.findAll({
        state: { checked: true },
      });
      if (selection) {
        selection.uncheck(true);
      }
    },
    updateTree() {
      let permitNames = collect(this.permissions)
        .whereIn("id", this.data.permissions)
        .pluck("fa_name")
        .toArray();
      permitNames.forEach((element) => {
        let selection = this.$refs.permissionTree.find(element);
        if (selection) selection.check();
      });
    },
    getDataSource(e) {
      this.isLoading = true;
      BaseService.getRoles("include=permissions").then((res) => {
        e.success(res.data.entity);
        this.isLoading = false;
      });
    },
    onDataBinding: function(ev) {
      this.record = (this.gpage - 1) * this.$refs.datasource.pageSize;
    },
    remove(e) {
      e.preventDefault();
      var grid = this.$refs.grid.kendoWidget();
      var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
      this.$alert(
        "question",
        "حذف نقش",
        "آیا مطمئن هستید؟عملیات قابل بازگشت نیست!"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          BaseService.deleteRole(dataItem.id)
            .then(() => {
              this.$izitoast("success", "عملیات موفق!", null, {
                position: "topRight",
                displayMode: 2,
              });
              this.reloadDataSource();
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    update() {
      this.isLoading = true;
      BaseService.updateRole({
        id: this.data.id,
        name: this.data.name,
        fa_name: this.data.fa_name,
        permissions: this.data.permissions,
      })
        .then(() => {
          this.$izitoast("success", "عملیات موفق!", null, {
            position: "topRight",
            displayMode: 2,
          });
          this.reloadDataSource();
          this.data = {};
          this.uncheckTree();
          this.updating = false;
          this.$refs.form.reset();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    edit(e) {
      this.uncheckTree();
      e.preventDefault();
      var grid = this.$refs.grid.kendoWidget();
      var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
      this.data = {
        id: dataItem.id,
        name: dataItem.name,
        fa_name: dataItem.fa_name,
      };
      // this.editTree = dataItem.permissions; //برای ویرایش کردن نیاز به نام پرمیشن هست پس کلا آش با جاش میریزیم در این متغیر
      let permit = collect(dataItem.permissions)
        .pluck("id")
        .toArray();
      this.data.permissions = permit.filter((i) => i); //بدلیل اینکه دیده شد کالکت مقادیری نال هم دارد باید فقط آنها که نال نیستند ریخته شود
      this.updating = true;
      this.updateTree();
      $("html, body").animate({ scrollTop: 0 }, "slow");
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in;
}
.fade-leave {
  opacity: 0.2;
}
</style>
