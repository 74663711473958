import { apiCall, createQueryString } from "../imports"

const PREFIX = "shop/features"

export default {

    find(id) {
        return apiCall('get', `${PREFIX}/${id}`)
    },

    get(filters) {
        return apiCall('get', `${PREFIX}${createQueryString(filters)}`)
    },

    create(data) {
        return apiCall('post', `${PREFIX}`, data)
    },

    update(id, data) {
        return apiCall('patch', `${PREFIX}/${id}`, data)
    },

    delete(id) {
        return apiCall('delete', `${PREFIX}/${id}`)
    },


    getFeatureValues(featureID) {
        return apiCall('get', `${PREFIX}/${featureID}/values`)
    },
    createFeatureValue(featureID, data) {
        return apiCall('post', `${PREFIX}/${featureID}/values`, data)
    },
    updateFeatureValue(id, data) {
        return apiCall('patch', `${PREFIX}/values/${id}`, data)
    },
    deleteFeatureValue(id) {
        return apiCall('delete', `${PREFIX}/values/${id}`)
    },
}